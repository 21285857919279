import { gql } from "@outschool/ui-apollo";

import { ClassPostWithOnlyCommentsFragment } from "../fragments/Classroom";

export const allClassPostCommentsQuery = gql`
  query AllClassPostCommentsQuery(
    $sectionUid: ID!
    $classPostUid: ID!
    $plainText: Boolean
  ) {
    classroom(sectionUid: $sectionUid) {
      uid
      postByUid(classPostUid: $classPostUid) {
        ...ClassPost_classPostWithOnlyComments
      }
    }
  }

  ${ClassPostWithOnlyCommentsFragment}
`;
