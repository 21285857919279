import { Box, Image } from "@outschool/backpack";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import appleSrc from "../../../../images/club/previewAvatars/apple.png";
import astronautSrc from "../../../../images/club/previewAvatars/astronaut.png";
import donutSrc from "../../../../images/club/previewAvatars/donut.png";
import marsupialSrc from "../../../../images/club/previewAvatars/marsupial.png";
import monkeySrc from "../../../../images/club/previewAvatars/monkey.png";
import monster1Src from "../../../../images/club/previewAvatars/monster-1.png";
import monster2Src from "../../../../images/club/previewAvatars/monster-2.png";
import mugSrc from "../../../../images/club/previewAvatars/mug.png";
import starSrc from "../../../../images/club/previewAvatars/star.png";
import turtleSrc from "../../../../images/club/previewAvatars/turtle.png";

export const SAMPLE_CLUB_POST_AVATAR_URLS = {
  "apple-preview-avatar": appleSrc,
  "astronaut-preview-avatar": astronautSrc,
  "donut-preview-avatar": donutSrc,
  "marsupial-preview-avatar": marsupialSrc,
  "monkey-preview-avatar": monkeySrc,
  "monster-preview-avatar-1": monster1Src,
  "monster-preview-avatar-2": monster2Src,
  "mug-preview-avatar": mugSrc,
  "star-preview-avatar": starSrc,
  "turtle-preview-avatar": turtleSrc,
};

interface SampleClubPostAvatar {
  avatar: keyof typeof SAMPLE_CLUB_POST_AVATAR_URLS;
  width?: number;
  height?: number;
  sx?: SystemStyleObject;
}

export default function SampleClubPostAvatar({
  avatar,
  width = 36,
  height = 36,
  sx = {},
}: SampleClubPostAvatar) {
  const avatarSrc =
    typeof SAMPLE_CLUB_POST_AVATAR_URLS[avatar] === "string"
      ? SAMPLE_CLUB_POST_AVATAR_URLS[avatar]
      : SAMPLE_CLUB_POST_AVATAR_URLS[avatar].src;
  return (
    <Box sx={sx}>
      <Image
        src={avatarSrc}
        alt=""
        sx={{
          width,
          height,
        }}
        height={height}
        width={width}
      />
    </Box>
  );
}
