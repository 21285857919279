import { SystemStyleObject } from "@styled-system/css";

export const CLASSROOM_BOX_MAX_WIDTH = "1120px";
export const CLASSROOM_BOX_SX: SystemStyleObject = {
  maxWidth: CLASSROOM_BOX_MAX_WIDTH,
  position: "relative",
  width: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  boxSizing: "border-box",
  // This SX is shared between website and shared code.
  // Only website is using MUI, so use media queries for both
  paddingX: "1em",
  // medium
  "@media screen and (min-width: 768px)": {
    paddingX: "2em",
  },
  // large
  "@media screen and (min-width: 1000px)": {
    paddingX: 0,
  },
};
