import { Box } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React, { PropsWithChildren } from "react";

const ClassDetails: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <Box
    flex
    sx={{
      flexDirection: "column",
      flexGrow: 1,
      maxWidth: "100%",
    }}
  >
    {children}
  </Box>
);

export default ClassDetails;
