import type { AttachmentFragmentFragment } from "..";

import { Box, SxProps } from "@outschool/backpack";
import { TextMimeType } from "@outschool/gql-frontend-generated";
import { AttachmentGallery } from "@outschool/ui-components-shared";
import { TextArea } from "@outschool/ui-legacy-component-library";
import {
  RichTextEditor,
  RichTextEditorHandle,
} from "@outschool/ui-rich-text-editor";
import React, { useCallback, useState } from "react";
import { MentionItem } from "react-mentions";

import InlineEditableAttachments from "./InlineEditableAttachments";
import MentionsTextArea from "./MentionsTextArea";

export interface EditableMessageContentType {
  text: string;
  htmlText?: string;
  originalMimeType?: TextMimeType;
  attachments?: AttachmentFragmentFragment[] | null;
  video?: AttachmentFragmentFragment | null;
  mentions?: MentionItem[];
}

export interface EditableMessageContentSubmitType {
  text: string;
  textMimeType?: TextMimeType;
  attachments?: AttachmentFragmentFragment[] | null;
  video?: AttachmentFragmentFragment | null;
  mentions?: MentionItem[];
}

interface EditableMessageContentProps {
  placeholder?: string;
  messageContent?: EditableMessageContentType | null;
  collapsedUntilFocused?: boolean;
  onSubmit: (messageContent: EditableMessageContentSubmitType) => void;
  renderButton?: (props: {
    disabled: boolean;
    reset: () => void;
    submit: () => void;
  }) => JSX.Element;
  autoFocus?: boolean;
  classMentionsData?: any[];
  showAttachments?: boolean;
  expandedRows?: number;
  inClassContext?: boolean;
  maxLength?: number;
  sx?: SxProps;
  useNewMessagingDesign?: boolean;
  allowRichText?: boolean;
  /**
   * Whether to show the video upload option in the attachments menu
   * @default true
   */
  showVideoUpload?: boolean;
}

export default function EditableMessageContent({
  placeholder,
  messageContent,
  collapsedUntilFocused,
  onSubmit,
  renderButton,
  autoFocus,
  classMentionsData = [],
  showAttachments = true,
  expandedRows = 3,
  inClassContext = false,
  maxLength,
  sx = {},
  useNewMessagingDesign = false,
  allowRichText = false,
  showVideoUpload = true,
}: EditableMessageContentProps) {
  const [text, setText] = useState(
    allowRichText ? messageContent?.htmlText || "" : messageContent?.text || ""
  );
  const [attachments, setAttachments] = useState(
    messageContent?.attachments || []
  );
  const [video, setVideo] = useState(messageContent?.video || null);
  const [isFocused, updateFocused] = useState(false);

  const [mentions, setMentions] = useState<MentionItem[]>([]);

  const richTextEditorRef = React.useRef<RichTextEditorHandle>(null);

  const reset = React.useCallback(() => {
    setText("");
    richTextEditorRef.current?.setHtml("");
    setMentions([]);
    setAttachments([]);
    setVideo(null);
    updateFocused(false);
  }, [setText, setMentions, setAttachments, setVideo, updateFocused]);

  React.useEffect(() => {
    if (!messageContent) {
      reset();
    } else {
      setText(messageContent.text || "");
      richTextEditorRef.current?.setHtml(
        messageContent.htmlText || messageContent.text || ""
      );
      setAttachments(messageContent.attachments || []);
      setVideo(messageContent.video || null);
    }
  }, [messageContent, reset]);

  const deleteAttachment = useCallback(
    (attachment: AttachmentFragmentFragment) => {
      if (video && video.uid === attachment.uid) {
        setVideo(null);
      } else {
        setAttachments(attachments.filter(a => a.uid !== attachment.uid));
      }
    },
    [attachments, video]
  );

  const disabled = !(text.trim().length > 0 || attachments.length > 0 || video);
  const textMimeType = allowRichText
    ? TextMimeType.TextHtml
    : TextMimeType.TextPlainWithMentions;

  const expanded = !collapsedUntilFocused || isFocused || !disabled;
  const onKeyDown = ({ metaKey, key }: React.KeyboardEvent<HTMLElement>) => {
    if (metaKey && key == "Enter") {
      onSubmit({ text, textMimeType, attachments, video });
      reset();
    }
  };
  const showTextAreaAndButtonSideBySide =
    useNewMessagingDesign && attachments.length === 0 && !video;
  return (
    <Box
      sx={[
        {
          display: showTextAreaAndButtonSideBySide ? "flex" : "auto",
          alignItems: showTextAreaAndButtonSideBySide ? "center" : "normal",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {allowRichText ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            backgroundColor: "common.white",
            width: "100%",
          }}
        >
          <RichTextEditor
            ref={richTextEditorRef}
            content={text}
            placeholder={placeholder}
            onChange={(html: string) => {
              setText(html);
            }}
          />
          {showAttachments && (
            <Box>
              <InlineEditableAttachments
                showVideoUpload={showVideoUpload}
                value={attachments}
                video={video}
                onChange={setAttachments}
                onVideoChanged={setVideo}
                useTextButton
              />
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={[
            {
              display: "flex",
              alignItems: "flex-start",
              backgroundColor: "common.white",
              padding: "0.5em",
              border: "1px solid",
              borderRadius: 4,
              borderColor: "grey.100",
              width: "100%",
            },
            useNewMessagingDesign
              ? {
                  borderRadius: "8px",
                  borderColor: "grey.500",
                  marginRight: "10px",
                }
              : {},
          ]}
        >
          {showAttachments && (
            <InlineEditableAttachments
              showVideoUpload={showVideoUpload}
              value={attachments}
              video={video}
              onChange={setAttachments}
              onVideoChanged={setVideo}
              sx={{ padding: 8 }}
            />
          )}
          <Box
            sx={{
              flexGrow: 1,
              alignSelf: "center",
              width: "100%",
            }}
          >
            {inClassContext ? (
              <MentionsTextArea
                sx={{ width: "calc(100% - 0.5em)", border: "none" }}
                value={text}
                placeholder={placeholder}
                mentionsData={classMentionsData}
                onKeyDown={onKeyDown}
                onChange={(_e, newValue, _newPlainTextValue, mentions) => {
                  setText(newValue);
                  setMentions(mentions);
                }}
                maxLength={maxLength}
              />
            ) : (
              <TextArea
                sx={{ width: "calc(100% - 0.5em)", border: "none" }}
                showCount={false}
                // eslint-disable-next-line
                autoFocus={autoFocus}
                data-private
                placeholder={placeholder}
                value={text}
                maxRows={expanded ? expandedRows : 0}
                onFocus={() => {
                  updateFocused(true);
                }}
                onChange={e => {
                  setText(e.currentTarget.value);
                }}
                onKeyDown={onKeyDown}
                maxLength={maxLength}
              />
            )}
          </Box>
        </Box>
      )}
      {(video || attachments.length > 0) && (
        <Box
          sx={{
            marginTop: 8,
            minHeight: "64px",
          }}
        >
          <AttachmentGallery
            video={video}
            attachments={attachments}
            numPrimaryAttachments={0}
            numSecondaryAttachments={5}
            onDelete={deleteAttachment}
          />
        </Box>
      )}
      <Box
        flex
        sx={{
          justifyContent: "right",
          marginTop: showTextAreaAndButtonSideBySide ? 0 : 8,
        }}
      >
        {renderButton &&
          renderButton({
            disabled,
            reset,
            submit: () => {
              onSubmit({ text, textMimeType, attachments, video, mentions });
            },
          })}
      </Box>
    </Box>
  );
}
