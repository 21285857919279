import { gql } from "@outschool/ui-apollo";

import { AttachmentFragment } from "../fragments/Attachment";

export const AddAttachmentMutation = gql`
  mutation addAttachment($file: FileInput!, $convert: Boolean) {
    addAttachment(file: $file, convert: $convert) {
      ...AttachmentFragment
    }
  }
  ${AttachmentFragment}
`;
