import { PaletteColorOptions, createTheme } from "@mui/material";
import {
  ACCORDION_DETAILS_THEME,
  ACCORDION_SUMMARY_THEME,
  ACCORDION_THEME,
} from "../components/Accordion/Accordion.theme";
import {
  ALERT_THEME,
  ALERT_TITLE_THEME,
} from "../components/Alert/Alert.theme";
import BUTTON_THEME from "../components/Button/Button.theme";
import BUTTON_GROUP_THEME from "../components/ButtonGroup/ButtonGroup.theme";
import CHECKBOX_THEME from "../components/Checkbox/Checkbox.theme";
import CHIP_THEME from "../components/Chip/Chip.theme";
import { PICKERS_DAY_THEME } from "../components/DatePicker/DatePicker.theme";
import { ARROW_SWITCHER_THEME } from "../components/DateTimePicker/DateTimePicker.theme";
import ICON_THEME from "../components/Icon/Icon.theme";
import { ICON_BUTTON_THEME } from "../components/IconButton/IconButton.theme";
import { BaseImage } from "../components/Image/BaseImage";
import { ImageType } from "../components/Image/types";
import { MENU_ITEM_THEME, MENU_THEME } from "../components/Menu/Menu.theme";
import { MODAL_THEME } from "../components/Modal/Modal.theme";
import POPPER_THEME from "../components/Popper/Popper.theme";
import { RADIO_THEME } from "../components/Radio/Radio.theme";
import {
  SNACKBAR_CONTENT_THEME,
  SNACKBAR_THEME,
} from "../components/Snackbar/Snackbar.theme";
import { STEPPER_THEME } from "../components/Stepper/Stepper.theme";
import { SWITCH_THEME } from "../components/Switch/Switch.theme";
import { TAB_PANEL_THEME, TAB_THEME } from "../components/Tabs/Tabs.theme";
import {
  OUTLINED_INPUT_THEME,
  TEXT_FIELD_THEME,
} from "../components/TextField/TextField.theme";
import { MULTI_SECTION_DIGITAL_CLOCK_SECTION_THEME } from "../components/TimePicker/TimePicker.theme";
import TOOLTIP_THEME from "../components/Tooltip/Tooltip.theme";
import TYPOGRAPHY_THEME, {
  applyAdditionalTypographyTheme,
} from "../components/Typography/Typography.theme";
import pxToRem from "../utils/pxToRem";
import {
  apple,
  bubblegum,
  gray,
  peacock,
  redhot,
  sunshine,
  waterslide,
} from "./colors";

/**
 * These imports extend the Theme type to include components from other MUI packages, such as Lab and X.
 */
import type {} from "@mui/lab/themeAugmentation"; // https://mui.com/material-ui/about-the-lab/#typescript
import type {} from "@mui/x-date-pickers/themeAugmentation"; // https://mui.com/x/react-date-pickers/base-concepts/#typescript
import PROGRESS_BAR_THEME from "../components/ProgressBar/ProgressBar.theme";
import { SLIDER_THEME } from "../components/Slider/Slider.theme";
import { DATE_RANGE_PICKER_DAY_THEME } from "../components/DateRangePicker/DateRangePicker.theme";
import { DATA_GRID_PRO_THEME } from "../components/DataGrid/DataGrid.theme";
import { DataGridProProps } from "@mui/x-data-grid-pro";
import { DateRangePickerDayProps } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: PaletteColor;
  }
  interface PaletteOptions {
    neutral?: PaletteColorOptions;
  }

  interface Gradients {
    primarySecondary?: string;
  }

  interface Theme {
    // Make optional because there's no guarantee that the theme will have gradients, depending on how it's rendered.
    // e.g. in tests it uses a default theme that doesn't contain this property
    gradients?: Gradients;
  }

  interface ThemeOptions {
    gradients?: Gradients;
  }
}

declare module "@mui/material" {
  /**
   * Custom component theming
   */
  // Image
  interface Components {
    MuiImage?: {
      BaseComponent: ImageType;
    };
    MuiDataGrid?: {
      defaultProps?: DataGridProProps["MuiDataGrid"];
    };
    MuiDateRangePickerDay?: {
      defaultProps?: DateRangePickerDayProps<Dayjs>["MuiDateRangePickerDay"];
    };
  }
}

let THEME = createTheme({
  breakpoints: {
    unit: "px",
    values: {
      xs: 0,
      sm: 575,
      md: 768,
      lg: 1000,
      xl: 1440,
    },
  },
  components: {
    MuiAccordion: ACCORDION_THEME,
    MuiAccordionDetails: ACCORDION_DETAILS_THEME,
    MuiAccordionSummary: ACCORDION_SUMMARY_THEME,
    MuiAlert: ALERT_THEME,
    MuiAlertTitle: ALERT_TITLE_THEME,
    MuiButton: BUTTON_THEME,
    MuiButtonGroup: BUTTON_GROUP_THEME,
    MuiCheckbox: CHECKBOX_THEME,
    MuiChip: CHIP_THEME,
    MuiDataGrid: DATA_GRID_PRO_THEME,
    MuiDateRangePickerDay: DATE_RANGE_PICKER_DAY_THEME,
    MuiIconButton: ICON_BUTTON_THEME,
    MuiLinearProgress: PROGRESS_BAR_THEME,
    MuiMenu: MENU_THEME,
    MuiMenuItem: MENU_ITEM_THEME,
    MuiMobileStepper: STEPPER_THEME,
    MuiModal: MODAL_THEME,
    MuiMultiSectionDigitalClockSection:
      MULTI_SECTION_DIGITAL_CLOCK_SECTION_THEME,
    MuiOutlinedInput: OUTLINED_INPUT_THEME,
    MuiPickersArrowSwitcher: ARROW_SWITCHER_THEME,
    MuiPickersDay: PICKERS_DAY_THEME,
    MuiPopper: POPPER_THEME,
    MuiRadio: RADIO_THEME,
    MuiSlider: SLIDER_THEME,
    MuiSnackbar: SNACKBAR_THEME,
    MuiSnackbarContent: SNACKBAR_CONTENT_THEME,
    MuiSvgIcon: ICON_THEME,
    MuiSwitch: SWITCH_THEME,
    MuiTab: TAB_THEME,
    MuiTabPanel: TAB_PANEL_THEME,
    MuiTextField: TEXT_FIELD_THEME,
    MuiTooltip: TOOLTIP_THEME,
    MuiTypography: TYPOGRAPHY_THEME,
  },
  palette: {
    common: {
      black: gray[950],
      white: gray[0],
    },
    primary: {
      light: peacock[300],
      main: peacock[700],
      dark: peacock[800],
      contrastText: gray[0],
      ...peacock,
    },
    secondary: {
      light: bubblegum[200],
      main: bubblegum[400],
      dark: bubblegum[700],
      contrastText: gray[0],
      ...bubblegum,
    },
    error: {
      light: redhot[200],
      main: redhot[500],
      dark: redhot[700],
      contrastText: gray[0],
      ...redhot,
    },
    warning: {
      light: sunshine[200],
      main: sunshine[500],
      dark: sunshine[700],
      contrastText: gray[0],
      ...sunshine,
    },
    info: {
      light: waterslide[200],
      main: waterslide[500],
      dark: waterslide[700],
      contrastText: gray[0],
      ...waterslide,
    },
    success: {
      light: apple[200],
      main: apple[500],
      dark: apple[700],
      contrastText: gray[0],
      ...apple,
    },
    grey: gray,
    neutral: {
      light: gray[200],
      main: gray[500],
      dark: gray[800],
      contrastText: gray[0],
      ...gray,
    },
    text: {
      primary: gray[800],
      secondary: gray[600],
      disabled: gray[400],
    },
  },
  shape: {
    // borderRadius values get multiplied by this value (e.g. borderRadius: 4 -> borderRadius: 4 * theme.shape.borderRadius)
    borderRadius: 1,
  },
  shadows: new Array(25).fill("none") as any,
  spacing: pxToRem,
  typography: {
    fontFamily: ["Ginto Normal", "sans-serif"].join(","),
    // Match htmlFontSize to what we use on website
    htmlFontSize: 10,
    button: {
      fontFamily: "Ginto Normal",
      fontWeight: 500, // medium
      textTransform: "none", // reset base MUI style
    },
  },
});

// Define custom component theming after the theme is created
// styled() uses withTheme internally and will create a circular dependency
if (THEME.components) {
  THEME.components.MuiImage = {
    // Default Image component
    BaseComponent: BaseImage,
  };
}

// Define gradients after the theme is created so we can use the theme.palette values
THEME.gradients = {
  primarySecondary: `linear-gradient(118deg, ${THEME.palette
    .primary[50]!} 31.38%, ${THEME.palette.secondary[50]!} 162.62%)`,
};

THEME = applyAdditionalTypographyTheme(THEME);

export default THEME;
