import {
  AvatarImage,
  DEFAULT_AVATAR,
  UserHeadshotImage,
} from "@outschool/ui-components-shared";
import { BoxButton } from "@outschool/ui-legacy-component-library";
import { useLinkComponent } from "@outschool/ui-utils";
import React, { FC } from "react";

import { Sender } from "../types";

function isLearner(user: Sender) {
  if (user.avatar) {
    return true;
  } else if (!user.photo) {
    return true;
  }
  return false;
}

interface ImageComponentProps {
  sender: Sender;
  size: number;
}

const ImageComponent: FC<ImageComponentProps> = ({ sender, size }) =>
  isLearner(sender) ? (
    <AvatarImage
      avatar={sender.avatar ?? DEFAULT_AVATAR}
      width={size}
      height={size}
    />
  ) : (
    <UserHeadshotImage user={sender} size={size} />
  );

export interface UserAvatarProps {
  sender: Sender;
  size: number;
  onClick?(user: Sender): void;
  href?: string;
}

const UserAvatar: FC<UserAvatarProps> = ({ sender, size, onClick, href }) => {
  const Link = useLinkComponent();

  if (onClick) {
    return (
      <BoxButton onClick={() => onClick(sender)}>
        <ImageComponent sender={sender} size={size} />
      </BoxButton>
    );
  }
  if (href) {
    return (
      <Link to={href}>
        <ImageComponent sender={sender} size={size} />
      </Link>
    );
  }

  return <ImageComponent sender={sender} size={size} />;
};

export default UserAvatar;
