/**
 * This file was generated by the `pnpm new` component script.
 *
 * THIS SKELETON TEMPLATE IS ONLY FOR QUICKSTART PURPOSES!
 * Make sure to review and test all code after filling in the skeleton!
 */

import React from "react";
import MUILinearProgress, {
  LinearProgressProps as MUILinearProgressProps,
} from "@mui/material/LinearProgress";

export interface ProgressBarProps extends MUILinearProgressProps {}

export function ProgressBar(props: ProgressBarProps) {
  return <MUILinearProgress {...props}></MUILinearProgress>;
}
