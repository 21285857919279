import { Box, Image, Typography } from "@outschool/backpack";
import { imageUrl } from "@outschool/filestack-urls";
import SectionCls from "@outschool/section";
import { pastDurationString } from "@outschool/time";
import { useIsMobile } from "@outschool/ui-utils";
import React, { FC } from "react";

const Section = new SectionCls();

interface ClassHeaderDetailsProps {
  section: {
    start_time: Date;
    end_time: Date;
    nextOngoingMeeting?: {
      start_time: Date;
    } | null;
  };
  activity: {
    title: string;
    published_at: Date | null;
    isClub: boolean;
    is_ongoing_weekly: boolean;
    weekly_meetings: number | null;
    details: {
      photo: string | null;
    };
  };
  userTimeZone: string;
}

const ClassHeaderDetails: FC<ClassHeaderDetailsProps> = ({
  activity,
  section,
  userTimeZone,
}) => {
  const isMobile = useIsMobile();
  const url = activity.details.photo
    ? imageUrl(activity.details.photo, {
        w: 750,
        h: 395,
        fit: "crop",
      })
    : undefined;

  return (
    <Box
      flex
      sx={{
        flexDirection: "row",
        alignItems: "stretch",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Typography variant="h3">{activity.title}</Typography>
        <div>
          {Section.isPast(section)
            ? pastDurationString(
                section.start_time,
                section.end_time,
                userTimeZone
              )
            : Section.fullDurationString({
                activity,
                section,
                nextOngoingMeeting: section.nextOngoingMeeting,
                userTimeZone,
              })}
        </div>
      </div>
      {!isMobile && (
        <Image
          alt={activity.title}
          src={url}
          sx={{
            maxHeight: "7em",
            marginLeft: "1em",
          }}
        />
      )}
    </Box>
  );
};

export default ClassHeaderDetails;
