import type { SystemStyleObject } from "@styled-system/css";

import { useComponentTrackingContext } from "@outschool/ui-legacy-component-library";
import { stringToPositiveHash, useMotionSx } from "@outschool/ui-utils";
import React from "react";

import shockerIcon from "../../../images/learner/icons/shocker.svg";
import { useClubContext } from "../../providers/ClubProvider";

export const MAX_COMMENT_CHAR_COUNT = 280;
export const CONTENT_BOX_BORDER_RADIUS = 16;
export const COMMENT_BOX_RATIO = 3 / 4;
// Pick a random color, but always the same one for the same sender uid
export function getClubContentBackgroundColor({
  senderUid,
  clubBackgroundColors = ["#E1E3E8"],
}: {
  senderUid: string;
  clubBackgroundColors?: string[];
}) {
  return clubBackgroundColors[
    stringToPositiveHash(senderUid) % clubBackgroundColors.length
  ];
}

export const COMMENT_BOX_SHADOW_CSS_VARIABLE_NAME = "--card-box-shadow";
export const COMMENT_REPLY_OFFSET_CSS_VARIABLE_NAME = "--comment-reply-offset";

export function useClubCommentBoxSx({
  hasCommentReplies,
}: {
  hasCommentReplies: boolean;
}) {
  const motionSx = useMotionSx();
  return {
    position: "relative",
    display: "flex",
    cursor: "pointer",

    [COMMENT_BOX_SHADOW_CSS_VARIABLE_NAME]: "1px 1px 2px rgba(0, 0, 0, 0.18)",
    [COMMENT_REPLY_OFFSET_CSS_VARIABLE_NAME]: "4px",
    ...motionSx({
      transition: "transform 0.2s ease-in",
      "&:hover": {
        [COMMENT_REPLY_OFFSET_CSS_VARIABLE_NAME]: hasCommentReplies
          ? "8px"
          : "4px",
        [COMMENT_BOX_SHADOW_CSS_VARIABLE_NAME]:
          "3px -4px 4px rgba(0, 0, 0, 0.13)",
        transform: `translate(
            calc(-1 * var(${COMMENT_REPLY_OFFSET_CSS_VARIABLE_NAME})),
            var(${COMMENT_REPLY_OFFSET_CSS_VARIABLE_NAME})
          )`,
      },
    } as SystemStyleObject),
  } as SystemStyleObject;
}

export const CLUB_INTRODUCTION_VIDEO_PLAYBACK_ID =
  "euXFb7FsnD49S006Sm2U6p5K01UGK3aTKv";
// The video has a boring first frame, so we're using a different thumbnail
// so it looks better
export const CLUB_INTRODUCTION_VIDEO_THUMBNAIL_URL = `https://image.mux.com/${CLUB_INTRODUCTION_VIDEO_PLAYBACK_ID}/thumbnail.jpg?time=26`;

// Pick a random color, but always the same one for the same sender uid
export function useClubPostAndCommentDefaultBackgroundColor({
  senderUid,
}: {
  senderUid: string;
}) {
  const { useClubPostAndCommentBackgroundColors } = useClubContext();
  const { data, loading, error } = useClubPostAndCommentBackgroundColors();
  const clubBackgroundColors = data?.clubPostAndCommentBackgroundColors ?? [];

  if (loading || error || clubBackgroundColors.length === 0) {
    return "#E1E3E8";
  }

  return getClubContentBackgroundColor({ senderUid, clubBackgroundColors });
}

export function useClubEventTracking({ sectionUid }: { sectionUid: string }) {
  const track = useComponentTrackingContext();
  return React.useCallback(
    (name: string, properties: { [propName: string]: any } = {}) => {
      track(
        name,
        { ...properties, sectionUid },
        { integrations: { All: false } }
      );
    },
    [track, sectionUid]
  );
}

export const clubBackground =
  "radial-gradient(134.14% 64.19% at 52.94% 94%, #FFF1E7 0%, rgba(254, 252, 251, 0) 100%), radial-gradient(56.54% 80.08% at 95.57% 38.23%, #E7F9F5 0%, rgba(241, 252, 250, 0) 100%), radial-gradient(79.58% 77.29% at 12.06% 33.83%, #F0F4FF 0%, rgba(253, 253, 254, 0) 100%), #FFFFFF";

export const emojiIcon = shockerIcon;

export const CLUB_THREAD_TEXT_AREA_SX: SystemStyleObject = {
  width: "100%",
  maxHeight: 300,
  backgroundColor: "#f8f8f9",
  border: "none",
  color: "#262626",
  padding: "12px 16px",
  borderRadius: 10,
  fontSize: 24,
  wordBreak: "break-word",
};

export const CLUB_THREAD_TEXT_AREA_PLACEHOLDER_SX: SystemStyleObject = {
  "textarea::placeholder": {
    color: "#808080",
    fontWeight: "bold",
  },
};

export type NewCommentType = "text" | "reaction";
