/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Image } from "@outschool/backpack";
import * as Enrollment from "@outschool/enrollment";
import * as FilestackUrls from "@outschool/filestack-urls";
import {
  AttachmentFragmentFragment,
  CurrentLearnerFragmentFragment,
  CurrentUserQueryQuery,
  PrivateClassMessageFragmentFragment,
  PrivateClassMessagesQuery,
  PrivateClassMessagesQueryVariables,
} from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import * as Time from "@outschool/time";
import {
  PureQueryOptions,
  useMutation,
  useQueryWithPreviousData,
} from "@outschool/ui-apollo";
import {
  AttachmentGallery,
  AvatarImage,
  DEFAULT_AVATAR,
  ErrorMessage,
  TextWithLinks,
  TrackedButton,
} from "@outschool/ui-components-shared";
import { BreakNewlines, Loading } from "@outschool/ui-legacy-component-library";
import {
  addTypeName,
  getScrollParent,
  useDevicePixelRatio,
  useIsMobile,
  validOptimisticResponse,
} from "@outschool/ui-utils";
import React, { useEffect, useRef } from "react";

import {
  addPrivateClassMessageMutation,
  markPrivateClassMessageThreadAsReadMutation,
} from "../graphql/mutations";
import { privateClassMessagesQuery } from "../graphql/queries";
import { useMarkAsRead } from "../hooks";
import EditableMessageContent, {
  EditableMessageContentSubmitType,
} from "./EditableMessageContent";

type NarrowedSection = {
  uid: string;
  usersCanSendPrivateMessage: boolean;
};

type NarrowedLearner = {
  uid: string;
  name: string | null;
  avatar: string | null;
};

type NarrowedUser = {
  uid: string;
  name: string | null;
  photo: string | null;
};

type Sender = NarrowedLearner | NarrowedUser;

type NarrowedEnrollment = {
  learner?: NarrowedLearner | null;
  usersCanCommunicate?: boolean;
  withdrawn_at: any | null;
};

interface PrivateClassMessagesProps {
  isTeacher?: boolean;
  currentUser?: CurrentUserQueryQuery["currentUser"];
  section: NarrowedSection;
  enrollment: NarrowedEnrollment;
  placeholder: string;
  emptyText: string;
  allowBodyScroll: boolean;
  userTimeZone?: string;
  onAutoScroll?: () => void;
  currentLearner?: CurrentLearnerFragmentFragment | null;
  isLearnerMode?: boolean;
  refetchQueriesAfterMarkAsRead?: PureQueryOptions[];
}

export default function PrivateClassMessages({
  isTeacher,
  currentUser = null,
  section,
  enrollment,
  placeholder,
  emptyText,
  allowBodyScroll,
  userTimeZone,
  onAutoScroll,
  currentLearner = null,
  isLearnerMode = false,
  refetchQueriesAfterMarkAsRead = [],
}: PrivateClassMessagesProps) {
  const { t } = useTranslation("ui-components-classroom\\PrivateClassMessages");

  const learner = enrollment.learner || currentLearner;

  const queryVariables = {
    sectionUid: section?.uid,
    learnerUid: learner?.uid,
    hasLearner: true,
  };

  const sender: Sender | null =
    isLearnerMode || !isTeacher ? learner : currentUser;

  const canPost =
    section?.usersCanSendPrivateMessage && !!enrollment?.usersCanCommunicate;

  const { data, loading } = useQueryWithPreviousData(
    privateClassMessagesQuery,
    {
      fetchPolicy: "cache-and-network",
      variables: queryVariables,
    }
  );

  const { privateClassMessages } = data || {};

  useMarkAsRead({
    markerMutation: markPrivateClassMessageThreadAsReadMutation,
    thread: privateClassMessages &&
      privateClassMessages[0] && {
        // we don't actually have a thread here, just an array of messages
        // so we construct this synthetic thread for the marker to mark based on
        uid: privateClassMessages[0].thread_uid,
        isRead: false,
      },
    refetchQueries: refetchQueriesAfterMarkAsRead,
  });

  if (loading || !sender || !learner) {
    return (
      <Box
        flex
        sx={{
          flexDirection: "column",
          flexGrow: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </Box>
    );
  }

  const footer = Enrollment.isWithdrawn(enrollment) ? (
    <Box
      sx={{
        padding: "2em",
        textAlign: "center",
        borderTop: "1px solid border",
        color: "grey.500",
      }}
    >
      {t("{{learnerName}} was withdrawn on {{date}}", {
        learnerName: learner?.name,
        date: Time.formatDate(enrollment.withdrawn_at, userTimeZone),
      })}
    </Box>
  ) : null;

  return (
    <Box
      flex
      sx={{
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <>
        {privateClassMessages?.length || Enrollment.isWithdrawn(enrollment) ? (
          <PrivateClassMessageList
            allowBodyScroll={allowBodyScroll}
            privateClassMessages={privateClassMessages}
            footer={footer}
            onAutoScroll={onAutoScroll}
          />
        ) : (
          <Box
            flex
            sx={{
              alignItems: "center",
              justifyContent: "center",
              color: "grey.500",
              flexGrow: 1,
              minHeight: "240px",
            }}
          >
            {emptyText}
          </Box>
        )}
        {canPost && (
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
            }}
          >
            <PrivateClassMessageForm
              sender={sender}
              section={section}
              learner={learner}
              placeholder={placeholder}
              threadUid={privateClassMessages?.[0]?.thread_uid}
            />
          </Box>
        )}
      </>
    </Box>
  );
}

interface PrivateClassMessageFormProps {
  sender: Sender;
  section: NarrowedSection;
  learner: NarrowedLearner;
  placeholder: string;
  threadUid: string;
}

function PrivateClassMessageForm({
  sender,
  section,
  learner,
  placeholder,
  threadUid,
}: PrivateClassMessageFormProps) {
  const { t } = useTranslation("ui-components-classroom\\PrivateClassMessages");
  const isMobile = useIsMobile();
  const [error, setError] = React.useState<string | null>(null);
  const queryVariables = {
    sectionUid: section.uid,
    learnerUid: learner.uid,
    hasLearner: true,
  };
  const [addPrivateClassMessage] = useMutation(addPrivateClassMessageMutation, {
    update: (
      cache,
      { data: { addPrivateClassMessage: newPrivateClassMessage } }
    ) => {
      const data = cache.readQuery<
        PrivateClassMessagesQuery,
        PrivateClassMessagesQueryVariables
      >({
        query: privateClassMessagesQuery,
        variables: queryVariables,
      });
      const privateClassMessages = (data?.privateClassMessages ?? []).concat(
        newPrivateClassMessage
      );
      cache.writeQuery({
        query: privateClassMessagesQuery,
        variables: queryVariables,
        data: {
          privateClassMessages,
        },
      });
    },
  });

  const handleSubmit = ({
    text,
    attachments,
    video,
  }: EditableMessageContentSubmitType) => {
    const attachmentUids = attachments
      ? attachments.map((a: AttachmentFragmentFragment) => a.uid)
      : [];
    const videoUid = video ? video.uid : undefined;
    addPrivateClassMessage({
      variables: {
        privateClassMessageInput: {
          sectionUid: section.uid,
          learnerUid: learner.uid,
          threadUid,
          messageContentInput: {
            text,
            attachmentUids,
            videoUid,
          },
        },
      },
      optimisticResponse: validOptimisticResponse({
        __typename: "Mutation",
        addPrivateClassMessage: {
          __typename: "PrivateClassMessage",
          uid: new Date(),
          thread_uid: threadUid || new Date(),
          sender: {
            __typename: "Participant",
            uid: sender.uid,
            name: sender.name,
            photo: (sender as NarrowedUser).photo || "",
            avatar: (sender as NarrowedLearner).avatar || "",
          },
          messageContent: {
            __typename: "MessageContent",
            uid: new Date(),
            text,
            attachments: attachments
              ? addTypeName("Attachment", attachments)
              : null,
            video: video ? addTypeName("Attachment", video) : null,
            editedAt: null,
          },
          sentAt: new Date(),
        },
      }),
    }).then(
      data => {},
      error => {
        setError(error);
      }
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f8f8f9",
        padding: "0.5em",
        borderTop: "1px solid",
        borderColor: "grey.100",
      }}
    >
      {error && <ErrorMessage value={error} />}
      <EditableMessageContent
        expandedRows={10}
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus={!isMobile}
        placeholder={placeholder}
        onSubmit={handleSubmit}
        renderButton={({ submit, reset, disabled }) => (
          <TrackedButton
            variant="contained"
            data-test-id="private-class-message-submit"
            trackingName="send_message"
            disabled={disabled}
            onClick={() => {
              submit();
              reset();
            }}
          >
            {t`Send`}
          </TrackedButton>
        )}
      />
    </Box>
  );
}

function PrivateClassMessageList({
  allowBodyScroll,
  onAutoScroll,
  privateClassMessages,
  footer,
}: {
  allowBodyScroll: boolean;
  onAutoScroll?: () => void;
  privateClassMessages: PrivateClassMessageFragmentFragment[];
  footer: React.ReactNode | null;
}) {
  const isMobile = useIsMobile();
  const scrollContainerRef = useRef<HTMLDivElement>();
  const scrollIntoViewRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (scrollIntoViewRef.current) {
      const scrollParent = getScrollParent(scrollIntoViewRef.current);
      if (
        scrollParent &&
        !(!allowBodyScroll && scrollParent === document.body)
      ) {
        scrollParent.scrollTop = scrollIntoViewRef.current.offsetTop;
        if (onAutoScroll) {
          onAutoScroll();
        }
      }
    }
  });

  return (
    <Box
      flex
      sx={{
        flexDirection: "column",
        flexGrow: 1,
        overflow: "auto",
        minHeight: isMobile ? "100vh" : "auto",
        flexBasis: !allowBodyScroll ? 0 : undefined,
      }}
      ref={scrollContainerRef}
    >
      <Box
        sx={{
          flex: "1 1 auto",
        }}
      />
      {privateClassMessages.map((privateClassMessage, i) => (
        <PrivateClassMessage
          key={privateClassMessage.uid}
          privateClassMessage={privateClassMessage}
          isFirst={i === 0}
        />
      ))}
      {footer}
      <Box ref={scrollIntoViewRef} />
    </Box>
  );
}

interface PrivateClassMessageProps {
  privateClassMessage: PrivateClassMessageFragmentFragment;
  isFirst: boolean;
}

const PrivateClassMessage = ({
  privateClassMessage: { sentAt, sender, messageContent },
  isFirst,
}: PrivateClassMessageProps) => {
  const pixelRatio = useDevicePixelRatio();
  const senderImage = sender.photo ? (
    <Image
      sx={{
        backgroundSize: "cover",
        backgroundColor: "#gray100",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        width: 30,
        height: 30,
        overflow: "hidden",
        flexShrink: 0,
        verticalAlign: "middle",
      }}
      src={FilestackUrls.headshotImageUrl(sender.photo, 48 * pixelRatio)}
      alt={sender.name ?? ""}
    />
  ) : (
    <AvatarImage
      avatar={sender.avatar || DEFAULT_AVATAR}
      name={sender.name ?? ""}
      sx={{
        width: 30,
        height: 30,
        overflow: "hidden",
        flexShrink: 0,
        verticalAlign: "middle",
      }}
    />
  );

  return (
    <Box
      sx={{
        padding: "1em",
        borderTop: isFirst ? null : "1px solid",
        borderColor: "grey.100",
      }}
    >
      <Box
        flex
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            marginRight: "0.5em",
          }}
        >
          {senderImage}
        </Box>
        <Box
          sx={{
            marginRight: "0.5em",
          }}
        >
          {sender.name}
        </Box>
        <Box
          sx={{
            color: "grey.400",
            whiteSpace: "nowrap",
          }}
        >
          {Time.fromNowCalendar(sentAt)}
        </Box>
      </Box>
      <>
        <Box
          sx={{
            marginTop: "0.5em",
          }}
        >
          <BreakNewlines sx={{ margin: 0 }}>
            <TextWithLinks
              clickableLinks={true}
              text={messageContent.text ? messageContent.text.trim() : ""}
            />
          </BreakNewlines>
        </Box>
        <AttachmentGallery
          video={messageContent.video}
          attachments={messageContent.attachments}
        />
      </>
    </Box>
  );
};
