/* eslint-disable @typescript-eslint/no-unused-vars */
import type {
  LearnerProfileQuery,
  CurrentLearnerAvatarFragmentFragment,
} from "../..";

import { Box, Button, Theme, Typography } from "@outschool/backpack";
import { gql } from "@outschool/ui-apollo";
import { Space, Modal } from "@outschool/ui-legacy-component-library";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import { useSaveLearnerProfileMutation } from "../../hooks/LearnerProfileQuery";
import { locationString } from "./LearnerProfile";
import LearnerProfileIcon from "./LearnerProfileIcon";
import SettingsModal from "./SettingsModal";
import { ErrorMessage } from "@outschool/ui-components-shared";

interface AvatarProps {
  learner: NonNullable<LearnerProfileQuery["learnerProfile"]>;
  isEditable?: boolean;
}

const CurrentLearnerAvatarFragment = gql`
  fragment CurrentLearnerAvatarFragment on CurrentLearner {
    __typename
    avatar
  }
`;

export default function Avatar({ learner, isEditable }: AvatarProps) {
  const [saveAvatar] = useSaveLearnerProfileMutation();
  const [isProfileSettingsModalOpen, setIsProfileSettingsModalOpen] =
    React.useState<boolean>(false);
  const [isProfileAgeHidden, setIsProfileAgeHidden] = React.useState<boolean>(
    learner.isProfileAgeHidden
  );
  const [isProfileLocationHidden, setIsProfileLocationHidden] =
    React.useState<boolean>(learner.isProfileLocationHidden);
  const [error, setError] = React.useState<string | null>(null);
  function onAvatarSave(avatar: string) {
    if (isEditable) {
      saveAvatar({
        variables: {
          profileInput: {
            avatar,
          },
        },
        update(store, { data }) {
          if (!data?.saveLearnerProfile) {
            return;
          }
          store.writeFragment<CurrentLearnerAvatarFragmentFragment, {}>({
            fragment: CurrentLearnerAvatarFragment,
            id: learner.uid + "CurrentLearner",
            data: {
              __typename: "CurrentLearner",
              avatar,
            },
          });
        },
      }).then(
        data => {},
        error => {
          setError(error);
        }
      );
    }
  }

  const location = learner.location && locationString(learner.location);
  return (
    <>
      <Modal open={error != null} onClose={() => setError(null)}>
        <ErrorMessage value={error} />
      </Modal>
      <Box
        flex
        sx={(theme: Theme) => ({
          maxWidth: "100%",

          [theme.breakpoints.up("md")]: {
            maxWidth: undefined,
          },

          justifyContent: "center",
        })}
      >
        <LearnerProfileIcon
          learner={learner}
          isEditable={isEditable}
          showEditIcon={false}
          iconSize={150}
          onSave={onAvatarSave}
          sx={{
            fontSize: "1.5em",
            paddingX: 16,
            marginX: "auto",
            boxShadow: "0px 12px 37px rgba(0, 0, 0, 0.08)",
            border: "none",
          }}
          avatarSx={{
            backgroundColor: "white",
            border: "none",
            boxShadow: "0px 7px 21px rgba(189, 226, 233, 0.21)",
          }}
        >
          <Space y={"small"} />
          {!isProfileAgeHidden && (
            <Typography
              variant="h4"
              sx={{
                textAlign: "center",
                paddingBottom: "0.5em",
              }}
            >
              {learner.age} years old
            </Typography>
          )}
          {location && !isProfileLocationHidden && (
            <Typography
              variant="h4"
              sx={{
                color: "grey.500",
                textAlign: "center",
                paddingBottom: "0.5em",
              }}
            >
              {location}
            </Typography>
          )}
          {isEditable && (
            <>
              <Button
                variant="link"
                sx={{
                  margin: "0 auto",
                  display: "block",
                  lineHeight: "26px",
                  fontWeight: 600,
                  fontSize: 16,
                }}
                onClick={() => setIsProfileSettingsModalOpen(true)}
              >
                Edit privacy policy
              </Button>
              <SettingsModal
                open={isProfileSettingsModalOpen}
                onClose={() => setIsProfileSettingsModalOpen(false)}
                learner={learner}
                isProfileAgeHidden={isProfileAgeHidden}
                setIsProfileAgeHidden={setIsProfileAgeHidden}
                isProfileLocationHidden={isProfileLocationHidden}
                setIsProfileLocationHidden={setIsProfileLocationHidden}
              />
            </>
          )}
        </LearnerProfileIcon>
      </Box>
    </>
  );
}
