import {
  GradingType,
  MasteryAndGradingFrequency,
  MasteryEvaluationFeedback,
  MasteryEvaluationType,
  isMasteryAndGradingFrequency,
  isMasteryEvaluationFeedback,
} from "@outschool/business-rules";
import { Activity, RefundPolicyType } from "@outschool/gql-backend-generated";
import { EducatorSelectedFormat } from "@outschool/gql-frontend-generated";
import { TFunction, useTranslation } from "@outschool/localization";

type ActivityDurationString = Pick<
  Activity,
  | "weekly_meetings"
  | "duration_weeks"
  | "is_ongoing_weekly"
  | "isClub"
  | "isFlexSchedule"
  | "isOneTime"
  | "hasTeacherSchedule"
>;

function durationString(activity: ActivityDurationString, t: TFunction) {
  if (activity.isClub) {
    return t("Group");
  }

  if (activity.hasTeacherSchedule) {
    return t("1-on-1 Tutoring");
  }

  if (activity.is_ongoing_weekly) {
    if (activity.weekly_meetings === 1) {
      return t("Meets once per week");
    } else if (activity.weekly_meetings === 2) {
      return t("Meets twice per week");
    } else {
      return t("Meets {{numMeetings}}x per week", {
        numMeetings: activity.weekly_meetings,
      });
    }
  }

  if (activity.isFlexSchedule && activity.duration_weeks) {
    return t("Flexible for {{numWeeks}} week", {
      count: activity.duration_weeks,
      numWeeks: activity.duration_weeks,
      defaultValue_plural: "Flexible for {{numWeeks}} weeks",
    });
  }

  if (activity.isOneTime) {
    return t("Meets Once");
  }

  if (activity.weekly_meetings && activity.duration_weeks) {
    if (activity.weekly_meetings === 1) {
      return t("{{numWeeks}} Week, once per week", {
        count: activity.duration_weeks,
        numWeeks: activity.duration_weeks,
        defaultValue_plural: "{{numWeeks}} Weeks, once per week",
      });
    } else if (activity.weekly_meetings === 2) {
      return t("{{numWeeks}} Week, twice per week", {
        count: activity.duration_weeks,
        numWeeks: activity.duration_weeks,
        defaultValue_plural: "{{numWeeks}} Weeks, twice per week",
      });
    } else {
      return t("{{numWeeks}} Week, {{numMeetings}}x per week", {
        numMeetings: activity.weekly_meetings,
        count: activity.duration_weeks,
        numWeeks: activity.duration_weeks,
        defaultValue_plural: "{{numWeeks}} Weeks, {{numMeetings}}x per week",
      });
    }
  }

  return t("Unknown schedule");
}

type ActivityAgeRangeString = {
  age_min?: number | null;
  age_max?: number | null;
};

type ActivityGradeLevelRangeString = {
  grade_level_min: string | null;
  grade_level_max: string | null;
};

function ageRangeString(
  activity: ActivityAgeRangeString,
  useAgesPrefix = true,
  t: TFunction
) {
  const minAge = activity.age_min;
  const maxAge = activity.age_max;
  if (!minAge && !maxAge) {
    return t("All ages");
  }
  if (useAgesPrefix) {
    if (!minAge) {
      return t("Ages {{numMaxAge}} or younger", {
        numMaxAge: maxAge,
        numMinAge: minAge,
      });
    }

    if (!maxAge) {
      return t("Ages {{numMinAge}} and up", {
        numMaxAge: maxAge,
        numMinAge: minAge,
      });
    }

    return t("Ages: {{numMinAge}}-{{numMaxAge}}", {
      numMaxAge: maxAge,
      numMinAge: minAge,
    });
  }
  if (!minAge) {
    return t("{{numMaxAge}} or younger", {
      numMaxAge: maxAge,
      numMinAge: minAge,
    });
  }

  if (!maxAge) {
    return t("{{numMinAge}} and up", {
      numMaxAge: maxAge,
      numMinAge: minAge,
    });
  }

  return t("{{numMinAge}}-{{numMaxAge}}", {
    numMaxAge: maxAge,
    numMinAge: minAge,
  });
}

/**
 * Returns the number of spots a class has based on "size_min" and "size_max"
 */
function classSizeString(
  activity: Pick<Activity, "size_min" | "size_max">,
  t: TFunction
) {
  const minSize = activity.size_min ?? 1;
  const maxSize = activity.size_max;

  if (!minSize && !maxSize) {
    return t("TBD");
  } else if (maxSize) {
    if (maxSize === 1) {
      return "1";
    } else {
      return t("{{numMinSize}}-{{numMaxSize}}", {
        numMinSize: minSize,
        numMaxSize: maxSize,
      });
    }
  } else {
    return t("{{numMinSize}} or more", { numMinSize: minSize });
  }
}
type ActivityLearnerTimeString = Pick<
  Activity,
  | "weekly_meetings"
  | "duration_minutes"
  | "details"
  | "isClub"
  | "isFlexSchedule"
  | "hasTeacherSchedule"
>;

function learnerTimeString(
  activity: ActivityLearnerTimeString,
  t: TFunction
): string {
  const {
    isClub,
    isFlexSchedule,
    weekly_meetings,
    hasTeacherSchedule,
    duration_minutes,
    details,
  } = activity;
  const { asyncTime } = details;

  if (isClub) {
    return t("View and create posts any time, at your own pace.");
  }
  if (isFlexSchedule) {
    if (asyncTime === undefined || asyncTime === null) {
      return t("No live meetings, and maybe some time outside of class");
    }
    if (asyncTime === "0") {
      return t("No live meetings, and no time outside of class");
    }
    return t(
      "No live meetings, and an estimated {{numAsyncHours}} hours per week outside of class",
      { numAsyncHours: asyncTime }
    );
  }

  if (
    !hasTeacherSchedule &&
    (weekly_meetings === null || duration_minutes === null)
  ) {
    // should only happen for draft classes
    if (asyncTime === undefined || asyncTime === null) {
      return t(
        "UNSPECIFIED time per week in class, and maybe some time outside of class"
      );
    }
    if (asyncTime === "0") {
      return t(
        "UNSPECIFIED time per week in class, and no time outside of class"
      );
    }
    return t(
      "UNSPECIFIED time per week in class, and an estimated {{numAsyncHours}} hours per week outside of class",
      { numAsynchHours: asyncTime }
    );
  }

  const totalMinutes = hasTeacherSchedule
    ? duration_minutes!
    : duration_minutes! * weekly_meetings!;
  const hours = Math.floor(totalMinutes / 60.0);
  const extraMinutes = totalMinutes - hours * 60;
  if (hours && extraMinutes) {
    if (asyncTime === undefined || asyncTime === null) {
      return t(
        "{{numHours}} hour {{numMinutes}} minutes {{frequency}}, and maybe some time outside of class",
        {
          frequency: !hasTeacherSchedule ? "per week in class" : "per class",
          numHours: hours,
          numMinutes: extraMinutes,
          count: hours,
          defaultValue_plural:
            "{{numHours}} hours {{numMinutes}} minutes per week in class, and maybe some time outside of class",
        }
      );
    }
    if (asyncTime === "0") {
      return t(
        "{{numHours}} hour {{numMinutes}} minutes per week in class, and no time outside of class",
        {
          numHours: hours,
          numMinutes: extraMinutes,
          count: hours,
          defaultValue_plural:
            "{{numHours}} hours {{numMinutes}} minutes per week in class, and no time outside of class",
        }
      );
    }
    return t(
      "{{numHours}} hour {{numMinutes}} minutes per week in class, and an estimated {{numAsyncHours}} hours per week outside of class",
      {
        numAsyncHours: asyncTime,
        numHours: hours,
        numMinutes: extraMinutes,
        count: hours,
        defaultValue_plural:
          "{{numHours}} hours {{numMinutes}} minutes per week in class, and an estimated {{numAsyncHours}} hours per week outside of class",
      }
    );
  } else if (hours) {
    if (asyncTime === undefined || asyncTime === null) {
      return t(
        "{{numHours}} hour {{frequency}}, and maybe some time outside of class",
        {
          frequency: !hasTeacherSchedule ? "per week in class" : "per class",
          numHours: hours,
          count: hours,
          defaultValue_plural:
            "{{numHours}} hours per week in class, and maybe some time outside of class",
        }
      );
    }
    if (asyncTime === "0") {
      return t(
        "{{numHours}} hour per week in class, and no time outside of class",
        {
          numHours: hours,
          count: hours,
          defaultValue_plural:
            "{{numHours}} hours per week in class, and no time outside of class",
        }
      );
    }
    return t(
      "{{numHours}} hour per week in class, and an estimated {{numAsyncHours}} hours per week outside of class",
      {
        numAsyncHours: asyncTime,
        numHours: hours,
        count: hours,
        defaultValue_plural:
          "{{numHours}} hours per week in class, and an estimated {{numAsyncHours}} hours per week outside of class",
      }
    );
  } else {
    if (details.asyncTime === undefined || details.asyncTime === null) {
      return t(
        "{{numMinutes}} minutes {{frequency}}, and maybe some time outside of class",
        {
          frequency: !hasTeacherSchedule ? "per week in class" : "per class",
          numMinutes: extraMinutes,
        }
      );
    }
    if (details.asyncTime === "0") {
      return t(
        "{{numMinutes}} minutes per week in class, and no time outside of class",
        {
          numMinutes: extraMinutes,
        }
      );
    }
    return t(
      "{{numMinutes}} minutes {{frequency}}, and an estimated {{numAsyncHours}} hours per week outside of class",
      {
        frequency: !hasTeacherSchedule ? "per week in class" : "per class",
        numAsyncHours: asyncTime,
        numMinutes: extraMinutes,
      }
    );
  }
}

function frequencyLanguage(t: TFunction, numMeetings?: number): string {
  if (numMeetings === undefined) {
    return "";
  }
  if (numMeetings === 1) {
    return t("Once per week");
  } else if (numMeetings === 2) {
    return t("Twice per week");
  } else {
    return t("{{numMeetings}}x per week", { numMeetings });
  }
}
const translateGradeLevel = (t: TFunction, grade: string): string => {
  switch (grade) {
    case "Preschool":
      return t("Preschool");
    case "Pre-Kindergarten":
      return t("Pre-Kindergarten");
    case "Kindergarten":
      return t("Kindergarten");
    case "1st grade":
      return t("1st grade");
    case "2nd grade":
      return t("2nd grade");
    case "3rd grade":
      return t("3rd grade");
    case "4th grade":
      return t("4th grade");
    case "5th grade":
      return t("5th grade");
    case "6th grade":
      return t("6th grade");
    case "7th grade":
      return t("7th grade");
    case "8th grade":
      return t("8th grade");
    case "9th grade":
      return t("9th grade");
    case "10th grade":
      return t("10th grade");
    case "11th grade":
      return t("11th grade");
    case "12th grade":
      return t("12th grade");
    default:
      return grade;
  }
};

function translateThemeName(t: TFunction, theme?: string | null): string {
  switch (theme) {
    case null:
    case undefined:
      return "";
    case "Arts":
      return t("Arts");
    case "Coding & Tech":
      return t("Coding & Tech");
    case "Early Education":
      return t("Early Education");
    case "English":
      return t("English");
    case "Games & Hobbies":
      return t("Games & Hobbies");
    case "Health & Wellness":
      return t("Health & Wellness");
    case "Life Skills":
      return t("Life Skills");
    case "Learner Favorites":
      return t("Learner Favorites");
    case "Math":
      return t("Math");
    case "Music":
      return t("Music");
    case "Science & Nature":
      return t("Science & Nature");
    case "Social Studies":
      return t("Social Studies");
    case "Test Prep":
      return t("Test Prep");
    case "World Languages":
      return t("World Languages");
    default:
      return theme;
  }
}

interface RefundPolicyString {
  refundPolicyName: string;
  refundPolicyDescription: string;
}

// Ensure that this stays
function translateRefundPolicies(
  refundPolicyType: RefundPolicyType,
  t: TFunction
): RefundPolicyString {
  if (refundPolicyType === RefundPolicyType.Flexible) {
    return {
      refundPolicyName: t("Flexible"),
      refundPolicyDescription: t(
        "Full refund until the class begins, or within 24 hours of purchase (until class starts), then a pro-rated refund based on the number of meetings."
      ),
    };
  }
  return {
    refundPolicyName: t("Standard"),
    refundPolicyDescription: t(
      "Refund within 24 hours of payment until your next meeting or lesson access begins."
    ),
  };
}

function refundPolicyString(refundPolicyType: string, t: TFunction) {
  if (refundPolicyType === RefundPolicyType.Flexible) {
    return translateRefundPolicies(RefundPolicyType.Flexible, t);
  }
  return translateRefundPolicies(RefundPolicyType.Limited, t);
}

export function gradeLevelString(
  t: TFunction,
  activity: ActivityGradeLevelRangeString,
  shouldKeepSuffix = true
): string {
  const { grade_level_min, grade_level_max } = activity;
  let gradeFromStr = grade_level_min && grade_level_min.split(" ").shift();
  let gradeToStr = grade_level_max?.includes("grade")
    ? grade_level_max.split(" ").shift()
    : grade_level_max;

  if (!shouldKeepSuffix) {
    gradeFromStr = removeOrdinalSuffix(gradeFromStr);
    gradeToStr = removeOrdinalSuffix(gradeToStr);
  }

  if (grade_level_max === grade_level_min) {
    return t("{{gradeLevel}}", { gradeLevel: gradeFromStr });
  }

  return t("{{gradeFrom}} - {{gradeTo}}", {
    gradeFrom: gradeFromStr,
    gradeTo: gradeToStr,
  });
}

/**
 * A drop-in replacement for `shared/Activity.ts` functions that supports translation.
 * TODO: we only support a subset of the useActivityString API so far
 */
export default function useActivityString() {
  const { t } = useTranslation(
    "ui-components-website\\hooks\\useActivityString"
  );

  return {
    ageRangeString(
      activity: ActivityAgeRangeString,
      useAgesPrefix: boolean = true
    ): string {
      return ageRangeString(activity, useAgesPrefix, t);
    },
    classFormatString(
      format: EducatorSelectedFormat | undefined,
      options?: ClassFormatStringOptions
    ) {
      return classFormatString(t, format, options);
    },
    classSizeOptions: [
      { value: "oneOnOne", label: t("1-on-1") },
      { value: "smallGroup", label: t("Small group") },
    ],
    classSizeString(activity: Pick<Activity, "size_min" | "size_max">) {
      return classSizeString(activity, t);
    },
    durationString(activity: ActivityDurationString) {
      return durationString(activity, t);
    },
    frequencyLanguage(numMeetings?: number): string {
      return frequencyLanguage(t, numMeetings);
    },
    gradeLevelString: function (
      activity: ActivityGradeLevelRangeString,
      shouldKeepSuffix?: boolean
    ): string {
      return gradeLevelString(t, activity, shouldKeepSuffix);
    },
    gradingType({
      type,
      displayTo,
    }: {
      type: GradingType;
      displayTo: "parent" | "educator";
    }): string {
      switch (type) {
        case GradingType.Assessment:
          return t("Assessment");
        case GradingType.LetterGrade:
          return t("Letter Grade");
        case GradingType.ProgressReport:
          return t("Progress Report");
        case GradingType.CertificateOfCompletion:
          return t("Certificate of Completion");
        case GradingType.Other: {
          if (displayTo === "educator") {
            return t("Other (Please specify)");
          }
          return t("Grading");
        }
      }
    },
    learnerTimeString(activity: ActivityLearnerTimeString) {
      return learnerTimeString(activity, t);
    },
    masteryAndGradingFrequency({
      frequency,
      displayTo,
    }: {
      frequency: any;
      displayTo: "parent" | "educator";
    }): string {
      const availableUponRequest = t("available upon request");
      const included = t("included");
      if (isMasteryAndGradingFrequency(frequency)) {
        switch (frequency) {
          case MasteryAndGradingFrequency.AvailableUponRequest:
            return displayTo === "educator" ? availableUponRequest : included;
          case MasteryAndGradingFrequency.OneToTwoThroughout:
            return t("1-2 throughout the class");
          case MasteryAndGradingFrequency.ThreeToSixThroughout:
            return t("3-6 throughout the class");
          case MasteryAndGradingFrequency.SevenOrMoreThroughout:
            return t("7 or more throughout the class");
          case MasteryAndGradingFrequency.OneToTwoPerWeek:
            return t("1-2 per week");
          case MasteryAndGradingFrequency.ThreeToSixPerWeek:
            return t("3-6 per week");
          case MasteryAndGradingFrequency.SevenOrMorePerWeek:
            return t("7 or more per week");
          case MasteryAndGradingFrequency.OneAfterCompletion:
            return t("1 after class completion");
        }
      }
      return availableUponRequest;
    },
    masteryEvaluationFeedback({
      feedback,
      displayTo,
    }: {
      feedback: any;
      displayTo: "parent" | "educator";
    }): string {
      const availableUponRequest = t("available upon request");
      const included = t("included");
      if (isMasteryEvaluationFeedback(feedback)) {
        switch (feedback) {
          case MasteryEvaluationFeedback.AvailableUponRequest:
            return displayTo === "educator" ? availableUponRequest : included;
          case MasteryEvaluationFeedback.AsNeeded:
            return t("as needed");
        }
      }
      return availableUponRequest;
    },
    masteryEvaluationType({
      type,
      displayTo,
    }: {
      type: MasteryEvaluationType;
      displayTo: "parent" | "educator";
    }): string {
      switch (type) {
        case MasteryEvaluationType.Homework:
          return t("Homework");
        case MasteryEvaluationType.Projects:
          return t("Projects");
        case MasteryEvaluationType.Assignments:
          return t("Assignments");
        case MasteryEvaluationType.Other: {
          if (displayTo === "parent") {
            return t("Mastery Evaluation");
          }
          return t("Other (Please specify)");
        }
      }
    },
    refundPolicyString(refundPolicyType: string) {
      return refundPolicyString(refundPolicyType, t);
    },
    translateThemeName(theme?: string | null) {
      return translateThemeName(t, theme);
    },
    translateGradeLevel(grade: string) {
      return translateGradeLevel(t, grade);
    },
  };
}

function removeOrdinalSuffix(
  gradeLevel: string | null | undefined
): string | null {
  if (!gradeLevel) {
    return null;
  }
  // grade level has no number
  if (!isFirstCharANumber(gradeLevel)) {
    return gradeLevel;
  }
  return gradeLevel
    .replace("st", "")
    .replace("nd", "")
    .replace("rd", "")
    .replace("th", "");
}

function isFirstCharANumber(gradeLevel: string | null | undefined): boolean {
  if (!gradeLevel) {
    return false;
  }
  const firstChar = gradeLevel.charAt(0);
  return !isNaN(parseInt(firstChar));
}

interface ClassFormatStringOptions {
  /**
   * If true, adds "class" to the end.
   * I.e., true = "One-Time Class" false = "One-Time"
   */
  includeClass?: boolean;
}

export function classFormatString(
  t: TFunction,
  format: EducatorSelectedFormat | undefined,
  { includeClass = true }: ClassFormatStringOptions = {}
) {
  const courseOrClass =
    format === EducatorSelectedFormat.Course ? "" : t("Class");
  const formatPrefix: Record<EducatorSelectedFormat, string> = {
    [EducatorSelectedFormat.Course]: t("Course"),
    [EducatorSelectedFormat.EnrichmentOrClub]: t("Enrichment & Clubs"),
    [EducatorSelectedFormat.Tutoring]: t("Tutoring"),
    [EducatorSelectedFormat.OneTime]: t("One-Time"),
  };
  return `${format ? formatPrefix[format] : ""}${
    includeClass ? " " + courseOrClass : ""
  }`;
}
