import { ReactionType } from "@outschool/animation-resources";

import { ClubContextProps } from "../../../providers";
import { SAMPLE_CLUB_POST_AVATAR_URLS } from "./SampleClubPostAvatar";

export type SampleClubPostAttachments = {
  file: { mimetype: string | null; url: string };
}[];
export type DefaultSampleClubPostComment = {
  text: string | null;
  attachments?: SampleClubPostAttachments | null;
  sender?: {
    uid: string;
    name: string;
    avatar: keyof typeof SAMPLE_CLUB_POST_AVATAR_URLS;
    backgroundColor: string | null;
  };
};

export interface SampleClubPostProps {
  sampleClubPost: {
    text: string | null;
    attachments?: SampleClubPostAttachments | null;
    video?: { file: { url: string } } | null;
    comments?: DefaultSampleClubPostComment[];
  };
  senderName?: string;
  useClubPostAndCommentDefaultBackgroundColor?: ClubContextProps["useClubPostAndCommentDefaultBackgroundColor"];
}

export type SampleClubPostActivity = {
  uid: string;
  title: string;
  sampleClubPosts: SampleClubPostProps["sampleClubPost"][];
  leader: { name?: string | null };
};
export const DEFAULT_SAMPLE_CLUB_POSTS: {
  text: string | null;
  attachments: SampleClubPostAttachments;
  comments: DefaultSampleClubPostComment[];
}[] = [
  {
    text: "Welcome to the Group! First daily challenge starts tomorrow",
    attachments: [
      {
        file: {
          mimetype: "image/png",
          url: "https://cdn.filestackcontent.com/77wjKBoHRKmAqJdFapil",
        },
      },
    ],
    comments: [
      {
        text: ReactionType.CELEBRATION_CLUB_REACTION,
        sender: {
          uid: "learner-1",
          name: "Learner",
          avatar: "apple-preview-avatar",
          backgroundColor: "#006ADC",
        },
      },
      {
        text: "I'm so excited!",
        sender: {
          uid: "learner-2",
          name: "Learner",
          avatar: "monkey-preview-avatar",
          backgroundColor: "#068474",
        },
      },
      {
        text: ReactionType.SUNGLASSES_CLUB_REACTION,
        sender: {
          uid: "learner-3",
          name: "Learner",
          avatar: "marsupial-preview-avatar",
          backgroundColor: "#4251D7",
        },
      },
      {
        text: "This is awesome!! I cannot wait to get started on all the amazing challenges!🎉",
        sender: {
          uid: "learner-4",
          name: "Learner",
          avatar: "monster-preview-avatar-1",
          backgroundColor: "#644FC1",
        },
      },
      {
        text: "I can't wait!! This is going to be so fun!!🎉😝",
        sender: {
          uid: "learner-5",
          name: "Learner",
          avatar: "monster-preview-avatar-1",
          backgroundColor: "#AF42D7",
        },
      },
      {
        text: ReactionType.THUMBS_UP_CLUB_REACTION,
        sender: {
          uid: "learner-6",
          name: "Learner",
          avatar: "apple-preview-avatar",
          backgroundColor: "#DB2F74",
        },
      },
    ],
  },
  {
    text: "Need feedback on your project? Ask here!",
    attachments: [
      {
        file: {
          mimetype: "image/jpeg",
          url: "https://cdn.filestackcontent.com/WH4ZRk97RdaJG5QjGYN5",
        },
      },
    ],
    comments: [
      {
        text: "This is awesome! 😃",
        sender: {
          uid: "learner-7",
          name: "Learner",
          avatar: "monkey-preview-avatar",
          backgroundColor: "#D83B40",
        },
      },
      {
        text: ReactionType.FIRE_CLUB_REACTION,
        sender: {
          uid: "learner-8",
          name: "Learner",
          avatar: "mug-preview-avatar",
          backgroundColor: "#006ADC",
        },
      },
      {
        text: ReactionType.LETS_GO_CLUB_REACTION,
        sender: {
          uid: "learner-9",
          name: "Learner",
          avatar: "marsupial-preview-avatar",
          backgroundColor: "#4251D7",
        },
      },
      {
        text: "YESSS!!! This is what I’m looking for 🎉",
        sender: {
          uid: "learner-10",
          name: "Learner",
          avatar: "monster-preview-avatar-1",
          backgroundColor: "#DB2F74",
        },
      },
      {
        text: "Can you look at the video I posted yesterday? Thank you!",
        sender: {
          uid: "learner-11",
          name: "Learner",
          avatar: "monster-preview-avatar-1",
          backgroundColor: "#006ADC",
        },
      },
      {
        text: ReactionType.CARE_CLUB_REACTION,
        sender: {
          uid: "learner-12",
          name: "Learner",
          avatar: "donut-preview-avatar",
          backgroundColor: "#255DAD",
        },
      },
    ],
  },
  {
    text: "share your work!",
    attachments: [
      {
        file: {
          mimetype: "image/jpeg",
          url: "https://cdn.filestackcontent.com/hrfNFg59RoSrZqgrLAAY",
        },
      },
    ],
    comments: [
      {
        text: "",
        attachments: [
          {
            file: {
              mimetype: "image/jpeg",
              url: "https://cdn.filestackcontent.com/pPdmfUo6R3yl3PufGoSa",
            },
          },
        ],
        sender: {
          uid: "learner-13",
          name: "Learner",
          avatar: "turtle-preview-avatar",
          backgroundColor: "#D83B40",
        },
      },
      {
        text: ReactionType.HEARTS_CLUB_REACTION,
        sender: {
          uid: "learner-14",
          name: "Learner",
          avatar: "marsupial-preview-avatar",
          backgroundColor: "#006ADC",
        },
      },
      {
        text: "",
        attachments: [
          {
            file: {
              mimetype: "image/jpeg",
              url: "https://cdn.filestackcontent.com/wbjYOHgLSBqsSZ4mzheW",
            },
          },
        ],
        sender: {
          uid: "learner-15",
          name: "Learner",
          avatar: "star-preview-avatar",
          backgroundColor: "#4251D7",
        },
      },
      {
        text: "",
        attachments: [
          {
            file: {
              mimetype: "image/jpeg",
              url: "https://cdn.filestackcontent.com/4G62uummQsiIPCMspBqV",
            },
          },
        ],
        sender: {
          uid: "learner-16",
          name: "Learner",
          avatar: "monster-preview-avatar-2",
          backgroundColor: "#DB2F74",
        },
      },
      {
        text: "",
        attachments: [
          {
            file: {
              mimetype: "image/jpeg",
              url: "https://cdn.filestackcontent.com/BB5eIlBR5iXZU9b9zAw4",
            },
          },
        ],
        sender: {
          uid: "learner-17",
          name: "Learner",
          avatar: "monster-preview-avatar-2",
          backgroundColor: "#006ADC",
        },
      },
      {
        text: "Everyone is so talented!",
        sender: {
          uid: "learner-18",
          name: "Learner",
          avatar: "astronaut-preview-avatar",
          backgroundColor: "#DB2F74",
        },
      },
    ],
  },
];
