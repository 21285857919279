/** @jsx jsxPragma */

import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import jsxPragma from "./jsxPragma";

interface SubscriptProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: string;
  sx?: SystemStyleObject;
}

function Subscript(
  { color = "grayText", ...props }: SubscriptProps,
  ref: React.Ref<any>
) {
  return (
    // @ts-ignore
    // eslint-disable-next-line
    <div ref={ref} sx={{ fontSize: 14, lineHeight: 1.5, color }} {...props} />
  );
}

export default React.forwardRef<React.Ref<any>, SubscriptProps>(Subscript);
