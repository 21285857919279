import {
  Popper as BasePopper,
  PopperProps as BasePopperProps,
  SxProps,
} from "@mui/material";
import React from "react";
import Box from "../Box/Box";

export const PopperPlacementOptions = [
  "bottom",
  "bottom-end",
  "bottom-start",
  "left",
  "left-end",
  "left-start",
  "right",
  "right-end",
  "right-start",
  "top",
  "top-end",
  "top-start",
];
export type PopperPlacement = (typeof PopperPlacementOptions)[number];

export type PopperProps = Omit<
  BasePopperProps,
  "component" | "components" | "componentsProps" | "ref"
> & {
  useArrow?: boolean;
};

const ARROW_SIZE_PX = 19;
const ARROW_OFFSET_PX = ARROW_SIZE_PX / 2;
const sharedArrowSx = {
  content: '""',
  position: "absolute",
  height: ARROW_SIZE_PX,
  width: ARROW_SIZE_PX,
  background: "white",
  borderRight: "1px solid rgba(37,93,173,0.25)",
  borderTop: "1px solid rgba(37,93,173,0.25)",
};
const arrowSx: Record<string, SxProps> = {
  bottom: {
    ...sharedArrowSx,
    right: `calc(50% - ${ARROW_OFFSET_PX}px)`,
    transform: "rotate(-45deg)",
  },
  "bottom-end": {
    ...sharedArrowSx,
    right: "1em",
    transform: "rotate(-45deg)",
  },
  "bottom-start": {
    ...sharedArrowSx,
    left: "1em",
    transform: "rotate(-45deg)",
  },
  left: {
    ...sharedArrowSx,
    top: `calc(50% - ${ARROW_OFFSET_PX}px)`,
    right: 0,
    transform: "rotate(45deg)",
  },
  "left-end": {
    ...sharedArrowSx,
    bottom: "1em",
    right: 0,
    transform: "rotate(45deg)",
  },
  "left-start": {
    ...sharedArrowSx,
    top: "1em",
    right: 0,
    transform: "rotate(45deg)",
  },
  right: {
    ...sharedArrowSx,
    top: `calc(50% - ${ARROW_OFFSET_PX}px)`,
    transform: "rotate(-135deg)",
  },
  "right-end": {
    ...sharedArrowSx,
    bottom: "1em",
    transform: "rotate(-135deg)",
  },
  "right-start": {
    ...sharedArrowSx,
    top: "1em",
    transform: "rotate(-135deg)",
  },
  top: {
    ...sharedArrowSx,
    bottom: 0,
    right: `calc(50% - ${ARROW_OFFSET_PX}px)`,
    transform: "rotate(135deg)",
  },
  "top-end": {
    ...sharedArrowSx,
    bottom: 0,
    right: "1em",
    transform: "rotate(135deg)",
  },
  "top-start": {
    ...sharedArrowSx,
    bottom: 0,
    left: "1em",
    transform: "rotate(135deg)",
  },
};
const marginForArrowSx: Record<string, SxProps> = {
  bottom: { marginTop: ARROW_OFFSET_PX },
  "bottom-end": { marginTop: ARROW_OFFSET_PX },
  "bottom-start": { marginTop: ARROW_OFFSET_PX },
  left: { marginRight: ARROW_OFFSET_PX },
  "left-end": { marginRight: ARROW_OFFSET_PX },
  "left-start": { marginRight: ARROW_OFFSET_PX },
  right: { marginLeft: ARROW_OFFSET_PX },
  "right-end": { marginLeft: ARROW_OFFSET_PX },
  "right-start": { marginLeft: ARROW_OFFSET_PX },
  top: { marginBottom: ARROW_OFFSET_PX },
  "top-end": { marginBottom: ARROW_OFFSET_PX },
  "top-start": { marginBottom: ARROW_OFFSET_PX },
};

export default function Popper({
  useArrow = true,
  placement = "bottom",
  children,
  sx,
  ...props
}: PopperProps) {
  return (
    <BasePopper placement={placement} {...props}>
      {useArrow && <Box sx={arrowSx[placement]} />}
      <Box
        sx={[
          {
            color: "neutral.dark",
            backgroundColor: "common.white",
            border: "1px solid rgba(37, 93, 173, 0.25)",
            boxShadow: "rgb(0 0 0 / 16%) 0px 8px 20px",
            borderRadius: 5,
            padding: "1em",
          },
          useArrow && { ...marginForArrowSx[placement] },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {children}
      </Box>
    </BasePopper>
  );
}
