import {
  Box,
  ClickAwayListener,
  Icon,
  Popper,
  visuallyHidden,
} from "@outschool/backpack";
import * as icons from "@outschool/icons";
import {
  BoxButton,
  Modal,
  ModalActionButtons,
  ModalProps,
} from "@outschool/ui-legacy-component-library";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { useBooleanState } from "@outschool/ui-utils";
import { SystemStyleObject } from "@styled-system/css";
import React, { useCallback, useRef } from "react";
import { useDebouncedCallback } from "use-debounce/lib";

import { useClubContext } from "../../providers/ClubProvider";
import { TrackedButton, TrackedButtonProps } from "../TrackedButton";

enum ManageContentOption {
  Edit = "Edit",
  Promote = "Promote",
  Delete = "Delete",
  AddPin = "AddPin",
  RemovePin = "RemovePin",
}
export type ManageContentOptions = {
  [key in ManageContentOption]: {
    onClick: () => void;
    trackingName?: TrackedButtonProps["trackingName"];
    label?: string | React.ReactNode;
  };
};
interface ManageContentPopoverProps {
  buttonSx?: SystemStyleObject;
  isContentPromoted?: boolean;
  manageContentOptions: Partial<ManageContentOptions>;
}

function ManageContentPopover({
  manageContentOptions,
  buttonSx = {},
  isContentPromoted = false,
}: ManageContentPopoverProps) {
  const { readOnly } = useClubContext()!;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const hide = () => setAnchorEl(null);

  if (readOnly) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={hide}>
      <Box>
        <BoxButton
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault();
            e.stopPropagation();
            setAnchorEl(anchorEl ? null : e.currentTarget);
          }}
          sx={{
            textAlign: "center",
            fontSize: 24,
            width: 24,
            cursor: "pointer",
            ...buttonSx,
          }}
        >
          <Icon icon={icons.faEllipsisV} />
          <span style={visuallyHidden}>Manage</span>
        </BoxButton>
        <Popper
          open={!!anchorEl}
          anchorEl={anchorEl}
          useArrow={false}
          sx={{ padding: 0 }}
        >
          <ManageContentDropdown
            onClose={hide}
            manageContentOptions={manageContentOptions}
            isContentPromoted={isContentPromoted}
          />
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}

function ManageContentDropdown({
  onClose,
  manageContentOptions,
  isContentPromoted,
}: {
  onClose: () => void;
  manageContentOptions: Partial<ManageContentOptions>;
  isContentPromoted?: boolean;
}) {
  const ref = useRef(null);
  const [deleteModalIsOpen, openDeleteModal, closeDeleteModal] =
    useBooleanState();

  const debounceDelay = 3000; //3 sec
  const [debouncedOnDelete] = useDebouncedCallback(
    manageContentOptions.Delete?.onClick ?? (() => {}),
    debounceDelay,
    {
      leading: true,
    }
  );
  const [debouncedOnPromote] = useDebouncedCallback(
    manageContentOptions.Promote?.onClick ?? (() => {}),
    debounceDelay,
    {
      leading: true,
    }
  );
  return (
    <Box
      flex
      ref={ref}
      sx={{
        fontFamily: "Helvetica Neue",
        fontWeight: "fontWeightRegular",
        paddingY: "0.5em",
        flexDirection: "column",
      }}
    >
      {manageContentOptions.Delete && (
        <ManageContentDropdownItem
          onClick={() => {
            openDeleteModal();
          }}
        >
          {manageContentOptions.Delete.label ?? "Delete"}
          <DeleteConfirmationModal
            open={deleteModalIsOpen}
            onClose={() => {
              closeDeleteModal();
              onClose();
            }}
            onDelete={() => {
              debouncedOnDelete();
              closeDeleteModal();
              onClose();
            }}
          >
            Are you sure you want to delete this content?
          </DeleteConfirmationModal>
        </ManageContentDropdownItem>
      )}
      {manageContentOptions.Edit && (
        <ManageContentDropdownItem
          onClick={() => {
            manageContentOptions.Edit?.onClick();
            onClose();
          }}
        >
          {manageContentOptions.Edit?.label ?? "Edit"}
        </ManageContentDropdownItem>
      )}
      {manageContentOptions.AddPin && (
        <ManageContentDropdownItem
          onClick={() => {
            manageContentOptions.AddPin?.onClick();
            onClose();
          }}
        >
          {manageContentOptions.AddPin?.label ?? "Pin to top"}
        </ManageContentDropdownItem>
      )}
      {manageContentOptions.RemovePin && (
        <ManageContentDropdownItem
          onClick={() => {
            manageContentOptions.RemovePin?.onClick();
            onClose();
          }}
        >
          {manageContentOptions.AddPin?.label ?? "Remove pin"}
        </ManageContentDropdownItem>
      )}
      {manageContentOptions.Promote && (
        <ManageContentDropdownItem
          disabled={isContentPromoted}
          onClick={() => {
            debouncedOnPromote();
            onClose();
          }}
        >
          {manageContentOptions.Promote.label ?? "Promote"}
        </ManageContentDropdownItem>
      )}
    </Box>
  );
}
const ManageContentDropdownItem = ({
  children,
  onClick,
  disabled = false,
  trackingName,
}: TrackedButtonProps) => {
  const stopPropagationThenCallback = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick && onClick(e);
    },
    [onClick]
  );

  return (
    <TrackedButton
      variant="link"
      onClick={stopPropagationThenCallback}
      disabled={disabled}
      trackingName={trackingName}
      sx={{
        border: "none",
        padding: "0.5em",
        textAlign: "left",
        background: "white",
        paddingX: "1em",
        paddingY: "0.5em",
        color: disabled ? "initial" : "black !important",

        "&:hover": {
          backgroundColor: disabled ? "common.white" : "#D4EFF8",
        },
      }}
    >
      {children}
    </TrackedButton>
  );
};

interface DeleteConfirmationModalProps {
  open: ModalProps["open"];
  onClose: ModalProps["onClose"];
  onDelete: () => void;
}

function DeleteConfirmationModal({
  open,
  onClose,
  onDelete,
  children,
}: React.PropsWithChildren<DeleteConfirmationModalProps>) {
  return (
    <Modal open={open} onClose={onClose}>
      {children}
      <ModalActionButtons
        onPrimary={onDelete}
        onCancel={onClose}
        primaryLabel="Delete"
        primaryVariant="danger"
        cancelVariant="raised"
        sx={{
          justifyContent: "end",
          border: "none",
          paddingTop: "24px",
          "> button": { marginLeft: "medium" },
        }}
      />
    </Modal>
  );
}

export default ManageContentPopover;
