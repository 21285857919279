import { Box, Chip, Typography } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { LearnerRoutes, WebsiteRoutes } from "@outschool/routes";
import { AvatarImage, DEFAULT_AVATAR } from "@outschool/ui-components-shared";
import { useLinkComponent } from "@outschool/ui-utils";
import React from "react";

import { ClassroomLearnerListFragments } from "../../graphql/fragments/Classroom";
import LearnerProfilesBanner from "./LearnerProfilesBanner";

import type { ClassroomLearnerList_ProfileFragment } from "../..";

type LearnerRowType = {
  learner: ClassroomLearnerList_ProfileFragment;
  isCurrentLearner: boolean;
  isLearnerView: boolean;
  t: ReturnType<typeof useTranslation>["t"];
  isPaused?: boolean;
};

function LearnerRow({
  learner,
  isCurrentLearner,
  isLearnerView,
  t,
  isPaused,
}: LearnerRowType) {
  const Link = useLinkComponent();
  const profilePath = isLearnerView
    ? LearnerRoutes.profilePath(learner)
    : WebsiteRoutes.learnerProfilePath(learner);

  return (
    <Box
      sx={{
        paddingBottom: "0.5em",
        paddingTop: "0.5em",

        "&:hover": {
          backgroundColor: "#F6F6F7",
        },

        "&:not(:last-child)": {
          borderBottom: "1px solid #DDDFE3",
        },
      }}
      title={t`View profile`}
    >
      <Link
        to={profilePath}
        trackingName="learner_profile.learner_list.open_profile"
      >
        <Box
          flex
          sx={{
            alignItems: "center",
          }}
        >
          <AvatarImage
            avatar={learner.avatar || DEFAULT_AVATAR}
            width={48}
            height={48}
            name={learner.name || ""}
          />
          <Box
            flex
            sx={{
              flexDirection: "column",
              paddingLeft: "12px",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "grey.900",
              }}
            >
              {learner.name}
            </Typography>
            {isCurrentLearner && (
              <Typography
                variant="inherit"
                sx={{
                  color: "grey.500",
                  fontSize: "0.875em",
                }}
              >
                {t`Me`}
              </Typography>
            )}
          </Box>
          {isPaused && (
            <Chip
              label={t`Paused`}
              size="small"
              color="warning"
              sx={{
                marginLeft: "auto",
              }}
            />
          )}
        </Box>
      </Link>
    </Box>
  );
}

type ClassroomLearnerListType = {
  currentLearnerUid?: string;
  learners: ClassroomLearnerList_ProfileFragment[];
  isCurrentLearnerPaused?: boolean;
};

export default function ClassroomLearnerList({
  currentLearnerUid,
  learners,
  isCurrentLearnerPaused,
}: ClassroomLearnerListType) {
  const { t } = useTranslation(
    "ui-components-classroom\\classroom\\ClassroomLearnerList"
  );
  // pull current learner to the top of the list
  const currentLearner = learners.find(l => l.uid === currentLearnerUid);
  const orderedLearners = currentLearner
    ? [currentLearner, ...learners.filter(l => l.uid !== currentLearnerUid)]
    : learners;

  return (
    <Box
      sx={{
        minWidth: "250px",
      }}
    >
      {!!currentLearner && (
        <LearnerProfilesBanner
          learner={currentLearner}
          isPaused={isCurrentLearnerPaused}
        />
      )}
      <Box
        sx={{
          backgroundColor: "common.white",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.16)",
          borderRadius: 10,
          height: "fit-content",
          minHeight: "320px",
          padding: "1em",
          marginTop: "2em",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            paddingBottom: "1em",
          }}
        >
          {t("Classmates ({{numLearners}})", {
            numLearners: orderedLearners.length,
          })}
        </Typography>
        {orderedLearners.map(learner => {
          const isCurrentLearner = learner.uid === currentLearnerUid;
          const isPaused = isCurrentLearner && isCurrentLearnerPaused;
          return (
            <LearnerRow
              learner={learner}
              isCurrentLearner={isCurrentLearner}
              isLearnerView={!!currentLearnerUid}
              key={learner.uid}
              isPaused={isPaused}
              t={t}
            />
          );
        })}
      </Box>
    </Box>
  );
}

ClassroomLearnerList.fragments = ClassroomLearnerListFragments;
