import { Box, Divider, Icon, Theme, Typography } from "@outschool/backpack";
import {
  AssignmentTrackerSyllabusFragment,
  SubmissionRequirementType,
} from "@outschool/gql-frontend-generated";
import { faFileAlt } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { gql } from "@outschool/ui-apollo";
import React from "react";

import { AssignmentRequirementChip } from "./AssignmentRequirementChip";
import { MessageContentFragment } from "../../graphql";

export type AssignmentTrackerLesson =
  AssignmentTrackerSyllabusFragment["lessons"][number];
export type AssignmentTrackerAssignment =
  AssignmentTrackerLesson["assignmentClassPosts"][number];

interface AssignmentTrackerProps {
  /**
   * AssignmentTracker contains AssignmentTrackerLesson
   */
  children?: React.ReactNode;
}
export const AssignmentTracker = ({ children }: AssignmentTrackerProps) => {
  const { t } = useTranslation(
    "ui-components-classroom\\assignments\\AssignmentTracker"
  );

  return (
    <Box
      sx={(theme: Theme) => ({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: 20,
        borderRadius: 6,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "neutral.200",
        backgroundColor: "common.white",
        [theme.breakpoints.down("md")]: {
          padding: 16,
        },
      })}
    >
      <Typography variant="h6" sx={{ marginBottom: 16 }}>
        {t("Assignment Tracker")}
      </Typography>
      <Divider />
      <Box
        sx={(theme: Theme) => ({
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 16,
          [theme.breakpoints.down("md")]: {
            maxHeight: "25vh",
            overflowY: "auto",
            overflowX: "hidden",
          },
        })}
      >
        {children}
      </Box>
    </Box>
  );
};

AssignmentTracker.fragments = {} as {
  assignmentClassPost: any;
  syllabus: any;
};
AssignmentTracker.fragments.assignmentClassPost = gql`
  fragment AssignmentTrackerAssignment on AssignmentClassPost {
    uid
    title
    submissionRequirement
    dueDate
    messageContent {
      ...MessageContentFragment
    }
    comments {
      uid
      sentAt
      isSubmission
      messageContent {
        ...MessageContentFragment
      }
    }
  }
  ${MessageContentFragment}
`;

AssignmentTracker.fragments.syllabus = gql`
  fragment AssignmentTrackerSyllabus on Syllabus {
    uid
    published_at
    lessons {
      uid
      lessonNumber
      assignmentClassPosts(sectionUid: $sectionUid) {
        ...AssignmentTrackerAssignment
      }
    }
  }
  ${AssignmentTracker.fragments.assignmentClassPost}
`;

interface AssignmentTrackerLessonProps {
  lessonNumber: number | null;
  /**
   * AssignmentTrackerLesson contains AssignmentTrackerLessonAssignment
   */
  children?: React.ReactNode;
}
export const AssignmentTrackerLesson = ({
  lessonNumber,
  children,
}: AssignmentTrackerLessonProps) => {
  const { t } = useTranslation(
    "ui-components-classroom\\assignments\\AssignmentTracker"
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          {t("Lesson {{lessonNumber}}", {
            lessonNumber: lessonNumber ?? "",
          })}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 24 }}>
          {children}
        </Box>
      </Box>
      <Divider />
    </>
  );
};

interface AssignmentTrackerLessonAssignmentProps {
  assignmentTitle: string;
  submissionRequirement: SubmissionRequirementType;
  onClick?: () => void;
  /**
   * AssignmentTrackerLessonAssignment contains additional details about the assignment
   */
  children?: React.ReactNode;
}
export const AssignmentTrackerLessonAssignment = ({
  assignmentTitle,
  submissionRequirement,
  onClick,
  children,
}: AssignmentTrackerLessonAssignmentProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: 8,
      cursor: "pointer",
      userSelect: "none",
      ...(onClick
        ? {
            "&:hover": {
              backgroundColor: "neutral.50",
              borderRadius: 6,
              margin: -8,
              padding: 8,
            },
          }
        : {}),
    }}
    onClick={onClick}
  >
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 5,
        }}
      >
        <Icon icon={faFileAlt} size="inherit" />
        <Typography variant="h6">{assignmentTitle}</Typography>
      </Box>
      <AssignmentRequirementChip
        submissionRequirement={submissionRequirement}
      />
    </Box>
    {children}
  </Box>
);
