// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { gql } from "@outschool/ui-apollo";
import React from "react";

import { DemoClubProvider } from "../../../providers";
import { useClubPostAndCommentDefaultBackgroundColor } from "../Club";
import { PostContent } from "../ClubPostContent";
import { LottieReact, loadLottieMap } from "../Lottie";
import { SampleClubPostProps } from "./SampleClubPosts";

interface ComponentWithFragments<T = {}> extends React.FC<T> {
  fragments: Record<string, unknown>;
}

const SampleClubPost: ComponentWithFragments<SampleClubPostProps> = ({
  sampleClubPost: { text, attachments, video },
  senderName = "Learner",
}) => {
  const attachmentProps = video
    ? { videoUrl: video?.file?.url }
    : attachments?.length || 0 > 0
    ? { imageUrl: attachments?.[0]?.file?.url }
    : {};
  return (
    // HACK: There are a few deeply-nested users of this context within PostContent
    <DemoClubProvider
      LottieReact={LottieReact}
      loadLottieMap={loadLottieMap}
      useClubPostAndCommentDefaultBackgroundColor={
        useClubPostAndCommentDefaultBackgroundColor
      }
      useClubPostAndCommentBackgroundColors={() => ({
        data: { clubPostAndCommentBackgroundColors: ["#255DAD"] },
        loading: false,
      })}
    >
      <PostContent
        sender={{ uid: "sample", isLearner: true, name: senderName }}
        title={text || ""}
        {...attachmentProps}
        hideFullPostText={true}
      />
    </DemoClubProvider>
  );
};

SampleClubPost.fragments = {
  sampleClubPost: gql`
    fragment SampleClubPost_welcomeMessage on WelcomeMessage {
      text
      attachments {
        uid
        file {
          url
          mimetype
        }
      }
      video {
        uid
        file {
          url
          mimetype
        }
      }
    }
  `,
};

export default SampleClubPost;
