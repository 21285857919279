// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Typography } from "@outschool/backpack";
import { participationGuidelinesUrl } from "@outschool/routes";
import { ErrorMessage } from "@outschool/ui-components-shared";
import {
  useComponentTrackingContext,
  useImpressionTracking,
} from "@outschool/ui-legacy-component-library";
import { responsive, useLinkComponent, useNodeRef } from "@outschool/ui-utils";
import React from "react";

import { useSaveLearnerProfileMutation } from "../../hooks/LearnerProfileQuery";
import InlineEditableText from "../InlineEditableText";
import Card from "../learnerSpace/Card";

interface AboutMeProps {
  text?: string | null;
  isEditable?: boolean;
}

const ABOUT_ME_TITLE = "About Me";

export default function AboutMe({ text, isEditable }: AboutMeProps) {
  const [saveAboutMe] = useSaveLearnerProfileMutation();
  const Link = useLinkComponent();
  const [error, setError] = React.useState<string | null>(null);
  const [editableTextNode, setEditableTextNode] = useNodeRef();
  const trackingPrefix = "learner_profile.about_me";
  const { trackTouch } = useImpressionTracking({
    node: editableTextNode,
    uniqueId: trackingPrefix,
    trackingLabel: trackingPrefix,
    impressionEventName: trackingPrefix + ".view",
    trackingEventName: trackingPrefix + ".edit",
  });
  const track = useComponentTrackingContext();

  function onSave(value: string | null) {
    saveAboutMe({
      variables: {
        profileInput: {
          aboutMe: value,
        },
      },
    }).then(
      () => {
        track(`${trackingPrefix}.save`, {}, { integrations: { All: false } });
      },
      error => {
        setError(error);
      }
    );
  }

  function onCancel() {
    track(`${trackingPrefix}.cancel`, {}, { integrations: { All: false } });
  }

  return (
    <Card
      title={ABOUT_ME_TITLE}
      sx={{ minWidth: responsive({ default: "100%", medium: "400px" }) }}
    >
      {isEditable && (
        <Box sx={{ marginBottom: 16 }}>
          <Typography
            variant="body1"
            sx={{
              typography: "h5",

              color: "#828282",
            }}
            paragraph
          >
            Please don&apos;t share your full name, city, or gaming handles.
          </Typography>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={participationGuidelinesUrl()}
          >
            Learn about safety
          </Link>
        </Box>
      )}
      {error && <ErrorMessage value={error} />}
      <InlineEditableText
        onSave={onSave}
        onCancel={onCancel}
        title={ABOUT_ME_TITLE}
        placeholder="What are some things you love to do?"
        value={text}
        isEditable={isEditable}
        trackTouch={trackTouch}
        ref={setEditableTextNode}
      />
    </Card>
  );
}
