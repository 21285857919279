import { Box, Button } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { TrackedButton } from "@outschool/ui-components-shared";
import { Space } from "@outschool/ui-legacy-component-library";
import React from "react";

import { useReplyToContext } from "../../../providers/ReplyToProvider";
import EditableMessageContent, {
  EditableMessageContentSubmitType,
} from "../../EditableMessageContent";

import type { AttachmentFragmentFragment } from "../../..";

// Two Hat's actual limit is 30000 characters
export const MAX_TEXT_LENGTH = 25000;

type MentionsDataItem = {
  id: string;
  display: string;
};

type onSubmitProps = {
  text: string;
  attachments?: AttachmentFragmentFragment[] | null;
  video?: AttachmentFragmentFragment | null;
  mentions?: MentionsDataItem[] | null;
};

export interface AddClassPostCommentInputProps {
  placeholder?: string;
  mentionNames?: MentionsDataItem[];
  onSubmit(props: onSubmitProps): void;
}

export default function AddClassPostCommentInput({
  placeholder,
  mentionNames,
  onSubmit,
}: AddClassPostCommentInputProps) {
  const { t } = useTranslation(
    "ui-components-classroom\\classroom\\ClassPostComment\\AddClassPostCommentInput"
  );
  const { messageContents, clearReplyTo } = useReplyToContext();
  const handleCommentSubmit = ({
    text,
    attachments,
    video,
    mentions,
  }: EditableMessageContentSubmitType) => {
    onSubmit({
      text,
      attachments,
      video,
      mentions,
    });
    clearReplyTo();
  };
  return (
    <Box
      data-test-id="AddClassPostComment"
      sx={{
        width: "100%",
      }}
    >
      <EditableMessageContent
        collapsedUntilFocused
        inClassContext
        placeholder={placeholder ?? `Write a comment`}
        messageContent={messageContents}
        onSubmit={handleCommentSubmit}
        classMentionsData={mentionNames}
        maxLength={MAX_TEXT_LENGTH}
        renderButton={({ submit, disabled, reset }) => {
          return (
            <Box
              flex
              sx={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TrackedButton
                variant="contained"
                disabled={disabled}
                trackingName="send_comment"
                onClick={() => {
                  submit();
                  reset();
                }}
              >
                {t`Send`}
              </TrackedButton>
              <Space x="medium" />
              <Button
                variant="link"
                onClick={() => {
                  reset();
                }}
              >
                {t`Cancel`}
              </Button>
            </Box>
          );
        }}
        // eslint-disable-next-line
        autoFocus={false}
        expandedRows={10}
      />
    </Box>
  );
}
