import { Box, BoxProps, Icon, SxProps, Typography } from "@outschool/backpack";
import { IconDefinition } from "@outschool/icons";
import React from "react";

type PostSummaryItemProps<D extends React.ElementType = typeof Box> = Omit<
  BoxProps<D>,
  "sx"
> & {
  label: string;
  icon: IconDefinition;
  sx?: SxProps;
  component?: D;
};
export const PostSummaryItem = ({
  label,
  icon,
  sx,
  ...props
}: PostSummaryItemProps) => (
  <Box
    sx={[
      {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 8,
        color: "neutral.main",
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  >
    <Icon icon={icon} height="1em" width="1em" color="inherit" />
    <Typography variant="body2" sx={{ fontWeight: "500" }}>
      {label}
    </Typography>
  </Box>
);
