// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { ReactionType } from "@outschool/animation-resources";
import { Box, Theme } from "@outschool/backpack";
import { LoopingCarousel, Space } from "@outschool/ui-legacy-component-library";
import { stringToPositiveHash, useIsTablet } from "@outschool/ui-utils";
import React from "react";

import {
  BoxWithHeightRatio,
  COMMENT_BOX_RATIO,
  COMMENT_BOX_SHADOW_CSS_VARIABLE_NAME,
  CommentContent,
  DEFAULT_SAMPLE_CLUB_POSTS,
  SampleClubPost,
  useClubCommentBoxSx,
} from "../..";
import SampleClubPostAvatar from "./SampleClubPostAvatar";
import { SampleClubPostActivity, SampleClubPostProps } from "./SampleClubPosts";

interface SampleClubPostsCarouselProps {
  activity: SampleClubPostActivity;
  useNarrowDisplay?: boolean;
}

export default function SampleClubPostsCarousel({
  activity,
  useNarrowDisplay,
}: SampleClubPostsCarouselProps) {
  let sampleClubPostsWithContent: SampleClubPostProps["sampleClubPost"][] = (
    activity.sampleClubPosts || []
  )
    .filter(
      p =>
        !!p &&
        ((p.attachments && p.attachments.length > 0) || p.video || p.text)
    )
    .map(p => {
      return {
        text: p.text,
        comments: (p.comments || []).map(c => {
          return {
            text: c.text,
            attachments: [],
          };
        }),
        attachments: (p.attachments || []).map(a => {
          return {
            file: {
              url: a.file.url,
              mimetype: a.file.mimetype,
            },
          };
        }),
        video: p.video,
      };
    });
  let addedDefaultPostCount = 0;
  while (sampleClubPostsWithContent.length < 3) {
    const defaultPost = DEFAULT_SAMPLE_CLUB_POSTS[addedDefaultPostCount];
    defaultPost &&
      sampleClubPostsWithContent.push({
        text: defaultPost.text,
        comments: defaultPost.comments.map(c => {
          return { text: c.text, attachments: c.attachments };
        }),
        attachments: defaultPost.attachments,
        video: null,
      });
    addedDefaultPostCount++;
  }
  return (
    <LoopingCarousel
      intervalMs={8000}
      noFade={true}
      hideDots={true}
      showNavigationArrows={true}
      items={sampleClubPostsWithContent.map((post, postIndex) => (
        <SampleClubPostPreview
          key={`preview-post-${postIndex}`}
          post={post}
          postIndex={postIndex}
          activity={activity}
          useNarrowDisplay={useNarrowDisplay}
        />
      ))}
    />
  );
}
interface SampleClubPostPreviewProps {
  post: SampleClubPostProps["sampleClubPost"];
  postIndex: number;
  activity: SampleClubPostActivity;
  useNarrowDisplay?: boolean;
}

function SampleClubPostPreview({
  post,
  postIndex,
  activity,
  useNarrowDisplay,
}: SampleClubPostPreviewProps) {
  const isNarrow = useIsTablet() || useNarrowDisplay;
  const maxCommentCount = isNarrow ? 3 : 6;
  // If less than six comments are defined, we will randomly fill the rest in with reactions.
  const samplePreviewReactions: { text: ReactionType }[] = [
    { text: ReactionType.SUNGLASSES_CLUB_REACTION },
    { text: ReactionType.CELEBRATION_CLUB_REACTION },
    { text: ReactionType.CREATIVITY_CLUB_REACTION },
    { text: ReactionType.FIRE_CLUB_REACTION },
    { text: ReactionType.THUMBS_UP_CLUB_REACTION },
    { text: ReactionType.HEARTS_CLUB_REACTION },
    { text: ReactionType.SIMPLE_SMILE_CLUB_REACTION },
    { text: ReactionType.VIBIN_CLUB_REACTION },
    { text: ReactionType.CARE_CLUB_REACTION },
  ];
  let commentsWithContent =
    post.comments
      ?.filter(c => c.text || (c.attachments && c.attachments.length > 0))
      .slice(0, maxCommentCount) || [];
  let reactions = [...samplePreviewReactions];
  while (commentsWithContent.length < maxCommentCount) {
    const nextReactionIndex =
      stringToPositiveHash(activity.uid + postIndex) % reactions.length;
    const nextReaction = reactions[nextReactionIndex];
    if (!commentsWithContent.find(c => c.text === nextReaction.text)) {
      commentsWithContent.push(reactions[nextReactionIndex]);
    }
    reactions.splice(nextReactionIndex, 1);
  }
  return (
    <Box
      sx={(theme: Theme) => ({
        width: "100%",
        display: "grid",
        borderRadius: 4,
        paddingY: "0.5em",

        [theme.breakpoints.up("sm")]: {
          gridTemplateColumns: "50% 50%",
          gridGap: "3%",
          gridTemplateAreas: '"post comments"',
        },

        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: isNarrow ? "50% 50%" : "42% 55%",
        },

        gridTemplateColumns: "100%",
        gridGap: "1em",
        gridTemplateAreas: '"post"  "comments"',
        gridTemplateRows: "auto auto 1fr",
      })}
    >
      <Box>
        <SampleClubPost
          senderName={activity.leader?.name || ""}
          sampleClubPost={{
            text: post.text || "",
            attachments: (post.attachments || []).map(a => {
              return { ...a, uid: `sample-club-post-${postIndex}` };
            }),
            video: post.video,
          }}
        />
        <Space y={"large"} />
        <Box>
          {activity.title} is a community of learners that come together on
          Outschool to share what they’re learning, ask questions, participate
          in challenges, and get feedback on their projects
        </Box>
      </Box>

      <Box>
        <Box
          sx={{
            display: "grid",

            gridTemplateColumns: `repeat(${
              isNarrow ? 1 : 2
            },minmax(200px, 275px))`,

            justifyContent: "center",
            gap: "24px",
          }}
        >
          {commentsWithContent.map((comment, commentIndex) => (
            <SampleClubPostCommentPreview
              comment={comment}
              commentIndex={commentIndex}
              postIndex={postIndex}
              key={`comment-box-${postIndex}-${commentIndex}`}
            />
          ))}
        </Box>
        <Space y="medium" />
        <Box
          sx={{
            color: "grey.700",
            textAlign: "center",
          }}
        >
          Sample learner reactions
        </Box>
        <Space y="medium" />
      </Box>
    </Box>
  );
}

interface SampleClubPostCommentPreviewProps {
  comment: NonNullable<
    NonNullable<SampleClubPostProps["sampleClubPost"]>["comments"]
  >[0];
  commentIndex: number;
  postIndex: number;
}

function SampleClubPostCommentPreview({
  comment,
  commentIndex,
  postIndex,
}: SampleClubPostCommentPreviewProps) {
  const commentBoxSx = useClubCommentBoxSx({
    hasCommentReplies: false,
  });
  const sender =
    DEFAULT_SAMPLE_CLUB_POSTS[
      Math.min(postIndex, DEFAULT_SAMPLE_CLUB_POSTS.length - 1)
    ].comments[commentIndex].sender;
  return (
    <BoxWithHeightRatio
      heightRatio={COMMENT_BOX_RATIO}
      sx={commentBoxSx}
      key={`comment-box-${postIndex}-${commentIndex}`}
    >
      <CommentContent
        comment={{
          backgroundColor: sender?.backgroundColor || "#255DAD",
          messageContent: {
            attachments: comment.attachments || [],
            text: comment.text,
            video: null,
          },
          sender: {
            uid: `sample-comment-${postIndex}-comment-${commentIndex}-sender`,
          },
        }}
        isDocentComment={false}
        sx={{
          transition: "box-shadow 0.2s linear",
          boxShadow: `var(${COMMENT_BOX_SHADOW_CSS_VARIABLE_NAME})`,
        }}
      />
      <SampleClubPostAvatar
        avatar={sender?.avatar || "star-preview-avatar"}
        sx={{ position: "absolute", top: "small", left: "small" }}
      />
    </BoxWithHeightRatio>
  );
}
