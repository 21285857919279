import {
  UpdateFilestackMetadataMutationVariables,
  UpdateFilestackMetadataMutation as UpdateFilestackMetadataMutationType,
} from "@outschool/gql-frontend-generated";
import { useMutation } from "@outschool/ui-apollo";
import { useMemo } from "react";
import { UpdateFilestackMetadataMutation } from "../graphql/mutations/FilestackMetadata";

export const useUpdateFilestackMetadataMutation = () => {
  const [mutate] = useMutation<
    UpdateFilestackMetadataMutationType,
    UpdateFilestackMetadataMutationVariables
  >(UpdateFilestackMetadataMutation);

  return useMemo(() => {
    return {
      async updateFilestackMetadata(handle: string, osS3Path: string) {
        const resp = await mutate({
          variables: {
            handle,
            osS3Path,
          },
        });
        return resp.data?.updateFilestackMetadata;
      },
    };
  }, [mutate]);
};
