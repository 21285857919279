import { Global, css } from "@emotion/react";
import { BasicModal, Box, Theme, Typography } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { useIsomorphicLayoutEffect } from "@outschool/ui-utils";
import { PickerInline as FilestackPickerInline } from "filestack-react";
import React from "react";
import { v4 } from "uuid";

import { useUpdateFilestackMetadataMutation } from "../hooks/useUpdateFilestackMetadata";

import type {
  PickerResponse,
  PickerFileMetadata,
  PickerOptions,
} from "filestack-js";

const FILESTACK_APP_KEY = (global as any).OUTSCHOOL_SERVER.FILESTACK_APP_KEY;
const SECURED_FILESTACK_APP_KEY = (global as any).OUTSCHOOL_SERVER
  .SECURED_FILESTACK_APP_KEY;
const SECURED_FILESTACK_APP_STORE_PICK_POLICY_2032_08_15 = (global as any)
  .OUTSCHOOL_SERVER.SECURED_FILESTACK_APP_STORE_PICK_POLICY_2032_08_15;
const SECURED_FILESTACK_APP_STORE_PICK_SIGNATURE_2032_08_15 = (global as any)
  .OUTSCHOOL_SERVER.SECURED_FILESTACK_APP_STORE_PICK_SIGNATURE_2032_08_15;
/**
 * From: https://filestack.github.io/filestack-js/interfaces/pickeroptions.html#fromsources
 */
type FilestackSources = "local_file_system" | "webcam" | "unsplash" | "video";

export function ImageUpload({
  renderButton,
  cropRatio,
  onChange,
  maxSize = 5 * 1024 * 1024,
  fromSources = ["local_file_system", "unsplash", "webcam"],
}: {
  renderButton: (onPick: () => void) => React.ReactNode;
  cropRatio?: number;
  onChange: (file: FilestackFile) => void;
  maxSize?: number;
  fromSources?: FilestackSources[];
}) {
  const handleUploaded = React.useCallback(
    (files: FilestackFile[]) => {
      onChange(files[0]);
    },
    [onChange]
  );
  return (
    <FileUploadModal
      renderButton={renderButton}
      cropRatio={cropRatio}
      accept={["image/*"]}
      fromSources={fromSources}
      maxSize={maxSize}
      maxFiles={1}
      onUploaded={handleUploaded}
    />
  );
}

export function PrivateAttachmentUpload({
  renderButton,
  onUploaded = () => {},
  accept,
  maxFiles = 5,
  fromSources = ["local_file_system"],
  cropRatio,
  maxSize = 50 * 1024 * 1024,
}: {
  renderButton: (onPick: () => void) => React.ReactNode;
  onUploaded: (files: FilestackFile[]) => void;
  accept?: string | string[];
  maxFiles?: number;
  fromSources?: FilestackSources[];
  cropRatio?: number;
  maxSize?: number;
}) {
  const clientOptions = {
    security: {
      policy: SECURED_FILESTACK_APP_STORE_PICK_POLICY_2032_08_15,
      signature: SECURED_FILESTACK_APP_STORE_PICK_SIGNATURE_2032_08_15,
    },
  };
  return (
    <FileUploadModal
      renderButton={renderButton}
      fromSources={fromSources}
      maxSize={maxSize}
      maxFiles={maxFiles}
      onUploaded={onUploaded}
      accept={accept}
      onFileSelected={(file: any) => {
        // Scrubbing uploaded filename to protect potential pii leak
        return { ...file, name: v4() };
      }}
      cropRatio={cropRatio}
      apiKey={SECURED_FILESTACK_APP_KEY}
      clientOptions={clientOptions}
    />
  );
}

interface AttachmentUploadBaseProps {
  onUploaded: (files: FilestackFile[]) => void;
  accept?: string | string[];
  maxFiles?: number;
  fromSources?: FilestackSources[];
  cropRatio?: number;
  maxSize?: number;
}

interface AttachmentUploadWithButtonProps extends AttachmentUploadBaseProps {
  renderButton: (onPick: () => void) => React.ReactNode;
  isOpen?: never;
  setIsOpen?: never;
}

interface AttachmentUploadWithoutButtonProps extends AttachmentUploadBaseProps {
  renderButton?: never;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export function AttachmentUpload({
  onUploaded = () => {},
  accept,
  maxFiles = 5,
  fromSources = ["local_file_system"],
  cropRatio,
  maxSize = 50 * 1024 * 1024,
  renderButton,
  isOpen,
  setIsOpen,
}: AttachmentUploadWithButtonProps | AttachmentUploadWithoutButtonProps) {
  return (
    <FileUploadModal
      fromSources={fromSources}
      maxSize={maxSize}
      maxFiles={maxFiles}
      onUploaded={onUploaded}
      accept={accept}
      cropRatio={cropRatio}
      {...(renderButton !== undefined
        ? {
            renderButton: renderButton!,
          }
        : {
            isOpen: isOpen,
            onClose: () => setIsOpen(false),
          })}
    />
  );
}

interface VideoUploadBaseProps {
  onUploaded: (file: FilestackFile) => void;
  fromSources?: FilestackSources[];
  startUploadingWhenMaxFilesReached?: boolean;
  onError?: (e: string) => void;
}

interface VideoUploadWithButtonProps extends VideoUploadBaseProps {
  renderButton: (onPick: () => void) => React.ReactNode;
  isOpen?: never;
  setIsOpen?: never;
}

interface VideoUploadWithoutButtonProps extends VideoUploadBaseProps {
  renderButton?: never;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export function VideoUpload({
  renderButton,
  fromSources = ["local_file_system", "video"],
  startUploadingWhenMaxFilesReached = false,
  onUploaded,
  onError = e => console.log(e),
  isOpen,
  setIsOpen,
}: VideoUploadWithButtonProps | VideoUploadWithoutButtonProps) {
  const { t } = useTranslation("ui-components-classroom\\FilestackUpload");
  const handleUploaded = React.useCallback(
    (files: FilestackFile[]) => {
      const file = files[0];
      if (file.size === 0) {
        onError(t("File size is zero"));
      } else {
        onUploaded(file);
      }
    },
    [onUploaded, onError, t]
  );

  return (
    <FileUploadModal
      accept={["video/*"]}
      fromSources={fromSources}
      maxSize={1000 * 1024 * 1024}
      maxFiles={1}
      onUploaded={handleUploaded}
      startUploadingWhenMaxFilesReached={startUploadingWhenMaxFilesReached}
      {...(renderButton !== undefined
        ? {
            renderButton: renderButton!,
          }
        : {
            isOpen: isOpen,
            onClose: () => setIsOpen(false),
          })}
    />
  );
}

export interface FilestackFile {
  url: string;
  size: number;
  handle: string;
  source: string;
  status: string;
  filename: string;
  mimetype: string;
  originalPath: string;
  key: string;
}

interface FileUploadModalBaseProps {
  onUploaded: (files: FilestackFile[]) => void;
  cropRatio?: number;
  accept?: string | string[];
  fromSources: FilestackSources[];
  maxSize: number;
  maxFiles: number;
  startUploadingWhenMaxFilesReached?: boolean;
  onFileSelected?: any;
  apiKey?: string;
  clientOptions?: Object;
}

interface FileUploadModalWithButtonProps extends FileUploadModalBaseProps {
  renderButton: (openModal: () => void) => React.ReactNode;
  isOpen?: never;
  onClose?: never;
}

interface FileUploadModalWithoutButtonProps extends FileUploadModalBaseProps {
  renderButton?: never;
  isOpen: boolean;
  onClose: () => void;
}

function FileUploadModal({
  onUploaded = () => {},
  cropRatio,
  accept,
  fromSources = ["local_file_system"],
  maxSize = 5 * 1024 * 1024,
  maxFiles,
  startUploadingWhenMaxFilesReached = false,
  onFileSelected,
  apiKey = FILESTACK_APP_KEY,
  clientOptions = {},
  renderButton,
  isOpen,
  onClose,
}: FileUploadModalWithButtonProps | FileUploadModalWithoutButtonProps) {
  const { t } = useTranslation("ui-components-classroom\\FilestackUpload");
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const { updateFilestackMetadata } = useUpdateFilestackMetadataMutation();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const actionOptions: PickerOptions = {
    fromSources,
    maxSize,
    maxFiles,
    onFileSelected,
    startUploadingWhenMaxFilesReached,
    onClose,
    allowManualRetry: true,
    onUploadDone: async (result: PickerResponse) => {
      for (const r of result.filesUploaded ?? []) {
        if (!r.key) {
          OsPlatform.captureMessage("Filestack key is missing after upload", {
            extra: {
              fileMetadata: r,
            },
          });
          continue;
        }
        await updateFilestackMetadata(r.handle, r.key);
      }
      if (result.filesUploaded.length > 0) {
        onUploaded(result.filesUploaded as FilestackFile[]);
      }
    },
    onFileUploadFailed: (result: PickerFileMetadata, error: Error) => {
      OsPlatform.captureError(error, {
        extra: {
          fileMetadata: result,
        },
      });
      setErrorMessage(
        t("An error occurred uploading file. Please try again later.")
      );
    },
  };
  if (!!accept) {
    actionOptions.accept = accept;
  }
  if (cropRatio) {
    actionOptions.imageMin = [750, 395];
    actionOptions.transformations = {
      crop: {
        aspectRatio: cropRatio,
        force: true,
      },
      rotate: true,
    };
  }

  // useIsomorphicLayoutEffect so that this works on SSR if it's ever used there
  useIsomorphicLayoutEffect(() => {
    const to = setTimeout(() => {
      const label = document.querySelector(
        'div[title="Unsplash"] > span[class="fsp-source-list__label"]'
      );
      if (label) {
        label.innerHTML = t("Image Search");
      }
    }, 300);
    return () => clearTimeout(to);
  });

  const handleOpenModal = () => {
    setErrorMessage(null);
    setIsModalOpen(true);
  };

  return (
    <>
      {renderButton && renderButton(handleOpenModal)}
      <BasicModal
        open={isOpen !== undefined ? isOpen : isModalOpen}
        onClose={onClose !== undefined ? onClose : () => setIsModalOpen(false)}
        hasCloseButton
        closeButtonArialLabel={t("Close upload modal")}
        modalContentProps={{
          sx: {
            padding: 0,
          },
        }}
        modalCloseButtonProps={{
          sx: (theme: Theme) => ({
            zIndex: theme.zIndex.modal + 1,
          }),
        }}
      >
        {!apiKey || apiKey === "test" ? (
          <Box sx={{ padding: 24 }}>
            <Typography variant="body1">
              {t(
                "File uploader is not configured. Please reload this page and try again."
              )}
            </Typography>
          </Box>
        ) : errorMessage !== null ? (
          <Box sx={{ padding: 24 }}>
            <Typography variant="body1">{errorMessage}</Typography>
          </Box>
        ) : (
          <FilestackPickerInline
            apikey={apiKey}
            clientOptions={clientOptions}
            pickerOptions={actionOptions}
          />
        )}
      </BasicModal>
      <GlobalFilestackTweaksStyle />
    </>
  );
}

const GlobalFilestackTweaksStyle = () => (
  <Global
    styles={css`
      .fsp-picker--inline {
        z-index: 1;
      }
      /*
        From https://github.com/filestack/filestack-js/issues/335#issuecomment-657988398
        This style definition positions the "less than X MB" error at the top of the
        screen, rather than the top of the page, which may be out-of-view
      */
      .fsp-notifications__container {
        position: fixed !important;
      }
    `}
  />
);
