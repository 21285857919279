// All of this has to stay in sync with lang-js/apps/website/app/shared/Section.ts

import { ActivitiesRow, MeetingsRow, SectionsRow } from "@outschool/db-queries";
import { Section as SectionGqlType } from "@outschool/gql-backend-generated";
import * as Time from "@outschool/time";

enum TIMING_STATUS {
  PAST = "past", // TODO(cp): Rename to COMPLETE?
  CURRENT = "current", // TODO(cp): Rename to IN_PROGRESS?
  FUTURE = "future",
}

type ActivityForDurationString = {
  is_club?: boolean;
  isClub?: boolean;
  is_ongoing_weekly: boolean | null;
  weekly_meetings: number | null;
  published_at?: Date | null;
};

type SectionWithSeatAvailability = Pick<SectionsRow, "published_at"> &
  Pick<SectionGqlType, "availableSpaces">;

type SectionWithSeatsToCalculate = Pick<
  SectionsRow,
  "size_max" | "published_at"
> &
  Partial<Pick<SectionGqlType, "filledSpaceCount" | "availableSpaces">>;

export default class Section {
  timingStatus(section: Pick<SectionsRow, "start_time" | "end_time">) {
    const now = new Date();
    if (!!(section && section.end_time && new Date(section.end_time) < now)) {
      return TIMING_STATUS.PAST;
    } else if (
      section &&
      section.start_time &&
      new Date(section.start_time) < now &&
      (!section.end_time || new Date(section.end_time) > now) // no section end_time means ongoing || has not ended
    ) {
      return TIMING_STATUS.CURRENT;
    } else if (
      !!(section && section.start_time && new Date(section.start_time) > now)
    ) {
      return TIMING_STATUS.FUTURE;
    }
    return null;
  }

  isFuture(section: Pick<SectionsRow, "start_time" | "end_time">) {
    return this.timingStatus(section) === TIMING_STATUS.FUTURE;
  }

  isPast(section: Pick<SectionsRow, "start_time" | "end_time">) {
    return this.timingStatus(section) === TIMING_STATUS.PAST;
  }

  fullDurationString({
    activity,
    section,
    userTimeZone,
    showTimes = true,
    nextOngoingMeeting,
    useAltText = false,
  }: {
    activity?: ActivityForDurationString;
    section?: Pick<SectionsRow, "start_time" | "end_time">;
    userTimeZone?: string;
    showTimes?: boolean;
    nextOngoingMeeting?: Pick<MeetingsRow, "start_time"> | null;
    useAltText?: boolean;
  }) {
    if (!activity || !section) {
      return "Suggest a time that works for you";
    }
    if ((activity.is_club || activity.isClub) && activity.published_at) {
      return `Started on ${Time.formatDateTimeWithWeekday(
        activity.published_at,
        userTimeZone
      )}`;
    }
    return this.fullDurationStringNotClub({
      activity,
      section,
      nextOngoingMeeting,
      userTimeZone,
      showTimes,
      useAltText,
    });
  }

  fullDurationStringNotClub({
    activity,
    section,
    userTimeZone,
    showTimes = true,
    nextOngoingMeeting,
    useAltText = false,
  }: {
    activity?: ActivityForDurationString;
    section?: Pick<SectionsRow, "start_time" | "end_time">;
    userTimeZone?: string;
    showTimes?: boolean;
    nextOngoingMeeting?: Pick<MeetingsRow, "start_time"> | null;
    useAltText?: boolean;
  }) {
    if (!activity || !section) {
      return "Suggest a time that works for you";
    }

    if (!section.start_time || !section.end_time) {
      return "Not yet scheduled";
    }

    if (!!activity.is_ongoing_weekly) {
      if (this.isFuture(section)) {
        if (useAltText) {
          return `starting on ${Time.formatDateTimeWithWeekday(
            section.start_time,
            userTimeZone
          )}`;
        }
        return `Starts on ${Time.formatDateTimeWithWeekday(
          section.start_time,
          userTimeZone
        )}`;
      } else {
        return nextOngoingMeeting
          ? `${
              useAltText ? `meeting next on` : `Next meets on`
            } ${Time.formatDateTimeWithWeekday(
              nextOngoingMeeting.start_time,
              userTimeZone
            )}`
          : `${
              useAltText ? `started on` : `Started on`
            }${Time.formatDateTimeWithWeekday(
              section.start_time,
              userTimeZone
            )}`;
      }
    } else if (!activity.weekly_meetings || !showTimes) {
      return Time.fullDurationStringWithoutTimes(
        section.start_time,
        section.end_time,
        userTimeZone
      );
    } else {
      return Time.fullDurationStringWithStartTime(
        section.start_time,
        section.end_time,
        userTimeZone
      );
    }
  }

  getTeacherUid(
    activity: Pick<ActivitiesRow, "user_uid">,
    section: Pick<SectionsRow, "user_uid">
  ): string {
    return section.user_uid || activity.user_uid;
  }

  offeredSpaces: (section: Pick<SectionsRow, "size_max">) => number =
    section => {
      return section.size_max ?? 0;
    };

  availableSpaces = (
    section: Pick<SectionsRow, "size_max"> &
      Partial<Pick<SectionGqlType, "filledSpaceCount">>,
    filledSpaceCount: number = 0
  ) => {
    const available =
      this.offeredSpaces(section) -
      (filledSpaceCount || section.filledSpaceCount || 0);
    return Math.max(0, available);
  };

  publicallyAvailableSpaces = (
    section: SectionWithSeatAvailability | SectionWithSeatsToCalculate,
    filledSpaceCount: number = 0
  ) => {
    return !!section.published_at
      ? section.availableSpaces
        ? section.availableSpaces
        : (section as SectionWithSeatsToCalculate).size_max
        ? this.availableSpaces(
            section as SectionWithSeatsToCalculate,
            filledSpaceCount
          )
        : 0
      : 0;
  };

  selfPacedReleaseTime(
    section: Pick<SectionsRow, "start_time">,
    learnerTimeZone: string,
    weekStart: Date
  ): Date {
    const learnerDay = Time.dayjs(section.start_time).tz(learnerTimeZone).day();
    let releaseTime = Time.dayjs(weekStart)
      .tz(learnerTimeZone)
      .set("day", learnerDay)
      .startOf("day");
    if (releaseTime.isBefore(weekStart)) {
      releaseTime = releaseTime.add(1, "week");
    }
    return releaseTime.toDate();
  }
}
