import { Box, Image, SxProps, Theme, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import flyerImg from "../../../images/Illustration_Flyer_200x200-Color.png";
import ClassCardBase from "../classCard/ClassCardBase";

interface FavoriteClassEmptyProps {
  onClick?: () => void;
  sx?: SxProps;
}

const FavoriteClassEmpty: React.FC<FavoriteClassEmptyProps> = ({
  onClick,
  sx,
}) => {
  if (onClick) {
    return (
      <ClassCardBase
        onClick={onClick}
        sx={[
          (theme: Theme) => ({
            boxShadow: "none",
            width: "100%",
            [theme.breakpoints.up("md")]: {
              width: "240px",
            },
            border: "dashed",
            borderColor: "primary.700",
            borderWidth: "1px",
            cursor: "pointer",
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Box
          flex
          sx={{
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            flex
            sx={(theme: Theme) => ({
              flexDirection: "row",

              [theme.breakpoints.up("md")]: {
                flexDirection: "column",
              },

              alignItems: "center",
              marginY: "1em",
            })}
          >
            <Image
              src={flyerImg}
              sx={(theme: Theme) => ({
                width: "auto",
                height: "100px",

                [theme.breakpoints.up("md")]: {
                  height: "200px",
                },
              })}
            />
            <Typography
              variant="inherit"
              sx={{
                color: "primary.700",
                fontWeight: "fontWeightMedium",
                textAlign: "center",
                marginY: "0.5em",
              }}
            >
              Add a class
            </Typography>
          </Box>
        </Box>
      </ClassCardBase>
    );
  }
  return (
    <ClassCardBase
      sx={[
        (theme: Theme) => ({
          boxShadow: "none",
          width: "100%",
          [theme.breakpoints.up("md")]: {
            width: "240px",
          },
          backgroundColor: "gray7",
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

export default FavoriteClassEmpty;
