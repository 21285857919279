import { useCallback } from "react";
import { useSpring } from "react-spring/web.cjs";

import { useIsMobile } from "./Screen";

export default function useScrollToElement({
  desktopOffset = 0,
}: { desktopOffset?: number } = {}) {
  const isMobile = useIsMobile();

  const [, setScroll] = useSpring(() => ({
    config: { clamp: true, precision: 1 },
    onFrame(props: any) {
      window.scroll(0, props.y);
    },
  }));
  return useCallback(
    (element: HTMLElement | null, onRest = () => {}) => {
      if (element) {
        const y = element.offsetTop - (isMobile ? 60 : desktopOffset);
        setScroll({
          reset: true,
          // @ts-ignore (this works, but types are broken for some reason)
          to: { y },
          from: { y: window.scrollY },
          onRest,
        });
      } else {
        onRest();
      }
    },
    [isMobile, setScroll, desktopOffset]
  );
}
