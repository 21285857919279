import {
  Box,
  Divider,
  Icon,
  SxProps,
  Theme,
  Typography,
} from "@outschool/backpack";
import {
  AssignmentSummaryAssignmentDescriptionFragment,
  AssignmentSummaryAssignmentTitleFragment,
  AssignmentSummaryCardAssignmentFragment,
} from "@outschool/gql-frontend-generated";
import { faFileAlt } from "@outschool/icons";
import { gql } from "@outschool/ui-apollo";
import { AttachmentGallery } from "@outschool/ui-components-shared";
import { LegacyThemeProvider } from "@outschool/ui-legacy-component-library";
import React from "react";

import { MessageContentFragment } from "../../graphql";
import LessonNumber from "../syllabus/LessonNumber";
import { AssignmentMessageContent } from "./AssignmentMessageContent";
import { AssignmentRequirementChip } from "./AssignmentRequirementChip";

const AssignmentSummaryAssignmentTitle = gql`
  fragment AssignmentSummaryAssignmentTitle on AssignmentClassPost {
    title
    publishAt
    submissionRequirement
    dueDate
    lesson {
      uid
      lessonNumber
    }
  }
`;

const AssignmentSummaryAssignmentDescription = gql`
  fragment AssignmentSummaryAssignmentDescription on AssignmentClassPost {
    messageContent {
      ...MessageContentFragment
    }
  }
  ${MessageContentFragment}
`;

interface AssignmentSummaryCardProps {
  assignmentPost: AssignmentSummaryCardAssignmentFragment;
  children: React.ReactNode;
  additionalAssignmentDetails?: React.ReactNode;
  sx?: SxProps;
}
export const AssignmentSummaryCard = ({
  assignmentPost,
  children,
  additionalAssignmentDetails,
  sx,
}: AssignmentSummaryCardProps) => (
  <Box
    id={assignmentPost.uid}
    sx={[
      (theme: Theme) => ({
        width: "100%",
        paddingY: 24,
        paddingX: 36,
        borderRadius: 6,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "neutral.200",
        backgroundColor: "common.white",
        [theme.breakpoints.down("md")]: {
          padding: 16,
        },
      }),
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
  >
    <LessonAssignmentSummaryTitle assignmentPost={assignmentPost} />
    {additionalAssignmentDetails}
    <LessonAssignmentSummaryDescription assignmentPost={assignmentPost} />
    <Divider sx={{ marginY: 24 }} />
    {children}
  </Box>
);
AssignmentSummaryCard.fragments = {
  assignmentSummary: gql`
    fragment AssignmentSummaryCardAssignment on AssignmentClassPost {
      uid
      ...AssignmentSummaryAssignmentTitle
      ...AssignmentSummaryAssignmentDescription
    }
    ${AssignmentSummaryAssignmentTitle}
    ${AssignmentSummaryAssignmentDescription}
  `,
};

interface LessonAssignmentSummaryTitleProps {
  assignmentPost: AssignmentSummaryAssignmentTitleFragment;
}
const LessonAssignmentSummaryTitle = ({
  assignmentPost,
}: LessonAssignmentSummaryTitleProps) => {
  const { lesson } = assignmentPost;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 8,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <LessonNumber lessonNumber={lesson?.lessonNumber} />
        {/* <Button
            variant="text"
            color="inherit"
            size="inherit"
            sx={{ fontSize: 20, padding: 12, margin: -12 }}
          >
            <Icon icon={faEdit} size="inherit" />
          </Button> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 8,
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display: "contents",
          }}
        >
          <Icon icon={faFileAlt} size="inherit" />
          <Typography
            variant="inherit"
            sx={{
              flex: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {assignmentPost.title}
          </Typography>
        </Typography>
        <AssignmentRequirementChip
          submissionRequirement={assignmentPost.submissionRequirement}
        />
      </Box>
    </Box>
  );
};

interface LessonAssignmentSummaryDescriptionProps {
  assignmentPost: AssignmentSummaryAssignmentDescriptionFragment;
}
const LessonAssignmentSummaryDescription = ({
  assignmentPost,
}: LessonAssignmentSummaryDescriptionProps) => {
  const { messageContent } = assignmentPost;
  return (
    <Box>
      <AssignmentMessageContent messageContent={messageContent} />
      <LegacyThemeProvider>
        <AttachmentGallery
          video={messageContent.video}
          attachments={messageContent.attachments}
          containerSx={{ marginTop: 24, marginBottom: 0 }}
        />
      </LegacyThemeProvider>
    </Box>
  );
};
