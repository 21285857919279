import React, { useEffect, useMemo, useRef, useState } from "react";

export type ReplyTo = {
  id: string;
  display: string;
};

type ReplyToContextProps = {
  replyTo: ReplyTo | null;
  setReplyTo(replyTo: ReplyTo | null): void;
  clearReplyTo(): void;
};

const ReplyToContext = React.createContext<ReplyToContextProps | undefined>(
  undefined
);

export const ReplyToProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [replyTo, _setReplyTo] = useState<ReplyTo | null>(null);

  function clearReplyTo() {
    setReplyTo(null);
  }

  function setReplyTo(replyTo: ReplyTo | null) {
    _setReplyTo(replyTo);
  }

  return (
    <ReplyToContext.Provider value={{ replyTo, setReplyTo, clearReplyTo }}>
      {children}
    </ReplyToContext.Provider>
  );
};

export const useReplyToContext = () => {
  const addCommentRef = useRef<HTMLTextAreaElement>();
  const context = React.useContext(ReplyToContext);

  const messageContents = useMemo(() => {
    if (context?.replyTo) {
      return { text: `@[${context.replyTo.display}](${context.replyTo.id}) ` };
    }
    return null;
  }, [context?.replyTo]);

  useEffect(() => {
    if (context?.replyTo && addCommentRef.current) {
      addCommentRef.current.scrollIntoView({ behavior: "smooth" });
      addCommentRef.current.focus();
    }
  }, [context?.replyTo]);

  if (!context) {
    return {
      replyTo: null,
      setReplyTo: () => {},
      clearReplyTo: () => {},
      messageContents: null,
      addCommentRef: null,
    };
  }

  return {
    ...context,
    messageContents,
    addCommentRef,
  };
};
