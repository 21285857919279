import { Box } from "@outschool/backpack";
import { useOnScreen } from "@outschool/ui-utils";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

export default function LazyBox({
  placeholder,
  rootMargin = "0px",
  sx,
  children,
}: {
  placeholder: React.ReactNode;
  rootMargin?: string;
  sx?: SystemStyleObject;
  children: React.ReactNode;
}) {
  const [node, setNode] = React.useState();
  const hasBeenOnScreen = React.useRef(false);
  const isOnScreen = useOnScreen(node, false, rootMargin);
  if (isOnScreen && !hasBeenOnScreen.current) {
    hasBeenOnScreen.current = true;
  }
  return (
    <Box ref={setNode} sx={sx}>
      {hasBeenOnScreen.current ? children : placeholder}
    </Box>
  );
}
