import { Box } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { FilestackImage } from "@outschool/ui-components-shared";
import {
  BoxButton,
  Modal,
  VideoPlaySvg,
  VideoPlayerProps,
  useComponentTrackingContext,
} from "@outschool/ui-legacy-component-library";
import { SystemStyleObject } from "@styled-system/css";
import React, { useState } from "react";

function DefaultImage({
  imageUrl,
  imageSx = {},
}: {
  imageUrl?: string;
  imageSx?: any;
}) {
  return (
    <FilestackImage
      src={imageUrl}
      imageWidth={320}
      imageHeight={230}
      sx={{
        gridArea: "video",
        objectFit: "cover",
        filter: "brightness(0.6)",
        width: "100%",
        height: "100%",
        ...imageSx,
      }}
    />
  );
}

interface VideoModalWithImageFallbackProps {
  videoUrl?: string | undefined | null;
  imageUrl?: string;
  sx?: SystemStyleObject;
  imageSx?: SystemStyleObject;
  trackingName?: string;
  trackingParameters?: { [key: string]: string };
  VideoPlayer: (props: VideoPlayerProps) => JSX.Element;
}

function VideoModalWithImageFallback({
  videoUrl,
  imageUrl,
  sx,
  imageSx = {},
  trackingName,
  trackingParameters = {},
  VideoPlayer,
}: VideoModalWithImageFallbackProps) {
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const track = useComponentTrackingContext();

  function handleVideoPlay(e: React.SyntheticEvent) {
    e.stopPropagation();
    if (trackingName) {
      track(trackingName, trackingParameters, { integrations: { All: false } });
    }
    setVideoModalOpen(true);
  }

  if (!videoUrl) {
    return (
      <Box
        sx={[
          {
            position: "relative",
            height: "100%",
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <DefaultImage imageUrl={imageUrl} imageSx={imageSx} />
      </Box>
    );
  }

  return (
    <BoxButton
      sx={{
        position: "relative",
        cursor: "pointer",
        height: "100%",
        ...sx,
      }}
      onClick={handleVideoPlay}
    >
      <DefaultImage imageUrl={imageUrl} imageSx={imageSx} />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <VideoPlaySvg />
      </Box>
      <Modal
        open={isVideoModalOpen}
        sx={{ minHeight: "auto", padding: "large" }}
        iconButtonSx={{ position: "relative", right: -16, top: -16 }}
        onClose={() => {
          setVideoModalOpen(false);
        }}
      >
        <VideoPlayer
          sx={{ gridArea: "video", width: "100%" }}
          src={videoUrl}
          poster={imageUrl ?? ""}
          autoPlay={true}
          playButtonType="white-center"
        />
      </Modal>
    </BoxButton>
  );
}

export default VideoModalWithImageFallback;
