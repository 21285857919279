import { ClassRecording } from "@outschool/gql-backend-generated";
import {
  MuxClassroomRecordingQuery,
  MuxClassroomRecordingQueryVariables,
} from "@outschool/gql-frontend-generated";
import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";

import { MeetingPostFragments } from "../graphql";

export const isClassRecordingType = (
  recording?: any
): recording is ClassRecording => recording?.__typename === "ClassRecording";

export const muxClassroomRecordingQuery = gql`
  query MuxClassroomRecording($sectionUid: ID!, $recordingUid: ID!) {
    classroom(sectionUid: $sectionUid) {
      uid
      usersCanPost
      activity {
        uid
        user_uid
        title
        weekly_meetings
        duration_weeks
        is_ongoing_weekly
        slug_id
        isClub
        published_at
        hasTeacherSchedule
        ...MeetingPost_activity
      }
      section {
        uid
        currentUserCanManage
        start_time
        end_time
        nextOngoingMeeting {
          uid
          start_time
          end_time
        }
        leader {
          uid
          name
        }
        classRecording(recordingUid: $recordingUid) {
          uid
          startTime
          endTime
          ... on ClassRecording {
            isExpired
            playUrl
          }
          meeting {
            ...MeetingPost_meeting
          }
        }
      }
    }
  }
  ${MeetingPostFragments.activity}
  ${MeetingPostFragments.meeting}
`;

export function useMuxClassRecordingQuery({
  sectionUid,
  recordingUid,
}: {
  sectionUid: string;
  recordingUid: string;
}) {
  const { loading, error, data } = useQueryWithPreviousData<
    MuxClassroomRecordingQuery,
    MuxClassroomRecordingQueryVariables
  >(muxClassroomRecordingQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      sectionUid,
      recordingUid,
    },
  });

  const classroom = data?.classroom;
  const { activity, section, usersCanPost } = classroom || {};
  const recording = section?.classRecording;
  const { startTime, endTime, meeting } = recording || {};
  const isExpired = isClassRecordingType(recording) && recording.isExpired;
  const canManage = section?.currentUserCanManage;

  return {
    loading: loading && !data && !error,
    isExpired,
    error,
    activity,
    section,
    startTime,
    endTime,
    canManage,
    usersCanPost,
    meeting,
  };
}
