import {
  Box,
  Button,
  ButtonProps,
  Icon,
  SxProps,
  Theme,
  visuallyHidden,
} from "@outschool/backpack";
import { falHeart, fasHeart } from "@outschool/icons";
import { useIsMobile } from "@outschool/ui-utils";
import React from "react";
import { animated, useTransition } from "react-spring";

export type HeartButtonProps = {
  isSet: boolean;
  simpleHeart?: any;
  hideLabelOnMobile?: boolean;
  setLabel?: any;
  unsetLabel?: any;
  onChange: any;
  unfilledColor: string;
  twoTone?: boolean;
  displayLabel?: boolean;
} & ButtonProps;

function HeartButton(
  {
    isSet,
    variant,
    simpleHeart,
    hideLabelOnMobile,
    setLabel,
    unsetLabel,
    onChange,
    unfilledColor = "#4B01D4",
    size,
    twoTone = false,
    displayLabel = false,
    ...props
  }: HeartButtonProps,
  ref: React.Ref<HTMLElement>
) {
  const isMobile = useIsMobile();
  const onChangeCallback = React.useCallback(() => {
    return onChange(!isSet);
  }, [onChange, isSet]);
  const onClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onChangeCallback();
  };
  const onKeyDown = (e: any) => {
    if (e.code === "Enter") {
      onChangeCallback();
    }
  };

  return simpleHeart ? (
    <Button
      variant="link"
      onKeyDown={onKeyDown}
      onClick={onClick}
      sx={{
        outline: "none",
        display: "flex",
      }}
      {...props}
      ref={ref as any}
      aria-pressed={isSet}
    >
      <HeartIcon
        isSet={isSet}
        unfilledColor={unfilledColor}
        twoTone={twoTone}
        iconSx={displayLabel ? { padding: "2px 0" } : {}}
      />
      {displayLabel ? (
        <Box
          component="span"
          sx={(theme: Theme) => ({
            color: theme.palette.primary.dark,
            fontWeight: "fontWeightRegular",
          })}
        >
          &ensp;{isSet ? setLabel : unsetLabel}
        </Box>
      ) : (
        <span style={visuallyHidden}>{isSet ? setLabel : unsetLabel}</span>
      )}
    </Button>
  ) : (
    <Button
      variant={variant || "outlined"}
      ref={ref as any}
      size={size}
      onClick={onClick}
      {...props}
    >
      <Box
        flex
        sx={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <HeartIcon
          isSet={isSet}
          unfilledColor={unfilledColor}
          twoTone={twoTone}
        />
        {!(hideLabelOnMobile && isMobile) ? (
          <React.Fragment>&ensp;{isSet ? setLabel : unsetLabel}</React.Fragment>
        ) : (
          <span style={visuallyHidden}>{isSet ? setLabel : unsetLabel}</span>
        )}
      </Box>
    </Button>
  );
}

const AnimatedIcon = animated(Icon);

interface HeartIconProps {
  unfilledColor: string;
  isSet: boolean;
  twoTone: boolean;
  iconSx?: SxProps;
}

function HeartIcon({
  unfilledColor,
  isSet,
  twoTone = false,
  iconSx = {},
  ...props
}: HeartIconProps) {
  const transitions = useTransition(isSet, null, {
    config: { mass: 3, tension: 500, friction: 30 },
    immediate: !isSet,
    from: { transform: "scale(0)" },
    enter: { transform: "scale(1)" },
    leave: { transform: "scale(0)" },
  });

  return (
    <Box
      flex
      sx={[
        {
          position: "relative",
        },
        ...(Array.isArray(iconSx) ? iconSx : [iconSx]),
      ]}
    >
      <Icon
        icon={fasHeart}
        sx={{
          visibility: "hidden",
        }}
        {...props}
      />
      {transitions.map(({ item: isSet, key, props: style }) => (
        <React.Fragment key={key}>
          {twoTone && (
            <AnimatedIcon
              key={`${key}-filter`}
              icon={fasHeart}
              sx={{
                position: "absolute",
                left: 0,
                color: "rgba(0,0,0,0.3)",
              }}
              style={style}
              {...props}
            />
          )}
          <AnimatedIcon
            key={key}
            icon={isSet ? fasHeart : falHeart}
            sx={{
              position: "absolute",
              left: 0,
              color: isSet ? "red" : unfilledColor,
              stroke: isSet || !twoTone ? undefined : unfilledColor,
              strokeWidth: isSet || !twoTone ? undefined : "10px",
            }}
            style={style}
            {...props}
          />
        </React.Fragment>
      ))}
    </Box>
  );
}

export default React.forwardRef(HeartButton);
