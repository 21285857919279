import { Box, SxProps } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import {
  ImpressionTracking,
  TrackingProperties,
} from "@outschool/ui-legacy-component-library";
import lodashGroupBy from "lodash/groupBy";
import React from "react";

import { Attachment, OnDeleteHandler } from "./types";
import { isImageAttachment } from "./util";
import { AttachmentLine, AttachmentThumbnail } from ".";

const splitAttachments = (
  video: Attachment | null | undefined = null,
  attachments: Attachment[] | null = [],
  maxPrimaryThumbnails = 1,
  maxSecondaryThumbnails = 5
) => {
  const { imageAttachments = [], otherAttachments = [] } = lodashGroupBy(
    attachments,
    (attachment: Attachment) =>
      isImageAttachment(attachment) ? "imageAttachments" : "otherAttachments"
  );
  if (video) {
    // NOTE: Removed due to "mimetype" is read-only
    // TODO: discuss more about how to handle this
    // video.file.mimetype = "video/mp4";
    imageAttachments.unshift(video);
  }
  return [
    imageAttachments.slice(0, maxPrimaryThumbnails),
    imageAttachments.slice(
      maxPrimaryThumbnails,
      maxPrimaryThumbnails + maxSecondaryThumbnails
    ),
    imageAttachments
      .slice(maxPrimaryThumbnails + maxSecondaryThumbnails)
      .concat(otherAttachments),
  ];
};

const getEventNameForAttachment = (attachment: Attachment) => {
  return isImageAttachment(attachment) ? "image" : "video";
};

// shows all attachments from as gallery w/
// one large thumbnail, a set of up to 5 little thumbnail and then
interface AttachmentGalleryProps {
  video?: Attachment | null;
  attachments?: Attachment[] | null;
  captionForAttachment?: (attachment: Attachment) => JSX.Element | null;
  trackingLabel?: string;
  trackingMetadata?: TrackingProperties;
  isPostOwner?: boolean;
  senderName?: string | null;
  numPrimaryAttachments?: number;
  numSecondaryAttachments?: number;
  onDelete?: OnDeleteHandler;
  containerSx?: SxProps;
  primaryAttachmentMaxHeight?: string;
  secondaryAttachmentMaxHeight?: string;
}
export default function AttachmentGallery({
  // currently videos that are playable are handled separately from attached videos
  video = null,
  attachments = [],
  captionForAttachment = _attachment => null,
  trackingLabel = "attachment_gallery_no_tracking_label",
  trackingMetadata = {},
  isPostOwner = false,
  senderName,
  numPrimaryAttachments = 1,
  numSecondaryAttachments = 5,
  onDelete = null,
  containerSx,
  primaryAttachmentMaxHeight = "500px",
  secondaryAttachmentMaxHeight = "300px",
}: AttachmentGalleryProps) {
  const { t } = useTranslation("ui-components-shared\\AttachmentGallery");
  senderName = senderName ?? t("an Outschool user");
  const [primaryAttachments, secondaryAttachments, otherAttachments] =
    splitAttachments(
      video,
      attachments,
      numPrimaryAttachments || 0,
      numSecondaryAttachments || 0
    );

  if (
    !primaryAttachments.length &&
    !secondaryAttachments.length &&
    !otherAttachments.length
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        marginBottom: 16,
        ...(Array.isArray(containerSx)
          ? Object.assign({}, ...containerSx)
          : containerSx),
      }}
    >
      {primaryAttachments.length > 0 && (
        <React.Fragment>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 8 }}>
            {primaryAttachments.map((attachment: Attachment) => (
              <React.Fragment key={attachment.uid}>
                <ImpressionTracking
                  trackingLabel={
                    trackingLabel + "_" + getEventNameForAttachment(attachment)
                  }
                  sharedProperties={trackingMetadata}
                  uniqueId={attachment.uid}
                >
                  {({ setNode, trackTouch }) => (
                    <Box ref={setNode}>
                      <AttachmentThumbnail
                        attachment={attachment}
                        isPostOwner={isPostOwner}
                        senderName={senderName}
                        onClick={() => trackTouch()}
                        maxHeight={primaryAttachmentMaxHeight}
                        onDelete={onDelete}
                        caption={captionForAttachment(attachment)}
                      />
                    </Box>
                  )}
                </ImpressionTracking>
              </React.Fragment>
            ))}
          </Box>
        </React.Fragment>
      )}
      {secondaryAttachments.length > 0 && (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 8 }}>
          {secondaryAttachments.map((attachment: Attachment) => (
            <React.Fragment key={attachment.uid}>
              <ImpressionTracking
                trackingLabel={
                  trackingLabel + "_" + getEventNameForAttachment(attachment)
                }
                sharedProperties={trackingMetadata}
                uniqueId={attachment.uid}
              >
                {({ setNode, trackTouch }) => (
                  <Box ref={setNode}>
                    <AttachmentThumbnail
                      attachment={attachment}
                      isPostOwner={isPostOwner}
                      senderName={senderName}
                      onClick={() => trackTouch()}
                      maxHeight={secondaryAttachmentMaxHeight}
                      onDelete={onDelete}
                      caption={captionForAttachment(attachment)}
                    />
                  </Box>
                )}
              </ImpressionTracking>
            </React.Fragment>
          ))}
        </Box>
      )}
      {otherAttachments.length > 0 && (
        <Box
          flex
          sx={{
            flexDirection: "column",
            gap: 16,
          }}
        >
          {otherAttachments.map((a: Attachment) => (
            <AttachmentLine key={a.uid} attachment={a} onDelete={onDelete} />
          ))}
        </Box>
      )}
    </Box>
  );
}
