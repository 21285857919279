import { Box, Button, Icon, SxProps, Theme } from "@outschool/backpack";
import * as icons from "@outschool/icons";
import { Space } from "@outschool/ui-legacy-component-library";
import { responsive } from "@outschool/ui-utils";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import { useClubContext } from "../../providers/ClubProvider";

interface BackgroundColorPaletteProps {
  onClick: React.Dispatch<React.SetStateAction<string>>;
  isPaletteVertical: boolean;
  chosenColor: string;
  sx?: SxProps;
}

export function BackgroundColorPickerPalette({
  onClick,
  isPaletteVertical,
  chosenColor,
  sx,
}: BackgroundColorPaletteProps) {
  const { useClubPostAndCommentBackgroundColors } = useClubContext();
  const { data, loading, error } = useClubPostAndCommentBackgroundColors();

  const clubBackgroundColors = data?.clubPostAndCommentBackgroundColors ?? [];

  if (loading || error || clubBackgroundColors.length === 0) {
    return null;
  }

  const reactSpace = isPaletteVertical ? (
    <Space
      y={responsive({
        default: 6,
        medium: "12px",
        large: 12,
      })}
    />
  ) : (
    <Space
      x={responsive({
        default: 6,
        medium: "12px",
        large: 12,
      })}
    />
  );
  const colorButtonFlexDirection = isPaletteVertical ? "column" : "row";
  return (
    <Box
      sx={[
        (theme: Theme) => ({
          display: "flex",
          alignItems: "center",
          borderRadius: 30,
          padding: isPaletteVertical ? "35%" : "2%",
          background: "white",
          zIndex: 1000,
          maxWidth: "fit-content",
          margin: "auto",
          flexDirection: isPaletteVertical ? "column" : "row",
          transform: isPaletteVertical
            ? "translateX(+70%)"
            : "translateY(-140%)",
          [theme.breakpoints.up("md")]: {
            transform: isPaletteVertical
              ? "translateX(+50%)"
              : "translateY(-120%)",
          },
          [theme.breakpoints.up("lg")]: {
            transform: isPaletteVertical
              ? "translateX(+50%)"
              : "translateY(-120%)",
          },
          overflowX: isPaletteVertical ? undefined : "auto",
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {clubBackgroundColors.map((color, colorIndex) => (
        <Box
          key={color}
          sx={{
            display: "flex",
            flexDirection: colorButtonFlexDirection,
          }}
        >
          <Button
            variant="link"
            sx={(theme: Theme) => ({
              alignItems: "center",
              width: isPaletteVertical ? "20px" : "30px",

              [theme.breakpoints.up("md")]: {
                width: "30px",
                height: "30px",
              },

              [theme.breakpoints.up("lg")]: {
                width: "30px",
                height: "30px",
              },

              height: isPaletteVertical ? "20px" : "30px",
              borderRadius: 30,
              background: color,
              verticalAlign: "bottom",
            })}
            onClick={() => onClick(color)}
          >
            {color === chosenColor && (
              <Icon
                sx={{
                  color: "common.white",
                }}
                icon={icons.faCheck}
              />
            )}
          </Button>

          {clubBackgroundColors.length - 1 !== colorIndex && reactSpace}
        </Box>
      ))}
    </Box>
  );
}
