import { Box } from "@outschool/backpack";
import React, { FC, ReactNode } from "react";

import useHighlight from "../../../hooks/useHighlight";

export interface ClassPostCommentProps {
  isNew?: boolean;
  children: ReactNode;
}

const ClassPostComment: FC<ClassPostCommentProps> = ({ isNew, children }) => {
  const [highlight] = useHighlight(isNew ?? false);

  return (
    <Box
      sx={{
        backgroundColor: highlight ? "#fffaea" : undefined,
        transition: "background-color 2s ease",
      }}
      data-test-id="ClassPostComment"
    >
      {children}
    </Box>
  );
};

export default ClassPostComment;
