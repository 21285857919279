import { Image } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Modal } from "@outschool/ui-legacy-component-library";
import React from "react";

interface ImageModalProps {
  open: boolean;
  onClose: () => void;
  imageUrl: string | undefined;
}

export default function ImageModal({
  open,
  onClose,
  imageUrl,
}: ImageModalProps) {
  if (!imageUrl) {
    return null;
  }
  return (
    <Modal
      open={open}
      sx={{ minHeight: "auto", padding: "large" }}
      iconButtonSx={{ position: "relative", right: -16, top: -16 }}
      onClose={onClose}
    >
      <Image
        sx={{
          width: "100%",
        }}
        src={imageUrl}
        alt=""
      />
    </Modal>
  );
}
