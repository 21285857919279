import { gql } from "@outschool/ui-apollo";

import { MessageContentFragment } from "./MessageContent";
import { MessageSenderFragment } from "./MessageSender";

export const PrivateClassMessageFragment = gql`
  fragment PrivateClassMessageFragment on PrivateClassMessage {
    uid
    thread_uid
    sentAt
    sender {
      ...MessageSenderFragment
    }
    messageContent {
      ...MessageContentFragment
    }
  }
  ${MessageSenderFragment}
  ${MessageContentFragment}
`;
