import { Box, Video, styled } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import { ErrorMessage } from "@outschool/ui-components-shared";
import {
  Loading,
  TrackingPageSection,
  useImpressionTracking,
} from "@outschool/ui-legacy-component-library";
import * as React from "react";

import logo from "../../images/Outschool_Logo_Color.svg";
import { isClassRecordingType } from "../hooks";

import type {
  ClassRecordingStatusQuery,
  ClassRecordingStatusQueryVariables,
} from "..";

const classRecordingStatusQuery = gql`
  query ClassRecordingStatus($recordingUid: ID!, $sectionUid: ID!) {
    section(uid: $sectionUid) {
      uid
      classRecording(recordingUid: $recordingUid) {
        uid
        playUrl
        ... on ClassRecording {
          readyToPlay
          isExpired
        }
      }
    }
  }
`;

function useClassRecordingStatusQuery(
  sectionUid: string,
  recordingUid: string
) {
  const { error, data } = useQueryWithPreviousData<
    ClassRecordingStatusQuery,
    ClassRecordingStatusQueryVariables
  >(classRecordingStatusQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      sectionUid: sectionUid,
      recordingUid: recordingUid,
    },
  });

  const recording = data?.section?.classRecording;
  const readyToPlay = isClassRecordingType(recording) && !!recording?.playUrl;

  return {
    statusError: error,
    playUrl: recording?.playUrl,
    readyToPlay,
  };
}

const StyledImage = styled("img")({});

export default function PlayerOrProcessingMessage({
  sectionUid,
  recordingUid,
  handlePlayClick,
}: {
  sectionUid: string;
  recordingUid: string;
  handlePlayClick?: () => void;
}) {
  const { t } = useTranslation(
    "ui-components-classroom\\PlayerOrProcessingMessage"
  );

  const { statusError, playUrl, readyToPlay } = useClassRecordingStatusQuery(
    sectionUid,
    recordingUid
  );

  const [playButtonNode, setPlayButtonNode] = React.useState<SVGElement>();
  const { trackTouch: videoPlayTouch } = useImpressionTracking({
    node: playButtonNode,
    trackingLabel: "playVideo",
    uniqueId: recordingUid,
  });

  const onPlayClick = () => {
    handlePlayClick?.();
    videoPlayTouch({ clicked: true });
  };

  if (statusError) {
    return <ErrorMessage value={statusError} />;
  }

  if (readyToPlay) {
    return (
      <TrackingPageSection name="s3Recording" uniqueId="s3Recording">
        {refNode => (
          <Video
            trackingRef={refNode}
            src={playUrl!}
            title={t("Class Video Recording")}
            splash={
              <StyledImage
                src={logo}
                // eslint-disable-next-line i18next/no-literal-string
                alt={"Outschool"}
                sx={{ width: "75%", padding: "1em" }}
              />
            }
            playButtonRef={(ref: SVGElement) => setPlayButtonNode(ref)}
            onPlayClick={onPlayClick}
            onVideoError={error => {
              //duplicating error so it won't be deduped by sentry
              //.. is this still necessary?
              const wrapperError = new Error(
                `S3 Video Playback error: ${error.message}`
              );
              wrapperError.name = error.name;
              wrapperError.stack = error.stack;
              OsPlatform.captureError(wrapperError, {
                tags: {
                  package: "ui-components-classroom",
                  s3_video_playback_error: true,
                },
              });
            }}
            errorMessage={
              <>
                {t("Sorry, that didn't work. We're looking into it.")}
                <br />
                {t("You can reload the page or contact support@outschool.com.")}
              </>
            }
          />
        )}
      </TrackingPageSection>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {t(
        "One moment - we’re processing the recording, it should be ready momentarily."
      )}
      <Loading />
    </Box>
  );
}
