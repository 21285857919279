import { Box } from "@outschool/backpack";
import { Loading } from "@outschool/ui-legacy-component-library";
import { usePreviousSet } from "@outschool/ui-utils";
import React, { FC, ReactElement, cloneElement } from "react";

import { ClassPostCommentProps } from "../ClassPostComment/ClassPostComment";
import LoadMoreButton from "../common/LoadMoreButton";

type Comment = ReactElement<ClassPostCommentProps>;

export type ClassPostCommentListProps = {
  remainingCommentsCount: number;
  onLoadMore?(): void;
  children: Comment[] | null;
};

/**
 * Handles the load more and is new logic for listed components.
 * Requires an array of ``` <ClassPostComment />``` to be passed as children.
 * If you have loading state then pass null and it will automatically
 * render a loading spinner.
 * ```jsx
 *  <ClassPostCommentList
        remainingCommentsCount={remainingCommentsCount}
        onLoadMore={fetchRestOfComments}
      >
        {loading
          ? null
          : comments.map(comment => {
              return (
                <ClassPostComment key={comment.uid}>
                  ...
                </ClassPostComment>
              );
            })}
      </ClassPostCommentList>
 * ```
 */
const ClassPostCommentList: FC<ClassPostCommentListProps> = ({
  remainingCommentsCount,
  onLoadMore,
  children,
}) => {
  const seenCommentUids = usePreviousSet(children?.map(c => c.key) ?? []);

  return (
    <Box>
      {onLoadMore && (
        <LoadMoreButton
          remainingCount={remainingCommentsCount}
          onClick={onLoadMore}
        />
      )}
      {!children && <Loading />}
      {children &&
        children.map(child =>
          cloneElement(child, {
            isNew: !seenCommentUids.has(child.key),
          })
        )}
    </Box>
  );
};

export default ClassPostCommentList;
