import { CurrencyCode, UserLocale } from "@outschool/gql-backend-generated";

// The locales in which the app is supported
// Distinct from the UserLocale gql type, which represents
// the user's preferred locale for emails
export enum I18nLocale {
  En = "en",
  Ko = "ko",
  Ja = "ja",
  ZhTw = "zh-TW",
  Es = "es",
}
export const SUPPORTED_LOCALES = Object.values(I18nLocale);

export const BASE_LOCALE = I18nLocale.En;

export type CurrencyInfo = {
  code: CurrencyCode;
  symbol: string;
  name: string;
  precision: number;
  disclaimer?: string;
  defaultLocales: I18nLocale[];
};

export type FlagInfo = {
  imageAlt: string;
  code: string;
};

export type CountryInfo = {
  currency: CurrencyInfo;
  flag: FlagInfo;
  code: CountryCode;
};

type CountryInfoMap = {
  [countryCode in CountryCode]: CountryInfo;
};

type CurrencyInfoMap = {
  [currencyCode in CurrencyCode]: CurrencyInfo;
};

export enum CountryCode {
  US = "US",
  GB = "GB",
  CA = "CA",
  AU = "AU",
  NZ = "NZ",
  KR = "KR",
  TH = "TH",
  IN = "IN",
  TW = "TW",
  JP = "JP",
  HK = "HK",
  MX = "MX",
  EU = "EU",
}

export const CURRENCIES_INFO: CurrencyInfoMap = {
  [CurrencyCode.Usd]: {
    code: CurrencyCode.Usd,
    symbol: "$",
    precision: 2,
    name: "United States Dollar",
    defaultLocales: [I18nLocale.En, I18nLocale.ZhTw],
  },
  [CurrencyCode.Gbp]: {
    code: CurrencyCode.Gbp,
    symbol: "£",
    name: "Pound Sterling",
    precision: 2,
    disclaimer:
      "The price in pounds is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Cad]: {
    code: CurrencyCode.Cad,
    symbol: "$",
    name: "Canadian Dollar",
    precision: 2,
    disclaimer:
      "The price in Canadian Dollars is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Aud]: {
    code: CurrencyCode.Aud,
    symbol: "$",
    name: "Australian Dollar",
    precision: 2,
    disclaimer:
      "The price in Australian Dollars is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Nzd]: {
    code: CurrencyCode.Nzd,
    symbol: "$",
    name: "New Zealand Dollar",
    precision: 2,
    disclaimer:
      "The price in New Zealand Dollars is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Krw]: {
    code: CurrencyCode.Krw,
    symbol: "₩",
    name: "Korean Republic Won",
    precision: 0,
    disclaimer:
      "The price in Korean Republic Won is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [I18nLocale.Ko],
  },
  [CurrencyCode.Thb]: {
    code: CurrencyCode.Thb,
    symbol: "฿",
    name: "Thai Baht",
    precision: 2,
    disclaimer:
      "The price in Thai Baht is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Inr]: {
    code: CurrencyCode.Inr,
    symbol: "₹",
    name: "Indian Rupee",
    precision: 2,
    disclaimer:
      "The price in Indian Rupees is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Twd]: {
    code: CurrencyCode.Twd,
    symbol: "$",
    name: "New Taiwan Dollar",
    precision: 2,
    disclaimer:
      "The price in New Taiwan Dollars is for reference only. Outschool will charge your financial institution the USD amount.",
    // can't be the default currency for zh-TW because zh-TW is also used for Hong Kong
    defaultLocales: [],
  },
  [CurrencyCode.Jpy]: {
    code: CurrencyCode.Jpy,
    symbol: "¥",
    precision: 0,
    name: "Japanese Yen",
    disclaimer:
      "The price in Japanese Yen is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [I18nLocale.Ja],
  },
  [CurrencyCode.Hkd]: {
    code: CurrencyCode.Hkd,
    symbol: "$",
    name: "Hong Kong Dollar",
    precision: 2,
    disclaimer:
      "The price in Hong Kong Dollars is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Mxn]: {
    code: CurrencyCode.Mxn,
    symbol: "$",
    name: "Mexican Peso",
    precision: 2,
    disclaimer:
      "The price in Mexican Pesos is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
  [CurrencyCode.Eur]: {
    code: CurrencyCode.Eur,
    symbol: "€",
    name: "Euro",
    precision: 2,
    disclaimer:
      "The price in Euros is for reference only. Outschool will charge your financial institution the USD amount.",
    defaultLocales: [],
  },
};

export const BASE_CURRENCY = CURRENCIES_INFO[CurrencyCode.Usd];
export const USD_STATIC_EXCHANGE_RATE = 1;
export const BASE_FREE_PRICE = "FREE";

// Fun fact: there are two in use currencies in the world that don't have a
// base-10 currency: the Mauritanian ouguiya (MRU) and Malagasy ariary (MGA) both
// use base-5.
// Until we have to support Madagascar and Mauritania, let's keep this simple.
export const STANDARD_CURRENCY_RADIX = 10;

export const COUNTRIES_INFO: CountryInfoMap = {
  [CountryCode.US]: {
    currency: CURRENCIES_INFO[CurrencyCode.Usd],
    flag: {
      imageAlt: "Flag of the United States",
      code: "US",
    },
    code: CountryCode.US,
  },
  [CountryCode.GB]: {
    currency: CURRENCIES_INFO[CurrencyCode.Gbp],
    flag: {
      imageAlt: "Flag of the United Kingdom",
      code: "GB",
    },
    code: CountryCode.GB,
  },
  [CountryCode.CA]: {
    currency: CURRENCIES_INFO[CurrencyCode.Cad],
    flag: {
      imageAlt: "Flag of Canada",
      code: "CA",
    },
    code: CountryCode.CA,
  },
  [CountryCode.AU]: {
    currency: CURRENCIES_INFO[CurrencyCode.Aud],
    flag: {
      imageAlt: "Flag of Australia",
      code: "AU",
    },
    code: CountryCode.AU,
  },
  [CountryCode.NZ]: {
    currency: CURRENCIES_INFO[CurrencyCode.Nzd],
    flag: {
      imageAlt: "Flag of New Zealand",
      code: "NZ",
    },
    code: CountryCode.NZ,
  },
  [CountryCode.KR]: {
    currency: CURRENCIES_INFO[CurrencyCode.Krw],
    flag: {
      imageAlt: "Flag of the Republic of Korea",
      code: "KR",
    },
    code: CountryCode.KR,
  },
  [CountryCode.TH]: {
    currency: CURRENCIES_INFO[CurrencyCode.Thb],
    flag: {
      imageAlt: "Flag of Thailand",
      code: "TH",
    },
    code: CountryCode.TH,
  },
  [CountryCode.IN]: {
    currency: CURRENCIES_INFO[CurrencyCode.Inr],
    flag: {
      imageAlt: "Flag of India",
      code: "IN",
    },
    code: CountryCode.IN,
  },
  [CountryCode.TW]: {
    currency: CURRENCIES_INFO[CurrencyCode.Twd],
    flag: {
      imageAlt: "Flag of Taiwan",
      code: "TW",
    },
    code: CountryCode.TW,
  },
  [CountryCode.JP]: {
    currency: CURRENCIES_INFO[CurrencyCode.Jpy],
    flag: {
      imageAlt: "Flag of Japan",
      code: "JP",
    },
    code: CountryCode.JP,
  },
  [CountryCode.HK]: {
    currency: CURRENCIES_INFO[CurrencyCode.Hkd],
    flag: {
      imageAlt: "Flag of Hong Kong",
      code: "HK",
    },
    code: CountryCode.HK,
  },
  [CountryCode.MX]: {
    currency: CURRENCIES_INFO[CurrencyCode.Mxn],
    flag: {
      imageAlt: "Flag of Mexico",
      code: "MX",
    },
    code: CountryCode.MX,
  },
  [CountryCode.EU]: {
    currency: CURRENCIES_INFO[CurrencyCode.Eur],
    flag: {
      imageAlt: "Flag of The European Union",
      code: "EU",
    },
    code: CountryCode.EU,
  },
};

export const ACTIVELY_CHARGED_CURRENCIES = [
  CurrencyCode.Usd,
  CurrencyCode.Cad,
  CurrencyCode.Gbp,
  CurrencyCode.Jpy,
  CurrencyCode.Krw,
  CurrencyCode.Aud,
  CurrencyCode.Hkd,
  CurrencyCode.Twd,
  CurrencyCode.Eur,
];

export type SupportedLanguage = {
  i18nLocale: I18nLocale;
  userLocale: UserLocale;
  localLanguageName: string;
};

export const SUPPORTED_LANGUAGES: SupportedLanguage[] = [
  {
    // English
    i18nLocale: I18nLocale.En,
    userLocale: UserLocale.En,
    localLanguageName: "English",
  },
  {
    // Korean
    i18nLocale: I18nLocale.Ko,
    userLocale: UserLocale.Ko,
    localLanguageName: "한국어",
  },
  {
    // Japanese
    i18nLocale: I18nLocale.Ja,
    userLocale: UserLocale.Ja,
    localLanguageName: "日本語",
  },
  {
    // Chinese (Traditional)
    i18nLocale: I18nLocale.ZhTw,
    userLocale: UserLocale.ZhTw,
    localLanguageName: "國語(繁體)",
  },
  {
    // Spanish (Latin America)
    i18nLocale: I18nLocale.Es,
    userLocale: UserLocale.Es,
    localLanguageName: "Español",
  },
];

// Query param key used to configure the page locale
export const LOCALE_QUERY_PARAM_KEY = "locale";

export type CountryOfResidence = {
  code: "us" | "gb" | "ca" | "au" | "nz" | "in" | "kr" | "mx";
  name: string;
  icon: string;
  prefix?: string;
  hidden?: boolean;
};

export const COUNTRIES_OF_RESIDENCE: CountryOfResidence[] = [
  {
    code: "us",
    name: "United States",
    icon: "🇺🇸",
    prefix: "the",
  },
  {
    code: "gb",
    name: "United Kingdom",
    icon: "🇬🇧",
    prefix: "the",
  },
  {
    code: "ca",
    name: "Canada",
    icon: "🇨🇦",
  },
  {
    code: "au",
    name: "Australia",
    icon: "🇦🇺",
  },
  {
    code: "nz",
    name: "New Zealand",
    icon: "🇳🇿",
  },
  {
    code: "in",
    name: "India",
    icon: "🇮🇳",
    // India is a hidden option, meaning the user cannot select this themselves.
    // An admin must set this manually for it to appear.
    hidden: true,
  },
  {
    code: "kr",
    name: "South Korea",
    icon: "🇰🇷",
  },
  {
    code: "mx",
    name: "Mexico",
    icon: "🇲🇽",
  },
];

export const LineLocales = [I18nLocale.Ja, I18nLocale.ZhTw];

export const KakaoLocales = [I18nLocale.Ko];
