import {
  Box,
  Icon,
  IconProps,
  SxProps,
  Typography,
  TypographyProps,
  visuallyHidden,
} from "@outschool/backpack";
import { fasStar } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import React from "react";

export default function SimpleRatings({
  starRating,
  reviewCount,
  onClickViewReviews,
  spellOutReviews = false,
  isBolded = true,
  typographyVariant = "caption",
  ratingSx = {},
  reviewsSx = {},
  starSize = "inherit",
}: {
  starRating: number | null;
  reviewCount: number | null;
  onClickViewReviews?: (event: React.SyntheticEvent) => void;
  spellOutReviews?: boolean;
  isBolded?: boolean;
  typographyVariant?: TypographyProps["variant"];
  ratingSx?: SxProps;
  reviewsSx?: SxProps;
  starSize?: IconProps["size"];
}) {
  const { t } = useTranslation(
    "ui-components-website\\Reviews\\StarAndNumberRatings"
  );
  return reviewCount && starRating != null ? (
    <Typography
      as={Box}
      emphasized={isBolded ? true : false}
      variant={typographyVariant}
    >
      <Box
        flex
        sx={{
          alignItems: "center",
          columnGap: typographyVariant == "caption" ? "4px" : "8px",
          cursor: onClickViewReviews ? "pointer" : "inherit",
        }}
        onClick={onClickViewReviews}
      >
        <Icon icon={fasStar} size={starSize} color="warning" />
        <Typography as={Box} sx={visuallyHidden}>
          {t("Average rating:")}
        </Typography>
        <Typography
          as={Box}
          variant="inherit"
          emphasized={isBolded ? true : false}
          sx={{
            color: "textDefault",
            textDecoration: "none",
            fontWeight: isBolded ? "inherit" : "500",

            ...ratingSx,
          }}
        >
          {(Math.round((starRating + Number.EPSILON) * 10) / 10).toFixed(1)}
        </Typography>
        <Typography sx={visuallyHidden}>{t("Number of reviews:")}</Typography>
        <Typography
          as={Box}
          variant={typographyVariant}
          sx={{
            color: "grey.500",
            textDecoration: "none",
            fontVariantLigatures: "none",
            ...reviewsSx,
          }}
        >
          ({reviewCount.toLocaleString()}
          {spellOutReviews ? " " + t("reviews") : ""})
        </Typography>
      </Box>
    </Typography>
  ) : null;
}
