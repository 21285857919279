import { ReactionType } from "@outschool/animation-resources";
import {
  ClubPostAndCommentBackgroundColorsQuery,
  ClubPostQueryVariables,
  ClubQueryVariables,
  CurrentLearnerFragmentFragment,
  FullCurrentUserFragmentFragment,
} from "@outschool/gql-frontend-generated";
import React from "react";

export type ClubContextProps = {
  clubHomePath: string;
  getClubPostPath: (classPostUid: string) => string;
  readOnly: boolean;
  learnerProfileRoute: ({ uid }: { uid: string }) => string;
  currentUserOrLearner:
    | CurrentLearnerFragmentFragment
    | FullCurrentUserFragmentFragment;
  sectionUid: string;
  clubPostQueryVariables?: ClubPostQueryVariables;
  clubQueryVariables?: ClubQueryVariables;
  page: "home" | "post";
  initialIsPrivateClassMessageThreadOpen?: boolean;
  LottieReact: React.ComponentType<any>;
  loadLottieMap: () => Promise<Record<ReactionType, object>>;
  useClubPostAndCommentDefaultBackgroundColor: ({
    senderUid,
  }: {
    senderUid: string;
  }) => string;
  useClubPostAndCommentBackgroundColors: () => {
    data: ClubPostAndCommentBackgroundColorsQuery | undefined;
    loading: boolean;
    error?: Error | undefined;
  };
};

const ClubContext = React.createContext<ClubContextProps | undefined>(
  undefined
);

export function ClubProvider({
  children,
  ...props
}: React.PropsWithChildren<ClubContextProps>) {
  return <ClubContext.Provider value={props}>{children}</ClubContext.Provider>;
}

export const useClubContext = () => {
  const context = React.useContext(ClubContext);

  if (!context) {
    throw new Error("ClubProvider is missing from the component tree");
  }

  return context;
};
