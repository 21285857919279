// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Button, Icon, Theme, Typography } from "@outschool/backpack";
import { LessonLiveMeeting } from "@outschool/gql-backend-generated";
import {
  faBell,
  faClock,
  faLockRegular,
  faVideoRegular,
} from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import * as Time from "@outschool/time";
import { dayjs } from "@outschool/time";
import { ExternalLink, TrackedButton } from "@outschool/ui-components-shared";
import {
  ConfirmationModal,
  useImpressionTracking,
} from "@outschool/ui-legacy-component-library";
import { useIsMobile, useLinkComponent } from "@outschool/ui-utils";
import React from "react";

import { useLiveMeeting } from "../hooks/JoinMeeting";
import { LessonMeetingState, getLessonMeetingState } from "../lib/util";
import theme from "@outschool/backpack/dist-cjs/theme/theme";

const TEST_ZOOM_CONNECTION_URL = "https://outschool.zoom.us/test";

type LiveMeetingButtonProps = {
  sectionUid: string;
  learnerUid: string;
  userTimeZone?: string;
  sectionJoinPathFn: (
    section: { uid: string },
    enrollment: { uid: string | null }
  ) => string;
  isLocked?: boolean;
  isMissed?: boolean;
};

export default function LiveMeetingButton({
  sectionUid,
  learnerUid,
  userTimeZone,
  sectionJoinPathFn,
}: LiveMeetingButtonProps) {
  const { data, loading, error } = useLiveMeeting(sectionUid, learnerUid);

  if (loading || !data) {
    return null;
  }
  if (!loading && error) {
    // TODO: Show the error message in modal
    // Sentry.logException(error);
    return null;
  }

  const {
    canManage,
    meetingStartTime,
    meetingEndTime,
    usersCanHaveLiveMeeting,
    isFlexSchedule,
    meetingsCount,
    sectionStartTime,
    enrollmentUid,
    isSellerOrg,
  } = data.joinMeeting;

  if (!canManage && !learnerUid) {
    return null;
  }

  const meetingStartTimeFormatted = meetingStartTime
    ? dayjs.tz(meetingStartTime, userTimeZone!)
    : null;

  let nextMeetingMessage;
  if (!usersCanHaveLiveMeeting || isFlexSchedule) {
    nextMeetingMessage = "";
  } else if (!meetingStartTimeFormatted) {
    nextMeetingMessage = `The ${
      meetingsCount > 1 ? "last" : "live"
    } meeting has ended`;
  } else if (!meetingStartTimeFormatted.isAfter()) {
    nextMeetingMessage = "Meeting in progress";
  } else {
    nextMeetingMessage = `${
      meetingsCount > 1 ? "Next" : "The live"
    } meeting starts ${
      meetingStartTimeFormatted.isAfter(dayjs().endOf("day"))
        ? Time.startDayShortString(meetingStartTimeFormatted, userTimeZone)
        : "today"
    } at ${Time.formatTime(meetingStartTimeFormatted, userTimeZone)}`;
  }

  return (
    <div>
      <Box
        sx={{
          marginBottom: "0.5em",
        }}
        id="message"
      >
        {nextMeetingMessage}
      </Box>
      <MeetingButton
        userTimeZone={userTimeZone}
        isFlexSchedule={isFlexSchedule}
        canManage={canManage}
        sectionStartTime={sectionStartTime}
        meetingStartTime={meetingStartTime}
        meetingEndTime={meetingEndTime}
        enrollmentUid={enrollmentUid}
        sectionUid={sectionUid}
        disabled={!usersCanHaveLiveMeeting}
        sectionJoinPathFn={sectionJoinPathFn}
        isSellerOrg={!!isSellerOrg}
      />
    </div>
  );
}

type MeetingButtonProps = {
  userTimeZone?: string;
  isFlexSchedule: boolean;
  canManage: boolean;
  sectionStartTime: any;
  meetingStartTime: any;
  meetingEndTime: any;
  enrollmentUid: string | null;
  sectionUid: string;
  disabled: boolean;
  isSellerOrg: boolean | null;
  sectionJoinPathFn: (
    section: { uid: string },
    enrollment: { uid: string | null }
  ) => string;
};

function MeetingButton({
  userTimeZone,
  isFlexSchedule,
  canManage,
  sectionStartTime,
  meetingStartTime,
  meetingEndTime,
  enrollmentUid,
  sectionUid,
  disabled,
  isSellerOrg,
  sectionJoinPathFn,
}: MeetingButtonProps) {
  const { t } = useTranslation("ui-components-classroom\\LiveMeetingButton");
  const Link = useLinkComponent();

  const shouldShowTestButtonNow = React.useCallback(
    () =>
      !canManage &&
      sectionStartTime &&
      dayjs().isBefore(dayjs(sectionStartTime).subtract(15, "minutes")),
    [canManage, sectionStartTime]
  );
  const [showTestButton, setShowTestButton] = React.useState(
    shouldShowTestButtonNow
  );

  const [confirmationModalContent, setConfirmationModalContent] =
    React.useState<string | null>(null);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setShowTestButton(shouldShowTestButtonNow()),
      1000 * 60
    );
    return () => clearInterval(intervalId);
  }, [shouldShowTestButtonNow]);

  const impressionNodeRef = React.useRef<HTMLButtonElement | null>(null);

  const { trackTouch } = useImpressionTracking({
    uniqueId: "live-meeting-tracker",
    node: impressionNodeRef.current,
    trackingLabel: canManage ? "start_meeting" : "join_meeting",
  });

  const isMobile = useIsMobile();

  if (showTestButton) {
    return (
      <TrackedButton
        variant="contained"
        component={ExternalLink}
        url={TEST_ZOOM_CONNECTION_URL}
        trackingName="test_meeting"
        fullWidth={isMobile}
      >
        {t("Test Zoom Connection")}
      </TrackedButton>
    );
  }

  const partialEnrollment = {
    uid: enrollmentUid,
  };
  const partialSection = { uid: sectionUid };
  const onlineClassroomPath = sectionJoinPathFn(
    partialSection,
    partialEnrollment
  );

  /* A parent/learner may have reached this classroom via a link, but has no active enrollment (e.g., an ended ongoing class). */
  const canJoinMeeting = Boolean(canManage || enrollmentUid);
  const goToLiveMeeting = () => {
    trackTouch();
    window.open(onlineClassroomPath, "_blank");
  };
  const meetingStartTimeByTz = dayjs.tz(meetingStartTime, userTimeZone);
  const meetingEndTimeByTz = dayjs.tz(meetingEndTime, userTimeZone);

  const hasNextMeetingOrMeetingInProgress =
    meetingStartTimeByTz.isValid() && meetingEndTimeByTz.isValid();

  const showConfirmationDialogOrStartLiveMeeting = () => {
    const currentTimeByTz = dayjs.tz(userTimeZone);

    if (
      !isFlexSchedule &&
      hasNextMeetingOrMeetingInProgress &&
      currentTimeByTz.isBefore(
        meetingStartTimeByTz.clone().subtract(0.5, "hours")
      )
    ) {
      showConfirmationDialog(
        `This section’s next meeting is not scheduled until ${meetingStartTimeByTz.format(
          "MMMM Do [at] h:mm a z"
        )}, ${meetingStartTimeByTz.fromNow(
          true
        )} from now. Are you sure you want to start this meeting?`
      );
    } else if (
      !isFlexSchedule &&
      (!hasNextMeetingOrMeetingInProgress ||
        currentTimeByTz.isAfter(meetingEndTimeByTz))
    ) {
      showConfirmationDialog(
        "This class has already ended. Are you sure you want to start this meeting?"
      );
    } else {
      goToLiveMeeting();
    }
  };

  const showConfirmationDialog = (content: string) =>
    setConfirmationModalContent(content);

  const closeConfirmationDialog = () => setConfirmationModalContent(null);

  const onStartLiveMeeting = (e: any) => {
    e.preventDefault();
    showConfirmationDialogOrStartLiveMeeting();
  };

  if (canManage) {
    return (
      <>
        <ConfirmationModal
          open={!!confirmationModalContent}
          onClose={closeConfirmationDialog}
          content={confirmationModalContent}
          onConfirm={() => {
            goToLiveMeeting();
          }}
          headline="Is this the right class?"
          confirmationLabel="Start Meeting Anyway"
        />
        <Button
          variant="contained"
          ref={impressionNodeRef}
          disabled={isSellerOrg || disabled || !canJoinMeeting}
          fullWidth={isMobile}
          onClick={onStartLiveMeeting}
        >
          Start Live Meeting
        </Button>
      </>
    );
  }

  return (
    <TrackedButton
      variant="contained"
      disabled={disabled || !canJoinMeeting}
      fullWidth={isMobile}
      component={Link}
      to={onlineClassroomPath}
      url={onlineClassroomPath}
      target="_blank"
      trackingName="join_meeting"
    >
      {t("Join Live Meeting")}
    </TrackedButton>
  );
}

type LessonLiveMeetingProps = Pick<
  LiveMeetingButtonProps,
  "sectionUid" | "sectionJoinPathFn" | "isLocked" | "userTimeZone" | "isMissed"
> & {
  liveMeeting: LessonLiveMeeting;
  classRecordingPathFn: (
    sectionUid: string,
    recordingUid?: string | null
  ) => string;
};

type MakeupMeetingButtonProps = {
  sectionUid: string;
  liveMeeting?: Pick<
    LessonLiveMeeting,
    | "meetingStartTime"
    | "meetingEndTime"
    | "usersCanHaveLiveMeeting"
    | "meetingsCount"
    | "enrollmentUid"
  >;
  userTimeZone: string;
  sectionJoinPathFn: (
    section: { uid: string },
    enrollment: { uid: string }
  ) => string;
  isLocked?: boolean;
};
export function LessonMakeupMeetingSection({
  sectionUid,
  liveMeeting,
  userTimeZone,
  sectionJoinPathFn,
  isLocked = false,
}: MakeupMeetingButtonProps) {
  if (!liveMeeting) {
    return null;
  }

  const {
    meetingStartTime,
    meetingEndTime,
    usersCanHaveLiveMeeting,
    meetingsCount,
    enrollmentUid,
  } = liveMeeting!;

  const meetingState = getLessonMeetingState(liveMeeting);

  const [meetingMessage, meetingIcon] = (() => {
    if (isLocked) {
      return [
        "Please complete your previous lesson to view this one",
        faLockRegular,
      ];
    }
    switch (meetingState) {
      case LessonMeetingState.NotJoinable:
        return ["", faLockRegular];
      case LessonMeetingState.SectionNotStarted:
        const sectionNotStartedMessage = `First Meeting: ${
          dayjs(meetingStartTime).isToday()
            ? "today"
            : Time.startDayShortString(meetingStartTime, userTimeZone)
        } at ${Time.formatTime(meetingStartTime, userTimeZone)}`;
        return [sectionNotStartedMessage, faBell];
      case LessonMeetingState.NoMeetings:
        return ["No meetings for this lesson", faBell];
      case LessonMeetingState.InProgress:
        return ["Meeting in progress", faBell];
      case LessonMeetingState.Ended:
        return ["The meeting has ended", faClock];
      default:
        const upcomingMessage = `${
          meetingsCount > 1 ? "Next" : "The live"
        } meeting starts ${
          dayjs(meetingStartTime).isAfter(dayjs().endOf("day"))
            ? Time.startDayShortString(meetingStartTime, userTimeZone)
            : "today"
        } at ${Time.formatTime(meetingStartTime, userTimeZone)}`;
        return [upcomingMessage, faBell];
    }
  })();

  return (
    <Box
      sx={(theme: Theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.up("md")]: {
          flexDirection: "row",
          gap: 8,
          alignItems: "center",
        },
      })}
    >
      <Box
        sx={(theme: Theme) => ({
          display: "flex",
          alignItems: "center",
          gap: 8,
          [theme.breakpoints.down("md")]: {
            marginBottom: 16,
          },
        })}
      >
        <Icon
          icon={meetingIcon}
          color="primary"
          sx={{
            margin: 8,
            width: 24,
            height: 24,
          }}
        />
        <Typography variant="inherit" id="message">
          {meetingMessage}
        </Typography>
      </Box>
      {!isLocked && (
        <LessonMeetingButton
          meetingStartTime={meetingStartTime}
          meetingEndTime={meetingEndTime}
          enrollmentUid={enrollmentUid}
          sectionUid={sectionUid}
          recordingUid={""}
          sectionJoinPathFn={sectionJoinPathFn}
          classRecordingPathFn={() => ""}
          disabled={!usersCanHaveLiveMeeting || isLocked}
        />
      )}
    </Box>
  );
}

export function LessonLiveMeetingSection({
  sectionUid,
  liveMeeting,
  userTimeZone,
  sectionJoinPathFn,
  classRecordingPathFn,
  isMissed,
  isLocked = false,
}: LessonLiveMeetingProps) {
  const {
    meetingStartTime,
    meetingEndTime,
    usersCanHaveLiveMeeting,
    meetingsCount,
    enrollmentUid,
    classRecordingUids: [recordingUid] = [],
  } = liveMeeting;

  const meetingState = getLessonMeetingState(liveMeeting);

  const [meetingMessage, meetingIcon] = (() => {
    switch (meetingState) {
      case LessonMeetingState.NotJoinable:
        return ["", faLockRegular];
      case LessonMeetingState.SectionNotStarted:
        const sectionNotStartedMessage = `First Meeting: ${
          dayjs(meetingStartTime).isToday()
            ? "today"
            : Time.startDayShortString(meetingStartTime, userTimeZone)
        } at ${Time.formatTime(meetingStartTime, userTimeZone)}`;
        return [sectionNotStartedMessage, faBell];
      case LessonMeetingState.NoMeetings:
        return ["No meetings for this lesson", faBell];
      case LessonMeetingState.InProgress:
        return ["Meeting in progress", faBell];
      case LessonMeetingState.Ended:
        if (isMissed) {
          return ["Missed your class?", faVideoRegular];
        } else if (recordingUid) {
          return ["Class recording is available", faVideoRegular];
        } else {
          return [`The meeting has ended`, faClock];
        }
      default:
        const upcomingMessage = `${
          meetingsCount > 1 ? "Next" : "The live"
        } meeting starts ${
          dayjs(meetingStartTime).isAfter(dayjs().endOf("day"))
            ? Time.startDayShortString(meetingStartTime, userTimeZone)
            : "today"
        } at ${Time.formatTime(meetingStartTime, userTimeZone)}`;
        return [upcomingMessage, faBell];
    }
  })();

  return (
    <Box
      sx={(theme: Theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.up("md")]: {
          flexDirection: "row",
          gap: 4,
          alignItems: "center",
        },
      })}
    >
      <Box
        sx={(theme: Theme) => ({
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          gap: 8,
          [theme.breakpoints.down("md")]: {
            marginBottom: 16,
          },
        })}
      >
        <Box
          sx={(theme: Theme) => ({
            display: "flex",
            alignItems: "center",
            gap: 8,
            [theme.breakpoints.down("md")]: {
              marginBottom: 16,
            },
          })}
        >
          <Icon
            icon={meetingIcon}
            color="primary"
            sx={{
              margin: 8,
              width: 24,
              height: 24,
            }}
          />
          <Typography
            id="message"
            sx={{
              fontWeight: 500,
              fontSize: "18",
              lineHeight: theme.spacing(22.5),
            }}
          >
            {meetingMessage}
          </Typography>
        </Box>
        {isMissed && (
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "16",
              marginLeft: 8,
              marginTop: -4,
              marginBottom: 16,
              lineHeight: theme.spacing(5),
            }}
          >
            Catch up before you next lesson!
          </Typography>
        )}
      </Box>
      {!isLocked && (
        <LessonMeetingButton
          meetingStartTime={meetingStartTime}
          meetingEndTime={meetingEndTime}
          enrollmentUid={enrollmentUid}
          sectionUid={sectionUid}
          recordingUid={recordingUid}
          sectionJoinPathFn={sectionJoinPathFn}
          classRecordingPathFn={classRecordingPathFn}
          disabled={!usersCanHaveLiveMeeting || isLocked}
        />
      )}
    </Box>
  );
}

type LessonMeetingButtonProps = Pick<
  MeetingButtonProps,
  | "meetingStartTime"
  | "meetingEndTime"
  | "enrollmentUid"
  | "sectionUid"
  | "disabled"
> &
  Pick<LessonLiveMeetingProps, "sectionJoinPathFn" | "classRecordingPathFn"> & {
    recordingUid: string | null;
  };

function LessonMeetingButton({
  meetingStartTime,
  meetingEndTime,
  enrollmentUid,
  sectionUid,
  recordingUid,
  disabled,
  sectionJoinPathFn,
  classRecordingPathFn,
}: LessonMeetingButtonProps) {
  const Link = useLinkComponent();

  const isMobile = useIsMobile();

  const meetingState = getLessonMeetingState({
    meetingStartTime,
    meetingEndTime,
  });

  const partialEnrollment = {
    uid: enrollmentUid,
  };
  const partialSection = { uid: sectionUid };
  const onlineClassroomPath = sectionJoinPathFn(
    partialSection,
    partialEnrollment
  );

  const viewRecordingPath = classRecordingPathFn(sectionUid, recordingUid);

  const [buttonLabel, buttonHref, canUseButton] = (() => {
    switch (meetingState) {
      case LessonMeetingState.Ended:
        if (recordingUid) {
          return ["Watch Class Recording", viewRecordingPath, true];
        } else {
          return ["No Class Recording", viewRecordingPath, false];
        }
      case LessonMeetingState.UpcomingJoinable:
      case LessonMeetingState.InProgress:
        return ["Join Live Meeting", onlineClassroomPath, true];
      default:
        return ["Join Live Meeting", onlineClassroomPath, false];
    }
  })();

  return (
    <TrackedButton
      variant="contained"
      disabled={disabled || !canUseButton}
      fullWidth={isMobile}
      component={Link}
      to={buttonHref}
      url={buttonHref}
      target="_blank"
      trackingName="join_meeting"
    >
      {buttonLabel}
    </TrackedButton>
  );
}
