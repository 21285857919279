import { Button } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import React, { FC } from "react";

import { ReplyTo, useReplyToContext } from "../providers/ReplyToProvider";

export interface ReplyButtonProps {
  replyToUser: ReplyTo;
  onClick?: (user: ReplyTo) => void;
}

const ReplyButton: FC<ReplyButtonProps> = ({ replyToUser, onClick }) => {
  const { setReplyTo } = useReplyToContext();
  const { t } = useTranslation("ui-components-classroom\\ReplyButton");

  function handleReply() {
    onClick && onClick(replyToUser);
    setReplyTo(replyToUser);
  }

  return (
    <Button
      variant="link"
      sx={{
        marginTop: "-0.5em",
        marginLeft: "0.5em",
        marginBottom: "0.5em",
        color: "gray",

        ":hover": {
          color: "#2ba7ce",
        },
      }}
      onClick={handleReply}
      data-test-id="ClassPostReplyButton"
    >
      {t("Reply")}
    </Button>
  );
};

export default ReplyButton;
