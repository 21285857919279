import type {
  ClassPost_ClassPostFragment,
  ClassPost_ClassPostWithCommentsFragment,
} from "../..";

import { gql } from "@outschool/ui-apollo";

/**
 * MeetingPost Fragments: Start
 */

// We use these fragments in Learner Mode and to avoid duplicate types during code generation
// we export this as an object
export const MeetingPostFragments = {
  activity: gql`
    fragment MeetingPost_activity on Activity {
      leader {
        uid
        name
      }
    }
  `,
  section: gql`
    fragment MeetingPost_section on Section {
      uid
      leader {
        uid
        name
      }
    }
  `,
  meeting: gql`
    fragment MeetingPost_meeting on Meeting {
      uid
      start_time
      end_time
      recordingAvailability
      recordingRequests {
        requestedBy {
          uid
          name
        }
        requestedAt
        message
      }
      classRecordings {
        uid
        startTime
        endTime
      }
      attendances {
        learner {
          name
        }
      }
    }
  `,
  learner: gql`
    fragment MeetingPost_learner on Learner {
      uid
    }
  `,
};

/**
 * MeetingPost Fragments: End
 */

/**
 * ClassPost Fragments: Start
 */

const ClassPostAttachmentFragment = gql`
  fragment ClassPost_attachment on Attachment {
    uid
    file {
      filename
      mimetype
      size
      url
      thumbUrl
    }
    conversionStatus
    created_at
  }
`;

export const ClassPostSenderFragment = gql`
  fragment ClassPost_sender on MessageSender {
    uid
    name
    photo
    avatar
    isLearner
  }
`;

export const ClassPostMessageContentFragment = gql`
  fragment ClassPost_messageContent on MessageContent {
    uid
    text(plainText: $plainText)
    htmlText: text(mimeType: TEXT_HTML)
    originalMimeType
    attachments {
      ...ClassPost_attachment
    }
    video {
      ...ClassPost_attachment
    }
    editedAt
  }
  ${ClassPostAttachmentFragment}
`;

export const ClassPostCommentFragment = gql`
  fragment ClassPost_comment on ClassPostComment {
    uid
    sendAt
    mentionedEnrollmentUids
    mentionedTeacherUid
    backgroundColor
    sender {
      ...ClassPost_sender
    }
    messageContent {
      ...ClassPost_messageContent
    }
    backgroundColor
  }
  ${ClassPostSenderFragment}
  ${ClassPostMessageContentFragment}
`;

export const ClassPostWithOnlyCommentsFragment = gql`
  fragment ClassPost_classPostWithOnlyComments on ClassPost {
    uid
    comments {
      ...ClassPost_comment
    }
  }
  ${ClassPostCommentFragment}
`;

export const ClassPostWithCommentsFragment = gql`
  fragment ClassPost_classPostWithComments on ClassPost {
    publishAt
    isWelcomePost
    sender {
      ...ClassPost_sender
    }
    messageContent {
      ...ClassPost_messageContent
    }
    ...ClassPost_classPostWithOnlyComments
  }
  ${ClassPostSenderFragment}
  ${ClassPostMessageContentFragment}
  ${ClassPostWithOnlyCommentsFragment}
`;

export type ClassPostType =
  | ClassPost_ClassPostFragment
  | ClassPost_ClassPostWithCommentsFragment;

export const isClassPostFragment = (
  post: ClassPostType
): post is ClassPost_ClassPostFragment => {
  return !!post && !!(post as ClassPost_ClassPostFragment).commentsPreview;
};

// We use these fragments in Learner Mode and to avoid duplicate types during code generation
// we export this as an object
export const ClassPostFragments = {
  learner: gql`
    fragment ClassPost_learner on Learner {
      uid
      name
    }
  `,
  classPost: gql`
    fragment ClassPost_classPost on ClassPost {
      uid
      isWelcomePost
      publishAt
      title
      promotedAt
      pinnedAt
      sender {
        ...ClassPost_sender
      }
      messageContent {
        ...ClassPost_messageContent
      }
      commentsPreview {
        remainingCommentsCount
        comments {
          ...ClassPost_comment
        }
      }
      mentionedEnrollmentUids
      backgroundColor
      score
    }
    ${ClassPostSenderFragment}
    ${ClassPostMessageContentFragment}
    ${ClassPostCommentFragment}
  `,
  activity: gql`
    fragment ClassPost_activity on Activity {
      user_uid
      leader {
        uid
        leader_link
      }
    }
  `,
  section: gql`
    fragment ClassPost_section on Section {
      uid
      leader {
        uid
      }
      currentUserCanManage
    }
  `,
  mentionNames: gql`
    fragment ClassPost_mentionNames on MentionName {
      id
      display
      avatar
      startTime
    }
  `,
};

/**
 * ClassPost Fragments: End
 */

/**
 * ClassPostList Fragments: Start
 */

const meetingFragment = gql`
  fragment ClassPostList_meeting on Meeting {
    uid
    start_time
    ...MeetingPost_meeting
  }
  ${MeetingPostFragments.meeting}
`;

export const ClassPostListFragments = {
  classroom: gql`
    fragment ClassPostList_classroom on Classroom {
      usersCanPost
      usersCanComment
      posts(
        publishedAfter: $start
        publishedBefore: $end
        filter: $postFilter
        order: $postOrder
      ) {
        ...ClassPost_classPost
      }
      unpublishedPosts {
        ...ClassPost_classPost
      }
      activity {
        user_uid
        ...ClassPost_activity
        ...MeetingPost_activity
      }
      section {
        currentUserCanManage
        meetings(filter: Enrolled, startAfter: $start, startBefore: $end) {
          ...ClassPostList_meeting
        }
        ...ClassPost_section
        ...MeetingPost_section
      }
      mentionNames {
        ...ClassPost_mentionNames
      }
    }
    ${meetingFragment}
    ${ClassPostFragments.classPost}
    ${ClassPostFragments.activity}
    ${ClassPostFragments.section}
    ${ClassPostFragments.mentionNames}
    ${MeetingPostFragments.activity}
    ${MeetingPostFragments.section}
  `,

  learner: gql`
    fragment ClassPostList_learner on Learner {
      ...ClassPost_learner
      ...MeetingPost_learner
    }
    ${ClassPostFragments.learner}
    ${MeetingPostFragments.learner}
  `,
};

/**
 * ClassPostList Fragments: End
 */

/**
 * ClassroomLearnerListFragments Fragments: Start
 */

export const ClassroomLearnerListFragments = {
  profile: gql`
    fragment ClassroomLearnerList_profile on LearnerProfile {
      uid
      name
      avatar
    }
  `,
};

/**
 * ClassPostList Fragments: End
 */
