// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Button, Icon, SxProps, Typography } from "@outschool/backpack";
import { faPencil } from "@outschool/icons";
import {
  BoxButton,
  Modal,
  Space,
  TextArea,
} from "@outschool/ui-legacy-component-library";
import { useIsSmall } from "@outschool/ui-utils";
import React, {
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

const CANCEL_BUTTON_SX: SxProps = {
  border: "none !important",
  paddingY: ".6em !important",
  paddingX: "1.5em !important",

  color: "white",
  backgroundColor: "grey.500",
  "&:hover": {
    color: "white !important",
    backgroundColor: "#5f6774 !important",
  },
};

interface InlineEditableTextProps {
  placeholder?: string;
  title?: string;
  value?: string | null;
  onSave: (value: string | null) => void;
  onCancel?: () => void;
  isEditable?: boolean;
  /** Limits the number of characters. Default is 250 */
  maxCharacterCount?: number | null;
  trackTouch?: () => void;
}

interface ComponentStyles {
  container: SxProps;
  text: SxProps;
}

enum InlineEditableTextStylesEnum {
  Default = "Default",
  Hover = "Hover",
  Filled = "Filled",
}

type InlineEditableTextStyles = Record<
  keyof typeof InlineEditableTextStylesEnum,
  ComponentStyles
>;

function InlineEditableText(
  {
    value,
    title,
    placeholder,
    isEditable,
    maxCharacterCount = 250,
    onSave,
    onCancel,
    trackTouch,
  }: InlineEditableTextProps,
  ref: React.Ref<HTMLDivElement>
) {
  const isSmall = useIsSmall();
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [textValue, setTextValue] = useState<string | null>(value ?? null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const state = useMemo<InlineEditableTextStylesEnum>(() => {
    if (!isEditable) {
      return (value ?? "").length > 0
        ? InlineEditableTextStylesEnum.Filled
        : InlineEditableTextStylesEnum.Default;
    } else if (isEditing && !isHovering) {
      return InlineEditableTextStylesEnum.Default;
    } else if (isHovering && !isEditing) {
      return InlineEditableTextStylesEnum.Hover;
    }
    return (value ?? "").length > 0
      ? InlineEditableTextStylesEnum.Filled
      : InlineEditableTextStylesEnum.Default;
  }, [value, isEditable, isEditing, isHovering]);

  const handleFocus = () => {
    if (!isEditing && isEditable) {
      setIsEditing(true);
      textAreaRef.current?.focus();
      trackTouch && trackTouch();
    }
  };

  const toggleHovering = useCallback(() => {
    setIsHovering(prev => !prev);
  }, []);

  function handleSave() {
    setIsEditing(false);
    setIsHovering(false);
    onSave(textValue);
  }

  function handleCancel() {
    setIsEditing(false);
    setIsHovering(false);
    onCancel && onCancel();
  }

  function handleChange(newValue: SyntheticEvent<HTMLTextAreaElement>) {
    const textLength = newValue.currentTarget.value.length;
    if (!maxCharacterCount) {
      setTextValue(newValue.currentTarget.value);
    } else if (textLength <= maxCharacterCount) {
      setTextValue(newValue.currentTarget.value.substr(0, maxCharacterCount));
    }
  }

  const basicContainerStyles: SxProps = {
    borderRadius: "0.5em",
    padding: "1em",
  };

  const basicTextStyles: SxProps = {
    fontWeight: "bold",
    wordBreak: "break-all",
    paddingRight: "0.5em",
    margin: 0,
    lineHeight: 1.5,
  };

  const styles: InlineEditableTextStyles = {
    Default: {
      container: {
        ...basicContainerStyles,
        backgroundColor: "#F0F0F0",
      },
      text: {
        ...basicTextStyles,
        color: "#8791A2",
      },
    },
    Hover: {
      container: {
        ...basicContainerStyles,
        backgroundColor: "#D9F1FE",
        cursor: "pointer",
      },
      text: {
        ...basicTextStyles,
        color: "#6A7482",
      },
    },
    Filled: {
      container: {
        ...basicContainerStyles,
        backgroundColor: "#F2F7FE",
      },
      text: {
        ...basicTextStyles,
      },
    },
  };

  const charactersLeft = maxCharacterCount
    ? maxCharacterCount - (textValue?.length ?? 0)
    : 0;

  if (isSmall && isEditing) {
    return (
      <Modal
        open
        onClose={handleCancel}
        sx={{
          width: "90%",
          minHeight: "90%",
          borderRadius: "xlarge",
          padding: "1.5em",
        }}
      >
        <Box
          flex
          sx={{
            flexDirection: "column",
            height: "100%",
          }}
        >
          {title && (
            <Typography
              variant="h2"
              sx={{
                marginBottom: "0.5em",
              }}
            >
              {title}
            </Typography>
          )}
          <Box
            flex
            sx={{
              flexGrow: 2,
              cursor: "pointer",
              ...styles[state].container,
            }}
            onClick={handleFocus}
            onMouseEnter={toggleHovering}
            onMouseLeave={toggleHovering}
            ref={ref}
          >
            <TextArea
              value={textValue ?? ""}
              placeholder={placeholder}
              onChange={handleChange}
              showCount={false}
              ref={textAreaRef}
              sx={{
                background: "transparent",
                border: "none",
                marginBottom: "small",
                fontWeight: "bold",
                height: "100%",
              }}
            />
          </Box>
          <Box
            flex
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "1em",
            }}
          >
            {maxCharacterCount && (
              <Box
                sx={{
                  marginRight: "0.5em",
                }}
              >
                {charactersLeft}
              </Box>
            )}
            <Button size="small" sx={CANCEL_BUTTON_SX} onClick={handleCancel}>
              Cancel
            </Button>
            <Space x="small" />
            <Button
              variant="contained"
              size="small"
              sx={{
                border: "none !important",
                paddingY: ".6em !important",
                paddingX: "1.5em !important",
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }

  const onClickProps = isEditing
    ? {}
    : {
        onClick: handleFocus,
        "aria-label": "Edit",
      };

  return (
    <BoxButton
      sx={styles[state].container as any}
      onMouseEnter={toggleHovering}
      onMouseLeave={toggleHovering}
      ref={ref}
      {...onClickProps}
    >
      {isEditing ? (
        <Box>
          <TextArea
            value={textValue ?? ""}
            placeholder={placeholder}
            onChange={handleChange}
            showCount={false}
            ref={textAreaRef}
            sx={{
              background: "transparent",
              border: "none",
              marginBottom: "small",
              fontWeight: "bold",
            }}
          />
          <Box
            flex
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {maxCharacterCount && (
              <Box
                sx={{
                  marginRight: "0.5em",
                }}
              >
                {charactersLeft}
              </Box>
            )}
            <Button size="small" sx={CANCEL_BUTTON_SX} onClick={handleCancel}>
              Cancel
            </Button>
            <Space x="small" />
            <Button
              variant="contained"
              size="small"
              sx={{
                border: "none !important",
                paddingY: ".6em !important",
                paddingX: "1.5em !important",
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          flex
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="inherit" sx={styles[state].text} component="pre">
            {value ?? placeholder}
          </Typography>
          {isEditable && (
            <Icon
              icon={faPencil}
              sx={{
                color: isHovering ? "#1286AB" : "grey.500",
              }}
            />
          )}
        </Box>
      )}
    </BoxButton>
  );
}

export default React.forwardRef(InlineEditableText);
