import {
  Box,
  Chip,
  ChipProps,
  Icon,
  Tooltip,
  Typography,
} from "@outschool/backpack";
import { EducatorTier } from "@outschool/gql-frontend-generated";
import {
  IconDefinition,
  faFire,
  faStarCircle,
  faStarShooting,
  fasPartyHorn,
} from "@outschool/icons";
import { TFunction, useTranslation } from "@outschool/localization";
import React from "react";

type BadgeDefinition = {
  textFn: (t: TFunction) => string;
  headingFn: (t: TFunction) => string;
  descriptionFn: (t: TFunction) => string;
  icon: IconDefinition;
  chipColor: ChipProps["color"];
  iconColor: string;
  iconSx: any;
};

const BADGE_DEFINITIONS: Record<EducatorTier, BadgeDefinition> = {
  star: {
    textFn: t => t("Star Educator"),
    headingFn: t => t("Stellar Quality"),
    descriptionFn: t =>
      t(
        "Book with confidence!  Star educators are top rated, experienced, and highly responsive, with classes that meet Outschool's quality learning standards."
      ),
    icon: faStarCircle,
    iconSx: {},
    chipColor: "primary",
    iconColor: "primary.600",
  },
  rising_star: {
    textFn: t => t("Rising Star"),
    headingFn: t => t("Emerging Excellence"),
    descriptionFn: t =>
      t(
        "Rising star educators have strong track records, offer classes that meet Outschool's quality learning standards, & are on their way to being Star Educators."
      ),
    icon: faStarShooting,
    iconSx: {
      rotate: "180deg",
      paddingLeft: "4px !important",
      paddingRight: "0 !important",
    },
    chipColor: "info",
    iconColor: "info.600",
  },
  popular: {
    textFn: t => t("Popular"),
    headingFn: t => t("Selling Fast"),
    descriptionFn: t =>
      t(
        "Popular educators offer classes that are in high demand with Outschool families - their classes tend to fill up quickly!"
      ),
    icon: faFire,
    iconSx: {},
    chipColor: "error",
    iconColor: "error.600",
  },
  new_on_outschool: {
    textFn: t => t("New on Outschool"),
    headingFn: t => t("Try Someone New"),
    descriptionFn: t =>
      t(
        "New educators are vetted by the Outschool team and hold either professional credentials or lived experience in their topic areas."
      ),
    icon: fasPartyHorn,
    iconSx: {},
    chipColor: "success",
    iconColor: "success.600",
  },
};

export default function EducatorTierBadge({ tier }: { tier: EducatorTier }) {
  const { t } = useTranslation("ui-components-website\\TeacherProfile");

  const badgeDefinition = BADGE_DEFINITIONS[tier];

  if (badgeDefinition) {
    return (
      <Box flex>
        <Tooltip title={<EducatorTierTooltip tier={tier} />}>
          <Chip
            icon={
              <Icon
                fixedWidth
                icon={badgeDefinition.icon}
                sx={{ ...badgeDefinition.iconSx }}
                size="medium"
              />
            }
            label={badgeDefinition.textFn(t)}
            color={badgeDefinition.chipColor}
            size="small"
            sx={{ borderRadius: "12px", padding: "4px 8px" }}
          />
        </Tooltip>
      </Box>
    );
  } else {
    return null;
  }
}

function EducatorTierTooltip({ tier }: { tier: EducatorTier }) {
  const { t } = useTranslation("ui-components-website\\TeacherProfile");

  const badgeDefinition = BADGE_DEFINITIONS[tier];

  return (
    <Box flex sx={{ alignItems: "center", gap: "16px", margin: "16px" }}>
      <Icon
        icon={badgeDefinition.icon}
        size="large"
        sx={{
          padding: "4px",
          color: badgeDefinition.iconColor,
          ...badgeDefinition.iconSx,
        }}
      />
      <Box flex sx={{ flexDirection: "column", textAlign: "left" }}>
        <Box sx={{ marginBottom: "4px" }}>
          <Typography variant="subtitle2" emphasized>
            {badgeDefinition.headingFn(t)}
          </Typography>
        </Box>
        <Typography as={Box} variant="body2">
          {badgeDefinition.descriptionFn(t)}
        </Typography>
      </Box>
    </Box>
  );
}
