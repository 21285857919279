import ldCompact from "lodash/compact";

export const ActivityAttributeKey = {
  curriculum: "curriculum",
  standard: "standard",
  proficiencyLevel: "proficiency_level",
  gradeLevelMin: "grade_level_min",
  gradeLevelMax: "grade_level_max",
  englishProficiencyLevel: "english_proficiency_level",
  /**
   * The attribute which is calculated from properies of the activity
   * Soon to be deprecated in favor of "educator_selected_class_format_type"
   */
  class_format_type: "class_format_type",
  /**
   * The format as selected by the educator in the create/edit form.
   * Considered to be the "intended" format.  Unlisted activites do not necessarily
   * conform with the definitions of the given format.
   */
  educator_selected_class_format_type:
    "class_format_type_educator_selected" as const,
  /**
   * The learning goal an educator chose for their class.
   */
  learning_goals: "learning_goals" as const,
  /**
   * ASFX experiment, helps us flag which educators and activities we should to users
   * who haven't completed a purchase.
   * The survey key is used for surveying supply outside of the active ASFX cohort
   */
  for_new_buyers: "for_new_buyers",
  for_new_buyers_survey: "for_new_buyers_survey",
} as const;

/**
 * All the types of master evaluation that the teacher can specify.
 * Use `getAttributesForOutsideOfClassLearningType` to get the attribute keys
 * associated with a given MasteryEvaluationType
 */
export enum MasteryEvaluationType {
  Homework = "homework",
  Assignments = "assignments",
  Projects = "projects",
  Other = "other_mastery_evaluation",
}

export const AllMasteryEvaluationTypes = Object.values(MasteryEvaluationType);

/**
 * All the types of grading that the teacher can specify.
 * Use getAttributesForGradingType to get attribute keys
 * associated with a given GradingType
 */
export enum GradingType {
  Assessment = "assessment",
  LetterGrade = "letter_grades",
  ProgressReport = "progress_report",
  CertificateOfCompletion = "certificate_of_completion",
  // using "grades" for for backwards compatibility with existing has_grades
  Other = "grades",
}

export const AllGradingTypes = Object.values(GradingType);

/**
 * Values (i.e., saved to DB) used for feedback options for MasteryEvaluationType
 */
export enum MasteryEvaluationFeedback {
  AvailableUponRequest = "Available upon request",
  AsNeeded = "As needed",
}

export function isMasteryEvaluationFeedback(
  input: any
): input is MasteryEvaluationFeedback {
  return Object.values(MasteryEvaluationFeedback).includes(input);
}

/**
 * Values (i.e., saved to DB) used for frequency options for MasteryEvaluationType and GradingType
 */
export enum MasteryAndGradingFrequency {
  AvailableUponRequest = "Available upon request",
  OneToTwoThroughout = "1-2 throughout the class",
  ThreeToSixThroughout = "3-6 throughout the class",
  SevenOrMoreThroughout = "7 or more throughout the class",
  OneToTwoPerWeek = "1-2 per week",
  ThreeToSixPerWeek = "3-6 per week",
  SevenOrMorePerWeek = "7 or more per week",
  OneAfterCompletion = "1 after class completion",
}

export function isMasteryAndGradingFrequency(
  input: any
): input is MasteryAndGradingFrequency {
  return Object.values(MasteryAndGradingFrequency).includes(input);
}

/**
 * Get the attribute keys associated with a given outside of class learning type
 * @param type The OOCL type
 * @returns An object of the attributes for a given type
 */
export function getAttributesForMasteryEvaluationOrGradingType(
  type: MasteryEvaluationType | GradingType
) {
  return {
    has: `has_${type}`,
    frequency: `${type}_frequency`,
    feedback: `${type}_feedback`,
    details: `${type}_details`,
  };
}

export function hasActivityAttribute({
  attributes,
  attributeKey,
}: {
  attributes?: { key: string }[] | null;
  attributeKey: string;
}) {
  if (!attributes) {
    return false;
  }

  return attributes.some(a => a.key === attributeKey);
}

type GetMasteryEvaluationActivityType = {
  attributes?:
    | {
        key: string;
        value: string;
      }[]
    | null;
  homework?: string | null;
};

export function getMasteryEvaluationDetails(
  activity: GetMasteryEvaluationActivityType,
  type: MasteryEvaluationType
) {
  const attributeKeys = getAttributesForMasteryEvaluationOrGradingType(type);
  let details = activity.attributes?.find(
    attr => attr.key === attributeKeys.details
  )?.value;
  if (!details) {
    if (type === MasteryEvaluationType.Homework) {
      details = activity.homework ?? undefined;
    }
  }
  return details;
}

type GetGradingActivityType = {
  attributes?:
    | {
        key: string;
        value: string;
      }[]
    | null;
  assessment?: string | null;
  grading_policy?: string | null;
};

export function getGradingDetails(
  activity: GetGradingActivityType,
  type: GradingType
) {
  const attributeKeys = getAttributesForMasteryEvaluationOrGradingType(type);
  let details = activity.attributes?.find(
    attr => attr.key === attributeKeys.details
  )?.value;
  if (!details) {
    if (type === GradingType.Assessment) {
      details = activity.assessment ?? undefined;
    }
    if (type === GradingType.Other) {
      details = activity.grading_policy ?? undefined;
    }
  }
  return details;
}

export function getGradingData(activity: GetGradingActivityType) {
  return ldCompact(
    AllGradingTypes.map(type => {
      const attributeKeys =
        getAttributesForMasteryEvaluationOrGradingType(type);
      if (
        !hasActivityAttribute({
          attributes: activity.attributes,
          attributeKey: attributeKeys.has,
        })
      ) {
        return null;
      }
      return {
        type: type as GradingType,
        details: getGradingDetails(activity, type) ?? "",
        frequency:
          activity.attributes?.find(
            attr => attr.key === attributeKeys.frequency
          )?.value ?? "Available upon request",
      };
    })
  );
}

export function getMasteryEvaluationData(
  activity: GetMasteryEvaluationActivityType
) {
  return ldCompact(
    AllMasteryEvaluationTypes.map(type => {
      const attributeKeys =
        getAttributesForMasteryEvaluationOrGradingType(type);
      if (
        !hasActivityAttribute({
          attributes: activity.attributes,
          attributeKey: attributeKeys.has,
        })
      ) {
        return null;
      }
      return {
        type: type as MasteryEvaluationType,
        details: getMasteryEvaluationDetails(activity, type) ?? "",
        feedback:
          activity.attributes?.find(attr => attr.key === attributeKeys.feedback)
            ?.value ?? "Available upon request",
        frequency:
          activity.attributes?.find(
            attr => attr.key === attributeKeys.frequency
          )?.value ?? "Available upon request",
      };
    })
  );
}
