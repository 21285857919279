// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { Box, Skeleton, SxProps, Typography } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import React from "react";

export default function TileInfoBox({
  topText = "",
  bottomText = "",
  sx,
}: {
  topText: string;
  bottomText?: string;
  sx?: SxProps;
}) {
  return (
    <Box
      sx={{
        backgroundColor: "#F8F8F9",
        borderRadius: 8,
        flex: 1,
        fontSize: 16,
        height: "58px",
        textAlign: "center",
        padding: "3px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
      }}
    >
      <Box
        sx={[
          {
            ...sx,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Typography
          as={Box}
          sx={{
            fontWeight: "fontWeightBold",
            whiteSpace: "nowrap",
            display: "block",
          }}
        >
          {topText}
        </Typography>
        {bottomText && (
          <Typography
            as={Box}
            sx={[
              {
                display: "block",
                fontSize: 10,
              },
              ...(Array.isArray(sx) ? sx : [sx]),
            ]}
          >
            {bottomText}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export function TileInfoBoxSimplified({
  price = "",
  isTutor = false,
  sx,
}: {
  price: string;
  isTutor: boolean;
  sx?: SxProps;
}) {
  const { t } = useTranslation(
    "ui-components-website\\src\\Listings\\components\\TileInfoBox"
  );

  function pricingFrequency() {
    return isTutor ? t("session") : t("class");
  }

  return (
    <Box
      sx={{
        ...sx,
        verticalAlign: "baseline",
      }}
    >
      <Typography
        as={Box}
        variant="subtitle1"
        emphasized
        sx={{
          color: "gray950",
          display: "inline",
        }}
      >
        {price}
      </Typography>
      <Typography
        as={Box}
        variant="caption"
        sx={{
          color: "gray500",
        }}
      >
        /{pricingFrequency()}
      </Typography>
    </Box>
  );
}

export function TileInfoBoxLoading() {
  return (
    <Box
      sx={{
        flex: 1,
        height: "56px",
        textAlign: "center",
        paddingX: 7,
      }}
    >
      <Skeleton sx={{ borderRadius: 8 }} variant="rounded" height={56} />
    </Box>
  );
}
