import { dayjs } from "@outschool/time";

/**
 * Given data about a meeting in the context of a lesson,
 * returns an enum value representing the state of that
 * meeting as it should be rendered in the classroom.
 */
export function getLessonMeetingState({
  usersCanHaveLiveMeeting = true,
  isFlexSchedule = false,
  sectionStartTime,
  meetingStartTime,
  meetingEndTime,
}: {
  meetingStartTime: dayjs.ConfigType;
  meetingEndTime?: dayjs.ConfigType;
  usersCanHaveLiveMeeting?: boolean | null;
  isFlexSchedule?: boolean;
  sectionStartTime?: dayjs.ConfigType;
}): LessonMeetingState {
  const noMeetings = !meetingStartTime;
  const sectionHasNotStarted =
    sectionStartTime && dayjs().endOf("day").isBefore(dayjs(sectionStartTime));

  const joinBufferTime = dayjs(meetingStartTime).subtract(30, "minutes");

  const isUpcoming = dayjs().isBefore(meetingStartTime);
  const isInProgress =
    meetingEndTime && dayjs().isBetween(meetingStartTime, meetingEndTime);
  const isToday = dayjs(meetingStartTime).isToday();
  const hasEnded = meetingEndTime && dayjs().isAfter(meetingEndTime);
  const isJoinable =
    isToday && (isInProgress || dayjs().isAfter(joinBufferTime));

  if (!usersCanHaveLiveMeeting || isFlexSchedule) {
    return LessonMeetingState.NotJoinable;
  } else if (noMeetings) {
    return LessonMeetingState.NoMeetings;
  } else if (isUpcoming && isJoinable) {
    return LessonMeetingState.UpcomingJoinable;
  } else if (isInProgress) {
    return LessonMeetingState.InProgress;
  } else if (hasEnded) {
    return LessonMeetingState.Ended;
  } else if (sectionHasNotStarted) {
    return LessonMeetingState.SectionNotStarted;
  } else {
    return LessonMeetingState.Upcoming;
  }
}

export enum LessonMeetingState {
  NotJoinable = "NotJoinable",
  SectionNotStarted = "SectionNotStarted", // First meeting has not started
  Upcoming = "Upcoming", // More than 30 minutes before
  UpcomingJoinable = "UpcomingJoinable", // Within 30 minutes of the meeting
  NoMeetings = "NoMeetings", // No meetings associated with this lesson
  InProgress = "InProgress", // Meeting is currently happening
  Ended = "Ended", // Meeting happened in the past day
}
