import { ReactionSpec, ReactionType } from "./types";

export { ReactionSpec, ReactionType } from "./types";

export const ALL_REACTIONS: ReactionSpec[] = [
  {
    type: ReactionType.SUNGLASSES_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/sunglasses.gif",
    altText: "cool sunglasses",
  },
  {
    type: ReactionType.CARE_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/care.gif",
    altText: "care",
  },
  {
    type: ReactionType.CELEBRATION_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/celebration.gif",
    altText: "celebration",
  },
  {
    type: ReactionType.CREATIVITY_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/creativity.gif",
    altText: "creative painting",
  },
  {
    type: ReactionType.FIRE_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/fire.gif",
    altText: "fire",
  },
  {
    type: ReactionType.LAUGH_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/laugh.gif",
    altText: "laughing",
  },
  {
    type: ReactionType.LETS_GO_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/lets-go.gif",
    altText: "let's go",
  },
  {
    type: ReactionType.THUMBS_UP_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/thumbs-up.gif",
    altText: "thumbs up",
  },
  {
    type: ReactionType.HEARTS_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/hearts.gif",
    altText: "hearts",
  },
  {
    type: ReactionType.METER_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/meter.gif",
    altText: "overwhelmed meter",
  },
  {
    type: ReactionType.NO_CAP_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/no-cap.gif",
    altText: "no cap",
  },
  {
    type: ReactionType.SHOCKED_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/shocked.gif",
    altText: "shocked face",
  },
  {
    type: ReactionType.SAD_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/sad.gif",
    altText: "crying face",
  },
  {
    type: ReactionType.SHEESH_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/sheesh.gif",
    altText: "sheesh",
  },
  {
    type: ReactionType.SIMPLE_SMILE_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/simple-smile.gif",
    altText: "simple smile",
  },
  {
    type: ReactionType.BUSSIN_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/bussin.gif",
    altText: "bussin",
  },
  {
    type: ReactionType.VIBIN_CLUB_REACTION,
    gifUrl:
      "https://outschool-static.s3.us-east-1.amazonaws.com/club-reaction-gifs/vibin.gif",
    altText: "vibin'",
  },
];

const reactionsMap = ALL_REACTIONS.reduce((map, reaction) => {
  map[reaction.type] = reaction;
  return map;
}, {} as Record<ReactionType, ReactionSpec>);

export const isReactionText = (text: string): text is ReactionType =>
  Boolean(reactionsMap[text as ReactionType]);

export const findReaction = (reactionType: ReactionType) =>
  reactionsMap[reactionType];
