import { dayjs } from "@outschool/time";
import { useMutation } from "@outschool/ui-apollo";
import useDeepCompareEffect from "use-deep-compare-effect";
import type { PureQueryOptions, DocumentNode } from "@outschool/ui-apollo";

import type { DataProxy } from "../graphql/types";

interface Thread {
  uid: string;
  isRead: boolean;
}

export function useMarkAsRead({
  markerMutation,
  thread,
  update,
  refetchQueries = [],
  canAutomaticallyMarkRead = true,
}: {
  markerMutation: DocumentNode;
  thread?: Thread;
  update?: (cache: DataProxy) => void;
  refetchQueries?: PureQueryOptions[];
  canAutomaticallyMarkRead?: boolean;
}) {
  const [handleMarkAsRead] = useMutation(markerMutation, {
    update,
    refetchQueries,
  });

  useDeepCompareEffect(() => {
    // Don't mark as read b/c it has been marked unread for some other reason
    if (!canAutomaticallyMarkRead) {
      return;
    }
    if (thread && dayjs(thread.uid).isValid()) {
      // ignoring because this was the optimistic response
      return;
    }
    if (thread && !thread.isRead && thread.uid) {
      handleMarkAsRead({
        variables: { threadUid: thread.uid },
      });
    }
  }, [thread, handleMarkAsRead]);
}
