import {
  faAtomAlt,
  faBookReader,
  faCalculatorAlt,
  faCode,
  faCoins,
  faCommentsAlt,
  faDumbbell,
  faFeatherAlt,
  faGamepadAlt,
  faHatChef,
  faLandmark,
  faLanguage,
  faMicrophoneStand,
  faMusic,
  faPaintbrushAlt,
  faPawAlt,
  faPencil,
  faPuzzlePiece,
  faSmile,
  faUserMusic,
} from "@outschool/icons";

import animalsIcon from "../../images/animals.png";
import * as circlePattern1 from "../../images/circular-background-pattern-1.svg";
import * as circlePattern2 from "../../images/circular-background-pattern-2.svg";
import * as cookingPattern from "../../images/cooking-background-pattern.svg";
import cookingIcon from "../../images/cooking.png";
import danceIcon from "../../images/dance.png";
import * as drawingPattern from "../../images/drawing-background-pattern.svg";
import drawingIcon from "../../images/drawing.png";
import emotionsIcon from "../../images/emotions.png";
import fitnessIcon from "../../images/fitness.png";
import * as videoGamesPattern from "../../images/gaming-background-pattern.svg";
import videoGamesIcon from "../../images/gaming.png";
import * as historyPattern from "../../images/history-background-pattern.svg";
import historyIcon from "../../images/history.png";
import * as mathPattern from "../../images/math-background-pattern.svg";
import mathIcon from "../../images/math.png";
import moneyIcon from "../../images/money.png";
import musicIcon from "../../images/music.png";
import * as paintingPattern from "../../images/painting-background-pattern.svg";
import paintingIcon from "../../images/painting.png";
import programmingIcon from "../../images/programming.png";
import puzzlesIcon from "../../images/puzzles.png";
import * as readingPattern from "../../images/reading-background-pattern.svg";
import readingIcon from "../../images/reading.png";
import scienceIcon from "../../images/science.png";
import * as singingPattern from "../../images/singing-background-pattern.svg";
import singingIcon from "../../images/singing.png";
import socializingIcon from "../../images/socializing.png";
import * as spanishPattern from "../../images/spanish-background-pattern.svg";
import spanishIcon from "../../images/spanish.png";
import * as triangularPattern from "../../images/triangular-background-pattern.svg";
import * as wavyPattern from "../../images/wavy-background-pattern.svg";
import * as writingPattern from "../../images/writing-background-pattern.svg";
import writingIcon from "../../images/writing.png";

import type { IconDefinition } from "@outschool/icons";

interface TopicIllustration {
  background: string;
  pattern: any;
  icon: string;
  smallIcon: IconDefinition;
}

interface TopicIllustrationObject {
  [key: string]: TopicIllustration;
}

export const TOPIC_ILLUSTRATIONS: TopicIllustrationObject = {
  "cc6c2e2d-9f21-433e-b9a4-e7195954b643": {
    background: "linear-gradient(141.22deg, #45BBE0 6.22%, #2BA7CE 87.36%)",
    pattern: drawingPattern,
    icon: drawingIcon,
    smallIcon: faPencil,
  },
  "6b9fbe7f-1f71-4a09-85ed-5e1f6bfa40bc": {
    background: "linear-gradient(126.59deg, #FFC773 3.92%, #FAAA32 105.4%)",
    pattern: readingPattern,
    icon: readingIcon,
    smallIcon: faBookReader,
  },
  "7c850011-0494-4810-a574-45d72025b03f": {
    background: "linear-gradient(141.22deg, #45BBE0 6.22%, #2BA7CE 87.36%)",
    pattern: mathPattern,
    icon: mathIcon,
    smallIcon: faCalculatorAlt,
  },
  "a8f0c9d7-c7a9-41ed-bdb2-0bb9ebabdc2b": {
    background: "linear-gradient(126.59deg, #FFC773 3.92%, #FAAA32 105.4%)",
    pattern: wavyPattern,
    icon: animalsIcon,
    smallIcon: faPawAlt,
  },
  "8ce266d2-d18f-4c78-8427-8538aa3b1cb4": {
    background: "linear-gradient(136.65deg, #3BD69A 6.91%, #29A877 83.63%);",
    pattern: cookingPattern,
    icon: cookingIcon,
    smallIcon: faHatChef,
  },
  "af5a5d9b-d5b1-4b12-9d60-9448b75b1d11": {
    background: "linear-gradient(136.65deg, #F76262 6.91%, #D34040 83.63%)",
    pattern: circlePattern1,
    icon: danceIcon,
    smallIcon: faUserMusic,
  },
  "7025c2c7-fdd4-4d9b-9e59-f2d495a7e3b7": {
    background: "linear-gradient(136.65deg, #467ECE 6.91%, #4784DB 83.63%)",
    pattern: triangularPattern,
    icon: emotionsIcon,
    smallIcon: faSmile,
  },
  "08e9e90a-de1d-475d-bc86-873dc9fd2e7d": {
    background: "linear-gradient(125.81deg, #E7537F 2.62%, #CC3068 109.05%)",
    pattern: historyPattern,
    icon: historyIcon,
    smallIcon: faLandmark,
  },
  "337f0d46-de4b-468a-8aa8-653cac8dd7c9": {
    background: "linear-gradient(136.65deg, #467ECE 6.91%, #4784DB 83.63%)",
    pattern: wavyPattern,
    icon: musicIcon,
    smallIcon: faMusic,
  },
  "1925bfd7-8654-40f2-8093-dace9d4461be": {
    background: "linear-gradient(136.65deg, #467ECE 6.91%, #4784DB 83.63%)",
    pattern: triangularPattern,
    icon: moneyIcon,
    smallIcon: faCoins,
  },
  "bd535965-8dae-4469-9c17-ed621b483402": {
    background:
      "linear-gradient(136.65deg, #3BD69A 6.91%, #29A877 83.63%),linear-gradient(136.65deg, #467ECE 6.91%, #4784DB 83.63%)",
    pattern: paintingPattern,
    icon: paintingIcon,
    smallIcon: faPaintbrushAlt,
  },
  "ab2188d5-65d0-465e-a62c-789ab2ac8bed": {
    background: "linear-gradient(130.67deg, #9970F0 7.07%, #7E5BE2 86.09%)",
    pattern: mathPattern,
    icon: programmingIcon,
    smallIcon: faCode,
  },
  "32d5852c-3ce3-45f5-b7fb-6a69eb2f5aff": {
    background: "linear-gradient(136.65deg, #F76262 6.91%, #D34040 83.63%)",
    pattern: circlePattern1,
    icon: puzzlesIcon,
    smallIcon: faPuzzlePiece,
  },
  "06cda453-fb97-4cea-a227-9a50db87f5aa": {
    background: "linear-gradient(125.81deg, #5494EF 2.62%, #3973C6 109.05%)",
    pattern: circlePattern2,
    icon: scienceIcon,
    smallIcon: faAtomAlt,
  },
  "4172666c-9709-4856-b080-b745569f3a38": {
    background: "linear-gradient(130.67deg, #9970F0 7.07%, #7E5BE2 86.09%)",
    pattern: singingPattern,
    icon: singingIcon,
    smallIcon: faMicrophoneStand,
  },
  "94f06b93-025a-4702-9256-0752dc9660c0": {
    background: "linear-gradient(136.65deg, #467ECE 6.91%, #4784DB 83.63%)",
    pattern: spanishPattern,
    icon: socializingIcon,
    smallIcon: faCommentsAlt,
  },
  "918f1840-83b1-419d-9196-8eed5f9e6656": {
    background: "linear-gradient(136.65deg, #467ECE 6.91%, #4784DB 83.63%)",
    pattern: spanishPattern,
    icon: spanishIcon,
    smallIcon: faLanguage,
  },
  "8ce6a480-80c5-4957-ab1c-c87464edf3bc": {
    background: "linear-gradient(130.67deg, #9970F0 7.07%, #7E5BE2 86.09%)",
    pattern: videoGamesPattern,
    icon: videoGamesIcon,
    smallIcon: faGamepadAlt,
  },
  "683c508e-ffea-4910-aea6-d63da8a52611": {
    background: "linear-gradient(130.67deg, #9970F0 7.07%, #7E5BE2 86.09%)",
    pattern: circlePattern2,
    icon: fitnessIcon,
    smallIcon: faDumbbell,
  },
  "f5bb9482-f42d-4787-b9b7-059301c8a0ba": {
    background: "linear-gradient(136.65deg, #467ECE 6.91%, #4784DB 83.63%)",
    pattern: writingPattern,
    icon: writingIcon,
    smallIcon: faFeatherAlt,
  },
};

export const TOPIC_COLOR = "#005320";
export const PLACEHOLDER_COLOR = "#4B01D4"; // peacock700
export const TEACHER_COLOR = "#4B01D4";
