import { Box, Button, Theme, Typography } from "@outschool/backpack";
import {
  Modal,
  VideoPlayerFactory,
  useComponentTrackingContext,
} from "@outschool/ui-legacy-component-library";
import { responsive } from "@outschool/ui-utils";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import fill from "lodash/fill";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Activity } from "../classCard/types";
import PageSelect from "../PageSelect";
import ClassCard from "./ClassCard";

type FavoriteClassSelectionModalProps = {
  open: boolean;
  activities: Activity[];
  totalResults: number;
  selected?: string[];
  trackingPrefix: string;
  trackingProperties?: { [key: string]: string | number };
  onPageChange: (newOffset: number) => void;
  onSave: (s: string[]) => void;
  onCancel: () => void;
  VideoPlayer: ReturnType<typeof VideoPlayerFactory>;
};

const FAVORITE_CLASS_MODAL_OFFSET = 6;

export default function FavoriteClassSelectionModal({
  open,
  activities,
  totalResults,
  selected = [],
  trackingPrefix,
  trackingProperties,
  onPageChange,
  onSave,
  onCancel,
  VideoPlayer,
}: FavoriteClassSelectionModalProps) {
  const [selectedActivities, setSelectedActivities] = useState(selected);

  useEffect(() => {
    setSelectedActivities(selected);
  }, [selected]);

  const track = useComponentTrackingContext();

  const trackAction = useCallback(
    (action: String, uid: String) => {
      track(
        "learner_profile.top_classes." + action,
        { activityUid: uid },
        { integrations: { All: false } }
      );
    },
    [track]
  );

  function handleSelect(uid: string) {
    if (selectedActivities.indexOf(uid) > -1) {
      setSelectedActivities([...selectedActivities.filter(v => v !== uid)]);
      trackAction("deselect", uid);
    } else if (selectedActivities.length === 3) {
      selectedActivities.shift();
      setSelectedActivities([...selectedActivities, uid]);
      trackAction("select", uid);
    } else {
      setSelectedActivities([...selectedActivities, uid]);
      trackAction("select", uid);
    }
  }

  function handleSave() {
    onSave(selectedActivities);
  }

  const pageOptions = useMemo(() => {
    const totalPages = Math.ceil(totalResults / FAVORITE_CLASS_MODAL_OFFSET);

    return fill(Array(totalPages), {}).map((_v, i) => ({
      label: `${i + 1}`,
      value: i,
    }));
  }, [totalResults]);

  return (
    <Modal
      sx={{
        maxWidth: "864px",
        width: responsive({ default: "90%", small: undefined }),
        minHeight: responsive({ default: "90%", small: undefined }),
        marginY: responsive({ default: "large", small: undefined }),
        borderRadius: "xlarge",
        padding: "1.5em",
      }}
      open={open}
      onClose={onCancel}
    >
      <Box
        sx={{
          borderColor: "#f1f2f3",
          borderBottom: "solid",
          borderWidth: "1px",
        }}
      >
        <Typography variant="h2">Add a class</Typography>
        <Typography
          variant="h5"
          sx={{
            marginBottom: "1em",
            color: "grey.500",
          }}
        >
          Choose what classes you want to show on your profile.
        </Typography>
      </Box>
      <Box
        flex
        sx={{
          flexWrap: "wrap",
          paddingTop: "1em",
        }}
      >
        {activities.map(activity => (
          <ClassCard
            key={activity.uid}
            activity={activity}
            trackingPrefix={trackingPrefix}
            trackingProperties={trackingProperties}
            onClick={() => handleSelect(activity.uid)}
            videoToShow="NONE"
            isSelected={selectedActivities.indexOf(activity.uid) > -1}
            sx={(theme: Theme) => ({
              width: "100%",
              marginRight: 0,
              [theme.breakpoints.up("md")]: {
                width: "240px",
              },
              [theme.breakpoints.up("sm")]: {
                marginRight: "1.5em",
              },
              marginBottom: "1.5em",
            })}
            VideoPlayer={VideoPlayer}
          />
        ))}
      </Box>
      <PageSelect pageOptions={pageOptions} onChange={onPageChange} />
      <Box
        flex
        sx={{
          justifyContent: "flex-end",
          marginTop: "1em",
        }}
      >
        <Button variant="contained" onClick={handleSave}>
          Select
        </Button>
      </Box>
    </Modal>
  );
}
