import { EnrollmentsRow, LearnersRow } from "@outschool/db-queries";
import { joinCommasAnd } from "@outschool/text";
import dayjs from "dayjs";
import lodashGet from "lodash/get";

interface LearnerPartial {
  name?: string | null;
  age?: string | number | null;
}
interface DisplayEnrollmentPartial {
  learner?: LearnerPartial;
  details?: LearnerPartial;
}

interface IsConfirmedArgs {
  confirmed_at?: Date | null;
  confirmedAt?: string;
}

interface IsWithdrawnArgs {
  withdrawn_at?: Date | null;
  withdrawnAt?: string;
}

export const displayName = (
  enrollment: DisplayEnrollmentPartial,
  learner?: LearnerPartial
) => {
  learner = learner || enrollment.learner;
  return learner
    ? learner.name
    : lodashGet(enrollment, "details.name", "a gift enrollment");
};

export function studentNames(
  enrollments: Array<Pick<EnrollmentsRow, "learner_uid" | "details">>,
  learners: Array<Pick<LearnersRow, "uid" | "name" | "age">>
) {
  if (!enrollments?.length) {
    return undefined;
  }

  const names = enrollments
    .map(e =>
      displayName(
        e,
        learners.find(l => l.uid === e.learner_uid)
      )
    )
    .filter(Boolean)
    .sort();

  return joinCommasAnd(names);
}

export const isConfirmed = ({ confirmed_at, confirmedAt }: IsConfirmedArgs) =>
  Boolean(confirmed_at || confirmedAt);

export const isValid = (enrollment: IsConfirmedArgs & IsWithdrawnArgs) =>
  !!(isConfirmed(enrollment) && !isWithdrawn(enrollment));

export const isWithdrawn = ({ withdrawn_at, withdrawnAt }: IsWithdrawnArgs) =>
  Boolean(withdrawn_at || withdrawnAt);

export const isOngoing = (
  enrollment: Pick<EnrollmentsRow, "ongoing_start_time">
) => Boolean(enrollment.ongoing_start_time);

interface HasOngoingStartedArgs {
  ongoing_start_time: Date | string | null;
}
export const hasOngoingStarted = ({
  ongoing_start_time,
}: HasOngoingStartedArgs) => {
  const _ongoingStartTime = ongoing_start_time;
  if (!!_ongoingStartTime) {
    return dayjs(_ongoingStartTime).isBefore(dayjs());
  }
  return false;
};

interface HasOngoingEndedArgs {
  pause_window_uid: string | null;
  ongoing_end_time: Date | null;
}

/**
 * Checks if an ongoing enrollment has ended. It is not considered "ended"
 * if it is paused.
 * Do not use in the FE. Use the Enrollments.isEnded GQL field.
 */
export const hasOngoingEnded = ({
  ongoing_end_time,
  pause_window_uid,
}: HasOngoingEndedArgs) =>
  Boolean(ongoing_end_time) &&
  dayjs(ongoing_end_time).isBefore(dayjs()) &&
  !Boolean(pause_window_uid);

/**
 * Checks whether the enrollment is paused by looking at the enrollment only.
 */
export function isPaused(enrollment: EnrollmentsRow, now: Date = new Date()) {
  /*
   * An enrollment can't be paused after being stopped, and can't be stopped
   * while paused, so if the ongoing_end_time is in the past, that must be due
   * to the enrollment being paused.
   */
  return Boolean(
    enrollment.pause_window_uid &&
      enrollment.ongoing_stopped_at &&
      enrollment.ongoing_end_time &&
      dayjs(enrollment.ongoing_end_time).isBefore(now)
  );
}

type IsUnclaimedGiftArgs = {
  purchased_as_gift?: boolean;
  purchasedAsGift?: boolean;
  gift_claimed_at?: Date | null;
  giftClaimedAt?: string;
};

export const isUnclaimedGift = ({
  purchased_as_gift,
  purchasedAsGift,
  gift_claimed_at,
  giftClaimedAt,
}: IsUnclaimedGiftArgs) =>
  Boolean(purchased_as_gift || purchasedAsGift) &&
  !Boolean(gift_claimed_at || giftClaimedAt);
