import { Box, Button, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { AvatarImage, COMMON_AVATARS } from "@outschool/ui-components-shared";
import { Modal } from "@outschool/ui-legacy-component-library";
import React from "react";

interface PickLearnerAvatarModalProps {
  unlockedAvatars?: string[];
  open?: boolean;
  onSelect: (s: string) => void;
  onCancel: () => void;
  headingText?: string;
  cancelText?: string;
}

export default function PickLearnerAvatarModal({
  unlockedAvatars = [],
  open = true,
  onSelect,
  onCancel,
  headingText = "Select avatar",
  cancelText = "Cancel",
}: PickLearnerAvatarModalProps) {
  //add the unlocked avatars to the deafult avatar list for full avatar list for current child
  const allAvatars = COMMON_AVATARS.concat(unlockedAvatars);

  return (
    <Modal open={open} onClose={onCancel}>
      <Typography variant="h3">{headingText}</Typography>
      <Box
        sx={{
          marginTop: "1em",
          marginBottom: "0.5em",
        }}
      >
        {allAvatars.map(avatar => (
          <AvatarImage
            key={avatar}
            avatar={avatar}
            height={50}
            width={50}
            sx={{ cursor: "pointer" }}
            onClick={() => onSelect(avatar)}
          />
        ))}
      </Box>
      <Box
        sx={{
          marginTop: "1em",
        }}
      >
        <Button
          variant="link"
          onClick={onCancel}
          sx={{
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          {cancelText}
        </Button>
      </Box>
    </Modal>
  );
}
