/** @jsx jsxPragma */

import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import jsxPragma from "./jsxPragma";

type BreakNewlinesProps = {
  sx?: SystemStyleObject | null;
  children: React.ReactNode;
} & React.ClassAttributes<HTMLPreElement>;

const BreakNewlines = React.forwardRef(
  ({ children, ...props }: BreakNewlinesProps, ref: React.Ref<any>) => {
    return (
      <pre {...props} ref={ref}>
        {children}
      </pre>
    );
  }
);

export default BreakNewlines;
