import {
  Box,
  Button,
  ButtonProps,
  Icon,
  Theme,
  Typography,
  visuallyHidden,
} from "@outschool/backpack";
import {
  faBold,
  faCode,
  faHeading,
  faItalic,
  faListOl,
  faListUl,
  faUnderline,
} from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { Editor as TipTapEditor } from "@tiptap/react";
import React from "react";

function MenuBarButton({
  selected,
  children,
  ...props
}: ButtonProps & { selected?: boolean }) {
  return (
    <Button
      {...props}
      aria-pressed={selected}
      size="small"
      sx={(theme: Theme) => {
        return {
          fontSize: theme.spacing(14),
          // @ts-ignore
          backgroundColor: selected ? theme.palette.primary["100"] : "inherit",
          padding: "6px 4px",
          color: selected
            ? theme.palette.primary.main
            : theme.palette.text.secondary,
          borderColor: "transparent !important",
          borderRadius: "4px",
        };
      }}
    >
      {children}
    </Button>
  );
}
export default function MenuBar({ editor }: { editor: TipTapEditor }) {
  const { t } = useTranslation("ui-rich-text-editor\\RichTextEditor\\MenuBar");

  if (!editor) {
    return null;
  }
  return (
    <Box
      flex
      sx={{
        paddingLeft: "4px",
        paddingBottom: "4px",
        fontSize: "10px",
        gap: "12px",
      }}
    >
      <Box flex sx={{ gap: "4px" }}>
        <MenuBarButton
          selected={
            // eslint-disable-next-line i18next/no-literal-string
            editor.isFocused && editor.isActive("heading", { level: 3 })
          }
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
        >
          <Icon icon={faHeading} />
          <Typography sx={visuallyHidden}>{t("Heading")}</Typography>
        </MenuBarButton>
        <MenuBarButton
          value="bold"
          // eslint-disable-next-line i18next/no-literal-string
          selected={editor.isFocused && editor.isActive("bold")}
          onClick={() => editor.chain().focus().toggleBold().run()}
        >
          <Icon icon={faBold} />
          <Typography sx={visuallyHidden}>{t("Bold")}</Typography>
        </MenuBarButton>
        <MenuBarButton
          value="italic"
          // eslint-disable-next-line i18next/no-literal-string
          selected={editor.isFocused && editor.isActive("italic")}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        >
          <Icon icon={faItalic} />
          <Typography sx={visuallyHidden}>{t("Italic")}</Typography>
        </MenuBarButton>
        <MenuBarButton
          // eslint-disable-next-line i18next/no-literal-string
          selected={editor.isFocused && editor.isActive("underline")}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
        >
          <Icon icon={faUnderline} />
          <Typography sx={visuallyHidden}>{t("Underline")}</Typography>
        </MenuBarButton>
      </Box>
      <Box flex sx={{ gap: "4px" }}>
        <MenuBarButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          // eslint-disable-next-line i18next/no-literal-string
          selected={editor.isFocused && editor.isActive("bulletList")}
        >
          <Icon icon={faListUl} />
          <Typography sx={visuallyHidden}>{t("Bulleted list")}</Typography>
        </MenuBarButton>
        <MenuBarButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          // eslint-disable-next-line i18next/no-literal-string
          selected={editor.isFocused && editor.isActive("orderedList")}
        >
          <Icon icon={faListOl} />
          <Typography sx={visuallyHidden}>{t("Ordered list")}</Typography>
        </MenuBarButton>
        <MenuBarButton
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          // eslint-disable-next-line i18next/no-literal-string
          selected={editor.isFocused && editor.isActive("codeBlock")}
        >
          <Icon icon={faCode} />
          <Typography sx={visuallyHidden}>{t("Code block")}</Typography>
        </MenuBarButton>
      </Box>
    </Box>
  );
}
