import { Box, Icon, Skeleton, Typography } from "@outschool/backpack";
import { Leader as LeaderType } from "@outschool/gql-backend-generated";
import { fasStar } from "@outschool/icons";
import { UserHeadshotImage } from "@outschool/ui-components-shared";
import { Subscript } from "@outschool/ui-legacy-component-library";
import React from "react";

// NOTE: We're passing around an incomplete credential object,
//  this is a workaround for using Partial<LeaderType>.
type credentialType = Pick<
  LeaderType["credentials"][number],
  "__typename" | "uid" | "degreeType" | "degreeSubject"
>;

export function LeaderDetailsCard({
  leader,
}: {
  leader: Omit<Partial<LeaderType>, "credentials"> & {
    credentials?: credentialType[] | undefined;
  };
}) {
  const leaderStyling = {
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    overflow: "hidden",
  };
  return (
    <Box flex>
      <UserHeadshotImage user={leader} size={32} />
      <Box
        sx={{
          ...leaderStyling,
          fontSize: 14,
          color: "neutral.800",
          lineHeight: "32px",
          marginLeft: "8px",
        }}
      >
        {leader?.name}
      </Box>
    </Box>
  );
}

export function LeaderDetailsCardLoading() {
  return (
    <Box flex>
      <Skeleton variant="circular" width={32} height={32} />
      <Skeleton
        sx={{ marginLeft: "8px" }}
        variant="text"
        width={140}
        height={32}
      />
    </Box>
  );
}

export function LeaderDetailsListing({
  leader,
}: {
  leader: Partial<
    Pick<LeaderType, "reviewCount" | "averageActivityStarRating">
  > &
    Pick<LeaderType, "name" | "photo">;
}) {
  const leaderStyling = {
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    overflow: "hidden",
    width: "200px",
  };
  return (
    <Box flex sx={{ flexDirection: "column", margin: "auto" }}>
      <Typography
        as={Box}
        variant="body2"
        sx={{ color: "grey.600", ...leaderStyling }}
      >
        {leader?.name}
      </Typography>
      {leader.averageActivityStarRating && (
        <Box
          flex
          sx={{
            alignItems: "center",
            gap: 4,
          }}
        >
          <Icon icon={fasStar} size={"small"} color="warning" />
          <Typography
            as={Box}
            variant="body2"
            emphasized
            sx={{
              paddingLeft: 4,
            }}
          >
            {(
              Math.round(
                (leader.averageActivityStarRating + Number.EPSILON) * 10
              ) / 10
            ).toFixed(1)}
          </Typography>
          {leader.reviewCount && (
            <Typography
              as={Box}
              variant="body2"
              sx={{
                color: "grey.600",
              }}
            >
              ({leader.reviewCount.toLocaleString()})
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
}

export function LeaderDetailsMobileListing({
  leader,
}: {
  leader: Pick<
    LeaderType,
    "reviewCount" | "name" | "averageActivityStarRating" | "photo"
  >;
}) {
  return (
    <Box
      flex
      sx={{
        backgroundColor: "grey.50",
        borderRadius: 100,
        alignItems: "center",
        color: "grey.600",
        minWidth: 0,
      }}
    >
      <UserHeadshotImage user={leader} size={24} />
      <Box
        flex
        sx={{
          margin: "0 0.5em 0",
          alignItems: "center",
          minWidth: 0,
        }}
      >
        <Typography as={Box} variant="caption" noWrap>
          {leader?.name}
        </Typography>
        {leader.reviewCount > 0 && leader.averageActivityStarRating != null && (
          <Box flex sx={{ alignItems: "center", paddingLeft: 5 }}>
            <Icon
              icon={fasStar}
              color="warning"
              sx={{
                fontSize: "10px",
              }}
            />
            <Typography
              as={Box}
              variant="body2"
              emphasized
              sx={{
                marginLeft: 2,
                marginRight: 3,
              }}
            >
              {" "}
              {(
                Math.round(
                  (leader.averageActivityStarRating + Number.EPSILON) * 10
                ) / 10
              ).toFixed(1)}
            </Typography>
            <Subscript> ({leader.reviewCount.toLocaleString()})</Subscript>
          </Box>
        )}
      </Box>
    </Box>
  );
}
