/** @jsx jsxPragma */

import { useTranslation } from "@outschool/localization";
import { SystemStyleObject } from "@styled-system/css";
import truncate from "lodash/truncate";
import React from "react";

import jsxPragma from "./jsxPragma";
import LegacyButton from "./LegacyButton/LegacyButton";

function CollapsedText({
  maxCharacters,
  children,
  as: Component = "pre",
  sx,
  onToggle,
  buttonRef,
  allowCollapse = true,
  showMoreLabel,
  showLessLabel,
  buttonSx = {},
}: {
  maxCharacters: number;
  children: string;
  as?: React.ComponentType | string;
  sx?: SystemStyleObject;
  onToggle?: (collapsed: boolean, fn: () => void) => void;
  buttonRef?: React.Ref<JSX.IntrinsicElements["button"]>;
  allowCollapse?: boolean;
  showMoreLabel?: string;
  showLessLabel?: string;
  buttonSx?: SystemStyleObject;
}) {
  const showCollapse = children && children.length > maxCharacters;
  const [collapsed, setCollapsed] = React.useState(showCollapse);
  const { t } = useTranslation("ui-legacy-component-library\\CollapsedText");

  showMoreLabel = showMoreLabel ?? t("Show More...");
  showLessLabel = showLessLabel ?? t("Show Less...");

  return (
    // @ts-ignore
    <Component sx={sx}>
      {collapsed
        ? truncate(children, {
            length: maxCharacters,
            separator: " ",
          }).trimEnd()
        : children}
      {showCollapse && (collapsed || allowCollapse) && (
        <React.Fragment>
          &nbsp;
          <LegacyButton
            // @ts-ignore
            sx={buttonSx}
            ref={buttonRef}
            onClick={() => {
              if (onToggle) {
                onToggle(!collapsed, () => {
                  setCollapsed(!collapsed);
                });
              } else {
                setCollapsed(!collapsed);
              }
            }}
            variant="link"
          >
            {collapsed ? showMoreLabel : showLessLabel}
          </LegacyButton>
        </React.Fragment>
      )}
    </Component>
  );
}

export default CollapsedText;
