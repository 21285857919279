import { Box, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React, { PropsWithChildren } from "react";

interface ActivityTitleProps {
  activity: {
    uid: string;
    title: string;
  };
}

const ActivityTitle: React.FC<PropsWithChildren<ActivityTitleProps>> = ({
  activity,
  children,
}) => {
  return (
    <Box
      flex
      sx={{
        paddingX: "16px",
        paddingTop: "12px",
        alignItems: "flex-start",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          WebkitLineClamp: "2px",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "left",
          paddingRight: "1em",
          color: "grey.900",
        }}
      >
        {activity.title}
      </Typography>
      {children}
    </Box>
  );
};

export default ActivityTitle;
