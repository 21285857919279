export enum DeliveryTypeValues {
  OneOnOneClass = "One-on-one class",
  OneTimeClass = "One-time class",
  OngoingClass = "Ongoing class",
  SemesterCourse = "Semester course",
  ShortCourse = "Short course",
  Camp = "Camp",
  Club = "Group",
  Course = "Course",
  EnrichmentOrClub = "Enrichment or Club",
}

export enum FormatValues {
  Live = "Live online",
  Flex = "Flexible schedule",
  SelfPaced = "Self-Paced",
}

type IsOneOnOneFormatArgs = {
  size_max?: number | null;
  duration_weeks?: number | null;
  weekly_meetings?: number | null;
  hasTeacherSchedule?: boolean | null;
  uses_teacher_scheduling?: boolean | null;
};

export function isOneOnOneFormat(activity?: IsOneOnOneFormatArgs) {
  if (!activity) {
    return false;
  }
  if (!activity.hasTeacherSchedule && !activity.uses_teacher_scheduling) {
    return false;
  }
  if (activity.size_max !== 1) {
    return false;
  }
  return true;
}

type IsLiveOneTimeArgs = {
  is_self_paced?: boolean | null;
  is_ongoing_weekly?: boolean | null;
  weekly_meetings?: number | null;
  duration_weeks?: number | null;
};

export const isLiveOneTime = (activity: IsLiveOneTimeArgs) => {
  if (activity.is_ongoing_weekly) {
    return false;
  }
  if (activity.is_self_paced) {
    return false;
  }
  return activity.weekly_meetings === 1 && activity.duration_weeks === 1;
};

type IsOneTimeArgs = {
  is_self_paced?: boolean | null;
  is_ongoing_weekly?: boolean | null;
  weekly_meetings?: number | null;
  duration_weeks?: number | null;
};

export const isOneTime = (
  activity: IsOneTimeArgs,
  lessons: { uid: string }[]
) => {
  if (activity.is_ongoing_weekly) {
    return false;
  }
  if (activity.is_self_paced) {
    return lessons.length === 1;
  }
  return isLiveOneTime(activity);
};

export enum ClassFormatType {
  Course = "course",
  EnrichmentOrClub = "enrichment_or_club",
  OneOnOne = "one_on_one",
  OneTime = "one_time",
}

export type IsCourseArgs = IsOneTimeArgs & {
  is_club: boolean;
  is_ongoing_weekly: boolean | null;
  weekly_meetings: number | null;
  duration_weeks: number | null;
  subject: string | null;
  title: string | null;
  has_building_content: boolean;
  size_max: number | null;
};

export function isCourseExcludedSubject(activity: { subject: string | null }) {
  return (
    !activity.subject ||
    [
      "Arts",
      "Games & Hobbies",
      "Health & Wellness",
      "Life Skills",
      "Music",
      "Test Prep",
    ].includes(activity.subject)
  );
}

export function isCourse(
  activity: IsCourseArgs,
  lessons: { uid: string }[]
): boolean {
  const testPrepKeywords = [
    "test prep",
    "test-prep",
    "bootcamp",
    "boot-camp",
    "boot camp",
  ];
  const courseKeywords = [
    "full year",
    "full-year",
    "fullyear",
    "complete year",
    "complete-year",
    "completeyear",
    "full course",
    "full-course",
    "fullcourse",
    "curriculum",
    "semester",
  ];
  const tutorKeywords = ["tutor", "homework help", "coaching"];

  // Exclude chat groups
  if (activity.is_club) {
    return false;
  }
  // Exclude one time
  if (isOneTime(activity, lessons)) {
    return false;
  }
  // Exclude certain subjects
  if (!!activity.subject && isCourseExcludedSubject(activity)) {
    return false;
  }

  const title = activity.title;
  const weeklyMeetings: number = activity.weekly_meetings ?? 0;
  const durationWeeks: number = activity.duration_weeks ?? 0;
  // Check for test prep keywords in title
  if (
    !!title &&
    testPrepKeywords.some(keyword => title.toLowerCase().includes(keyword))
  ) {
    return false;
  }

  // Check for course keywords in title
  if (
    !!title &&
    courseKeywords.some(keyword => title.toLowerCase().includes(keyword))
  ) {
    return true;
  }

  // Conditions for ongoing classes with building content
  if (activity.is_ongoing_weekly && activity.has_building_content) {
    return true;
  }

  // Conditions for set end date classes that meet for 8+ weeks or meeting more than once a week
  if (
    !activity.is_ongoing_weekly &&
    (weeklyMeetings > 1 || durationWeeks >= 8)
  ) {
    // Exclude one-on-ones with tutor keywords
    if (
      activity.size_max === 1 &&
      tutorKeywords.some(
        keyword => !!title && title.toLowerCase().includes(keyword)
      )
    ) {
      return false;
    }
    return true;
  }

  return false;
}

export function getClassFormatType(
  activity: IsCourseArgs & IsOneTimeArgs & IsOneOnOneFormatArgs,
  lessons: { uid: string }[]
): ClassFormatType {
  if (activity.is_club) {
    return ClassFormatType.EnrichmentOrClub;
  }
  if (isOneTime(activity, lessons)) {
    return ClassFormatType.OneTime;
  }
  if (isCourse(activity, lessons)) {
    return ClassFormatType.Course;
  }
  if (isOneOnOneFormat(activity)) {
    return ClassFormatType.OneOnOne;
  }
  return ClassFormatType.EnrichmentOrClub;
}

type IsFixedLengthMultiDayLiveClassArgs = {
  weekly_meetings?: number | null;
  duration_weeks?: number | null;
  is_ongoing_weekly?: boolean | null;
  is_club?: boolean;
  isClub?: boolean;
  uses_teacher_scheduling?: boolean;
  hasTeacherSchedule?: boolean;
};

export function isFixedLengthMultiDayLiveClass(
  activity: IsFixedLengthMultiDayLiveClassArgs
): boolean {
  if (
    !activity ||
    !activity.duration_weeks ||
    !activity.weekly_meetings ||
    activity.is_ongoing_weekly ||
    Boolean(activity?.hasTeacherSchedule) ||
    Boolean(activity?.uses_teacher_scheduling) ||
    Boolean(activity?.isClub) ||
    Boolean(activity?.is_club)
  ) {
    return false;
  }

  if (activity.duration_weeks === 1 && activity.weekly_meetings > 1) {
    return true;
  }

  if (activity.duration_weeks > 1 && activity.weekly_meetings >= 1) {
    return true;
  }

  return false;
}

export const SELF_PACED_MAX_DELAY_START_TIME = 180;
