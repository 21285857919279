import {
  ReactionSpec,
  ReactionType,
  findReaction,
} from "@outschool/animation-resources";
import React from "react";

import { useClubContext } from "../../providers/ClubProvider";
import { LottieAnimation } from "./LottiePlayer";

interface LoadedReaction extends ReactionSpec {
  animation: LottieAnimation;
}

const isLoadedReaction = (
  reaction: ReactionSpec | LoadedReaction
): reaction is LoadedReaction => !!reaction?.hasOwnProperty("animation");

const loadReaction = async (
  reactionToLoad: ReactionSpec | LoadedReaction,
  loadLottieMap: ReturnType<typeof useClubContext>["loadLottieMap"]
): Promise<LoadedReaction> => {
  if (isLoadedReaction(reactionToLoad)) {
    return reactionToLoad;
  }

  const lottieMap = await loadLottieMap();
  return {
    ...reactionToLoad,
    animation: {
      animationData: lottieMap[reactionToLoad.type],
      altText: reactionToLoad.altText,
    },
  };
};

export const useLoadReaction = (
  reactionToLoad: ReactionSpec | undefined
): LoadedReaction | undefined => {
  const [reaction, setReaction] = React.useState<LoadedReaction | undefined>();
  const { loadLottieMap } = useClubContext();

  React.useEffect(() => {
    const loadAndSet = async (reaction: ReactionSpec) => {
      const reactionLoaded = await loadReaction(reaction, loadLottieMap);
      setReaction(reactionLoaded);
    };
    if (!!reactionToLoad) {
      loadAndSet(reactionToLoad);
    }
  }, [reactionToLoad, loadLottieMap]);

  return reaction;
};

export const useFindAndLoadReaction = (
  reactionTextValue: ReactionType
): LoadedReaction | undefined => {
  const reactionToLoad = findReaction(reactionTextValue);
  return useLoadReaction(reactionToLoad);
};
