/** @jsx jsxPragma */
import { jsxPragma } from "@outschool/ui-legacy-component-library";
import { pathnameWithoutLocale } from "@outschool/routes";

import { LinkProps, useLinkComponent } from "@outschool/ui-utils";
import React from "react";

import ExternalLink from "./ExternalLink";

import type { SystemStyleObject } from "@styled-system/css";

interface TabListProps {
  sx?: SystemStyleObject;
  children?: React.ReactNode;
}

/**
 * @deprecated  @deprecated Please use the `Tabs` or `TabList` component from @outschool/backpack
 */
export function TabList({ sx = {}, children }: TabListProps) {
  return (
    <ul
      /* eslint-disable react/no-unknown-property */
      // @ts-ignore
      sx={{
        display: "flex",
        flexWrap: "wrap",
        paddingLeft: 0,
        marginBottom: 0,
        listStyle: "none",
        ...sx,
      }}
      role="tablist"
    >
      {children}
    </ul>
  );
}

export interface TabProps extends Pick<LinkProps, "exact"> {
  sx?: SystemStyleObject;
  linkSx?: SystemStyleObject;
  to: string;
  matchPaths?: string[];
  label?: string | React.ReactNode;
  disabled?: boolean;
  children?:
    | null
    | React.ReactNode
    | ((isActive: boolean) => React.ReactNode | null);
  isActive?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
}

/**
 * @deprecated  @deprecated Please use the `Tab` component from @outschool/backpack
 */
export function Tab({
  sx = {},
  linkSx = {},
  to,
  matchPaths,
  label,
  disabled,
  exact = true,
  children = null,
}: TabProps) {
  const isActive = isActivePath(matchPaths ?? to, exact);
  const Link = useLinkComponent();
  const childContent = !!children
    ? typeof children === "function"
      ? children(isActive)
      : children
    : label;
  return (
    // @ts-ignore
    <li sx={{ marginBottom: -1, ...sx }} role="presentation">
      <Link
        to={to}
        // @ts-ignore
        sx={{
          display: "block",
          paddingY: "small",
          paddingX: "medium",
          border: "1px solid",
          borderTopLeftRadius: "medium",
          borderTopRightRadius: "medium",
          backgroundColor: isActive ? "white" : "transparent",
          borderColor: isActive ? "border" : "transparent",
          borderBottomColor: "transparent",
          color: isActive ? "text" : disabled ? "disabledText" : "link",
          "&:hover": {
            color: isActive ? "text" : disabled ? "disabledText" : "link",
            cursor: isActive || disabled ? "default" : "pointer",
          },
          ...linkSx,
        }}
        isActive={() => false}
        onClick={(e: React.SyntheticEvent) => disabled && e.preventDefault()}
        role="tab"
        aria-selected={isActive}
        aria-disabled={disabled}
        aria-label={label}
      >
        {childContent}
      </Link>
    </li>
  );
}

/**
 * @deprecated  @deprecated Please use the `Tab` component from @outschool/backpack
 */
export function UnderlinedTab({
  sx = {},
  linkSx = {},
  to,
  matchPaths,
  label,
  disabled,
  exact = true,
  children = null,
  isActive,
  onClick,
}: TabProps) {
  const isTabActive =
    isActivePath(matchPaths ?? to, exact) || isActive || false;
  const Link = useLinkComponent();
  const childContent = !!children
    ? typeof children === "function"
      ? children(isTabActive)
      : children
    : label;

  return (
    // @ts-ignore
    <li sx={{ marginBottom: -1, marginTop: "1px", ...sx }} role="presentation">
      <Link
        to={to}
        sx={underlinedTabStyle(isTabActive, disabled || false, linkSx)}
        isActive={() => false}
        onClick={(e: React.SyntheticEvent) => {
          disabled && e.preventDefault();
          if (!disabled) {
            onClick && onClick(e);
          }
        }}
        role="tab"
        aria-selected={isTabActive}
        aria-disabled={disabled}
        aria-label={label}
      >
        {childContent}
      </Link>
    </li>
  );
}

/**
 * @deprecated  @deprecated Please use the `Tab` component from @outschool/backpack
 */
export function UnderlinedTabExternal({
  sx = {},
  linkSx = {},
  to,
  label,
  disabled,
  children = null,
  onClick,
}: Pick<
  TabProps,
  "sx" | "linkSx" | "to" | "label" | "disabled" | "children" | "onClick"
>) {
  const childContent = !!children
    ? typeof children === "function"
      ? children(false)
      : children
    : label;
  return (
    // @ts-ignore
    <li sx={{ marginBottom: -1, marginTop: "1px", ...sx }} role="presentation">
      <ExternalLink
        url={to}
        // @ts-ignore
        sx={underlinedTabStyle(false, disabled || false, linkSx)}
        onClick={(e: React.SyntheticEvent) => {
          disabled && e.preventDefault();
          if (!disabled) {
            onClick && onClick(e);
          }
        }}
        aria-disabled={disabled}
        aria-label={label}
      >
        {childContent}
      </ExternalLink>
    </li>
  );
}

const underlinedTabStyle = (
  isTabActive: boolean,
  disabled: boolean,
  linkSx: SystemStyleObject
) => ({
  display: "block",
  paddingY: "small",
  marginRight: "2em",
  color: isTabActive ? "blue2" : disabled ? "disabledText" : "#696969",
  "&:hover:not(:disabled)": {
    color: isTabActive ? "blue2" : disabled ? "disabledText" : "#696969",
    cursor: isTabActive || disabled ? "default" : "pointer",
    textDecoration: "none",
  },
  borderBottom: "4px solid",
  borderBottomColor: isTabActive ? "blue2" : "transparent",
  ...linkSx,
});

function isActivePath(pathname: string | string[], exact = true) {
  const pathnames = Array.isArray(pathname) ? pathname : [pathname];
  return pathnames.some(pathname => {
    const [path] = pathname.split("?");
    const windowLocationPathname = pathnameWithoutLocale(
      window.location.pathname
    );
    const pathWithoutLocale = pathnameWithoutLocale(path);
    return exact
      ? windowLocationPathname === pathWithoutLocale
      : windowLocationPathname.startsWith(pathWithoutLocale);
  });
}
