import { Box, Typography } from "@outschool/backpack";
import * as Time from "@outschool/time";
import React from "react";

import UserAvatar from "../common/UserAvatar";
import { Sender } from "../types";

export interface ClassPostCommentSenderPhotoAndPublishDateProps {
  sender: Sender;
  publishedAt: Date;
  onUserClick?: (user: Sender) => void;
  userHref?: string;
  timeZone?: string;
}

const ClassPostCommentSenderPhotoAndPublishDate = ({
  sender,
  publishedAt,
  timeZone,
  onUserClick,
  userHref,
}: ClassPostCommentSenderPhotoAndPublishDateProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 8,
    }}
  >
    <UserAvatar
      size={30}
      sender={sender}
      onClick={onUserClick}
      href={userHref}
    />
    <Typography
      variant="body1"
      sx={{
        fontWeight: "700",
      }}
    >
      {sender.name?.trim()}
    </Typography>
    <Typography variant="body2" sx={{ color: "neutral.500" }}>
      {Time.formatDateTimeWithFullWeekday(publishedAt, timeZone)}
    </Typography>
  </Box>
);

export default ClassPostCommentSenderPhotoAndPublishDate;
