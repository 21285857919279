import {
  BasicModal,
  Box,
  Button,
  ButtonProps,
  Icon,
  Image,
  Typography,
} from "@outschool/backpack";
import * as FilestackUrls from "@outschool/filestack-urls";
import { AttachmentFragmentFragment } from "@outschool/gql-frontend-generated";
import { faFileImage, faFileVideo, faMemo } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import React from "react";

import { useVideoPlayerComponent } from "../providers";
import { isImageAttachment, isVideoAttachment } from "./attachments";
import ExternalLink from "./ExternalLink";
import { LegacyThemeProvider } from "@outschool/ui-legacy-component-library";

interface InlineAttachmentsProps {
  attachments: AttachmentFragmentFragment[];
  /**
   * Max number of attachments to show.
   * If there are more attachments than this, a message will be shown indicating how many more there are.
   */
  maxAttachments?: number;
  /**
   * Max width of the attachment buttons. If the filename is longer than this, it will be truncated.
   */
  maxButtonWidth?: number;
}
export const InlineAttachments = ({
  attachments,
  maxAttachments,
  maxButtonWidth,
}: InlineAttachmentsProps) => {
  const { t } = useTranslation("ui-components-shared\\InlineAttachments");
  return attachments.length > 0 ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 8,
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      {(maxAttachments
        ? attachments.slice(0, maxAttachments)
        : attachments
      ).map(attachment => (
        <AttachmentButton
          key={attachment.uid}
          attachment={attachment}
          maxWidth={maxButtonWidth}
        />
      ))}
      {maxAttachments && attachments.length > maxAttachments ? (
        <Typography variant="body2">
          {t("+ {{numberOfAdditionalAttachments}} more", {
            numberOfAdditionalAttachments: attachments.length - maxAttachments,
          })}
        </Typography>
      ) : null}
    </Box>
  ) : null;
};

interface AttachmentButtonProps {
  attachment: AttachmentFragmentFragment;
  maxWidth?: number | null;
}
const AttachmentButton = ({ attachment, maxWidth }: AttachmentButtonProps) =>
  isImageAttachment(attachment) || isVideoAttachment(attachment) ? (
    <AttachmentViewerButton attachment={attachment} maxWidth={maxWidth} />
  ) : (
    <AttachmentDownloadButton attachment={attachment} maxWidth={maxWidth} />
  );

interface AttachmentDownloadButtonProps extends ButtonProps {
  attachment: AttachmentFragmentFragment;
  maxWidth?: number | null;
}
const AttachmentDownloadButton = ({
  attachment,
  maxWidth,
  onClick,
  ...buttonProps
}: AttachmentDownloadButtonProps) => (
  <AttachmentButtonBase
    startIcon={<Icon icon={faMemo} color="inherit" />}
    component={ExternalLink}
    url={attachment.file.url}
    maxWidth={maxWidth}
    onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // Prevent the accordion from expanding when clicking on the attachment
      e.stopPropagation();
      onClick?.(e);
    }}
    {...buttonProps}
  >
    {attachment.file.filename}
  </AttachmentButtonBase>
);

interface AttachmentViewerButtonProps extends ButtonProps {
  attachment: AttachmentFragmentFragment;
  maxWidth?: number | null;
}
const AttachmentViewerButton = ({
  attachment,
  onClick,
  maxWidth,
  ...buttonProps
}: AttachmentViewerButtonProps) => {
  const { t } = useTranslation("ui-components-shared\\InlineAttachments");
  const [modalOpen, setModalOpen] = React.useState(false);
  const VideoPlayer = useVideoPlayerComponent();

  const isImage = isImageAttachment(attachment);

  return (
    <>
      <BasicModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        hasCloseButton
        closeButtonArialLabel={t("close")}
        // prevent parent components from receiving the click event (e.g. an accordion)
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
          e.stopPropagation()
        }
      >
        {isImage ? (
          <Image
            src={FilestackUrls.imageUrl(attachment.file.url)}
            sx={{ maxWidth: "100%" }}
          />
        ) : (
          <LegacyThemeProvider>
            <VideoPlayer
              src={attachment.file.url}
              poster={attachment.file.thumbUrl ?? undefined}
            />
          </LegacyThemeProvider>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 8,
          }}
        >
          <Typography variant="body1">{t("Download: ")}</Typography>
          <AttachmentDownloadButton
            attachment={attachment}
            variant="link"
            startIcon={null}
            maxWidth={null}
          />
        </Box>
      </BasicModal>
      <AttachmentButtonBase
        startIcon={
          <Icon icon={isImage ? faFileImage : faFileVideo} color="inherit" />
        }
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          setModalOpen(true);
          // Prevent the accordion from expanding when clicking on the attachment
          e.stopPropagation();
          onClick?.(e);
        }}
        maxWidth={maxWidth}
        {...buttonProps}
      >
        {attachment.file.filename}
      </AttachmentButtonBase>
    </>
  );
};

type AttachmentButtonBaseProps<D extends React.ElementType> = ButtonProps<D> & {
  maxWidth?: number | null;
};
const AttachmentButtonBase = <D extends React.ElementType>({
  maxWidth = 100,
  children,
  ...props
}: AttachmentButtonBaseProps<D>) => (
  <Button variant="outlined" size="small" {...props}>
    <Typography
      variant="inherit"
      sx={{
        ...(maxWidth
          ? {
              maxWidth,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }
          : {}),
      }}
    >
      {children}
    </Typography>
  </Button>
);
