import { Box, Image, Theme, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createPathWithActivity } from "@outschool/routes";
import { ErrorMessage } from "@outschool/ui-components-shared";
import {
  VideoPlayerFactory,
  useComponentTrackingContext,
  useImpressionTracking,
} from "@outschool/ui-legacy-component-library";
import { Screen, useNodeRef } from "@outschool/ui-utils";
import fill from "lodash/fill";
import React, { useMemo, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

import swimmingImg from "../../../images/Illustration_Swimming_200x200-Color.png";
import {
  learnerProfileQuery,
  useCurrentLearnerPastActivitiesQuery,
  useSaveFavoriteClassesMutation,
} from "../../hooks/LearnerProfileQuery";
import { Activity } from "../classCard/types";
import Card from "../learnerSpace/Card";
import ClassCard from "./ClassCard";
import FavoriteClassEmpty from "./FavoriteClassEmpty";
import FavoriteClassSelectionModal from "./FavoriteClassSelectionModal";

interface TopClassesProps {
  topClasses: Activity[];
  learnerUid: string;
  activityUrl?: typeof createPathWithActivity;
  isEditable?: boolean;
  VideoPlayer: ReturnType<typeof VideoPlayerFactory>;
}

const ACTIVITY_QUERY_PAGE_SIZE = 6;

export default function TopClasses({
  topClasses,
  learnerUid,
  activityUrl,
  isEditable,
  VideoPlayer,
}: TopClassesProps) {
  const [offset, setOffset] = useState(0);
  const { loading, activities, totalResults } =
    useCurrentLearnerPastActivitiesQuery({
      offset,
      limit: ACTIVITY_QUERY_PAGE_SIZE,
    });

  const [saveClasses] = useSaveFavoriteClassesMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [classesNode, setClassesNode] = useNodeRef();
  const trackingPrefix = "learner_profile.top_classes";
  const { trackTouch } = useImpressionTracking({
    node: classesNode,
    uniqueId: trackingPrefix,
    trackingLabel: trackingPrefix,
    impressionEventName: trackingPrefix + ".view",
    trackingEventName: trackingPrefix + ".edit",
  });
  const track = useComponentTrackingContext();

  useDeepCompareEffect(() => {
    topClasses.forEach(topClass =>
      track(
        `${trackingPrefix}.activity_view`,
        { activityUid: topClass.uid },
        { integrations: { All: false } }
      )
    );
  }, [track, topClasses]);

  const maxSlots = 3;
  const emptySlots = isEditable
    ? maxSlots - Math.min(maxSlots, topClasses.length)
    : 0;
  const isZeroState = !activities.length && isEditable;

  function handleOpenModal() {
    setIsOpen(true);
    trackTouch();
  }

  function handlePageChange(newOffset: number) {
    setOffset(newOffset * ACTIVITY_QUERY_PAGE_SIZE);
  }

  function handleSave(uids: string[]) {
    if (isEditable) {
      saveClasses({
        variables: {
          activityUids: uids,
        },
        refetchQueries: [
          { query: learnerProfileQuery, variables: { learnerUid } },
        ],
      }).then(
        data => {},
        error => {
          setError(error);
        }
      );
      setIsOpen(false);
    }
  }

  function handleRemove(uidToRemove: string) {
    if (isEditable) {
      const selectedActivities = topClasses
        .filter(({ uid }) => uid !== uidToRemove)
        .map(({ uid }) => uid);
      saveClasses({
        variables: {
          activityUids: selectedActivities,
        },
        refetchQueries: [
          { query: learnerProfileQuery, variables: { learnerUid } },
        ],
      }).then(
        data => {},
        error => {
          setError(error);
        }
      );
      track(
        trackingPrefix + ".remove",
        { activityUid: uidToRemove },
        { integrations: { All: false } }
      );
    }
  }

  return (
    <Card sx={{ marginBottom: "40px" }} title="Top Classes">
      {!loading &&
        (isZeroState ? (
          <Box
            flex
            sx={{
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Image
              src={swimmingImg}
              sx={{
                width: "auto",
                height: 200,
                marginTop: "40px",
              }}
            />
            <Typography
              variant="inherit"
              sx={{
                color: "grey.500",
                fontWeight: "fontWeightMedium",
                margin: "20px",
                textAlign: "center",
              }}
            >
              You haven&apos;t taken any classes with Outschool
            </Typography>
          </Box>
        ) : (
          <>
            <Typography
              sx={{
                marginBottom: "1em",
                color: "#828282",
              }}
              variant="h5"
            >
              Tell your teachers and fellow learners what classes you like best.
            </Typography>
            {error && <ErrorMessage value={error} />}
            <Box
              flex
              sx={{
                flexWrap: "wrap",
              }}
              ref={setClassesNode}
            >
              {topClasses.map(activity => (
                <ClassCard
                  key={activity.uid}
                  activity={activity}
                  trackingPrefix={trackingPrefix}
                  activityUrl={activityUrl}
                  onRemove={
                    isEditable ? () => handleRemove(activity.uid) : undefined
                  }
                  sx={(theme: Theme) => ({
                    width: "100%",
                    [theme.breakpoints.up("md")]: {
                      width: "240px",
                    },
                    marginRight: 0,
                    [theme.breakpoints.up("sm")]: {
                      marginRight: "1em",
                    },
                    marginBottom: "1em",
                  })}
                  VideoPlayer={VideoPlayer}
                />
              ))}
              {isEditable && (
                <PickAClass
                  onPickClass={handleOpenModal}
                  emptySlots={emptySlots}
                />
              )}
            </Box>
            {isEditable && (
              <FavoriteClassSelectionModal
                trackingPrefix={trackingPrefix}
                open={isOpen}
                activities={activities}
                totalResults={totalResults}
                selected={topClasses.map(({ uid }) => uid)}
                onPageChange={handlePageChange}
                onCancel={() => setIsOpen(false)}
                onSave={handleSave}
                VideoPlayer={VideoPlayer}
              />
            )}
          </>
        ))}
    </Card>
  );
}

interface PickAClassProps {
  emptySlots: number;
  onPickClass?: () => void;
}

function PickAClass({ emptySlots, onPickClass }: PickAClassProps) {
  const isMobile = Screen.useIsMobile();
  const slotItems = useMemo(() => {
    return fill(Array(emptySlots), 1);
  }, [emptySlots]);

  if (isMobile && emptySlots > 0) {
    return (
      <FavoriteClassEmpty
        onClick={onPickClass}
        sx={(theme: Theme) => ({
          marginRight: 0,
          [theme.breakpoints.up("sm")]: {
            marginRight: "1em",
          },
          marginBottom: "1em",
          height: "unset",
          paddingY: "2em",
        })}
      />
    );
  }

  return (
    <>
      {slotItems.map((_v, i) => (
        <FavoriteClassEmpty
          key={i}
          onClick={i === 0 ? onPickClass : undefined}
          sx={(theme: Theme) => ({
            marginRight: 0,
            [theme.breakpoints.up("sm")]: {
              marginRight: "0.5em",
            },
            marginBottom: "1em",
          })}
        />
      ))}
    </>
  );
}
