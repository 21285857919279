import React, { useState } from "react";

import { HIGHLIGHT_TIMEOUT } from "../lib/constants";

function useHighlight(isNew: boolean) {
  const [highlight, setHighlight] = useState(isNew);
  React.useEffect(() => {
    if (highlight) {
      const timer = setTimeout(() => {
        setHighlight(false);
      }, HIGHLIGHT_TIMEOUT);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [highlight, setHighlight]);

  return [highlight, setHighlight];
}

export default useHighlight;
