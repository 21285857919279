import { Box } from "@outschool/backpack";
import { useOnScreen } from "@outschool/ui-utils";
import React, { useContext } from "react";
import { v4 as uuid } from "uuid";

/**
 * This React Context coordinates which animations are running for any number
 * of "reaction" components. In other words, which "reaction animation" is
 * "active".
 *
 * The provider tracks the existance of all the reaction components
 * in the component tree under it and chooses which one is animating at any
 * given time.
 *
 * The consumer tracks the following and notifies the producer so that it can
 * decide which component to animate:
 * - Whether it is on-screen or not
 * - Whether the user has hovered the mouse over it
 *
 * If the user has not hovered over any reaction, or if the last reaction the
 * user hovered over moves off-screen, it picks another reaction to animate,
 * randomly. It will also pick a new reaction to animate after a period of
 * some seconds.
 */

/**
 * The properties exposed by the provider to the consumer for coordinating
 * which reaction should animate.
 */
interface ActiveReactionAnimationContextProps {
  activeReactionComponentId: string | null;
  onReactionComponentVisible: (id: string) => void;
  onReactionComponentNotVisible: (id: string) => void;
  onReactionComponentHover: (id: string) => void;
}

/**
 * The flag exposed to the children of the consumer through a render prop,
 * to control animation.
 */
interface ReactionRenderFunctionParams {
  shouldAnimate: boolean;
}

/**
 * The input to the consumer, consisting of a render prop that should render
 * the reaction component.
 */
interface ActiveReactionAnimationConsumerProps {
  children(params: ReactionRenderFunctionParams): JSX.Element;
}

/*
 * When there is no provider in the component tree above the consumer, this
 * value will be used by default. It signals to animate the reaction.
 */
const ALWAYS_ANIMATE = "always-animate";

const FIVE_SECONDS_IN_MILLIS = 5000;
const TEN_SECONDS_IN_MILLIS = 10000;

const ActiveReactionAnimationContext =
  React.createContext<ActiveReactionAnimationContextProps>({
    activeReactionComponentId: ALWAYS_ANIMATE,
    onReactionComponentVisible: () => undefined,
    onReactionComponentNotVisible: () => undefined,
    onReactionComponentHover: () => undefined,
  });
ActiveReactionAnimationContext.displayName = "ActiveReactionAnimationContext";

/**
 * This provider enforces that only one reaction in the component tree below it
 * is animating at a time.
 */
export function ActiveReactionAnimationProvider({
  children,
}: React.PropsWithChildren<{}>) {
  // Store which reaction is animating.
  const [activeReactionComponentId, setActiveReactionComponentId] =
    React.useState<string | null>(null);
  // Store all reaction components in the component tree by id.
  const [allReactionComponentIds, setAllReactionComponentIds] = React.useState<
    Set<string>
  >(new Set<string>());

  // Should be called by the consumer when it becomes visible on-screen.
  const onReactionComponentVisible = React.useCallback((id: string) => {
    setAllReactionComponentIds(prevIds => {
      if (prevIds.has(id)) {
        return prevIds;
      }

      const newIds = new Set(prevIds);
      newIds.add(id);
      return newIds;
    });
  }, []);

  // Should be called by the consumer when it is no longer visible on-screen.
  const onReactionComponentNotVisible = React.useCallback((id: string) => {
    setAllReactionComponentIds(prevIds => {
      if (!prevIds.has(id)) {
        return prevIds;
      }

      const newIds = new Set(prevIds);
      newIds.delete(id);
      return newIds;
    });

    // If this reaction was animating, stop it so that another can.
    setActiveReactionComponentId(prevId => {
      if (prevId === id) {
        return null;
      }

      return prevId;
    });
  }, []);

  // Choose a reaction to animate if none are currently running.
  React.useEffect(() => {
    if (
      activeReactionComponentId === null &&
      allReactionComponentIds.size > 0
    ) {
      const randomIndex = Math.floor(
        Math.random() * allReactionComponentIds.size
      );
      setActiveReactionComponentId([...allReactionComponentIds][randomIndex]);
    }
  }, [allReactionComponentIds, activeReactionComponentId]);

  // Move to a different animation after some seconds.
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();
  React.useEffect(() => {
    timeoutRef.current = setTimeout(
      () => setActiveReactionComponentId(null),
      getRandomNumberInRange(FIVE_SECONDS_IN_MILLIS, TEN_SECONDS_IN_MILLIS)
    );

    // Clear (and reset) the timeout when the active Reaction changes.
    return () => clearTimeout(timeoutRef.current);
  }, [activeReactionComponentId]);

  const activeReactionAnimationProps =
    React.useMemo<ActiveReactionAnimationContextProps>(
      () => ({
        activeReactionComponentId,
        onReactionComponentVisible,
        onReactionComponentNotVisible,
        onReactionComponentHover: setActiveReactionComponentId,
      }),
      [
        activeReactionComponentId,
        onReactionComponentVisible,
        onReactionComponentNotVisible,
      ]
    );

  return (
    <ActiveReactionAnimationContext.Provider
      value={activeReactionAnimationProps}
    >
      {children}
    </ActiveReactionAnimationContext.Provider>
  );
}

/**
 * This consumer signals to the provider when it is on-screen and ready to
 * animate. It renders its children through a render prop, which allows it to
 * pass in a flag indicating whether the animation should run.
 */
export function ActiveReactionAnimationConsumer({
  children: renderChildren,
}: ActiveReactionAnimationConsumerProps) {
  const {
    activeReactionComponentId,
    onReactionComponentVisible,
    onReactionComponentNotVisible,
    onReactionComponentHover,
  } = useContext(ActiveReactionAnimationContext);

  // Generate a id for this reaction component.
  const reactionComponentId = React.useMemo(() => uuid(), []);

  const wrapperRef = React.useRef();
  const isComponentOnScreen = useOnScreen(wrapperRef.current);

  // Signal when this component is on-screen and ready to animate.
  React.useEffect(() => {
    if (isComponentOnScreen) {
      onReactionComponentVisible(reactionComponentId);
    }
    return () => onReactionComponentNotVisible(reactionComponentId);
  }, [
    isComponentOnScreen,
    onReactionComponentVisible,
    onReactionComponentNotVisible,
    reactionComponentId,
  ]);

  const shouldAnimate = React.useMemo(
    () =>
      activeReactionComponentId === ALWAYS_ANIMATE ||
      activeReactionComponentId === reactionComponentId,
    [activeReactionComponentId, reactionComponentId]
  );

  return (
    <Box
      ref={wrapperRef}
      onMouseEnter={() => onReactionComponentHover(reactionComponentId)}
    >
      {renderChildren({ shouldAnimate })}
    </Box>
  );
}

const getRandomNumberInRange = (low: number, high: number) =>
  Math.floor(low + Math.random() * (high - low));
