import type { LearnerProfileQuery } from "../..";

import { Box, Theme } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { createPathWithActivity } from "@outschool/routes";
import {
  Space,
  VideoPlayerFactory,
} from "@outschool/ui-legacy-component-library";
import compact from "lodash/compact";
import React from "react";

import BackButton from "../learnerSpace/BackButton";
import HeroLayout from "../learnerSpace/HeroLayout";
import AboutMe from "./AboutMe";
import Avatar from "./Avatar";
import TopClasses from "./TopClasses";

interface ProfilePageProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  learner: NonNullable<LearnerProfileQuery["learnerProfile"]>;
  isEditable?: boolean;
  activityUrl?: typeof createPathWithActivity;
  VideoPlayer: ReturnType<typeof VideoPlayerFactory>;
}

export default function ProfilePage({
  header,
  footer,
  learner,
  activityUrl,
  isEditable,
  VideoPlayer,
}: ProfilePageProps) {
  const topClasses = compact(
    learner.favoritedClassList?.listings?.map(l => l?.activity) ?? []
  );

  const showTopClasses = isEditable || topClasses.length > 0;
  const showAboutMe = learner.aboutMe || isEditable;
  return (
    <HeroLayout header={header} footer={footer} includeBackgroundGradient>
      <BackButton />
      <Box
        flex
        sx={{
          alignItems: "flex-start",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            flex
            sx={(theme: Theme) => ({
              flexDirection: "column",

              [theme.breakpoints.up("xl")]: {
                flexDirection: "row",
                alignItems: "flex-start",
              },

              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <Avatar learner={learner} isEditable={isEditable} />
            {(showAboutMe || showTopClasses) && (
              <Box
                flex
                sx={(theme: Theme) => ({
                  flexDirection: "column",
                  marginTop: "1em",

                  [theme.breakpoints.up("xl")]: {
                    marginTop: "75px",
                  },

                  marginX: "0",

                  [theme.breakpoints.up("md")]: {
                    marginX: "1em",
                  },

                  width: "100%",
                  maxWidth: "800px",
                })}
              >
                {showAboutMe && (
                  <AboutMe text={learner.aboutMe} isEditable={isEditable} />
                )}
                {showTopClasses && (
                  <>
                    <Space y={["small", "medium"]} />
                    <TopClasses
                      topClasses={topClasses}
                      learnerUid={learner.uid}
                      activityUrl={activityUrl}
                      isEditable={isEditable}
                      VideoPlayer={VideoPlayer}
                    />
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </HeroLayout>
  );
}
