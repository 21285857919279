import React from "react";

/**
 * From https://developers.google.com/speed/webp/faq#in_your_own_javascript
 */
const TestImages = {
  lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA" as const,
  lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==" as const,
  alpha:
    "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==" as const,
  animation:
    "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA" as const,
} as const;

/**
 * The global value cache of if we support certain features of WebP or not
 */
export const SupportsWebpFeature: { animation: boolean | undefined } = {
  animation: undefined,
};

/**
 * We trigger these on module load to reduce time to get an answer back to the hook
 * Add more promises to this array to check other features of WebP
 */
export const SupportsWebpFeaturePromises = {
  animation: checkWebpImage(TestImages.animation).then(
    () => (SupportsWebpFeature.animation = true)
  ),
};

/**
 * Based off of https://developers.google.com/speed/webp/faq#in_your_own_javascript
 * @param imageUrl the image URL to check
 */
async function checkWebpImage(image: string) {
  return new Promise<boolean>(resolve => {
    // TODO: In SSR app, `new Image()` throws a Reference error. We need a different way to check webp support.
    if (typeof Image === "undefined") {
      resolve(false);
    }
    const img = new Image();
    img.onload = function () {
      const result = img.width > 0 && img.height > 0;
      resolve(result);
    };
    img.onerror = function () {
      resolve(false);
    };
    img.src = "data:image/webp;base64," + image;
  });
}

function useBrowserSupportsWebpFeature(
  feature: keyof typeof SupportsWebpFeaturePromises
) {
  const [supportsFeature, setSupportsFeature] = React.useState(
    SupportsWebpFeature[feature]
  );
  React.useEffect(() => {
    if (SupportsWebpFeature[feature] === undefined) {
      let aborted = false;
      SupportsWebpFeaturePromises[feature].then(() => {
        if (!aborted) {
          setSupportsFeature(SupportsWebpFeature[feature]);
        }
      });
      return () => {
        aborted = true;
      };
    } else {
      return () => {};
    }
  }, [feature]);
  return supportsFeature ?? false;
}

export function useBrowserSupportsWebpAnimation() {
  return useBrowserSupportsWebpFeature("animation");
}
