import { Box } from "@outschool/backpack";
import React from "react";

type BoxProps = Parameters<typeof Box>[0];

interface BoxWithHeightRatioProps extends BoxProps {
  // The height will be set to this ratio * the width of the box
  heightRatio: number;
}

const BoxWithHeightRatio: React.FC<BoxWithHeightRatioProps> = ({
  heightRatio,
  sx,
  ...props
}) => (
  <Box
    sx={[
      {
        ":after": {
          content: '""',
          display: "block",
          height: "0px",
          width: "0px",
          paddingBottom: `calc(${heightRatio} * 100%)`,
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  />
);

export default BoxWithHeightRatio;
