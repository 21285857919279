import type { DocumentNode, OperationDefinitionNode } from "graphql";

/**
 * Given a list of `DocumentNode`s (the object returned from the `gql` tag),
 * returns a list of the names of those queries as strings.
 * Useful for e.g. constructing a list to pass to `refetchQueries`.
 */
export function getQueryNames(queries: DocumentNode[]) {
  return queries
    .map(query => {
      const operation = query.definitions.find(
        def => def.kind === "OperationDefinition"
      ) as OperationDefinitionNode;
      return operation?.name?.value!;
    })
    .filter(Boolean);
}
