import { gql } from "@outschool/ui-apollo";

export const AttachmentFragment = gql`
  fragment AttachmentFragment on Attachment {
    uid
    file {
      filename
      mimetype
      size
      url
      thumbUrl
    }
    conversionStatus
    created_at
  }
`;
