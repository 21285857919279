import { useCallback, useState } from "react";

// Use this for getting a dom node for useEffect instead of useRef.
// See https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
export default function useNodeRef<T = any>(): [T, (node: T) => void] {
  const [node, setNode] = useState<any>();
  const ref = useCallback((node: T) => {
    setNode(node);
  }, []);
  return [node, ref];
}
