import { TEACHER_MENTION_ID } from "@outschool/text";

export default function useValidMentions<
  T extends { id: string; startTime: any | null }
>(
  post: { isWelcomePost: boolean; publishAt: Date },
  mentions: T[] | undefined
) {
  if (!mentions) {
    return [];
  } else if (post.isWelcomePost) {
    return mentions;
  }
  return mentions.filter(mention => {
    const isTeacher = mention.id === TEACHER_MENTION_ID;
    const canAccessAllPosts = mention.startTime === null;
    const mentionCanParticipate =
      canAccessAllPosts || mention.startTime >= post.publishAt;

    return mentionCanParticipate || isTeacher;
  });
}
