export enum ReactionType {
  SUNGLASSES_CLUB_REACTION = "SUNGLASSES_CLUB_REACTION",
  CARE_CLUB_REACTION = "CARE_CLUB_REACTION",
  CELEBRATION_CLUB_REACTION = "CELEBRATION_CLUB_REACTION",
  CREATIVITY_CLUB_REACTION = "CREATIVITY_CLUB_REACTION",
  FIRE_CLUB_REACTION = "FIRE_CLUB_REACTION",
  LAUGH_CLUB_REACTION = "LAUGH_CLUB_REACTION",
  LETS_GO_CLUB_REACTION = "LETS_GO_CLUB_REACTION",
  THUMBS_UP_CLUB_REACTION = "THUMBS_UP_CLUB_REACTION",
  HEARTS_CLUB_REACTION = "HEARTS_CLUB_REACTION",
  METER_CLUB_REACTION = "METER_CLUB_REACTION",
  NO_CAP_CLUB_REACTION = "NO_CAP_CLUB_REACTION",
  SHOCKED_CLUB_REACTION = "SHOCKED_CLUB_REACTION",
  SAD_CLUB_REACTION = "SAD_CLUB_REACTION",
  SHEESH_CLUB_REACTION = "SHEESH_CLUB_REACTION",
  SIMPLE_SMILE_CLUB_REACTION = "SIMPLE_SMILE_CLUB_REACTION",
  BUSSIN_CLUB_REACTION = "BUSSIN_CLUB_REACTION",
  VIBIN_CLUB_REACTION = "VIBIN_CLUB_REACTION",
}

export interface ReactionSpec {
  type: ReactionType;
  gifUrl: string;
  altText: string;
}
