export const commonStyles = {
  "& p": {
    margin: 0,
  },
  "& ol, & ul": {
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
  },
  "& li": {
    marginLeft: "2em",
    paddingLeft: "0.5em",
  },
  "& li > p": {
    display: "inline",
  },
  "& .code-block": {
    backgroundColor: "grey.100",
    padding: "0.5em",
  },
};
