import {
  ActiveContentQuery,
  ActiveContentQueryVariables,
} from "@outschool/gql-frontend-generated";
import { gql, useQuery } from "@outschool/ui-apollo";
import { defaultTo, filter } from "lodash";

const fragments = {
  activity: gql`
    fragment ContinueLearning_activity on Activity {
      uid
      titleTranslated
      details {
        photo
      }
      leader {
        name
        photo
      }
      unlimitedSection {
        uid
      }
    }
  `,

  syllabus: gql`
    fragment ContinueLearning_syllabus on Syllabus {
      uid
      lessons {
        uid
        isCompleted(learnerUid: $learnerUid)
      }
    }
  `,
};

const subscriptionActiveContent = gql`
  query ActiveContent($learnerUid: ID!) {
    currentSubscription {
      activeContentForLearner(learnerUid: $learnerUid) {
        activities {
          ...ContinueLearning_activity
          syllabus {
            ...ContinueLearning_syllabus
          }
        }
      }
    }
  }
  ${fragments.activity}
  ${fragments.syllabus}
`;

export function useSubscriptionActiveContentQuery({
  isLoadingUserInfo,
  userHasActiveSubscription,
  learnerUid,
}: {
  isLoadingUserInfo: boolean;
  userHasActiveSubscription: boolean;
  learnerUid?: string;
}) {
  const { loading, data, error } = useQuery<
    ActiveContentQuery,
    ActiveContentQueryVariables
  >(subscriptionActiveContent, {
    variables: {
      learnerUid: defaultTo(learnerUid, ""),
    },
    skip: isLoadingUserInfo || !userHasActiveSubscription || !learnerUid,
  });

  return {
    loading,
    activities: defaultTo(
      data?.currentSubscription?.activeContentForLearner?.activities,
      []
    ),
    error,
  };
}

export type ActivityType = ReturnType<
  typeof useSubscriptionActiveContentQuery
>["activities"][number];

export function getCompletedLessonCount(activity: ActivityType) {
  return defaultTo(
    filter(activity.syllabus?.lessons, lesson => lesson.isCompleted).length,
    0
  );
}

export function getTotalLessonCount(activity: ActivityType) {
  return defaultTo(activity.syllabus?.lessons.length, 0);
}
