import { Chip, ChipColor } from "@outschool/backpack";
import { SubmissionRequirementType } from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import React from "react";

interface AssignmentRequirementChipProps {
  submissionRequirement: SubmissionRequirementType;
}
export const AssignmentRequirementChip = ({
  submissionRequirement,
}: AssignmentRequirementChipProps) => {
  const { t } = useTranslation(
    "ui-components-classroom\\AssignmentRequirementChip"
  );
  const chipState: {
    label: string;
    color: ChipColor;
  } = {
    [SubmissionRequirementType.Required]: {
      label: t("Required"),
      color: "secondary" as ChipColor,
    },
    [SubmissionRequirementType.Optional]: {
      label: t("Optional"),
      color: "neutral" as ChipColor,
    },
    [SubmissionRequirementType.Recommended]: {
      label: t("Recommended"),
      color: "primary" as ChipColor,
    },
  }[submissionRequirement];

  return <Chip label={chipState.label} color={chipState.color} size="small" />;
};
