import { Box, SxProps } from "@outschool/backpack";
import { YoutubeSupportedRegexs } from "@outschool/ui-youtube";
import React from "react";

import EditorContent from "../RichTextEditor/EditorContent";
import { useTiptapEditor } from "../RichTextEditor/TiptapEditor";

export const RichTextView = React.forwardRef(
  (
    {
      content,
      allowLinks = false,
      allowEmails = false,
      maxLines = Infinity,
      sx,
    }: {
      content: string | undefined | null;
      allowLinks?: boolean;
      allowEmails?: boolean;
      maxLines?: number | undefined;
      sx?: SxProps;
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const html = React.useMemo(
      () => addLinksAndEmails(content || "", allowLinks, allowEmails),
      [content, allowLinks, allowEmails]
    );

    const { setHtml, tiptapEditor } = useTiptapEditor({
      content: html,
      editable: false,
      allowLinks: allowLinks || allowEmails,
    });

    React.useEffect(() => {
      setHtml(html);
    }, [html, setHtml]);

    return (
      <Box
        ref={ref}
        sx={{
          "& .tiptap": {
            WebkitLineClamp: maxLines === Infinity ? undefined : maxLines,
            WebkitBoxOrient: "vertical",
            display: "-webkit-box",
            textOverflow: "ellipsis",
            overflow: "hidden",
            ...sx,
          },
        }}
      >
        <EditorContent tiptapEditor={tiptapEditor} />
      </Box>
    );
  }
);

const emailRegex =
  /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gim;

const urlRegex = /(https?):\/\/[^\s"<]+/gim;

function addLinksAndEmails(
  content: string,
  allowLinks: boolean,
  allowEmails: boolean
): string {
  const withLinks = allowLinks ? addLinks(content) : addYoutubeLinks(content);
  const withEmails = addEmails(withLinks, allowEmails);
  return withEmails;
}

function addEmails(content: string, allowEmails: boolean): string {
  return content.replaceAll(emailRegex, email => {
    if (allowEmails) {
      return `<a href="mailto:${email}">${email}</a>`;
    } else {
      return "[email address not shown]";
    }
  });
}

function addYoutubeLinks(content: string): string {
  return YoutubeSupportedRegexs.reduce(
    (s, regex) =>
      s.replaceAll(
        new RegExp(regex, "g"),
        url => `<a href="${url}">${url}</a>`
      ),
    content
  );
}

function addLinks(content: string): string {
  return content.replaceAll(urlRegex, url => `<a href="${url}">${url}</a>`);
}
