import {
  AddAttachmentMutation as AddAttachmentMutationType,
  AddAttachmentMutationVariables,
} from "@outschool/gql-frontend-generated";
import { useMutation } from "@outschool/ui-apollo";
import pick from "lodash/pick";
import { useMemo } from "react";

import { AddAttachmentMutation } from "../graphql/mutations/Attachment";

export const useAddAttachmentMutation = () => {
  const [mutate] = useMutation<
    AddAttachmentMutationType,
    AddAttachmentMutationVariables
  >(AddAttachmentMutation);
  return useMemo(() => {
    return {
      async addAttachment(file: any, convert?: boolean) {
        const resp = await mutate({
          variables: {
            file: pick(file, [
              "handle",
              "filename",
              "mimetype",
              "originalPath",
              "size",
              "source",
              "status",
              "url",
            ]),
            convert,
          },
        });
        return resp.data?.addAttachment;
      },
    };
  }, [mutate]);
};
