/** @jsx jsxPragma */

import { Box, SxProps } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import {
  AvatarImage,
  DEFAULT_AVATAR,
  UserHeadshotImage,
} from "@outschool/ui-components-shared";
import {
  jsxPragma,
  useImpressionTracking,
} from "@outschool/ui-legacy-component-library";
import { SystemStyleObject } from "@styled-system/css";
import { useEffect, useRef } from "react";
import {
  DisplayTransformFunc,
  Mention,
  MentionsInput,
  MentionsInputProps,
  OnChangeHandlerFunc,
} from "react-mentions";

import { useReplyToContext } from "../providers/ReplyToProvider";

type MentionsDataItem = {
  id: string;
  display: string;
  avatar: string | null;
  startTime: any | null;
};

type MentionsTextAreaProps = {
  value: string;
  mentionsData: MentionsDataItem[];
  placeholder?: string;
  onKeyDown: (e: React.KeyboardEvent) => void;
  onChange: OnChangeHandlerFunc;
  onFocus?: () => void;
  sx?: SxProps;
  autofocus?: boolean;
  textBoxStyle?: SystemStyleObject;
  maxLength?: number;
  id?: string;
} & Omit<MentionsInputProps, "children">;

const defaultTextBoxStyle = {
  input: {
    border: "none",
    padding: "0px 8px",
    overflow: "auto",
    lineHeight: "inherit",
  },
};

export default function MentionsTextArea({
  value,
  placeholder,
  mentionsData,
  onKeyDown,
  onChange,
  sx,
  maxLength,
  textBoxStyle = {},
  id,
  ...otherProps
}: MentionsTextAreaProps) {
  const { addCommentRef } = useReplyToContext();
  const mentionsDataLastIndex = mentionsData.length - 1;
  const attemptedMentionRef = useRef<any[]>([]);
  const { trackTouch: track } = useImpressionTracking({
    trackingLabel: "attempted-at-mention",
    uniqueId: "",
  });
  const { t } = useTranslation("ui-components-classroom\\MentionsTextArea");
  const displayTransformFunction: DisplayTransformFunc = (id, display) =>
    `@${display}`;

  useEffect(() => {
    if (value === "") {
      attemptedMentionRef.current = [];
    }
  }, [value]);

  const handleChange: OnChangeHandlerFunc = (
    e,
    newValue,
    newPlainTextValue,
    mentions
  ) => {
    if (mentions.length > 0) {
      const notTracked = mentions.filter(
        mention =>
          !attemptedMentionRef.current.some(
            attempted => attempted.id === mention.id
          )
      );
      notTracked.forEach(mention => {
        track({
          id: mention.id,
          display: mention.display,
        });
      });

      attemptedMentionRef.current = [
        ...attemptedMentionRef.current,
        ...notTracked,
      ];
    }
    onChange(e, newValue, newPlainTextValue, mentions);
  };

  return (
    <Box sx={sx}>
      <MentionsInput
        id={id}
        inputRef={
          addCommentRef as React.Ref<HTMLTextAreaElement> /* Dunno how to actually fix this */
        }
        style={{ ...defaultTextBoxStyle, ...textBoxStyle }}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        data-test-id="mentions-text-area"
        maxLength={maxLength}
        a11ySuggestionsListLabel={t("Suggested mentions")}
        {...otherProps}
      >
        <Mention
          markup="@[__display__](__id__)"
          trigger="@"
          displayTransform={displayTransformFunction}
          data={mentionsData.filter(item => !!item)}
          renderSuggestion={(
            suggestion,
            _search,
            highlightedDisplay,
            index,
            focused
          ) => {
            const notLastItem = index < mentionsDataLastIndex;
            const mentionData = suggestion as MentionsDataItem;
            return (
              <Box
                sx={{
                  fontWeight: focused ? "fontWeightBold" : undefined,
                  padding: ".4em",
                  backgroundColor: focused ? "#F8F8F9" : undefined,
                  borderBottom: notLastItem ? "1px solid #e5e5e5" : undefined,
                  display: "flex",
                }}
              >
                {suggestion.id === "teacher" ? (
                  <UserHeadshotImage
                    user={{ photo: mentionData.avatar }}
                    size={30}
                  />
                ) : (
                  <AvatarImage
                    avatar={mentionData.avatar || DEFAULT_AVATAR}
                    width={30}
                    height={30}
                    sx={{ borderRadius: "100%" }}
                  />
                )}
                <Box
                  sx={{
                    marginLeft: "0.5em",
                    paddingRight: "0.5em",
                  }}
                >
                  {highlightedDisplay}
                </Box>
              </Box>
            );
          }}
        />
      </MentionsInput>
    </Box>
  );
}
