import { COUNTRIES_OF_RESIDENCE } from "@outschool/localization";
import keyBy from "lodash/keyBy";

interface Location {
  country?: string | null | undefined;
  state?: string | null | undefined;
}

export function locationString({ state, country }: Location) {
  const residencesByKey = keyBy(COUNTRIES_OF_RESIDENCE, "name");
  const residence = residencesByKey[country ?? ""];
  let locationString: string = "";
  if (state) {
    locationString = `${state}, `;
  }
  if (residence) {
    locationString += `${residence.code.toUpperCase()} ${residence.icon}`;
  } else if (country) {
    locationString += `${country} `;
  }

  return locationString;
}
