import { Box, useTheme } from "@outschool/backpack";
import { Editor as TiptapEditor } from "@tiptap/react";
import React from "react";

import EditorContent from "./EditorContent";
import MenuBar from "./MenuBar";
import { useTiptapEditor } from "./TiptapEditor";

export type RichTextEditorHandle = {
  setHtml: (html: string) => void;
  getHtml: () => string | undefined;
};

export type RichTextEditorProps = {
  content?: string;
  placeholder?: string;
  onChange?: (html: string) => void;
  sx?: any;
};

export const RichTextEditor = React.forwardRef<
  RichTextEditorHandle,
  RichTextEditorProps
>(function RichTextEditor(
  {
    content = "",
    placeholder = "",
    onChange,
    sx = {},
  }: RichTextEditorProps = {},
  ref
) {
  const { tiptapEditor, setHtml, getHtml } = useTiptapEditor({
    content,
    placeholder,
  });
  React.useImperativeHandle(ref, () => ({ setHtml, getHtml }), [
    setHtml,
    getHtml,
  ]);

  return (
    <Component
      tiptapEditor={tiptapEditor}
      getHtml={getHtml}
      sx={sx}
      onChange={onChange}
    />
  );
});

function Component({
  tiptapEditor,
  getHtml,
  sx,
  onChange,
}: {
  tiptapEditor: TiptapEditor | null;
  getHtml: () => string;
  sx: any;
  onChange?: (html: string) => void;
}) {
  const theme = useTheme();
  React.useEffect(() => {
    if (tiptapEditor && onChange) {
      const onUpdate = () => {
        onChange(getHtml());
      };
      tiptapEditor.on("update", onUpdate);
      return () => {
        tiptapEditor.off("update", onUpdate);
      };
    }
    return undefined;
  }, [tiptapEditor, getHtml, onChange]);

  if (!tiptapEditor) {
    return null;
  }
  return (
    <Box flex sx={{ ...sx, flexDirection: "column" }}>
      <MenuBar editor={tiptapEditor} />
      <EditorContent
        tiptapEditor={tiptapEditor}
        tiptapSx={{
          cursor: "text",
          overflow: "hidden",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.neutral[400],
          backgroundColor: "white",
          "&:focus,&:focus-within,&:focus-visible": {
            outlineColor: theme.palette.primary.main,
            outlineWidth: "2px",
          },
          borderRadius: theme.spacing(8),
          minHeight: theme.spacing(88),
          paddingY: theme.spacing(10),
          paddingX: theme.spacing(14),
        }}
      />
    </Box>
  );
}
