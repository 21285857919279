// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import * as FilestackUrls from "@outschool/filestack-urls";
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import LegacyImage from "./LegacyImage";
import InnerBorder from "./InnerBorder";
import LegacyButton from "./LegacyButton/LegacyButton";
import LegacyFlex from "./LegacyFlex";
import Modal from "./Modal";

type ImageWithModalProps = {
  imageUrl: string;
  imageSx?: SystemStyleObject;
  caption?: JSX.Element | null;
  imageSize?: number | null;
} & React.HTMLAttributes<HTMLDivElement>;
/*
A user-supplied (i.e., has an inner border) Image that opens to a Modal when clicked
*/
export default function ImageWithModal({
  // String! url of image passed to FilestackUrls.imageUrl
  imageUrl,
  // css maxHeight for Image
  imageSx = {},
  onClick = () => {},
  caption = null,
  imageSize = null,
}: ImageWithModalProps) {
  const [isImageModalOpen, setIsImageModalOpen] = React.useState(false);

  const dimensions = imageSize != null ? { w: imageSize, h: imageSize } : {};

  return (
    <InnerBorder sx={{ borderRadius: "16px" }}>
      <Modal open={isImageModalOpen} onClose={() => setIsImageModalOpen(false)}>
        <LegacyFlex sx={{ flexDirection: "column", marginTop: "medium" }}>
          <InnerBorder sx={{ marginTop: "small", borderRadius: "16px" }}>
            <LegacyImage
              sx={{ display: "block", maxWidth: "100%" }}
              src={FilestackUrls.imageUrl(imageUrl)}
            />
          </InnerBorder>
          {caption}
          {!caption && (
            <LegacyFlex
              sx={{ justifyContent: "space-between", marginTop: "medium" }}
            >
              <LegacyButton
                color="secondary"
                onClick={() => setIsImageModalOpen(false)}
              >
                Cancel
              </LegacyButton>
              <LegacyButton
                color="facebook"
                onClick={() => window.open(imageUrl, "_blank")}
              >
                Download this file
              </LegacyButton>
            </LegacyFlex>
          )}
        </LegacyFlex>
      </Modal>
      <LegacyImage
        sx={{
          display: "block",
          ...imageSx,
        }}
        src={FilestackUrls.imageUrl(imageUrl, dimensions)}
        onClick={(e: any) => {
          if (!isImageModalOpen && typeof onClick === "function") {
            onClick(e);
          }
          setIsImageModalOpen(true);
        }}
      />
    </InnerBorder>
  );
}
