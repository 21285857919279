import { Box, SxProps, Theme } from "@outschool/backpack";
import React, { FC, ReactNode } from "react";

import useHighlight from "../../../hooks/useHighlight";
import { ReplyToProvider } from "../../../providers/ReplyToProvider";

export interface ClassPostProps {
  children: ReactNode;
  isNew?: boolean;
  sx?: SxProps;
}

const ClassPost: FC<ClassPostProps> = ({ children, isNew, sx }) => {
  const [highlight] = useHighlight(isNew ?? false);

  return (
    <Box
      sx={[
        (theme: Theme) => ({
          backgroundColor: highlight ? "#fffaea" : "common.white",
          transition: "background-color 2s ease",
          maxWidth: "992px",
          position: "relative",
          width: "100%",
          margin: "0 auto",
          [theme.breakpoints.down("md")]: {
            paddingX: "1em",
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      data-test-id="ClassPost"
    >
      <Box
        flex
        sx={{
          flexDirection: "column",
        }}
      >
        <ReplyToProvider>{children}</ReplyToProvider>
      </Box>
    </Box>
  );
};

export default ClassPost;
