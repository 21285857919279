import { Box, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { UserHeadshotImage } from "@outschool/ui-components-shared";
import React, { PropsWithChildren } from "react";

import { Leader } from "./types";

interface ActivityTeacherProps {
  leader: Leader;
}

const ActivityTeacher: React.FC<PropsWithChildren<ActivityTeacherProps>> = ({
  leader,
  children,
}) => {
  return (
    <Box
      flex
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "auto",
        marginBottom: "1em",
      }}
    >
      <Box
        flex
        sx={{
          alignItems: "center",
          minWidth: "0px",
          paddingX: "1em",
        }}
      >
        <UserHeadshotImage
          user={leader}
          size={48}
          sx={{ marginRight: "small" }}
        />
        <Typography
          variant="inherit"
          sx={{
            fontSize: "0.875em",
            color: "grey.700",
            fontWeight: 500,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {leader.name}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

export default ActivityTeacher;
