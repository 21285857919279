import { gql } from "@outschool/ui-apollo";

import { PrivateClassMessageFragment } from "../fragments/PrivateClassMessage";

export const addPrivateClassMessageMutation = gql`
  mutation addPrivateClassMessage(
    $privateClassMessageInput: PrivateClassMessageInput!
  ) {
    addPrivateClassMessage(
      privateClassMessageInput: $privateClassMessageInput
    ) {
      ...PrivateClassMessageFragment
    }
  }
  ${PrivateClassMessageFragment}
`;

export const markPrivateClassMessageThreadAsReadMutation = gql`
  mutation MarkPrivateClassMessageThreadAsRead($threadUid: ID!) {
    markPrivateClassMessageThreadAsRead(threadUid: $threadUid)
  }
`;
