import { Box, SxProps, Typography } from "@outschool/backpack";
import React, { PropsWithChildren } from "react";

interface CardProps {
  title: string;
  headingSx?: SxProps;
  sx?: SxProps;
}

const Card: React.FC<PropsWithChildren<CardProps>> = ({
  title,
  sx,
  headingSx,
  children,
}) => {
  return (
    <Box
      sx={[
        {
          background: "white",
          borderRadius: "1em",
          padding: "1em",
          filter: "drop-shadow(0px 15px 30px rgba(189, 226, 233, 0.15))",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography variant="h2" sx={headingSx}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default Card;
