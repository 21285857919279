import { Box, Image, SxProps, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { MessageSender } from "@outschool/gql-backend-generated";
import { useIsMobile, useLinkComponent } from "@outschool/ui-utils";
import React from "react";

import replyIcon from "../../../images/club/reply.svg";
import { useClubContext } from "../../providers/ClubProvider";
import AvatarImage from "../AvatarImage";
import UserHeadshotImage from "../UserHeadshotImage";
import { CONTENT_BOX_BORDER_RADIUS } from "./Club";
import ManageContentPopover, {
  ManageContentOptions,
} from "./ManageContentPopover";
import SampleClubPostAvatar, {
  SAMPLE_CLUB_POST_AVATAR_URLS,
} from "./postPreview/SampleClubPostAvatar";

// Only require exactly the fields we need for these components.
// avatar, photo, and isLearner aren't present on all user types, so make them optional.
type OptionalFields = "avatar" | "photo" | "isLearner";
export type Sender = Omit<MessageSender, OptionalFields | "__typename"> &
  Partial<Pick<MessageSender, OptionalFields>> & {
    __typename?: string;
  };

interface ClubContentHeaderProps {
  sender: Sender;
  isHoveringOverPost?: boolean;
  manageContentOptions: Partial<ManageContentOptions>;
  sx?: SxProps;
  nameSx?: SxProps;
  showReplyIcon?: boolean;
  isContentPromoted?: boolean;
  isContentPinned?: boolean;
}

function ClubContentHeader({
  sender,
  isHoveringOverPost = false,
  manageContentOptions = {},
  sx = {},
  nameSx = {},
  showReplyIcon = false,
  isContentPromoted = false,
  isContentPinned = false,
}: ClubContentHeaderProps) {
  const isMobile = useIsMobile();
  const isMobileOrIsHoveringOverPost = isMobile || isHoveringOverPost;

  return (
    <Box
      flex
      sx={[
        {
          zIndex: 1,
          background: isMobileOrIsHoveringOverPost
            ? "rgba(0, 0, 0, 0.2)"
            : "transparent",
          color: "common.white",
          transition: "background 0.2s linear",
          position: "relative",
          height: "60px",
          alignItems: "center",
          fontFamily: "'Ginto Normal',sans-serif",
          fontSize: 16,
          paddingX: "1em",
          borderRadius: `${CONTENT_BOX_BORDER_RADIUS}px ${CONTENT_BOX_BORDER_RADIUS}px 0 0`,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <SenderProfileLink sender={sender}>
        <SenderImage sender={sender} />
      </SenderProfileLink>
      <Typography
        variant="inherit"
        sx={[
          {
            opacity: isMobileOrIsHoveringOverPost ? 1 : 0,
            transition: "opacity 0.6s",
            fontWeight: "fontWeightBold",
            borderRadius: 8,
            padding: "4px 8px",
            background: "rgba(255, 255, 255, 0.8)",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            marginRight: "16px",
            color: "grey.900",
          },
          ...(Array.isArray(nameSx) ? nameSx : [nameSx]),
        ]}
      >
        <SenderProfileLink sender={sender}>{sender.name}</SenderProfileLink>
      </Typography>
      <Box
        flex
        sx={{
          marginLeft: "auto",
        }}
      >
        {isContentPinned ? (
          <PinnedLabel />
        ) : showReplyIcon ? (
          <Image
            src={replyIcon}
            sx={{
              opacity: isMobileOrIsHoveringOverPost ? 1 : 0,
              transition: "opacity 0.6s",
            }}
          />
        ) : null}
        {Object.keys(manageContentOptions).length > 0 && (
          <ManageContentPopover
            manageContentOptions={manageContentOptions}
            isContentPromoted={isContentPromoted}
            buttonSx={{ marginLeft: "16px" }}
          />
        )}
      </Box>
    </Box>
  );
}

function PinnedLabel() {
  return (
    <Box
      sx={{
        fontSize: 14,
        fontWeight: 600,
        color: "#2da571",
        background: "#f5faf8",
        boxShadow: "2px 2px 0px rgba(45, 165, 113, 0.9)",
        borderRadius: "30px",
        padding: "0.5em",
      }}
    >
      Pinned
    </Box>
  );
}

function SenderProfileLink({
  sender,
  children,
}: React.PropsWithChildren<{
  sender: Sender;
}>) {
  const { learnerProfileRoute } = useClubContext();
  const Link = useLinkComponent();
  if (sender.__typename === "MessageSender" && sender.isLearner) {
    return (
      <Link
        to={learnerProfileRoute({ uid: sender.uid })}
        onClick={e => e.stopPropagation()}
        data-test-id="club-post-learner-profile-link"
      >
        {children}
      </Link>
    );
  }
  return <>{children}</>;
}

export function SenderImage({
  sender,
  size = 32,
  sx = {},
}: {
  sender: { uid: string; photo?: string | null; avatar?: string | null };
  size?: number;
  sx?: SxProps;
}) {
  const isPreviewAvatar = (
    avatar: string
  ): avatar is keyof typeof SAMPLE_CLUB_POST_AVATAR_URLS =>
    Object.keys(SAMPLE_CLUB_POST_AVATAR_URLS).includes(avatar);
  return (
    <Box
      sx={[
        {
          width: size * 1.2,
          height: size * 1.2,
          padding: size * 0.1,
          borderRadius: "50%",
          background:
            sender?.avatar && isPreviewAvatar(sender.avatar)
              ? "transparent"
              : "rgba(0, 0, 0, 0.05)",
          marginRight: "0.5em",
          position: "relative",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {sender.avatar ? (
        isPreviewAvatar(sender.avatar) ? (
          <SampleClubPostAvatar
            avatar={sender.avatar || "star-preview-avatar"}
          />
        ) : (
          <AvatarImage avatar={sender.avatar} width={size} height={size} />
        )
      ) : (
        <UserHeadshotImage user={sender} size={size} />
      )}
    </Box>
  );
}
export default ClubContentHeader;
