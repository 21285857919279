import { Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import { LegacyButtonColor } from "./LegacyButton/LegacyButton";
import { Modal, ModalActionButtons } from ".";

// For a simple confirm on button click, see ./ConfirmationButton.tsx
function ConfirmationModal({
  open,
  onConfirm,
  content,
  onClose,
  confirmationLabel,
  cancelLabel,
  disabled,
  headline,
  buttonColor,
  hasCloseButton,
}: {
  open: boolean;
  onConfirm?: () => void;
  content?: any;
  onClose: () => void;
  confirmationLabel?: string;
  cancelLabel?: string;
  disabled?: boolean;
  headline?: string;
  buttonColor?: LegacyButtonColor;
  hasCloseButton?: boolean;
}) {
  return (
    <Modal open={open} onClose={onClose} hasCloseButton={hasCloseButton}>
      {headline && (
        <Typography variant="h2" sx={{ marginBottom: "0.5em" }}>
          {headline}
        </Typography>
      )}
      {!!content && content}
      <ModalActionButtons
        buttonColor={buttonColor}
        cancelLabel={cancelLabel}
        disabled={disabled}
        primaryLabel={confirmationLabel}
        onCancel={onClose}
        onPrimary={() => {
          onConfirm && onConfirm();
        }}
      />
    </Modal>
  );
}
ConfirmationModal.displayName = "ConfirmationModal";

export default ConfirmationModal;
