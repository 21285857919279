import { Box, Button, Chip, Typography } from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import { LearnerRoutes } from "@outschool/routes";
import { dayjs } from "@outschool/time";
import { useLinkComponent } from "@outschool/ui-utils";
import React from "react";

import LearnerProfileIcon from "../learnerProfile/LearnerProfileIcon";

export default function LearnerProfilesBanner({
  learner,
  isPaused = false,
  sectionStartTime,
}: {
  learner: { avatar?: string | null; name?: string | null; uid: string };
  isPaused?: boolean;
  sectionStartTime?: Date;
}) {
  const { t } = useTranslation(
    "ui-components-classroom\\classroom\\LearnerProfilesBanner"
  );
  const Link = useLinkComponent();

  return (
    <LearnerProfileIcon
      learner={learner}
      isEditable={false}
      showEditIcon={false}
      iconSize={97}
      sx={{
        fontSize: "1.5em",
        borderRadius: "large",
        background: "white",
      }}
    >
      <Box
        flex
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          marginTop: 16,
        }}
      >
        {sectionStartTime && <ClassInfo sectionStartTime={sectionStartTime} />}
        {isPaused && <Chip label={t`Paused`} size="medium" color="warning" />}
        <Button component={Link} to={LearnerRoutes.profilePath(learner)}>
          {t`Edit your profile`}
        </Button>
      </Box>
    </LearnerProfileIcon>
  );
}

const ClassInfo = ({ sectionStartTime }: { sectionStartTime: Date }) => {
  const { t } = useTranslation(
    "ui-components-classroom\\classroom\\LearnerProfilesBanner"
  );
  const classStartDateText = dayjs(sectionStartTime).format("MMM D");
  return (
    <Box
      sx={{
        textAlign: "center",
        fontSize: "initial",
        marginBottom: 16,
      }}
    >
      {dayjs(sectionStartTime).isBefore() ? (
        <Trans t={t}>
          Started:{" "}
          <Typography sx={{ fontWeight: "bold" }}>
            {{ classStartDateText }}
          </Typography>
        </Trans>
      ) : (
        <Trans t={t}>
          Starts:{" "}
          <Typography sx={{ fontWeight: "bold" }}>
            {{ classStartDateText }}
          </Typography>
        </Trans>
      )}
    </Box>
  );
};
