import { useEffect } from "react";

import useOnScreen from "./useOnScreen";
import usePreviousValue from "./usePreviousValue";

interface LoadMoreSearchResultsProps {
  endOfScreenRef?: HTMLDivElement;
  fetchMore: () => Promise<any>;
  isInfiniteScrollEnabled?: boolean;
  trackLoadMore?: () => void;
}

function useLoadMoreIfEndOfScreen({
  endOfScreenRef,
  fetchMore,
  isInfiniteScrollEnabled = true,
  trackLoadMore,
}: LoadMoreSearchResultsProps) {
  const isEndOfListOnScreen: boolean = useOnScreen(
    endOfScreenRef,
    false,
    "200px 0px"
  );
  const wasEndOfListOnScreenLastRender = usePreviousValue(isEndOfListOnScreen);

  useEffect(() => {
    if (
      isEndOfListOnScreen &&
      // check if isEndOfListOnScreen changing from false to true. prevents double fetching
      isEndOfListOnScreen !== wasEndOfListOnScreenLastRender &&
      isInfiniteScrollEnabled
    ) {
      fetchMore();
      trackLoadMore && trackLoadMore();
    }
  }, [
    isEndOfListOnScreen,
    fetchMore,
    wasEndOfListOnScreenLastRender,
    isInfiniteScrollEnabled,
    trackLoadMore,
  ]);
}

export default useLoadMoreIfEndOfScreen;
