import { Box, Image, ProgressBar } from "@outschool/backpack";
import * as FilestackUrls from "@outschool/filestack-urls";
import { useTranslation } from "@outschool/localization";

import React from "react";

import {
  ActivityType,
  LeaderDetailsCard,
  MIN_CARD_HEIGHT,
  TileInfoBox,
} from "../..";

import {
  getCompletedLessonCount,
  getTotalLessonCount,
} from "../queries/ContentQueries";
import { CARD_COLUMN_WIDTH } from "../../Listings/ListingCard/constants";
import { useLinkComponent } from "@outschool/ui-utils";

export function ContinueWithActivityContent({
  activity,
  navigateToContent,
}: {
  activity: ActivityType;
  navigateToContent: (activityUid: string, sectionUid?: string) => void;
}) {
  const { t } = useTranslation(
    "ui-components-website\\Subscription\\Components\\ContinueLearning"
  );
  const Link = useLinkComponent();
  const progressCount = getCompletedLessonCount(activity);
  const totalCount = getTotalLessonCount(activity);

  return (
    <Link
      to={""}
      trackingName="continue_learning"
      trackingUniqueId={activity.uid}
      onClick={e => {
        e.preventDefault();
        navigateToContent(activity.uid, activity.unlimitedSection?.uid);
      }}
      style={{
        textDecoration: "none",
        display: "flex",
        position: "relative",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#FFF",
          border: "1px solid #DDDFE3",
          borderRadius: 8,
          color: "#2E3238",
          height: MIN_CARD_HEIGHT,
          width: CARD_COLUMN_WIDTH,
          "&:hover": {
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <Image
          height={140}
          width={CARD_COLUMN_WIDTH - 2}
          sx={{
            borderRadius: "8px 8px 0 0",
          }}
          src={`${FilestackUrls.imageUrl(activity.details.photo, {
            w: CARD_COLUMN_WIDTH - 2,
            h: 140,
          })}`}
        />

        <Box
          sx={{
            padding: "16px",
          }}
        >
          <ProgressBar
            variant="determinate"
            value={(progressCount / totalCount) * 100}
            sx={{ marginBottom: 10 }}
          />
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Box
              sx={{
                fontSize: 16,
                fontWeight: "fontWeightBold",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                maxHeight: "calc(1.5em * 3)",
                marginBottom: "12px",
                marginRight: "4px",
              }}
            >
              {activity.titleTranslated}
            </Box>
          </Box>
          <Box
            sx={{
              width: "calc(100% - 16px)",
              position: "absolute",
              bottom: "12px",
              left: "0px",
              marginX: "8px",
            }}
          >
            <LeaderDetailsCard leader={activity.leader} />
            <Box
              flex
              sx={{
                flexDirection: "row",
                gap: "8px",
                marginTop: "12px",
              }}
            >
              <TileInfoBox
                topText={t("{{ progressCount }} out of {{ totalCount }}", {
                  progressCount,
                  totalCount,
                })}
                bottomText={
                  progressCount === totalCount
                    ? t`Class Complete`
                    : t`Lessons complete`
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Link>
  );
}
