import { LessonLiveMeeting } from "@outschool/gql-backend-generated";
import {
  LearnerLiveMeetingQuery,
  LearnerLiveMeetingQueryVariables,
} from "@outschool/gql-frontend-generated";
import { gql, useQueryWithPreviousData } from "@outschool/ui-apollo";
import type {
  JoinNextMeetingQuery,
  JoinNextMeetingQueryVariables,
  LiveMeetingButtonQuery,
  LiveMeetingButtonQueryVariables,
} from "..";
import type { ApolloError, QueryResult } from "@outschool/ui-apollo";

const liveMeetingButtonQuery = gql`
  query LiveMeetingButton($sectionUid: ID!, $learnerUid: ID, $lessonUid: ID) {
    joinMeeting(
      sectionUid: $sectionUid
      learnerUid: $learnerUid
      lessonUid: $lessonUid
    ) {
      uid
      canManage
      meetingStartTime
      meetingEndTime
      usersCanHaveLiveMeeting
      isFlexSchedule
      meetingsCount
      sectionStartTime
      enrollmentUid
      isSellerOrg
    }
  }
`;

export function useLiveMeeting(
  sectionUid: string,
  learnerUid: string,
  skip = false
): QueryResult<LiveMeetingButtonQuery, LiveMeetingButtonQueryVariables> {
  return useQueryWithPreviousData<
    LiveMeetingButtonQuery,
    LiveMeetingButtonQueryVariables
  >(liveMeetingButtonQuery, {
    variables: { sectionUid, learnerUid },
    skip,
    fetchPolicy: "cache-and-network",
  });
}

const learnerLiveMeetingQuery = gql`
  query LearnerLiveMeeting(
    $sectionUid: ID!
    $learnerUid: ID!
    $lessonUid: ID!
  ) {
    lessonLiveMeeting(
      sectionUid: $sectionUid
      learnerUid: $learnerUid
      lessonUid: $lessonUid
    ) {
      uid
      enrollmentUid
      classRecordingUids
      sectionStartTime
      meetingStartTime
      meetingEndTime
      meetingsCount
      usersCanHaveLiveMeeting
    }
  }
`;

type LearnerLiveMeetingQueryResult = {
  loading: boolean;
  error?: ApolloError;
  liveMeeting?: LessonLiveMeeting;
};

export function useLearnerLiveMeeting(
  variables: LearnerLiveMeetingQueryVariables,
  skip = false
): LearnerLiveMeetingQueryResult {
  const { loading, error, data } = useQueryWithPreviousData<
    LearnerLiveMeetingQuery,
    LearnerLiveMeetingQueryVariables
  >(learnerLiveMeetingQuery, {
    variables,
    skip,
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-only",
  });
  const liveMeeting = data?.lessonLiveMeeting;
  return {
    loading,
    error,
    liveMeeting,
  };
}

const JoinMeetingQuery = gql`
  query JoinNextMeeting($sectionUid: ID!, $enrollmentUid: ID) {
    joinNextMeeting(sectionUid: $sectionUid, enrollmentUid: $enrollmentUid) {
      uid
      currentUserCanManage
      url
      shouldMarkAttendance
    }
  }
`;

type JoinMeetingQueryResult = QueryResult<
  JoinNextMeetingQuery,
  JoinNextMeetingQueryVariables
>;

export function useJoinNextMeeting(
  sectionUid: string,
  enrollmentUid: string
): JoinMeetingQueryResult {
  return useQueryWithPreviousData<
    JoinNextMeetingQuery,
    JoinNextMeetingQueryVariables
  >(JoinMeetingQuery, {
    variables: { sectionUid, enrollmentUid },
    fetchPolicy: "network-only",
  });
}
