import { Box, Icon, visuallyHidden } from "@outschool/backpack";
import { IconDefinition, faFileExclamation, faTimes } from "@outschool/icons";
import { Trans, useTranslation } from "@outschool/localization";
import {
  BoxButton,
  ImageWithModal,
} from "@outschool/ui-legacy-component-library";
import React from "react";

import { useVideoPlayerComponent } from "../../providers";
import ExternalLink from "../ExternalLink";
import { Attachment, OnDeleteHandler } from "./types";
import { isImageAttachment, isVideoAttachment } from "./util";

interface ErrorBoxProps {
  children?: React.ReactNode;
  icon?: IconDefinition | null;
  useWarningColor?: boolean;
  height?: string;
}
const ErrorBox = ({
  children = null,
  icon = null,
  useWarningColor = false,
  height = "none",
}: ErrorBoxProps) => {
  const { t } = useTranslation("ui-components-shared\\AttachmentThumbnail");
  children = children || (
    <Trans t={t}>
      Oops! Something went wrong with the Outschool website. Our engineers have
      been notified. Please reload the page and try again, or contact us at{" "}
      <a href="mailto:support@outschool.com">support@outschool.com</a> if the
      error persists. Thanks for bearing with us!
    </Trans>
  );
  return (
    <Box
      flex
      sx={{
        padding: "0.5em",
        borderRadius: "4px",
        height,
        alignItems: "center",
        justifyContent: "center",
        ...(useWarningColor
          ? { backgroundColor: "warning.500" }
          : { color: "grey.500", backgroundColor: "#f1f2f3" }),
      }}
    >
      <Box
        flex
        sx={{
          justifyContent: "space-between",
        }}
      >
        {icon && (
          <Box
            flex
            sx={{
              alignItems: "center",
              flexBasis: 1,
              margin: "0.5em",
            }}
          >
            <Icon
              sx={{
                fontSize: "1.333em",
              }}
              icon={icon}
            />
          </Box>
        )}
        <Box
          flex
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            margin: "0.5em",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

/*

A business-logic aware component which renders a nice preview of an attachment
with `maxHeight = 'none'` as well as clicks to a detail view of attachment

Default behaviour is for the thumbnail to expand while keeping native content
aspect ratio until maxHeight is reached, then further expansion centers content

 */
export interface AttachmentThumbnailProps {
  attachment: Attachment;
  minWidth?: string;
  maxHeight?: string;
  onClick?: (attachment?: Attachment, evt?: React.SyntheticEvent) => void;
  isPostOwner?: boolean; // truthy iff current user can edit
  senderName?: string | null; // A publicly displayable name for attachment poster
  caption?: JSX.Element | null;
  onDelete?: OnDeleteHandler;
}
export default function AttachmentThumbnail({
  attachment,
  minWidth = "32px",
  maxHeight = "none",
  onClick = (_attachment, _evt) => {},
  isPostOwner = false,
  senderName,
  caption = null,
  onDelete = null,
}: AttachmentThumbnailProps) {
  const { t } = useTranslation("ui-components-shared\\AttachmentThumbnail");
  senderName = senderName ?? t("an Outschool user");
  const VideoPlayer = useVideoPlayerComponent();
  const {
    conversionStatus,
    file: { url, thumbUrl },
  } = attachment;
  const isVideo = isVideoAttachment(attachment);
  const isImage = isImageAttachment(attachment);

  const failedMsg = isPostOwner
    ? t("Please delete the video and try uploading again.")
    : t("Please wait for {{senderName}} to try posting again.", { senderName });

  const innerContent = isVideo ? (
    conversionStatus === "started" ? (
      <ErrorBox useWarningColor>
        {t(
          "Video coming soon. This post has a video attachment, but we're still processing it. You'll see it here when it's ready."
        )}
      </ErrorBox>
    ) : conversionStatus === "failed" ? (
      <ErrorBox icon={faFileExclamation} useWarningColor>
        {t("There was an error processing this upload.")} {failedMsg}
      </ErrorBox>
    ) : (
      <Box flex>
        <VideoPlayer
          sx={{ minWidth, maxHeight }}
          src={url}
          poster={thumbUrl ?? undefined}
          onClick={() => onClick()}
        />
      </Box>
    )
  ) : isImage ? (
    <Box
      flex
      sx={{
        justifyContent: "flex-start",
        minWidth,
      }}
    >
      <Box>
        <ImageWithModal
          imageUrl={url}
          imageSx={{ maxHeight, maxWidth: "100%" }}
          onClick={(evt: React.SyntheticEvent) => onClick(attachment, evt)}
          caption={caption}
        />
      </Box>
    </Box>
  ) : (
    <ExternalLink url={url}>
      <ErrorBox icon={faFileExclamation} height={maxHeight}>
        {t("No preview")}
      </ErrorBox>
    </ExternalLink>
  );

  const onDeleteClick = (evt: React.SyntheticEvent) =>
    onDelete && onDelete(attachment, evt);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {innerContent}
      {onDelete && (
        <BoxButton
          sx={{
            position: "absolute",
            top: "4px",
            right: 4,
            background: "rgba(0, 0, 0, 0.8)",
            border: "1px solid",
            borderColor: "border",
            borderRadius: "50%",
            cursor: "pointer",
          }}
          onClick={onDeleteClick}
        >
          <Box
            flex
            sx={{
              width: "24px",
              height: "24px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon
              sx={{
                fontSize: "11px",
                color: "common.white",
              }}
              icon={faTimes}
            />
            <span style={visuallyHidden}>{t("Remove")}</span>
          </Box>
        </BoxButton>
      )}
    </Box>
  );
}
