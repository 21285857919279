import { Box, Button, Icon } from "@outschool/backpack";
import * as icons from "@outschool/icons";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

export interface PageOption<T> {
  label: string;
  value: T;
}

interface PageSelectProps<T> {
  /** defaultPageIndex: zero based index. (default = 0) */
  defaultPageIndex?: number;
  forcePageIndex?: number;
  pageOptions: PageOption<T>[];
  onChange(value: T): void;
}

function PageSelect<T>({
  defaultPageIndex = 0,
  pageOptions,
  onChange,
  forcePageIndex,
}: PageSelectProps<T>) {
  const [currentPageIndex, setCurrentPageIndex] =
    React.useState(defaultPageIndex);

  React.useEffect(() => {
    if (forcePageIndex !== undefined) {
      setCurrentPageIndex(forcePageIndex);
    }
  }, [setCurrentPageIndex, forcePageIndex]);
  const numberOfPages = pageOptions.length;
  const pageLabels = pageOptions.map(pc => pc.label);

  const disablePreviousArrow = currentPageIndex === 0;
  const disableNextArrow = currentPageIndex === numberOfPages - 1;

  const getHandleIndexCallback = (index: number) => () => {
    setCurrentPageIndex(index);
    onChange(pageOptions[index].value);
  };

  if (numberOfPages <= 1) {
    return null;
  }

  return (
    <Box
      flex
      sx={{
        marginBottom: "2em",
        maxWidth: "100%",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          flex: 1,
        }}
      />
      <Button
        sx={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          padding: "0.8em 1em !important",
        }}
        disabled={disablePreviousArrow}
        onClick={getHandleIndexCallback(0)}
      >
        <Icon icon={icons.faChevronDoubleLeft} />
      </Button>
      <Button
        sx={{
          borderRadius: 0,
          padding: "0.8em 1em !important",
        }}
        disabled={disablePreviousArrow}
        onClick={getHandleIndexCallback(currentPageIndex - 1)}
      >
        <Icon icon={icons.faChevronLeft} />
      </Button>
      {pageLabels.map((pageLabel, i) => (
        <Button
          key={i}
          disabled={currentPageIndex === i}
          sx={{
            borderRadius: 0,
            padding: "0.8em 1em !important",
          }}
          onClick={getHandleIndexCallback(i)}
        >
          {pageLabel}
        </Button>
      ))}
      <Button
        sx={{
          borderRadius: 0,
          padding: "0.8em 1em !important",
        }}
        disabled={disableNextArrow}
        onClick={getHandleIndexCallback(currentPageIndex + 1)}
      >
        <Icon icon={icons.faChevronRight} />
      </Button>
      <Button
        sx={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          padding: "0.8em 1em !important",
        }}
        disabled={disableNextArrow}
        onClick={getHandleIndexCallback(numberOfPages - 1)}
      >
        <Icon icon={icons.faChevronDoubleRight} />
      </Button>
      <Box
        sx={{
          flex: 1,
        }}
      />
    </Box>
  );
}

export default PageSelect;
