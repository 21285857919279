import { YoutubeLinkInModal, YoutubeParsers } from "@outschool/ui-youtube";
import { Node } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import React from "react";

export interface LinkViewOptions {
  embedYoutubeVideos?: boolean;
  allowLinks?: boolean;
}

const LinkView = Node.create<LinkViewOptions>({
  name: "outschoolLink",
  priority: 2000,
  group: "inline",
  inline: true,
  selectable: true,
  atom: true,

  parseHTML() {
    return [
      {
        tag: 'a[href]:not([href *= "javascript:" i])',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { href, text } = HTMLAttributes;
    const supportedParser = YoutubeParsers.find(
      parser => !!parser.matcher(href)
    );
    return [
      "a",
      {
        href: HTMLAttributes.href,
        rel: "noopener noreferrer",
        target: "_blank",
      },
      supportedParser ? supportedParser.linkText : text,
    ];
  },

  addAttributes() {
    return {
      embedYoutubeVideos: {
        default: true,
      },
      allowLinks: {
        default: false,
      },
      href: {
        parseHTML: (element: HTMLAnchorElement) => element.href,
      },
      text: {
        parseHTML: (element: HTMLAnchorElement) => element.textContent,
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(OutschoolLinkComponent, {
      contentDOMElementTag: "span",
    });
  },
});

function OutschoolLinkComponent({ node: { attrs } }: any) {
  const { href, text } = attrs;
  const supportedParser = YoutubeParsers.find(parser => !!parser.matcher(href));

  return (
    <NodeViewWrapper as="span" className="outschool-link-component">
      {supportedParser ? (
        <YoutubeLinkInModal url={href} linkText={supportedParser.linkText} />
      ) : (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      )}
    </NodeViewWrapper>
  );
}

export default LinkView;
