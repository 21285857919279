import { usePrefersReducedMotion } from "@outschool/ui-utils";
import React from "react";

import { useClubContext } from "../../providers";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import BoxWithHeightRatio from "../BoxWithHeightRatio";

import type { SystemStyleObject } from "@styled-system/css";
interface AnimationDataProps extends Object {
  h?: number;
  w?: number;
}
export interface LottieAnimation {
  animationData: AnimationDataProps;
  altText: string;
  restingFrame?: number;
}

export interface LottiePlayerProps {
  autoplay?: boolean;
  loop?: boolean;
  lottieRef?: React.MutableRefObject<any>;
  onLoopComplete?: () => void;
  animation: LottieAnimation;
  sx?: SystemStyleObject;
}
const LottiePlayer: React.FC<LottiePlayerProps> = ({
  animation,
  sx,
  ...props
}) => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const { LottieReact } = useClubContext();
  if ((global as any).OUTSCHOOL_TEST_INIT) {
    // https://github.com/airbnb/lottie-web/issues/2133
    return null;
  }

  if (prefersReducedMotion) {
    props = {
      ...props,
      autoplay: false,
      loop: false,
    };
  }

  const { animationData, altText } = animation;

  const height = animationData?.["h"] ?? 3;
  const width = animationData?.["w"] ?? 4;
  const heightRatio = height / width;
  return (
    <BoxWithHeightRatio
      heightRatio={heightRatio}
      sx={{ position: "relative", ...sx }}
    >
      <React.Suspense fallback={null}>
        <LottieReact
          /* eslint-disable-next-line i18next/no-literal-string */
          renderer="canvas" // using canvas seems to be more portable, especially in Safari
          {...props}
          animationData={animationData}
          aria-label={altText}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
          }}
        />
      </React.Suspense>
    </BoxWithHeightRatio>
  );
};

export default LottiePlayer;
