import { Component } from "@outschool/ownership-areas";
import React from "react";

import useMarkAttendance, { UseMarkAttendanceArgs } from "./useMarkAttendance";

// TODO use useNavigation hook instead
function goToRedirectUrl(url: string) {
  /* eslint-disable no-restricted-syntax */
  if (typeof window.location.replace === "function") {
    window.location.replace(url);
  } else {
    window.location.href = url;
  }
}

interface UseSectionJoinRedirectArgs extends UseMarkAttendanceArgs {
  /**
   * The url to redirect to after possibly marking attendance
   */
  redirectUrl: string;
  /**
   * If set to false, only redirects and does not run the mark attendance mutation
   */
  shouldMarkAttendance: boolean;
  enrollmentUid?: string;
}

export default function useSectionJoinRedirect({
  redirectUrl,
  currentUserCanManage,
  sectionUid,
  enrollmentUid,
  shouldMarkAttendance,
}: UseSectionJoinRedirectArgs) {
  const [markAttended, { error }] = useMarkAttendance({
    currentUserCanManage,
    sectionUid,
  });

  const [hasTimedOut, setHasTimedOut] = React.useState(false);
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      OsPlatform.captureMessage("Marking attendance timed out", {
        extra: { enrollmentUid },
        tags: {
          package: "ui-components-classroom",
          component: Component.Classroom,
        },
      });
      setHasTimedOut(true);
    }, 4000);

    const mutateThenRedirect = async () => {
      try {
        if (shouldMarkAttendance) {
          await markAttended();
        }
        clearTimeout(timeoutId);
        goToRedirectUrl(redirectUrl);
      } catch (error) {
        OsPlatform.captureMessage("Marking attendance failed", {
          extra: { enrollmentUid, error },
          tags: {
            package: "ui-components-classroom",
            component: Component.Classroom,
          },
        });
      }
    };
    mutateThenRedirect();

    return () => clearTimeout(timeoutId);
  }, [markAttended, shouldMarkAttendance, redirectUrl, enrollmentUid]);

  const shouldShowDirectLink = Boolean(error) || hasTimedOut;
  return { shouldShowDirectLink };
}
