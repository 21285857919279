import Knex from "knex";

/*
 * This file was generated by a tool.
 * Rerun `yarn generate-types` to regenerate this file.
 */
export interface ActivitiesRow {
  age_max: number | null;
  age_min: number | null;
  allow_waitlist: boolean;
  allows_late_enrollments_for_fixed_length_classes: boolean;
  allows_recurring_payment: boolean | null;
  archived_at: Date | null;
  assessment: string | null;
  attribution: any | null;
  auto_scheduling_days_notice_max: number | null;
  auto_scheduling_days_notice_min: number | null;
  checklist: any | null;
  class_experience: string | null;
  class_featured_at: Date | null;
  class_request_uid: string | null;
  club_banner_image_uid: string | null;
  club_welcome_video_uid: string | null;
  created_at: Date | null;
  created_by_uid: string | null;
  deleted_at: Date | null;
  details: any | null;
  disclaimer: string | null;
  duration_minutes: number | null;
  duration_weeks: number | null;
  english_proficiency_level: english_proficiency_level | null;
  first_listed_at: Date | null;
  /** The highest grade level in the range. */
  grade_level_max: string | null;
  /** The lowest grade level in the range. */
  grade_level_min: string | null;
  grading_policy: string | null;
  has_building_content: boolean;
  has_external_resources: boolean | null;
  homework: string | null;
  is_auto_scheduling_enabled: boolean;
  is_club: boolean;
  is_ongoing_weekly: boolean | null;
  is_online: boolean | null;
  is_self_paced: boolean | null;
  is_waitlist_allowed: boolean | null;
  language_of_instruction: string | null;
  learning_goals: string | null;
  location: any | null;
  parental_guidance: string | null;
  prerequisites: string | null;
  price_cents: number | null;
  /** JSON object with class proficiency level and notes by teacher. */
  proficiency_level: any | null;
  published_at: Date | null;
  refund_policy_type: refund_policy;
  requested_listing_at: Date | null;
  sample_club_post: any | null;
  /** Only non-null for flex classes (weekly_meetings &#x3D; 0). If not null, is the time that the class will be available to have self-paced enrollments occur.  If null, self-paced enrollments are not currently available. */
  self_paced_enrollment_enabled_at: Date | null;
  size_max: number | null;
  size_min: number | null;
  slug_id: string;
  sources: string | null;
  strict_age_max: number | null;
  strict_age_min: number | null;
  subject: string | null;
  summary: string | null;
  summary_for_learners: string | null;
  tags: string | null;
  title: string | null;
  uid: string;
  updated_at: Date | null;
  upsell_activity_uid: string | null;
  user_uid: string;
  uses_teacher_scheduling: boolean;
  video_for_learners_uid: string | null;
  video_uid: string | null;
  weekly_meetings: number | null;
  welcome_message: any | null;
  why_me: string | null;
}
export interface CreateActivitiesRow {
  age_max?: number | null;
  age_min?: number | null;
  allow_waitlist?: boolean;
  allows_late_enrollments_for_fixed_length_classes?: boolean;
  allows_recurring_payment?: boolean | null;
  archived_at?: Date | null;
  assessment?: string | null;
  attribution?: any | null;
  auto_scheduling_days_notice_max?: number | null;
  auto_scheduling_days_notice_min?: number | null;
  checklist?: any | null;
  class_experience?: string | null;
  class_featured_at?: Date | null;
  class_request_uid?: string | null;
  club_banner_image_uid?: string | null;
  club_welcome_video_uid?: string | null;
  created_at?: Date | null;
  created_by_uid?: string | null;
  deleted_at?: Date | null;
  details?: any | null;
  disclaimer?: string | null;
  duration_minutes?: number | null;
  duration_weeks?: number | null;
  english_proficiency_level?: english_proficiency_level | null;
  first_listed_at?: Date | null;
  /** The highest grade level in the range. */
  grade_level_max?: string | null;
  /** The lowest grade level in the range. */
  grade_level_min?: string | null;
  grading_policy?: string | null;
  has_building_content?: boolean;
  has_external_resources?: boolean | null;
  homework?: string | null;
  is_auto_scheduling_enabled?: boolean;
  is_club?: boolean;
  is_ongoing_weekly?: boolean | null;
  is_online?: boolean | null;
  is_self_paced?: boolean | null;
  is_waitlist_allowed?: boolean | null;
  language_of_instruction?: string | null;
  learning_goals?: string | null;
  location?: any | null;
  parental_guidance?: string | null;
  prerequisites?: string | null;
  price_cents?: number | null;
  /** JSON object with class proficiency level and notes by teacher. */
  proficiency_level?: any | null;
  published_at?: Date | null;
  refund_policy_type?: refund_policy;
  requested_listing_at?: Date | null;
  sample_club_post?: any | null;
  /** Only non-null for flex classes (weekly_meetings &#x3D; 0). If not null, is the time that the class will be available to have self-paced enrollments occur.  If null, self-paced enrollments are not currently available. */
  self_paced_enrollment_enabled_at?: Date | null;
  size_max?: number | null;
  size_min?: number | null;
  slug_id?: string;
  sources?: string | null;
  strict_age_max?: number | null;
  strict_age_min?: number | null;
  subject?: string | null;
  summary?: string | null;
  summary_for_learners?: string | null;
  tags?: string | null;
  title?: string | null;
  uid?: string;
  updated_at?: Date | null;
  upsell_activity_uid?: string | null;
  user_uid: string;
  uses_teacher_scheduling?: boolean;
  video_for_learners_uid?: string | null;
  video_uid?: string | null;
  weekly_meetings?: number | null;
  welcome_message?: any | null;
  why_me?: string | null;
}
export interface ActivitiesAsSearchDocumentRow {
  document: any | null;
  uid: string | null;
}
export interface CreateActivitiesAsSearchDocumentRow {
  document?: any | null;
  uid?: string | null;
}
export interface ActivityActionsRow {
  action: string;
  activity_uid: string;
  created_at: Date | null;
  message_to_teacher: string | null;
  notes: string | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string;
}
export interface CreateActivityActionsRow {
  action: string;
  activity_uid: string;
  created_at?: Date | null;
  message_to_teacher?: string | null;
  notes?: string | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid: string;
}
export interface ActivityExternalResourcesRow {
  activity_uid: string;
  created_at: Date | null;
  deleted_at: Date | null;
  name: string | null;
  policy_uid: string | null;
  uid: string;
  updated_at: Date | null;
  url: string | null;
}
export interface CreateActivityExternalResourcesRow {
  activity_uid: string;
  created_at?: Date | null;
  deleted_at?: Date | null;
  name?: string | null;
  policy_uid?: string | null;
  uid?: string;
  updated_at?: Date | null;
  url?: string | null;
}
export interface ActivityScheduleSettingsRow {
  activity_uid: string;
  created_at: Date;
  deleted_at: Date | null;
  published_at: Date | null;
  schedule_maximum_notice: any;
  schedule_mininum_notice: any;
  suggested_weekly_meetings: number | null;
  teacher_schedule_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreateActivityScheduleSettingsRow {
  activity_uid: string;
  created_at?: Date;
  deleted_at?: Date | null;
  published_at?: Date | null;
  schedule_maximum_notice: any;
  schedule_mininum_notice: any;
  suggested_weekly_meetings?: number | null;
  teacher_schedule_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface ActivityScoresRow {
  activity_uid: string | null;
  created_at: Date | null;
  deleted_at: Date | null;
  dimensions: any | null;
  score: number | null;
  strategy: string | null;
  uid: string;
  updated_at: Date | null;
}
export interface CreateActivityScoresRow {
  activity_uid?: string | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  dimensions?: any | null;
  score?: number | null;
  strategy?: string | null;
  uid?: string;
  updated_at?: Date | null;
}
export interface ActivitySimilarityRow {
  activity_uid: string;
  other_activity_uid: string;
  score: string;
  type: string;
}
export interface CreateActivitySimilarityRow {
  activity_uid: string;
  other_activity_uid: string;
  score: string;
  type: string;
}
export interface ActivityStatsRow {
  activity_uid: string | null;
  average_star_rating: string | null;
  completed_enrollment_count: number | null;
  reviews_count: number | null;
  reviews_with_text_count: number | null;
}
export interface CreateActivityStatsRow {
  activity_uid?: string | null;
  average_star_rating?: string | null;
  completed_enrollment_count?: number | null;
  reviews_count?: number | null;
  reviews_with_text_count?: number | null;
}
export interface ActivityTagsRow {
  activity_uid: string;
  created_at: Date;
  deleted_at: Date | null;
  key: string;
  uid: string;
  updated_at: Date;
  value: string;
}
export interface CreateActivityTagsRow {
  activity_uid: string;
  created_at?: Date;
  deleted_at?: Date | null;
  key: string;
  uid?: string;
  updated_at?: Date;
  value: string;
}
export interface ActivityTopicsRow {
  activity_uid: string;
  created_at: Date;
  deleted_at: Date | null;
  topic_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreateActivityTopicsRow {
  activity_uid: string;
  created_at?: Date;
  deleted_at?: Date | null;
  topic_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface ActivityTranslationsRow {
  activity_uid: string;
  created_at: Date | null;
  language_code: string;
  summary: string | null;
  title: string | null;
  updated_at: Date | null;
}
export interface CreateActivityTranslationsRow {
  activity_uid: string;
  created_at?: Date | null;
  language_code: string;
  summary?: string | null;
  title?: string | null;
  updated_at?: Date | null;
}
export interface AdminEnrollmentPaymentRefundActionsRow {
  action: string;
  admin_uid: string;
  created_at: Date;
  deleted_at: Date | null;
  enrollment_payment_refund_uid: string;
  notes: string | null;
  uid: string;
  updated_at: Date;
  user_uid: string;
}
export interface CreateAdminEnrollmentPaymentRefundActionsRow {
  action: string;
  admin_uid: string;
  created_at?: Date;
  deleted_at?: Date | null;
  enrollment_payment_refund_uid: string;
  notes?: string | null;
  uid?: string;
  updated_at?: Date;
  user_uid: string;
}
export interface AdminUserActionsRow {
  action: string;
  admin_uid: string;
  created_at: Date | null;
  notes: string | null;
  uid: string;
  updated_at: Date | null;
  user_incident_uid: string | null;
  user_uid: string;
}
export interface CreateAdminUserActionsRow {
  action: string;
  admin_uid: string;
  created_at?: Date | null;
  notes?: string | null;
  uid?: string;
  updated_at?: Date | null;
  user_incident_uid?: string | null;
  user_uid: string;
}
export interface AgreementTemplatesRow {
  created_at: Date;
  name: string;
  template: string;
  uid: string;
  updated_at: Date;
}
export interface CreateAgreementTemplatesRow {
  created_at?: Date;
  name: string;
  template: string;
  uid?: string;
  updated_at?: Date;
}
export interface AppleUsersRow {
  access_token: string;
  apple_app_id: string;
  apple_id: string;
  created_at: Date;
  deleted_at: Date | null;
  email: string;
  id_token: string;
  id_token_decoded: any;
  learner_uid: string | null;
  refresh_token: string;
  uid: string;
  updated_at: Date;
  user_uid: string;
}
export interface CreateAppleUsersRow {
  access_token: string;
  apple_app_id: string;
  apple_id: string;
  created_at?: Date;
  deleted_at?: Date | null;
  email: string;
  id_token: string;
  id_token_decoded: any;
  learner_uid?: string | null;
  refresh_token: string;
  uid?: string;
  updated_at?: Date;
  user_uid: string;
}
export interface AttachmentConversionsRow {
  action: string | null;
  created_at: Date | null;
  deleted_at: Date | null;
  details: any | null;
  original_uid: string;
  result_uid: string | null;
  status: string | null;
  uid: string;
  updated_at: Date | null;
}
export interface CreateAttachmentConversionsRow {
  action?: string | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  details?: any | null;
  original_uid: string;
  result_uid?: string | null;
  status?: string | null;
  uid?: string;
  updated_at?: Date | null;
}
export interface AttachmentsRow {
  created_at: Date | null;
  deleted_at: Date | null;
  file: any | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string;
}
export interface CreateAttachmentsRow {
  created_at?: Date | null;
  deleted_at?: Date | null;
  file?: any | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid: string;
}
export interface AttendancesRow {
  attended_at: Date | null;
  created_at: Date | null;
  deleted_at: Date | null;
  learner_uid: string;
  meeting_uid: string;
  uid: string;
  updated_at: Date | null;
}
export interface CreateAttendancesRow {
  attended_at?: Date | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  learner_uid: string;
  meeting_uid: string;
  uid?: string;
  updated_at?: Date | null;
}
export interface BundleElementsRow {
  activity_uid: string;
  bundle_uid: string;
  created_at: Date;
  deleted_at: Date | null;
  order_in_bundle: number;
  uid: string;
  updated_at: Date;
}
export interface CreateBundleElementsRow {
  activity_uid: string;
  bundle_uid: string;
  created_at?: Date;
  deleted_at?: Date | null;
  order_in_bundle: number;
  uid?: string;
  updated_at?: Date;
}
export interface BundlesRow {
  created_at: Date;
  created_by_uid: string;
  deleted_at: Date | null;
  description: string | null;
  is_ordered: boolean | null;
  published_at: Date | null;
  thumbnail: string | null;
  title: string;
  uid: string;
  updated_at: Date;
}
export interface CreateBundlesRow {
  created_at?: Date;
  created_by_uid: string;
  deleted_at?: Date | null;
  description?: string | null;
  is_ordered?: boolean | null;
  published_at?: Date | null;
  thumbnail?: string | null;
  title: string;
  uid?: string;
  updated_at?: Date;
}
export interface CategoriesRow {
  base_path: string | null;
  created_at: Date;
  deleted_at: Date | null;
  description_override: string | null;
  display_strategy: string;
  inclusion_strategy: string;
  name: string;
  name_sentence_cased_override: string | null;
  name_title_cased_override: string | null;
  published_at: Date | null;
  redirect_to_path: string | null;
  saved_search_uid: string | null;
  slug: string;
  title_override: string | null;
  uid: string;
  updated_at: Date;
}
export interface CreateCategoriesRow {
  base_path?: string | null;
  created_at?: Date;
  deleted_at?: Date | null;
  description_override?: string | null;
  display_strategy: string;
  inclusion_strategy: string;
  name: string;
  name_sentence_cased_override?: string | null;
  name_title_cased_override?: string | null;
  published_at?: Date | null;
  redirect_to_path?: string | null;
  saved_search_uid?: string | null;
  slug: string;
  title_override?: string | null;
  uid?: string;
  updated_at?: Date;
}
export interface CategoryActionsRow {
  action: string;
  category_uid: string;
  created_at: Date | null;
  notes: string | null;
  uid: string;
  updated_at: Date | null;
  updated_by: string;
}
export interface CreateCategoryActionsRow {
  action: string;
  category_uid: string;
  created_at?: Date | null;
  notes?: string | null;
  uid?: string;
  updated_at?: Date | null;
  updated_by: string;
}
export interface CategoryRelationshipsRow {
  created_at: Date;
  deleted_at: Date | null;
  from_category_uid: string;
  relationship_type: string;
  taxonomy_type: string;
  to_category_uid: string | null;
  uid: string;
  updated_at: Date;
}
export interface CreateCategoryRelationshipsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  from_category_uid: string;
  relationship_type: string;
  taxonomy_type: string;
  to_category_uid?: string | null;
  uid?: string;
  updated_at?: Date;
}
export interface CharterSchoolsRow {
  address: string | null;
  created_at: Date | null;
  invoice_email: string | null;
  logo_url: string | null;
  name: string | null;
  notes: string | null;
  state: string | null;
  uid: string | null;
  updated_at: Date | null;
  url_name: string | null;
}
export interface CreateCharterSchoolsRow {
  address?: string | null;
  created_at?: Date | null;
  invoice_email?: string | null;
  logo_url?: string | null;
  name?: string | null;
  notes?: string | null;
  state?: string | null;
  uid?: string | null;
  updated_at?: Date | null;
  url_name?: string | null;
}
export interface ClassPostCommentsRow {
  audit_log: any | null;
  background_color: string | null;
  class_post_uid: string;
  content_safety_check_completed_at: Date | null;
  content_safety_check_failed_at: Date | null;
  created_at: Date | null;
  deleted_at: Date | null;
  learner_uid: string | null;
  mentioned_enrollment_uids: string[] | null;
  mentioned_teacher_uid: string | null;
  message: any | null;
  message_content_uid: string;
  thread_parent_comment_uid: string | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string;
}
export interface CreateClassPostCommentsRow {
  audit_log?: any | null;
  background_color?: string | null;
  class_post_uid: string;
  content_safety_check_completed_at?: Date | null;
  content_safety_check_failed_at?: Date | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  learner_uid?: string | null;
  mentioned_enrollment_uids?: string[] | null;
  mentioned_teacher_uid?: string | null;
  message?: any | null;
  message_content_uid: string;
  thread_parent_comment_uid?: string | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid: string;
}
export interface ClassPostScoresRow {
  class_post_uid: string;
  created_at: Date;
  deleted_at: Date | null;
  /** Shows the facets of how the score has been calculated. For example, let&#x27;s say a metric is (views + comments), you may store it as score &#x3D; 42, dimension &#x3D; {
  &quot;viewMultiplier&quot;: 1,
  &quot;views&quot;: {
    &quot;2bdd0d81-5893-431b-8cad-0f689c8a2a75&quot;: 1,
    &quot;022df19b-2985-423d-afcf-2334705ccc7f&quot;: 1
  },
  &quot;commentMultiplier&quot;: 10,
  &quot;comments&quot;: {
    &quot;2bdd0d81-5893-431b-8cad-0f689c8a2a75&quot;: 2,
    &quot;022df19b-2985-423d-afcf-2334705ccc7f&quot;: 2
  }
} */
  dimensions: any;
  score: number;
  strategy: string;
  uid: string;
  updated_at: Date;
}
export interface CreateClassPostScoresRow {
  class_post_uid: string;
  created_at?: Date;
  deleted_at?: Date | null;
  /** Shows the facets of how the score has been calculated. For example, let&#x27;s say a metric is (views + comments), you may store it as score &#x3D; 42, dimension &#x3D; {
  &quot;viewMultiplier&quot;: 1,
  &quot;views&quot;: {
    &quot;2bdd0d81-5893-431b-8cad-0f689c8a2a75&quot;: 1,
    &quot;022df19b-2985-423d-afcf-2334705ccc7f&quot;: 1
  },
  &quot;commentMultiplier&quot;: 10,
  &quot;comments&quot;: {
    &quot;2bdd0d81-5893-431b-8cad-0f689c8a2a75&quot;: 2,
    &quot;022df19b-2985-423d-afcf-2334705ccc7f&quot;: 2
  }
} */
  dimensions?: any;
  score: number;
  strategy: string;
  uid?: string;
  updated_at?: Date;
}
export interface ClassPostsRow {
  audit_log: any | null;
  background_color: string | null;
  content_safety_check_completed_at: Date | null;
  content_safety_check_failed_at: Date | null;
  created_at: Date | null;
  created_by_learner_uid: string | null;
  deleted_at: Date | null;
  is_welcome_post: boolean;
  lesson_post_uid: string | null;
  lesson_uid: string | null;
  mentioned_enrollment_uids: string[] | null;
  message: any | null;
  message_content_uid: string;
  pinned_at: Date | null;
  promoted_at: Date | null;
  published_at: Date;
  section_uid: string;
  title: string | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string;
}
export interface CreateClassPostsRow {
  audit_log?: any | null;
  background_color?: string | null;
  content_safety_check_completed_at?: Date | null;
  content_safety_check_failed_at?: Date | null;
  created_at?: Date | null;
  created_by_learner_uid?: string | null;
  deleted_at?: Date | null;
  is_welcome_post?: boolean;
  lesson_post_uid?: string | null;
  lesson_uid?: string | null;
  mentioned_enrollment_uids?: string[] | null;
  message?: any | null;
  message_content_uid: string;
  pinned_at?: Date | null;
  promoted_at?: Date | null;
  published_at?: Date;
  section_uid: string;
  title?: string | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid: string;
}
export interface ClassRecordingsRow {
  created_at: Date;
  deleted_at: Date | null;
  duration: number | null;
  is_mux_video_ready: boolean;
  meeting_uid: string | null;
  mux_asset_id: string | null;
  mux_attempts: number;
  mux_errored_at: Date | null;
  mux_playback_id: string | null;
  mux_ready_at: Date | null;
  mux_uploaded_at: Date | null;
  s3_audio_key: string | null;
  s3_bucket_name: string;
  s3_chat_key: string | null;
  s3_transcript_key: string | null;
  s3_video_key: string | null;
  section_uid: string | null;
  start_time: Date | null;
  uid: string;
  updated_at: Date;
}
export interface CreateClassRecordingsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  duration?: number | null;
  is_mux_video_ready?: boolean;
  meeting_uid?: string | null;
  mux_asset_id?: string | null;
  mux_attempts?: number;
  mux_errored_at?: Date | null;
  mux_playback_id?: string | null;
  mux_ready_at?: Date | null;
  mux_uploaded_at?: Date | null;
  s3_audio_key?: string | null;
  s3_bucket_name: string;
  s3_chat_key?: string | null;
  s3_transcript_key?: string | null;
  s3_video_key?: string | null;
  section_uid?: string | null;
  start_time?: Date | null;
  uid?: string;
  updated_at?: Date;
}
export interface ClassRequestsRow {
  age: number | null;
  content: string | null;
  created_at: Date | null;
  deleted_at: Date | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string | null;
}
export interface CreateClassRequestsRow {
  age?: number | null;
  content?: string | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid?: string | null;
}
export interface ClassScheduleRequestsRow {
  activity_uid: string;
  created_at: Date | null;
  deleted_at: Date | null;
  message: string | null;
  timetable: any | null;
  timeZone: string;
  uid: string;
  updated_at: Date | null;
  user_uid: string;
}
export interface CreateClassScheduleRequestsRow {
  activity_uid: string;
  created_at?: Date | null;
  deleted_at?: Date | null;
  message?: string | null;
  timetable?: any | null;
  timeZone: string;
  uid?: string;
  updated_at?: Date | null;
  user_uid: string;
}
export interface ClubAttributesRow {
  activity_uid: string;
  club_preview_content: any;
  created_at: Date;
  deleted_at: Date | null;
  uid: string;
  updated_at: Date;
}
export interface CreateClubAttributesRow {
  activity_uid: string;
  club_preview_content: any;
  created_at?: Date;
  deleted_at?: Date | null;
  uid?: string;
  updated_at?: Date;
}
export interface CouponActivityConstraintsRow {
  activity_uid: string;
  coupon_uid: string;
  created_at: Date;
  deleted_at: Date | null;
  uid: string;
  updated_at: Date;
}
export interface CreateCouponActivityConstraintsRow {
  activity_uid: string;
  coupon_uid: string;
  created_at?: Date;
  deleted_at?: Date | null;
  uid?: string;
  updated_at?: Date;
}
export interface CouponCodesRow {
  code: string;
  coupon_uid: string;
  created_at: Date;
  created_by: string;
  deleted_at: Date | null;
  expires_at: Date | null;
  max_uses: number | null;
  uid: string;
  updated_at: Date;
  user_uid: string | null;
}
export interface CreateCouponCodesRow {
  code: string;
  coupon_uid: string;
  created_at?: Date;
  created_by: string;
  deleted_at?: Date | null;
  expires_at?: Date | null;
  max_uses?: number | null;
  uid?: string;
  updated_at?: Date;
  user_uid?: string | null;
}
export interface CouponsRow {
  class_formats_allowed: string[] | null;
  coupon_type: string;
  created_at: Date;
  created_by: string;
  deleted_at: Date | null;
  description: string | null;
  expires_at: Date | null;
  is_buyer_first_purchase_of_class_format_only: boolean;
  is_first_purchase_only: boolean;
  max_amount_cents_per_use: number | null;
  max_uses: number | null;
  max_uses_per_user: number;
  name: string;
  percent_off: number | null;
  starts_at: Date | null;
  subjects_allowed: string[] | null;
  uid: string;
  updated_at: Date;
}
export interface CreateCouponsRow {
  class_formats_allowed?: string[] | null;
  coupon_type: string;
  created_at?: Date;
  created_by: string;
  deleted_at?: Date | null;
  description?: string | null;
  expires_at?: Date | null;
  is_buyer_first_purchase_of_class_format_only?: boolean;
  is_first_purchase_only?: boolean;
  max_amount_cents_per_use?: number | null;
  max_uses?: number | null;
  max_uses_per_user?: number;
  name: string;
  percent_off?: number | null;
  starts_at?: Date | null;
  subjects_allowed?: string[] | null;
  uid?: string;
  updated_at?: Date;
}
export interface CreditAccountsRow {
  balance: number;
  created_at: Date;
  deleted_at: Date | null;
  learner_uid: string | null;
  open_to_buy: number;
  uid: string;
  updated_at: Date;
  user_uid: string | null;
}
export interface CreateCreditAccountsRow {
  balance?: number;
  created_at?: Date;
  deleted_at?: Date | null;
  learner_uid?: string | null;
  open_to_buy?: number;
  uid?: string;
  updated_at?: Date;
  user_uid?: string | null;
}
export interface CreditPurchasesRow {
  amount_cents: number;
  amount_granted_cents: number;
  canceled_at: Date | null;
  confirmed_at: Date | null;
  created_at: Date;
  credit_account_uid: string;
  deleted_at: Date | null;
  deposit_credit_transaction_uid: string | null;
  order_slug_id: string;
  uid: string;
  updated_at: Date;
}
export interface CreateCreditPurchasesRow {
  amount_cents: number;
  amount_granted_cents: number;
  canceled_at?: Date | null;
  confirmed_at?: Date | null;
  created_at?: Date;
  credit_account_uid: string;
  deleted_at?: Date | null;
  deposit_credit_transaction_uid?: string | null;
  order_slug_id: string;
  uid?: string;
  updated_at?: Date;
}
export interface CreditTransactionsRow {
  admin_note: string | null;
  amount_cents: number;
  canceled_at: Date | null;
  captured_at: Date | null;
  created_at: Date;
  credit_account_uid: string;
  deleted_at: Date | null;
  expires_at: Date | null;
  order_slug_id: string | null;
  transaction_type: string;
  uid: string;
  updated_at: Date;
}
export interface CreateCreditTransactionsRow {
  admin_note?: string | null;
  amount_cents: number;
  canceled_at?: Date | null;
  captured_at?: Date | null;
  created_at?: Date;
  credit_account_uid: string;
  deleted_at?: Date | null;
  expires_at?: Date | null;
  order_slug_id?: string | null;
  transaction_type: string;
  uid?: string;
  updated_at?: Date;
}
export interface CurrencyExchangeRatesRow {
  created_at: Date;
  currency_code: string;
  deleted_at: Date | null;
  exchange_rate: number;
  uid: string;
  updated_at: Date;
}
export interface CreateCurrencyExchangeRatesRow {
  created_at?: Date;
  currency_code: string;
  deleted_at?: Date | null;
  exchange_rate: number;
  uid?: string;
  updated_at?: Date;
}
export interface DeletionLogRow {
  /** Explains the action that was taken on the entity. Scrubbed &#x3D; data has been scrubbed, but the row itself is preserved in order to satisfy table relationship constraints. Hard-deleted &#x3D; row has been completed deleted from database. Reverted &#x3D; the data was previously deleted or scrubbed but now has been re-added to the state it was previously in. Incomplete-soft-deleted &#x3D; row has been soft deleted but not all of the data has gone through rigorous scrubbing process. This action indicates this row may need to be revisited in order to properly be scrubbed or hard-deleted. */
  action: string;
  created_at: Date;
  /** References the name of the database table being hard deleted or scrubbed */
  entity_table: string;
  /** References the uid of the row being hard deleted or scrubbed */
  entity_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreateDeletionLogRow {
  /** Explains the action that was taken on the entity. Scrubbed &#x3D; data has been scrubbed, but the row itself is preserved in order to satisfy table relationship constraints. Hard-deleted &#x3D; row has been completed deleted from database. Reverted &#x3D; the data was previously deleted or scrubbed but now has been re-added to the state it was previously in. Incomplete-soft-deleted &#x3D; row has been soft deleted but not all of the data has gone through rigorous scrubbing process. This action indicates this row may need to be revisited in order to properly be scrubbed or hard-deleted. */
  action: string;
  created_at?: Date;
  /** References the name of the database table being hard deleted or scrubbed */
  entity_table: string;
  /** References the uid of the row being hard deleted or scrubbed */
  entity_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface DirectoryMembershipRow {
  created_at: Date;
  deleted_at: Date | null;
  learner_uid: string;
  organization_uid: string | null;
  uid: string;
  updated_at: Date;
}
export interface CreateDirectoryMembershipRow {
  created_at?: Date;
  deleted_at?: Date | null;
  learner_uid: string;
  organization_uid?: string | null;
  uid?: string;
  updated_at?: Date;
}
export interface DonationsRow {
  amount_cents: number;
  confirmed_at: Date | null;
  created_at: Date;
  deleted_at: Date | null;
  order_slug_id: string;
  uid: string;
  updated_at: Date;
  user_uid: string;
}
export interface CreateDonationsRow {
  amount_cents: number;
  confirmed_at?: Date | null;
  created_at?: Date;
  deleted_at?: Date | null;
  order_slug_id: string;
  uid?: string;
  updated_at?: Date;
  user_uid: string;
}
export interface EnrollmentMeetingPurchasesRow {
  activity_uid: string | null;
  /** Used for the 1-on-1 migration to indicate whether associated meetings can be cancelled or rescheduled. */
  can_parent_update: boolean;
  confirmed_at: Date | null;
  created_at: Date;
  deleted_at: Date | null;
  end_time: Date;
  enrollment_uid: string | null;
  meeting_uid: string | null;
  order_slug_id: string;
  org_teacher_uid: string | null;
  start_time: Date;
  uid: string;
  updated_at: Date;
}
export interface CreateEnrollmentMeetingPurchasesRow {
  activity_uid?: string | null;
  /** Used for the 1-on-1 migration to indicate whether associated meetings can be cancelled or rescheduled. */
  can_parent_update?: boolean;
  confirmed_at?: Date | null;
  created_at?: Date;
  deleted_at?: Date | null;
  end_time: Date;
  enrollment_uid?: string | null;
  meeting_uid?: string | null;
  order_slug_id: string;
  org_teacher_uid?: string | null;
  start_time: Date;
  uid?: string;
  updated_at?: Date;
}
export interface EnrollmentPauseWindowsRow {
  created_at: Date;
  deleted_at: Date | null;
  enrollment_uid: string;
  pause_reason: string;
  uid: string;
  unpaused_at: Date | null;
  updated_at: Date;
  window_end: Date;
  window_start: Date;
}
export interface CreateEnrollmentPauseWindowsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  enrollment_uid: string;
  pause_reason: string;
  uid?: string;
  unpaused_at?: Date | null;
  updated_at?: Date;
  window_end: Date;
  window_start: Date;
}
export interface EnrollmentPaymentRefundsRow {
  amount_cents: number;
  amount_in_local_currency: number;
  created_at: Date;
  credit_transaction_uid: string | null;
  currency_code: string;
  deleted_at: Date | null;
  enrollment_payment_uid: string;
  exchange_rate: number;
  note: string | null;
  reason: string | null;
  reduces_teacher_payout: boolean;
  refunded_at: Date;
  stripe_refund_id: string | null;
  uid: string;
  updated_at: Date;
  user_role: string;
  user_uid: string;
  voucher_name: string | null;
}
export interface CreateEnrollmentPaymentRefundsRow {
  amount_cents?: number;
  amount_in_local_currency: number;
  created_at?: Date;
  credit_transaction_uid?: string | null;
  currency_code: string;
  deleted_at?: Date | null;
  enrollment_payment_uid: string;
  exchange_rate: number;
  note?: string | null;
  reason?: string | null;
  reduces_teacher_payout?: boolean;
  refunded_at: Date;
  stripe_refund_id?: string | null;
  uid?: string;
  updated_at?: Date;
  user_role: string;
  user_uid: string;
  voucher_name?: string | null;
}
export interface EnrollmentPaymentsRow {
  amount_cents: number;
  amount_in_local_currency: number | null;
  captured_at: Date | null;
  charter_school_uid: string | null;
  confirmed_at: Date | null;
  coupon_code_uid: string | null;
  created_at: Date;
  credit_transaction_uid: string | null;
  currency_code: string;
  deleted_at: Date | null;
  details: any | null;
  disbursed_at: Date | null;
  disbursed_cents: number | null;
  end_time: Date | null;
  enrollment_meeting_purchase_uid: string | null;
  enrollment_uid: string;
  esa_order_id: string | null;
  exchange_rate: number;
  funding_program_invoice_uid: string | null;
  intercom_url: string | null;
  invoice_uid: string | null;
  last4: string | null;
  order_slug_id: string | null;
  organization_uid: string | null;
  paid_at: Date;
  payout_uid: string | null;
  pricing_offer_uid: string | null;
  purchase_order_id: string | null;
  start_time: Date | null;
  stripe_charge_id: string | null;
  uid: string;
  updated_at: Date;
  user_uid: string | null;
  voucher_name: string | null;
}
export interface CreateEnrollmentPaymentsRow {
  amount_cents?: number;
  amount_in_local_currency?: number | null;
  captured_at?: Date | null;
  charter_school_uid?: string | null;
  confirmed_at?: Date | null;
  coupon_code_uid?: string | null;
  created_at?: Date;
  credit_transaction_uid?: string | null;
  currency_code: string;
  deleted_at?: Date | null;
  details?: any | null;
  disbursed_at?: Date | null;
  disbursed_cents?: number | null;
  end_time?: Date | null;
  enrollment_meeting_purchase_uid?: string | null;
  enrollment_uid: string;
  esa_order_id?: string | null;
  exchange_rate: number;
  funding_program_invoice_uid?: string | null;
  intercom_url?: string | null;
  invoice_uid?: string | null;
  last4?: string | null;
  order_slug_id?: string | null;
  organization_uid?: string | null;
  paid_at: Date;
  payout_uid?: string | null;
  pricing_offer_uid?: string | null;
  purchase_order_id?: string | null;
  start_time?: Date | null;
  stripe_charge_id?: string | null;
  uid?: string;
  updated_at?: Date;
  user_uid?: string | null;
  voucher_name?: string | null;
}
export interface EnrollmentsRow {
  accept_gift_slug_id: string | null;
  amount_in_local_currency: number;
  attribution: any | null;
  charter_school_uid: string | null;
  confirmed_at: Date | null;
  created_at: Date;
  currency_code: string;
  deleted_at: Date | null;
  details: any | null;
  exchange_rate: number;
  gift_claimed_at: Date | null;
  is_late: boolean;
  learner_uid: string | null;
  ongoing_end_time: Date | null;
  ongoing_start_time: Date | null;
  ongoing_stopped_at: Date | null;
  order_slug_id: string | null;
  organization_uid: string | null;
  pause_window_uid: string | null;
  payment_schedule_uid: string | null;
  price_cents: number;
  pricing_offer: string | null;
  pricing_offer_uid: string | null;
  purchased_as_gift: boolean;
  purchased_by_user_uid: string;
  recording_for_clubs_verification_approved_at: Date | null;
  recording_for_clubs_verification_flagged_at: Date | null;
  recording_for_clubs_verification_rejected_at: Date | null;
  recording_for_clubs_verification_uid: string | null;
  refund_policy_type: refund_policy;
  section_uid: string | null;
  stripe_payment_intent_id: string | null;
  uid: string;
  updated_at: Date;
  user_uid: string | null;
  withdrawn_at: Date | null;
}
export interface CreateEnrollmentsRow {
  accept_gift_slug_id?: string | null;
  amount_in_local_currency: number;
  attribution?: any | null;
  charter_school_uid?: string | null;
  confirmed_at?: Date | null;
  created_at?: Date;
  currency_code: string;
  deleted_at?: Date | null;
  details?: any | null;
  exchange_rate: number;
  gift_claimed_at?: Date | null;
  is_late?: boolean;
  learner_uid?: string | null;
  ongoing_end_time?: Date | null;
  ongoing_start_time?: Date | null;
  ongoing_stopped_at?: Date | null;
  order_slug_id?: string | null;
  organization_uid?: string | null;
  pause_window_uid?: string | null;
  payment_schedule_uid?: string | null;
  price_cents?: number;
  pricing_offer?: string | null;
  pricing_offer_uid?: string | null;
  purchased_as_gift?: boolean;
  purchased_by_user_uid: string;
  recording_for_clubs_verification_approved_at?: Date | null;
  recording_for_clubs_verification_flagged_at?: Date | null;
  recording_for_clubs_verification_rejected_at?: Date | null;
  recording_for_clubs_verification_uid?: string | null;
  refund_policy_type?: refund_policy;
  section_uid?: string | null;
  stripe_payment_intent_id?: string | null;
  uid?: string;
  updated_at?: Date;
  user_uid?: string | null;
  withdrawn_at?: Date | null;
}
export interface EntityAttributesRow {
  created_at: Date;
  deleted_at: Date | null;
  entity_type: string;
  entity_uid: string;
  key: string;
  source: string | null;
  uid: string;
  updated_at: Date;
  value: string;
}
export interface CreateEntityAttributesRow {
  created_at?: Date;
  deleted_at?: Date | null;
  entity_type: string;
  entity_uid: string;
  key: string;
  source?: string | null;
  uid?: string;
  updated_at?: Date;
  value: string;
}
export interface EntityAvailabilitiesRow {
  created_at: Date;
  day: number;
  deleted_at: Date | null;
  end_hour: number;
  end_minute: number;
  entity_availability_schedule_uid: string;
  start_hour: number;
  start_minute: number;
  uid: string;
  updated_at: Date;
}
export interface CreateEntityAvailabilitiesRow {
  created_at?: Date;
  day: number;
  deleted_at?: Date | null;
  end_hour: number;
  end_minute: number;
  entity_availability_schedule_uid: string;
  start_hour: number;
  start_minute: number;
  uid?: string;
  updated_at?: Date;
}
export interface EntityAvailabilitySchedulesRow {
  created_at: Date;
  deleted_at: Date | null;
  entity_type: string;
  entity_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreateEntityAvailabilitySchedulesRow {
  created_at?: Date;
  deleted_at?: Date | null;
  entity_type: string;
  entity_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface ExperimentOverridesRow {
  created_at: Date;
  deleted_at: Date | null;
  experiment_uid: string;
  randomization_unit_id: string;
  uid: string;
  updated_at: Date;
  variant: string;
}
export interface CreateExperimentOverridesRow {
  created_at?: Date;
  deleted_at?: Date | null;
  experiment_uid: string;
  randomization_unit_id: string;
  uid?: string;
  updated_at?: Date;
  variant: string;
}
export interface ExperimentsRow {
  analytics_dashboard_url: string | null;
  audit_log: any;
  created_at: Date;
  deleted_at: Date | null;
  experiment_doc_url: string | null;
  exposure_pct: number;
  name: string;
  ownership_team: string | null;
  product_area: string | null;
  randomization_unit: string;
  release_variant: string | null;
  status: string;
  uid: string;
  updated_at: Date;
  variants: string[];
}
export interface CreateExperimentsRow {
  analytics_dashboard_url?: string | null;
  audit_log: any;
  created_at?: Date;
  deleted_at?: Date | null;
  experiment_doc_url?: string | null;
  exposure_pct: number;
  name: string;
  ownership_team?: string | null;
  product_area?: string | null;
  randomization_unit: string;
  release_variant?: string | null;
  status?: string;
  uid?: string;
  updated_at?: Date;
  variants: string[];
}
export interface ExternalActivitiesRow {
  created_at: Date;
  external_activity_id: string;
  post: Object | null;
  source: string;
  status: string;
  uid: string;
  user_uid: string;
}
export interface CreateExternalActivitiesRow {
  created_at?: Date;
  external_activity_id: string;
  post?: Object | null;
  source: string;
  status: string;
  uid?: string;
  user_uid: string;
}
export interface ExternalResourcePoliciesRow {
  category: string | null;
  created_at: Date;
  deleted_at: Date | null;
  name: string;
  policy_type: string;
  uid: string;
  updated_at: Date;
  urls: string[];
}
export interface CreateExternalResourcePoliciesRow {
  category?: string | null;
  created_at?: Date;
  deleted_at?: Date | null;
  name: string;
  policy_type: string;
  uid?: string;
  updated_at?: Date;
  urls: string[];
}
export interface FacebookUsersRow {
  access_token: string | null;
  created_at: Date | null;
  deleted_at: Date | null;
  email: string | null;
  facebook_id: string | null;
  learner_uid: string | null;
  profile: any | null;
  refresh_token: string | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string | null;
}
export interface CreateFacebookUsersRow {
  access_token?: string | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  email?: string | null;
  facebook_id?: string | null;
  learner_uid?: string | null;
  profile?: any | null;
  refresh_token?: string | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid?: string | null;
}
export interface FailedContentSecurityChecksRow {
  attachment_uid: string | null;
  comprehend_response: any | null;
  content_source_table: string;
  content_source_uid: string;
  content_type: string;
  created_at: Date;
  deleted_at: Date | null;
  learner_uid: string | null;
  marked_safe_manually_at: Date | null;
  moderator_id: string | null;
  rejected_manually_at: Date | null;
  rejection_severity: string | null;
  review_started_at: Date | null;
  static_check_response: any | null;
  twohat_content_id: string;
  twohat_response: any | null;
  uid: string;
  updated_at: Date;
  user_uid: string | null;
}
export interface CreateFailedContentSecurityChecksRow {
  attachment_uid?: string | null;
  comprehend_response?: any | null;
  content_source_table: string;
  content_source_uid: string;
  content_type: string;
  created_at?: Date;
  deleted_at?: Date | null;
  learner_uid?: string | null;
  marked_safe_manually_at?: Date | null;
  moderator_id?: string | null;
  rejected_manually_at?: Date | null;
  rejection_severity?: string | null;
  review_started_at?: Date | null;
  static_check_response?: any | null;
  twohat_content_id: string;
  twohat_response?: any | null;
  uid?: string;
  updated_at?: Date;
  user_uid?: string | null;
}
export interface FailedInboundMessagesRow {
  created_at: Date | null;
  error: any | null;
  from_email: string;
  message: any | null;
  uid: string;
}
export interface CreateFailedInboundMessagesRow {
  created_at?: Date | null;
  error?: any | null;
  from_email: string;
  message?: any | null;
  uid?: string;
}
export interface FailedOutboundMessagesRow {
  created_at: Date | null;
  deleted_at: Date | null;
  error: any | null;
  message: any | null;
  retried_at: Date | null;
  retry_count: number | null;
  sent_at: Date | null;
  to_emails: string[] | null;
  uid: string;
  updated_at: Date | null;
}
export interface CreateFailedOutboundMessagesRow {
  created_at?: Date | null;
  deleted_at?: Date | null;
  error?: any | null;
  message?: any | null;
  retried_at?: Date | null;
  retry_count?: number | null;
  sent_at?: Date | null;
  to_emails?: string[] | null;
  uid?: string;
  updated_at?: Date | null;
}
export interface FeedbackRow {
  activity_rating: number | null;
  activity_star_rating: number | null;
  attachment_uids: string[] | null;
  attribution: any | null;
  can_promote: boolean;
  created_at: Date | null;
  deleted_at: Date | null;
  details: any | null;
  outschool_rating: number | null;
  section_uid: string | null;
  teacher_responded_at: Date | null;
  teacher_response: string | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string | null;
}
export interface CreateFeedbackRow {
  activity_rating?: number | null;
  activity_star_rating?: number | null;
  attachment_uids?: string[] | null;
  attribution?: any | null;
  can_promote?: boolean;
  created_at?: Date | null;
  deleted_at?: Date | null;
  details?: any | null;
  outschool_rating?: number | null;
  section_uid?: string | null;
  teacher_responded_at?: Date | null;
  teacher_response?: string | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid?: string | null;
}
export interface FeedbackVotesRow {
  attribution: any | null;
  created_at: Date | null;
  deleted_at: Date | null;
  feedback_uid: string | null;
  referer: string | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string | null;
}
export interface CreateFeedbackVotesRow {
  attribution?: any | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  feedback_uid?: string | null;
  referer?: string | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid?: string | null;
}
export interface FilestackMetadataRow {
  created_at: Date;
  deleted_at: Date | null;
  handle: string;
  os_s3_path: string;
  uid: string;
  updated_at: Date;
}
export interface CreateFilestackMetadataRow {
  created_at?: Date;
  deleted_at?: Date | null;
  handle: string;
  os_s3_path: string;
  uid?: string;
  updated_at?: Date;
}
export interface FollowedUsersRow {
  attribution: any | null;
  created_at: Date | null;
  deleted_at: Date | null;
  followed_uid: string | null;
  follower_uid: string | null;
  referer: string | null;
  uid: string;
  updated_at: Date | null;
}
export interface CreateFollowedUsersRow {
  attribution?: any | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  followed_uid?: string | null;
  follower_uid?: string | null;
  referer?: string | null;
  uid?: string;
  updated_at?: Date | null;
}
export interface FriendRequestsRow {
  approved_at: Date | null;
  created_at: Date | null;
  from_user_uid: string | null;
  referer: string | null;
  to_email: string | null;
  to_user_uid: string | null;
  uid: string;
  updated_at: Date | null;
}
export interface CreateFriendRequestsRow {
  approved_at?: Date | null;
  created_at?: Date | null;
  from_user_uid?: string | null;
  referer?: string | null;
  to_email?: string | null;
  to_user_uid?: string | null;
  uid?: string;
  updated_at?: Date | null;
}
export interface FriendshipsRow {
  created_at: Date | null;
  uid: string;
  updated_at: Date | null;
  user1_uid: string | null;
  user2_uid: string | null;
}
export interface CreateFriendshipsRow {
  created_at?: Date | null;
  uid?: string;
  updated_at?: Date | null;
  user1_uid?: string | null;
  user2_uid?: string | null;
}
export interface FundingProgramInvoicesRow {
  award_id: string;
  child_name: string;
  created_at: Date;
  deleted_at: Date | null;
  funding_program: string | null;
  order_slug_id: string;
  parent_name: string;
  rejected_at: Date | null;
  uid: string;
  updated_at: Date;
}
export interface CreateFundingProgramInvoicesRow {
  award_id: string;
  child_name: string;
  created_at?: Date;
  deleted_at?: Date | null;
  funding_program?: string | null;
  order_slug_id: string;
  parent_name: string;
  rejected_at?: Date | null;
  uid?: string;
  updated_at?: Date;
}
export interface GiftCardsRow {
  amount_cents: number;
  audit_log: any | null;
  confirmed_at: Date | null;
  created_at: Date;
  deleted_at: Date | null;
  deliver_after: Date;
  delivered_at: Date | null;
  delivery_timezone: string;
  note: string | null;
  order_slug_id: string;
  purchaser_email: string;
  purchaser_name: string;
  recipient_email: string | null;
  recipient_name: string;
  redeemed_at: Date | null;
  redeemer_uid: string | null;
  redemption_code: string;
  redemption_credit_transaction_uid: string | null;
  uid: string;
  updated_at: Date;
  user_uid: string | null;
}
export interface CreateGiftCardsRow {
  amount_cents: number;
  audit_log?: any | null;
  confirmed_at?: Date | null;
  created_at?: Date;
  deleted_at?: Date | null;
  deliver_after?: Date;
  delivered_at?: Date | null;
  delivery_timezone?: string;
  note?: string | null;
  order_slug_id: string;
  purchaser_email: string;
  purchaser_name: string;
  recipient_email?: string | null;
  recipient_name?: string;
  redeemed_at?: Date | null;
  redeemer_uid?: string | null;
  redemption_code: string;
  redemption_credit_transaction_uid?: string | null;
  uid?: string;
  updated_at?: Date;
  user_uid?: string | null;
}
export interface GoogleUsersRow {
  access_token: string | null;
  created_at: Date;
  deleted_at: Date | null;
  email: string | null;
  google_id: string | null;
  id_token: string | null;
  learner_uid: string | null;
  profile: any | null;
  refresh_token: string | null;
  uid: string;
  updated_at: Date;
  user_uid: string | null;
}
export interface CreateGoogleUsersRow {
  access_token?: string | null;
  created_at?: Date;
  deleted_at?: Date | null;
  email?: string | null;
  google_id?: string | null;
  id_token?: string | null;
  learner_uid?: string | null;
  profile?: any | null;
  refresh_token?: string | null;
  uid?: string;
  updated_at?: Date;
  user_uid?: string | null;
}
export interface HolidaysRow {
  created_at: Date | null;
  day: Date;
  deleted_at: Date | null;
  name: string;
  uid: string;
}
export interface CreateHolidaysRow {
  created_at?: Date | null;
  day: Date;
  deleted_at?: Date | null;
  name: string;
  uid?: string;
}
export interface InvoicesRow {
  charter_school_uid: string | null;
  created_at: Date | null;
  deleted_at: Date | null;
  invoice_email: string | null;
  invoice_id: string | null;
  organization_uid: string | null;
  paypal_response: any | null;
  uid: string;
  updated_at: Date | null;
}
export interface CreateInvoicesRow {
  charter_school_uid?: string | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  invoice_email?: string | null;
  invoice_id?: string | null;
  organization_uid?: string | null;
  paypal_response?: any | null;
  uid?: string;
  updated_at?: Date | null;
}
export interface KnownSpamUsersRow {
  created_at: Date;
  deleted_at: Date | null;
  learner_emails: string[] | null;
  learner_names: string[] | null;
  name: string | null;
  uid: string;
  updated_at: Date;
  user_emails: string[] | null;
  user_uid: string;
}
export interface CreateKnownSpamUsersRow {
  created_at?: Date;
  deleted_at?: Date | null;
  learner_emails?: string[] | null;
  learner_names?: string[] | null;
  name?: string | null;
  uid?: string;
  updated_at?: Date;
  user_emails?: string[] | null;
  user_uid: string;
}
export interface LearnerAllowancePaymentsRow {
  amount_cents: number;
  canceled_at: Date | null;
  confirmed_at: Date | null;
  created_at: Date;
  deleted_at: Date | null;
  deposit_credit_transaction_uid: string | null;
  learner_allowance_uid: string;
  order_slug_id: string;
  payment_schedule_payment_uid: string | null;
  payment_type: string;
  uid: string;
  updated_at: Date;
}
export interface CreateLearnerAllowancePaymentsRow {
  amount_cents: number;
  canceled_at?: Date | null;
  confirmed_at?: Date | null;
  created_at?: Date;
  deleted_at?: Date | null;
  deposit_credit_transaction_uid?: string | null;
  learner_allowance_uid: string;
  order_slug_id: string;
  payment_schedule_payment_uid?: string | null;
  payment_type: string;
  uid?: string;
  updated_at?: Date;
}
export interface LearnerAllowancesRow {
  audit_log: any;
  confirmed_at: Date | null;
  created_at: Date;
  deleted_at: Date | null;
  learner_uid: string;
  payment_schedule_uid: string | null;
  stopped_at: Date | null;
  stopped_reason: string | null;
  uid: string;
  updated_at: Date;
}
export interface CreateLearnerAllowancesRow {
  audit_log: any;
  confirmed_at?: Date | null;
  created_at?: Date;
  deleted_at?: Date | null;
  learner_uid: string;
  payment_schedule_uid?: string | null;
  stopped_at?: Date | null;
  stopped_reason?: string | null;
  uid?: string;
  updated_at?: Date;
}
export interface LearnerAttributesRow {
  created_at: Date;
  deleted_at: Date | null;
  key: string;
  learner_uid: string;
  uid: string;
  updated_at: Date;
  value: string;
}
export interface CreateLearnerAttributesRow {
  created_at?: Date;
  deleted_at?: Date | null;
  key: string;
  learner_uid: string;
  uid?: string;
  updated_at?: Date;
  value: string;
}
export interface LearnerBehaviorReportsRow {
  blocked_learner_uid: string;
  created_at: Date;
  deleted_at: Date | null;
  note: string | null;
  reason: string;
  reporting_user_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreateLearnerBehaviorReportsRow {
  blocked_learner_uid: string;
  created_at?: Date;
  deleted_at?: Date | null;
  note?: string | null;
  reason: string;
  reporting_user_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface LearnerDevicesRow {
  app_version: string;
  created_at: Date;
  deleted_at: Date | null;
  device_id: string;
  device_token: string;
  last_seen_at: Date;
  learner_uid: string | null;
  os_version: string;
  platform: string;
  supported_notifications: string[];
  uid: string;
  updated_at: Date;
  user_uid: string;
}
export interface CreateLearnerDevicesRow {
  app_version: string;
  created_at?: Date;
  deleted_at?: Date | null;
  device_id: string;
  device_token: string;
  last_seen_at: Date;
  learner_uid?: string | null;
  os_version: string;
  platform: string;
  supported_notifications: string[];
  uid?: string;
  updated_at?: Date;
  user_uid: string;
}
export interface LearnerEnrollmentRequestsRow {
  activity_uid: string;
  created_at: Date;
  deleted_at: Date | null;
  enrollment_uid: string | null;
  learner_cancelled_at: Date | null;
  learner_uid: string;
  order_slug_id: string;
  parent_confirmed_at: Date | null;
  parent_denied_at: Date | null;
  uid: string;
  updated_at: Date;
}
export interface CreateLearnerEnrollmentRequestsRow {
  activity_uid: string;
  created_at?: Date;
  deleted_at?: Date | null;
  enrollment_uid?: string | null;
  learner_cancelled_at?: Date | null;
  learner_uid: string;
  order_slug_id: string;
  parent_confirmed_at?: Date | null;
  parent_denied_at?: Date | null;
  uid?: string;
  updated_at?: Date;
}
export interface LearnerInterestTopicsRow {
  created_at: Date;
  deleted_at: Date | null;
  learner_uid: string;
  topic_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreateLearnerInterestTopicsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  learner_uid: string;
  topic_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface LearnerInterfacePreferencesRow {
  created_at: Date;
  deleted_at: Date | null;
  learner_uid: string;
  name: string;
  uid: string;
  updated_at: Date;
  value: any;
}
export interface CreateLearnerInterfacePreferencesRow {
  created_at?: Date;
  deleted_at?: Date | null;
  learner_uid: string;
  name: string;
  uid?: string;
  updated_at?: Date;
  value: any;
}
export interface LearnerMeetingSummaryRow {
  created_at: Date;
  deleted_at: Date | null;
  learner_uid: string;
  meeting_uid: string;
  /** Number of times the learner talked in the meeting */
  talk_count: number;
  uid: string;
  updated_at: Date;
}
export interface CreateLearnerMeetingSummaryRow {
  created_at?: Date;
  deleted_at?: Date | null;
  learner_uid: string;
  meeting_uid: string;
  /** Number of times the learner talked in the meeting */
  talk_count?: number;
  uid?: string;
  updated_at?: Date;
}
export interface LearnerPlanActivitiesRow {
  activity_uid: string;
  created_at: Date;
  deleted_at: Date | null;
  is_pending: boolean;
  is_suggested: boolean | null;
  learner_plan_uid: string;
  meeting_duration_weeks: number | null;
  meeting_end_time: Date | null;
  meeting_start_time: Date | null;
  section_uid: string | null;
  uid: string;
  updated_at: Date;
}
export interface CreateLearnerPlanActivitiesRow {
  activity_uid: string;
  created_at?: Date;
  deleted_at?: Date | null;
  is_pending?: boolean;
  is_suggested?: boolean | null;
  learner_plan_uid: string;
  meeting_duration_weeks?: number | null;
  meeting_end_time?: Date | null;
  meeting_start_time?: Date | null;
  section_uid?: string | null;
  uid?: string;
  updated_at?: Date;
}
export interface LearnerPlanGoalFiltersRow {
  created_at: Date;
  deleted_at: Date | null;
  learner_plan_goal_uid: string;
  type: string;
  uid: string;
  updated_at: Date;
  value: string;
}
export interface CreateLearnerPlanGoalFiltersRow {
  created_at?: Date;
  deleted_at?: Date | null;
  learner_plan_goal_uid: string;
  type: string;
  uid?: string;
  updated_at?: Date;
  value: string;
}
export interface LearnerPlanGoalsRow {
  created_at: Date;
  deleted_at: Date | null;
  learner_plan_activity_uid: string | null;
  learner_plan_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreateLearnerPlanGoalsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  learner_plan_activity_uid?: string | null;
  learner_plan_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface LearnerPlanRejectedSuggestionsRow {
  activity_uid: string;
  created_at: Date;
  deleted_at: Date | null;
  learner_plan_goal_uid: string;
  learner_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreateLearnerPlanRejectedSuggestionsRow {
  activity_uid: string;
  created_at?: Date;
  deleted_at?: Date | null;
  learner_plan_goal_uid: string;
  learner_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface LearnerPlansRow {
  created_at: Date;
  deleted_at: Date | null;
  end_date: Date | null;
  entity_availability_schedule_uid: string | null;
  learner_uid: string | null;
  name: string | null;
  start_date: Date | null;
  uid: string;
  updated_at: Date;
  user_uid: string;
}
export interface CreateLearnerPlansRow {
  created_at?: Date;
  deleted_at?: Date | null;
  end_date?: Date | null;
  entity_availability_schedule_uid?: string | null;
  learner_uid?: string | null;
  name?: string | null;
  start_date?: Date | null;
  uid?: string;
  updated_at?: Date;
  user_uid: string;
}
export interface LearnerSearchGuardrailsRow {
  audit_log: any | null;
  created_at: Date;
  deleted_at: Date | null;
  delivery_type: string | null;
  learner_uid: string;
  max_duration_minutes: number | null;
  max_price_cents: number | null;
  outschool_hours: any | null;
  time_zone: string | null;
  uid: string;
  updated_at: Date;
}
export interface CreateLearnerSearchGuardrailsRow {
  audit_log?: any | null;
  created_at?: Date;
  deleted_at?: Date | null;
  delivery_type?: string | null;
  learner_uid: string;
  max_duration_minutes?: number | null;
  max_price_cents?: number | null;
  outschool_hours?: any | null;
  time_zone?: string | null;
  uid?: string;
  updated_at?: Date;
}
export interface LearnerSectionReviewsRow {
  attribution: any | null;
  created_at: Date;
  deleted_at: Date | null;
  enrollment_uid: string;
  name: string | null;
  response: any | null;
  uid: string;
  updated_at: Date;
}
export interface CreateLearnerSectionReviewsRow {
  attribution?: any | null;
  created_at?: Date;
  deleted_at?: Date | null;
  enrollment_uid: string;
  name?: string | null;
  response?: any | null;
  uid?: string;
  updated_at?: Date;
}
export interface LearnerUnlockablesRow {
  created_at: Date;
  deleted_at: Date | null;
  key: string;
  learner_uid: string;
  uid: string;
  updated_at: Date;
  value: any;
}
export interface CreateLearnerUnlockablesRow {
  created_at?: Date;
  deleted_at?: Date | null;
  key: string;
  learner_uid: string;
  uid?: string;
  updated_at?: Date;
  value: any;
}
export interface LearnersRow {
  about_me: string | null;
  age: number | null;
  age_updated_at: Date | null;
  approved_for_clubs_at: Date | null;
  avatar: string | null;
  birth_year: number | null;
  content_safety_check_completed_at: Date | null;
  content_safety_check_failed_at: Date | null;
  created_at: Date | null;
  date_of_birth: Date | null;
  deleted_at: Date | null;
  derived_key: any | null;
  email: string | null;
  email_confirmation_token: string | null;
  email_confirmation_token_issued_at: Date | null;
  email_confirmed_at: Date | null;
  intro: string | null;
  login_enabled_at: Date | null;
  name: string | null;
  opted_out_of_personalized_recommendations_at: Date | null;
  parent_uid: string;
  password_reset_token: string | null;
  password_reset_token_issued_at: Date | null;
  profile_age_hidden_at: Date | null;
  profile_location_hidden_at: Date | null;
  pronoun: string | null;
  provisioned_for_organization_uid: string | null;
  race_ethnicity: string | null;
  salt: any | null;
  suspended_at: Date | null;
  suspended_reason: string | null;
  uid: string;
  updated_at: Date | null;
  username: string | null;
}
export interface CreateLearnersRow {
  about_me?: string | null;
  age?: number | null;
  age_updated_at?: Date | null;
  approved_for_clubs_at?: Date | null;
  avatar?: string | null;
  birth_year?: number | null;
  content_safety_check_completed_at?: Date | null;
  content_safety_check_failed_at?: Date | null;
  created_at?: Date | null;
  date_of_birth?: Date | null;
  deleted_at?: Date | null;
  derived_key?: any | null;
  email?: string | null;
  email_confirmation_token?: string | null;
  email_confirmation_token_issued_at?: Date | null;
  email_confirmed_at?: Date | null;
  intro?: string | null;
  login_enabled_at?: Date | null;
  name?: string | null;
  opted_out_of_personalized_recommendations_at?: Date | null;
  parent_uid: string;
  password_reset_token?: string | null;
  password_reset_token_issued_at?: Date | null;
  profile_age_hidden_at?: Date | null;
  profile_location_hidden_at?: Date | null;
  pronoun?: string | null;
  provisioned_for_organization_uid?: string | null;
  race_ethnicity?: string | null;
  salt?: any | null;
  suspended_at?: Date | null;
  suspended_reason?: string | null;
  uid?: string;
  updated_at?: Date | null;
  username?: string | null;
}
export interface LearningActorsRow {
  created_at: Date;
  deleted_at: Date | null;
  learner_uid: string | null;
  uid: string;
  updated_at: Date;
}
export interface CreateLearningActorsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  learner_uid?: string | null;
  uid?: string;
  updated_at?: Date;
}
export interface LearningAssignmentClassPostCommentsRow {
  content_safety_check_completed_at: Date | null;
  content_safety_check_failed_at: Date | null;
  created_at: Date;
  deleted_at: Date | null;
  /** The learner who wrote the comment */
  learner_uid: string | null;
  learning_assignment_class_post_uid: string;
  message_content_uid: string;
  /** The learner whose assignment submission this comment is concerning, making it private between the teacher and the learner. Or left blank can indicate all learners can see the teacher&#x27;s reply. */
  reply_for_learner_uid: string | null;
  uid: string;
  updated_at: Date;
  /** The teacher who wrote the comment or the parent of the learner who wrote the comment */
  user_uid: string;
}
export interface CreateLearningAssignmentClassPostCommentsRow {
  content_safety_check_completed_at?: Date | null;
  content_safety_check_failed_at?: Date | null;
  created_at?: Date;
  deleted_at?: Date | null;
  /** The learner who wrote the comment */
  learner_uid?: string | null;
  learning_assignment_class_post_uid: string;
  message_content_uid: string;
  /** The learner whose assignment submission this comment is concerning, making it private between the teacher and the learner. Or left blank can indicate all learners can see the teacher&#x27;s reply. */
  reply_for_learner_uid?: string | null;
  uid?: string;
  updated_at?: Date;
  /** The teacher who wrote the comment or the parent of the learner who wrote the comment */
  user_uid: string;
}
export interface LearningAssignmentClassPostsRow {
  content_safety_check_completed_at: Date | null;
  content_safety_check_failed_at: Date | null;
  created_at: Date;
  deleted_at: Date | null;
  due_date: Date | null;
  learning_assignment_lesson_post_uid: string | null;
  lesson_uid: string | null;
  message_content_uid: string;
  publish_at: Date;
  section_uid: string;
  /** Whether or not the assignment is required */
  submission_requirement_type: string;
  title: string;
  uid: string;
  updated_at: Date;
  /** The teacher who created the assignment */
  user_uid: string;
}
export interface CreateLearningAssignmentClassPostsRow {
  content_safety_check_completed_at?: Date | null;
  content_safety_check_failed_at?: Date | null;
  created_at?: Date;
  deleted_at?: Date | null;
  due_date?: Date | null;
  learning_assignment_lesson_post_uid?: string | null;
  lesson_uid?: string | null;
  message_content_uid: string;
  publish_at: Date;
  section_uid: string;
  /** Whether or not the assignment is required */
  submission_requirement_type: string;
  title: string;
  uid?: string;
  updated_at?: Date;
  /** The teacher who created the assignment */
  user_uid: string;
}
export interface LearningAssignmentLessonPostsRow {
  content_safety_check_completed_at: Date | null;
  content_safety_check_failed_at: Date | null;
  created_at: Date;
  deleted_at: Date | null;
  lesson_uid: string;
  message_content_uid: string;
  /** Order of the assignment lesson post within a given lesson.  Must be greater than or equal to zero.  Lower number &#x3D; earlier post */
  order_in_lesson: number;
  /** Whether or not the assignment is required */
  submission_requirement_type: string;
  title: string;
  uid: string;
  updated_at: Date;
  /** The teacher who created the assignment */
  user_uid: string;
}
export interface CreateLearningAssignmentLessonPostsRow {
  content_safety_check_completed_at?: Date | null;
  content_safety_check_failed_at?: Date | null;
  created_at?: Date;
  deleted_at?: Date | null;
  lesson_uid: string;
  message_content_uid: string;
  /** Order of the assignment lesson post within a given lesson.  Must be greater than or equal to zero.  Lower number &#x3D; earlier post */
  order_in_lesson: number;
  /** Whether or not the assignment is required */
  submission_requirement_type: string;
  title: string;
  uid?: string;
  updated_at?: Date;
  /** The teacher who created the assignment */
  user_uid: string;
}
export interface LearningLessonsRow {
  created_at: Date;
  deleted_at: Date | null;
  description: string | null;
  learning_syllabus_uid: string;
  learning_unit_uid: string | null;
  order_within_week: number;
  title: string | null;
  uid: string;
  updated_at: Date;
  week_number: number;
}
export interface CreateLearningLessonsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  description?: string | null;
  learning_syllabus_uid: string;
  learning_unit_uid?: string | null;
  order_within_week: number;
  title?: string | null;
  uid?: string;
  updated_at?: Date;
  week_number: number;
}
export interface LearningStatementsRow {
  created_at: Date;
  deleted_at: Date | null;
  learning_actor_uid: string;
  object_type: string;
  object_uid: string;
  uid: string;
  updated_at: Date;
  verb: string;
}
export interface CreateLearningStatementsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  learning_actor_uid: string;
  object_type: string;
  object_uid: string;
  uid?: string;
  updated_at?: Date;
  verb: string;
}
export interface LearningSyllabusesRow {
  activity_uid: string;
  created_at: Date;
  deleted_at: Date | null;
  published_at: Date | null;
  uid: string;
  updated_at: Date;
}
export interface CreateLearningSyllabusesRow {
  activity_uid: string;
  created_at?: Date;
  deleted_at?: Date | null;
  published_at?: Date | null;
  uid?: string;
  updated_at?: Date;
}
export interface LearningUnitsRow {
  created_at: Date;
  deleted_at: Date | null;
  learning_syllabus_uid: string;
  title: string | null;
  uid: string;
  updated_at: Date;
}
export interface CreateLearningUnitsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  learning_syllabus_uid: string;
  title?: string | null;
  uid?: string;
  updated_at?: Date;
}
export interface LessonPostsRow {
  created_at: Date;
  deleted_at: Date | null;
  is_posted_for_enrollments: boolean;
  is_posted_for_subscriptions: boolean;
  lesson_uid: string;
  message_content_uid: string;
  /** Order of the lesson post within a given lesson.  Must be greater than or equal to zero.  Lower number &#x3D; earlier post */
  order_in_lesson: number;
  title: string | null;
  uid: string;
  updated_at: Date;
}
export interface CreateLessonPostsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  is_posted_for_enrollments?: boolean;
  is_posted_for_subscriptions?: boolean;
  lesson_uid: string;
  message_content_uid: string;
  /** Order of the lesson post within a given lesson.  Must be greater than or equal to zero.  Lower number &#x3D; earlier post */
  order_in_lesson: number;
  title?: string | null;
  uid?: string;
  updated_at?: Date;
}
export interface MeetingRequestsRow {
  /** Set when the parent accepts a request. */
  accepted_at: Date | null;
  /** activity_uid is set and section_uid is null when the meeting request represents a private meeting request rather than a new meeting request for an existing section. */
  activity_uid: string;
  created_at: Date;
  /** Set when the parent declines the request. */
  declined_at: Date | null;
  deleted_at: Date | null;
  /** The proposed end time.  This is to be consistent with sections/meetings existing API.  Always equal to start_time + interval duration_minutes minutes. */
  end_time: Date;
  /** When the request can no longer be accepted or declined. Set when request is created or a new time is suggested. */
  expires_at: Date;
  is_recurring: boolean;
  /** The meeting that this requests affects.  For pending requests, this indicates that it is a reschedule request.  For accepted requests, it is the rescheduled/created meeting. */
  meeting_uid: string | null;
  /** The message displayed to the parent. */
  message: string | null;
  org_teacher_uid: string | null;
  /** The section associated with this meeting request. */
  section_uid: string | null;
  /** The proposed start time. */
  start_time: Date;
  uid: string;
  updated_at: Date;
}
export interface CreateMeetingRequestsRow {
  /** Set when the parent accepts a request. */
  accepted_at?: Date | null;
  /** activity_uid is set and section_uid is null when the meeting request represents a private meeting request rather than a new meeting request for an existing section. */
  activity_uid: string;
  created_at?: Date;
  /** Set when the parent declines the request. */
  declined_at?: Date | null;
  deleted_at?: Date | null;
  /** The proposed end time.  This is to be consistent with sections/meetings existing API.  Always equal to start_time + interval duration_minutes minutes. */
  end_time: Date;
  /** When the request can no longer be accepted or declined. Set when request is created or a new time is suggested. */
  expires_at: Date;
  is_recurring?: boolean;
  /** The meeting that this requests affects.  For pending requests, this indicates that it is a reschedule request.  For accepted requests, it is the rescheduled/created meeting. */
  meeting_uid?: string | null;
  /** The message displayed to the parent. */
  message?: string | null;
  org_teacher_uid?: string | null;
  /** The section associated with this meeting request. */
  section_uid?: string | null;
  /** The proposed start time. */
  start_time: Date;
  uid?: string;
  updated_at?: Date;
}
export interface MeetingsRow {
  /** The message expanding on why a meeting is canceled. */
  cancel_note: string | null;
  /** The reason why a meeting is canceled. */
  cancel_reason: string | null;
  canceled_by_user_role: string | null;
  canceled_by_user_uid: string | null;
  created_at: Date;
  deleted_at: Date | null;
  details: any | null;
  end_time: Date;
  lesson_uid: string | null;
  migration: any | null;
  recordings_details: any | null;
  recordings_visibility: boolean;
  section_uid: string;
  start_time: Date;
  uid: string;
  updated_at: Date;
}
export interface CreateMeetingsRow {
  /** The message expanding on why a meeting is canceled. */
  cancel_note?: string | null;
  /** The reason why a meeting is canceled. */
  cancel_reason?: string | null;
  canceled_by_user_role?: string | null;
  canceled_by_user_uid?: string | null;
  created_at?: Date;
  deleted_at?: Date | null;
  details?: any | null;
  end_time: Date;
  lesson_uid?: string | null;
  migration?: any | null;
  recordings_details?: any | null;
  recordings_visibility: boolean;
  section_uid: string;
  start_time: Date;
  uid?: string;
  updated_at?: Date;
}
export interface MessageContentsRow {
  attachment_uids: string[] | null;
  created_at: Date | null;
  deleted_at: Date | null;
  edited_at: Date | null;
  email_message: any | null;
  text: string | null;
  text_mime_type: string | null;
  uid: string;
  updated_at: Date | null;
  video_uid: string | null;
}
export interface CreateMessageContentsRow {
  attachment_uids?: string[] | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  edited_at?: Date | null;
  email_message?: any | null;
  text?: string | null;
  text_mime_type?: string | null;
  uid?: string;
  updated_at?: Date | null;
  video_uid?: string | null;
}
export interface MessageReadEventsRow {
  created_at: Date;
  deleted_at: Date | null;
  learner_uid: string | null;
  message_uid: string | null;
  private_class_message_uid: string | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string | null;
}
export interface CreateMessageReadEventsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  learner_uid?: string | null;
  message_uid?: string | null;
  private_class_message_uid?: string | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid?: string | null;
}
export interface MessageSpamMarkersRow {
  created_at: Date;
  deleted_at: Date | null;
  message_uid: string;
  uid: string;
  updated_at: Date;
  user_uid: string;
}
export interface CreateMessageSpamMarkersRow {
  created_at?: Date;
  deleted_at?: Date | null;
  message_uid: string;
  uid?: string;
  updated_at?: Date;
  user_uid: string;
}
export interface MessagesRow {
  activity_uid: string | null;
  content: string | null;
  created_at: Date | null;
  deleted_at: Date | null;
  details: any | null;
  hold_new_threads: boolean;
  message_content_uid: string | null;
  participants: string[] | null;
  section_uid: string | null;
  sender: string | null;
  thread_uid: string | null;
  uid: string;
  updated_at: Date | null;
}
export interface CreateMessagesRow {
  activity_uid?: string | null;
  content?: string | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  details?: any | null;
  hold_new_threads?: boolean;
  message_content_uid?: string | null;
  participants?: string[] | null;
  section_uid?: string | null;
  sender?: string | null;
  thread_uid?: string | null;
  uid?: string;
  updated_at?: Date | null;
}
export interface NotificationsRow {
  action: string;
  created_at: Date;
  deleted_at: Date | null;
  learner_uid: string | null;
  object_id: string;
  object_type: string;
  platform: string;
  read_at: Date | null;
  seen_at: Date | null;
  sent_at: Date | null;
  uid: string;
  updated_at: Date;
  user_uid: string;
}
export interface CreateNotificationsRow {
  action: string;
  created_at?: Date;
  deleted_at?: Date | null;
  learner_uid?: string | null;
  object_id: string;
  object_type: string;
  platform: string;
  read_at?: Date | null;
  seen_at?: Date | null;
  sent_at?: Date | null;
  uid?: string;
  updated_at?: Date;
  user_uid: string;
}
export interface OidcUsersRow {
  created_at: Date;
  deleted_at: Date | null;
  email: string | null;
  learner_uid: string | null;
  profile: any | null;
  provider: string;
  subject: string;
  uid: string;
  updated_at: Date;
  user_uid: string | null;
}
export interface CreateOidcUsersRow {
  created_at?: Date;
  deleted_at?: Date | null;
  email?: string | null;
  learner_uid?: string | null;
  profile?: any | null;
  provider: string;
  subject: string;
  uid?: string;
  updated_at?: Date;
  user_uid?: string | null;
}
export interface OrderPaymentsRow {
  amount_cents: number;
  amount_in_local_currency: number | null;
  canceled_at: Date | null;
  captured_at: Date | null;
  charter_school_uid: string | null;
  confirmed_at: Date | null;
  coupon_code_uid: string | null;
  created_at: Date;
  credit_transaction_uid: string | null;
  currency_code: string | null;
  deleted_at: Date | null;
  esa_order_id: string | null;
  exchange_rate: number | null;
  funding_program_invoice_uid: string | null;
  intercom_url: string | null;
  invoice_uid: string | null;
  last4: string | null;
  order_slug_id: string;
  organization_uid: string | null;
  pricing_offer_uid: string | null;
  purchase_order_id: string | null;
  purchaser_email: string | null;
  purchaser_name: string | null;
  receipt_data: string | null;
  stripe_charge_id: string | null;
  stripe_payment_intent_id: string | null;
  tender_type: string;
  uid: string;
  updated_at: Date;
  user_uid: string | null;
}
export interface CreateOrderPaymentsRow {
  amount_cents: number;
  amount_in_local_currency?: number | null;
  canceled_at?: Date | null;
  captured_at?: Date | null;
  charter_school_uid?: string | null;
  confirmed_at?: Date | null;
  coupon_code_uid?: string | null;
  created_at?: Date;
  credit_transaction_uid?: string | null;
  currency_code?: string | null;
  deleted_at?: Date | null;
  esa_order_id?: string | null;
  exchange_rate?: number | null;
  funding_program_invoice_uid?: string | null;
  intercom_url?: string | null;
  invoice_uid?: string | null;
  last4?: string | null;
  order_slug_id: string;
  organization_uid?: string | null;
  pricing_offer_uid?: string | null;
  purchase_order_id?: string | null;
  purchaser_email?: string | null;
  purchaser_name?: string | null;
  receipt_data?: string | null;
  stripe_charge_id?: string | null;
  stripe_payment_intent_id?: string | null;
  tender_type: string;
  uid?: string;
  updated_at: Date;
  user_uid?: string | null;
}
export interface OrganizationsRow {
  address: string | null;
  audit_log: any | null;
  created_at: Date;
  deleted_at: Date | null;
  details: any;
  invoice_email: string | null;
  is_esa_or_microgrant: boolean;
  is_org_community_partner: boolean;
  logo_url: string | null;
  name: string | null;
  notes: string | null;
  state: string | null;
  tech_support_email: string | null;
  trust_safety_email: string | null;
  type: string;
  uid: string;
  updated_at: Date;
  url_name: string | null;
}
export interface CreateOrganizationsRow {
  address?: string | null;
  audit_log?: any | null;
  created_at?: Date;
  deleted_at?: Date | null;
  details?: any;
  invoice_email?: string | null;
  is_esa_or_microgrant?: boolean;
  is_org_community_partner?: boolean;
  logo_url?: string | null;
  name?: string | null;
  notes?: string | null;
  state?: string | null;
  tech_support_email?: string | null;
  trust_safety_email?: string | null;
  type?: string;
  uid?: string;
  updated_at?: Date;
  url_name?: string | null;
}
export interface OtherUserEmailsRow {
  confirmed_at: Date | null;
  created_at: Date | null;
  deleted_at: Date | null;
  email: string | null;
  token_uid: string | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string;
}
export interface CreateOtherUserEmailsRow {
  confirmed_at?: Date | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  email?: string | null;
  token_uid?: string | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid: string;
}
export interface ParsedClassRecordingsRow {
  class_recording_uid: string;
  created_at: Date;
  deleted_at: Date | null;
  parsed_at: Date | null;
  uid: string;
}
export interface CreateParsedClassRecordingsRow {
  class_recording_uid: string;
  created_at?: Date;
  deleted_at?: Date | null;
  parsed_at?: Date | null;
  uid?: string;
}
export interface PaymentSchedulePaymentsRow {
  amount_cents: number;
  audit_log: any | null;
  canceled_at: Date | null;
  confirmed_at: Date | null;
  created_at: Date;
  deleted_at: Date | null;
  order_slug_id: string;
  payment_schedule_uid: string;
  period_end_time: Date;
  period_start_time: Date;
  uid: string;
  updated_at: Date;
}
export interface CreatePaymentSchedulePaymentsRow {
  amount_cents: number;
  audit_log?: any | null;
  canceled_at?: Date | null;
  confirmed_at?: Date | null;
  created_at?: Date;
  deleted_at?: Date | null;
  order_slug_id: string;
  payment_schedule_uid: string;
  period_end_time: Date;
  period_start_time: Date;
  uid?: string;
  updated_at?: Date;
}
export interface PaymentSchedulePaymentsFailuresRow {
  amount_cents: number;
  created_at: Date;
  deleted_at: Date | null;
  enrollment_uid: string;
  payment_schedule_uid: string;
  period_end_time: Date;
  period_start_time: Date;
  reason: string;
  stripe_payment_intent_id: string;
  uid: string;
  updated_at: Date;
}
export interface CreatePaymentSchedulePaymentsFailuresRow {
  amount_cents: number;
  created_at?: Date;
  deleted_at?: Date | null;
  enrollment_uid: string;
  payment_schedule_uid: string;
  period_end_time: Date;
  period_start_time: Date;
  reason: string;
  stripe_payment_intent_id: string;
  uid?: string;
  updated_at?: Date;
}
export interface PaymentSchedulesRow {
  amount_cents: number | null;
  audit_log: any | null;
  created_at: Date;
  deleted_at: Date | null;
  end_time: Date | null;
  schedule_type: string;
  start_time: Date;
  stopped_at: Date | null;
  stopped_reason: string | null;
  uid: string;
  updated_at: Date;
}
export interface CreatePaymentSchedulesRow {
  amount_cents?: number | null;
  audit_log?: any | null;
  created_at?: Date;
  deleted_at?: Date | null;
  end_time?: Date | null;
  schedule_type: string;
  start_time: Date;
  stopped_at?: Date | null;
  stopped_reason?: string | null;
  uid?: string;
  updated_at?: Date;
}
export interface PayoutBatchAttemptsRow {
  batch_details_at: Date | null;
  completed_at: Date | null;
  created_at: Date;
  deleted_at: Date | null;
  paypal_batch_id: string | null;
  retry_count: number;
  script_started_at: Date;
  status: string | null;
  uid: string;
  updated_at: Date;
}
export interface CreatePayoutBatchAttemptsRow {
  batch_details_at?: Date | null;
  completed_at?: Date | null;
  created_at?: Date;
  deleted_at?: Date | null;
  paypal_batch_id?: string | null;
  retry_count?: number;
  script_started_at: Date;
  status?: string | null;
  uid?: string;
  updated_at?: Date;
}
export interface PayoutSourcesRow {
  created_at: Date;
  enrollment_payment_uid: string;
  payout_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreatePayoutSourcesRow {
  created_at?: Date;
  enrollment_payment_uid: string;
  payout_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface PayoutsRow {
  amount_cents: number | null;
  created_at: Date | null;
  deleted_at: Date | null;
  email: string | null;
  is_manual: boolean | null;
  payout_batch_attempt_uid: string | null;
  response: any | null;
  section_uid: string | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string | null;
}
export interface CreatePayoutsRow {
  amount_cents?: number | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  email?: string | null;
  is_manual?: boolean | null;
  payout_batch_attempt_uid?: string | null;
  response?: any | null;
  section_uid?: string | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid?: string | null;
}
export interface PricingAdjustmentsRow {
  created_at: Date;
  deleted_at: Date | null;
  /** References the name of the database table */
  entity_type: string;
  entity_uid: string;
  /** The title of the pricing adjustment rule */
  rule: string;
  uid: string;
  updated_at: Date;
  value: number;
}
export interface CreatePricingAdjustmentsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  /** References the name of the database table */
  entity_type: string;
  entity_uid: string;
  /** The title of the pricing adjustment rule */
  rule: string;
  uid?: string;
  updated_at?: Date;
  value: number;
}
export interface PricingOfferEmailAddressesRow {
  audit_log: any | null;
  created_at: Date;
  deleted_at: Date | null;
  eligibility: string | null;
  email_address: string;
  pricing_offer_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreatePricingOfferEmailAddressesRow {
  audit_log?: any | null;
  created_at?: Date;
  deleted_at?: Date | null;
  eligibility?: string | null;
  email_address: string;
  pricing_offer_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface PricingOfferEmailDomainsRow {
  audit_log: any | null;
  created_at: Date;
  deleted_at: Date | null;
  email_domain: string;
  pricing_offer_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreatePricingOfferEmailDomainsRow {
  audit_log?: any | null;
  created_at?: Date;
  deleted_at?: Date | null;
  email_domain: string;
  pricing_offer_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface PricingOffersRow {
  audit_log: any | null;
  buyer_org_name: string | null;
  created_at: Date;
  deleted_at: Date | null;
  discount: number | null;
  end_by: Date;
  name: string;
  organization_uid: string | null;
  require_financial_assistance: boolean;
  require_payment_method: boolean;
  start_after: Date;
  total_cap_cents: number;
  uid: string;
  updated_at: Date;
  user_limit_cents: number;
  users_synced_at: Date | null;
}
export interface CreatePricingOffersRow {
  audit_log?: any | null;
  buyer_org_name?: string | null;
  created_at?: Date;
  deleted_at?: Date | null;
  discount?: number | null;
  end_by: Date;
  name: string;
  organization_uid?: string | null;
  require_financial_assistance?: boolean;
  require_payment_method?: boolean;
  start_after: Date;
  total_cap_cents: number;
  uid?: string;
  updated_at?: Date;
  user_limit_cents: number;
  users_synced_at?: Date | null;
}
export interface PrivateClassMessagesRow {
  activity_uid: string | null;
  audit_log: any | null;
  content_safety_check_completed_at: Date | null;
  content_safety_check_failed_at: Date | null;
  created_at: Date | null;
  deleted_at: Date | null;
  message_content_uid: string | null;
  participant_learner_uids: string[];
  participant_user_uids: string[];
  section_uid: string;
  sender_learner_uid: string | null;
  sender_user_uid: string;
  thread_uid: string | null;
  uid: string;
  updated_at: Date | null;
}
export interface CreatePrivateClassMessagesRow {
  activity_uid?: string | null;
  audit_log?: any | null;
  content_safety_check_completed_at?: Date | null;
  content_safety_check_failed_at?: Date | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  message_content_uid?: string | null;
  participant_learner_uids: string[];
  participant_user_uids: string[];
  section_uid: string;
  sender_learner_uid?: string | null;
  sender_user_uid: string;
  thread_uid?: string | null;
  uid?: string;
  updated_at?: Date | null;
}
export interface PrivateLearnerNotesRow {
  content: string | null;
  created_at: Date;
  deleted_at: Date | null;
  learner_uid: string;
  owner_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreatePrivateLearnerNotesRow {
  content?: string | null;
  created_at?: Date;
  deleted_at?: Date | null;
  learner_uid: string;
  owner_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface SavedActivitiesRow {
  activity_uid: string | null;
  attribution: any | null;
  created_at: Date | null;
  deleted_at: Date | null;
  referer: string | null;
  saved_by_learner_uid: string | null;
  saved_class_list_uid: string | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string | null;
}
export interface CreateSavedActivitiesRow {
  activity_uid?: string | null;
  attribution?: any | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  referer?: string | null;
  saved_by_learner_uid?: string | null;
  saved_class_list_uid?: string | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid?: string | null;
}
export interface SavedClassListsRow {
  created_at: Date;
  deleted_at: Date | null;
  for_learner_uid: string | null;
  name: string | null;
  privacy_status: string;
  uid: string;
  updated_at: Date;
  user_uid: string | null;
}
export interface CreateSavedClassListsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  for_learner_uid?: string | null;
  name?: string | null;
  privacy_status?: string;
  uid?: string;
  updated_at?: Date;
  user_uid?: string | null;
}
export interface SavedSearchesRow {
  created_at: Date | null;
  deleted_at: Date | null;
  filters: any | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string | null;
}
export interface CreateSavedSearchesRow {
  created_at?: Date | null;
  deleted_at?: Date | null;
  filters?: any | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid?: string | null;
}
export interface SectionModeratorsRow {
  created_at: Date;
  deleted_at: Date | null;
  section_uid: string;
  uid: string;
  user_uid: string;
}
export interface CreateSectionModeratorsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  section_uid: string;
  uid?: string;
  user_uid: string;
}
export interface SectionRecurringMeetingsRow {
  created_at: Date;
  deleted_at: Date | null;
  /** The meeting associated with the section_recurring_schedule. */
  meeting_uid: string;
  /** The associated section_recurring_schedule. */
  section_recurring_schedule_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreateSectionRecurringMeetingsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  /** The meeting associated with the section_recurring_schedule. */
  meeting_uid: string;
  /** The associated section_recurring_schedule. */
  section_recurring_schedule_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface SectionRecurringSchedulesRow {
  created_at: Date;
  deleted_at: Date | null;
  /** When the recurring event should end. If NULL, meetings will continue to be added for this schedule.. */
  end_at: Date | null;
  /** End time of the first meeting, used to determine day_of_week and time local to the user for repeatability. */
  end_time: Date;
  /** The section associated with this recurring schedule. */
  section_uid: string;
  /** Start time of the first meeting, used to determine day_of_week and time local to the user for repeatability. */
  start_time: Date;
  uid: string;
  updated_at: Date;
}
export interface CreateSectionRecurringSchedulesRow {
  created_at?: Date;
  deleted_at?: Date | null;
  /** When the recurring event should end. If NULL, meetings will continue to be added for this schedule.. */
  end_at?: Date | null;
  /** End time of the first meeting, used to determine day_of_week and time local to the user for repeatability. */
  end_time: Date;
  /** The section associated with this recurring schedule. */
  section_uid: string;
  /** Start time of the first meeting, used to determine day_of_week and time local to the user for repeatability. */
  start_time: Date;
  uid?: string;
  updated_at?: Date;
}
export interface SectionsRow {
  activity_uid: string;
  attribution: any | null;
  canceled_at: Date | null;
  checklist: any | null;
  created_at: Date | null;
  deleted_at: Date | null;
  details: any | null;
  duration_minutes: number | null;
  end_time: Date | null;
  is_cross_listed: boolean | null;
  /** If true, is a self-paced flex class otherwise it is a “traditional” cohorted flex class (not self-paced).  Only true for flex classes (weekly_meetings &#x3D; 0) */
  is_self_paced: boolean;
  learning_syllabus_uid: string | null;
  location: any | null;
  ongoing_stopped_at: Date | null;
  /** Allow null for section price to indicate it is the same with activity */
  price_cents: number | null;
  published_at: Date | null;
  size_max: number | null;
  size_min: number | null;
  start_time: Date | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string | null;
}
export interface CreateSectionsRow {
  activity_uid: string;
  attribution?: any | null;
  canceled_at?: Date | null;
  checklist?: any | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  details?: any | null;
  duration_minutes?: number | null;
  end_time?: Date | null;
  is_cross_listed?: boolean | null;
  /** If true, is a self-paced flex class otherwise it is a “traditional” cohorted flex class (not self-paced).  Only true for flex classes (weekly_meetings &#x3D; 0) */
  is_self_paced?: boolean;
  learning_syllabus_uid?: string | null;
  location?: any | null;
  ongoing_stopped_at?: Date | null;
  /** Allow null for section price to indicate it is the same with activity */
  price_cents?: number | null;
  published_at?: Date | null;
  size_max?: number | null;
  size_min?: number | null;
  start_time?: Date | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid?: string | null;
}
export interface SectionsAvailabilityRow {
  filled_spaces: number | null;
  is_available: boolean | null;
  next_meeting_end_time: Date | null;
  next_meeting_time: Date | null;
  section_uid: string | null;
  total_spaces: number | null;
}
export interface CreateSectionsAvailabilityRow {
  filled_spaces?: number | null;
  is_available?: boolean | null;
  next_meeting_end_time?: Date | null;
  next_meeting_time?: Date | null;
  section_uid?: string | null;
  total_spaces?: number | null;
}
export interface SellerOrgAgreementsRow {
  agreement: string;
  agreement_template_uid: string | null;
  created_at: Date;
  fields: any | null;
  signed_at: Date | null;
  uid: string;
  updated_at: Date;
  user_uid: string;
}
export interface CreateSellerOrgAgreementsRow {
  agreement: string;
  agreement_template_uid?: string | null;
  created_at?: Date;
  fields?: any | null;
  signed_at?: Date | null;
  uid?: string;
  updated_at?: Date;
  user_uid: string;
}
export interface SellerOrgApplicationsRow {
  admin_response: string | null;
  applied_at: Date | null;
  approved_at: Date | null;
  contract_signed_at: Date | null;
  created_at: Date | null;
  other_answers: any | null;
  rejected_at: Date | null;
  status: string;
  uid: string;
  updated_at: Date | null;
  user_uid: string | null;
}
export interface CreateSellerOrgApplicationsRow {
  admin_response?: string | null;
  applied_at?: Date | null;
  approved_at?: Date | null;
  contract_signed_at?: Date | null;
  created_at?: Date | null;
  other_answers?: any | null;
  rejected_at?: Date | null;
  status?: string;
  uid?: string;
  updated_at?: Date | null;
  user_uid?: string | null;
}
export interface SellerOrgsRow {
  background_check_owner: string;
  created_at: Date | null;
  pin_derived_key: any | null;
  pin_salt: any | null;
  status: string;
  uid: string;
  updated_at: Date | null;
}
export interface CreateSellerOrgsRow {
  background_check_owner?: string;
  created_at?: Date | null;
  pin_derived_key?: any | null;
  pin_salt?: any | null;
  status?: string;
  uid?: string;
  updated_at?: Date | null;
}
export interface SentNotificationsRow {
  created_at: Date | null;
  deleted_at: Date | null;
  key: string;
  namespace: string;
  uid: string;
}
export interface CreateSentNotificationsRow {
  created_at?: Date | null;
  deleted_at?: Date | null;
  key: string;
  namespace: string;
  uid?: string;
}
export interface TeacherActivityStatsRow {
  average_star_rating: string | null;
  learners_taught_count: number | null;
  reviews_count: string | null;
  reviews_with_text_count: string | null;
  successful_class_count: number | null;
  successful_private_class_count: number | null;
  updated_at: Date | null;
  user_uid: string | null;
}
export interface CreateTeacherActivityStatsRow {
  average_star_rating?: string | null;
  learners_taught_count?: number | null;
  reviews_count?: string | null;
  reviews_with_text_count?: string | null;
  successful_class_count?: number | null;
  successful_private_class_count?: number | null;
  updated_at?: Date | null;
  user_uid?: string | null;
}
export interface TeacherApplicationsRow {
  age_ranges_taught: string[] | null;
  class_subjects: string[] | null;
  class_types: string | null;
  created_at: Date | null;
  deleted_at: Date | null;
  legal_name: string | null;
  online_profiles: string | null;
  reviewed_at: Date | null;
  sample_classes: string | null;
  status: string;
  submitted_at: Date | null;
  teaching_credentials: string | null;
  teaching_experience: string | null;
  teaching_motivation: string | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string;
  video_uid: string | null;
}
export interface CreateTeacherApplicationsRow {
  age_ranges_taught?: string[] | null;
  class_subjects?: string[] | null;
  class_types?: string | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  legal_name?: string | null;
  online_profiles?: string | null;
  reviewed_at?: Date | null;
  sample_classes?: string | null;
  status?: string;
  submitted_at?: Date | null;
  teaching_credentials?: string | null;
  teaching_experience?: string | null;
  teaching_motivation?: string | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid: string;
  video_uid?: string | null;
}
export interface TeacherAttendancesRow {
  attended_at: Date | null;
  created_at: Date | null;
  deleted_at: Date | null;
  meeting_uid: string;
  teacher_uid: string;
  uid: string;
  updated_at: Date | null;
}
export interface CreateTeacherAttendancesRow {
  attended_at?: Date | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  meeting_uid: string;
  teacher_uid: string;
  uid?: string;
  updated_at?: Date | null;
}
export interface TeacherAttributesRow {
  auto_scheduling_buffer: any;
  created_at: Date;
  deleted_at: Date | null;
  is_active: boolean | null;
  is_auto_scheduling_enabled: boolean;
  main_toolbar_pinned_tabs: string[] | null;
  show_calendar: boolean;
  teacher_availability: any | null;
  uid: string;
  updated_at: Date;
  user_uid: string;
}
export interface CreateTeacherAttributesRow {
  auto_scheduling_buffer?: any;
  created_at?: Date;
  deleted_at?: Date | null;
  is_active?: boolean | null;
  is_auto_scheduling_enabled?: boolean;
  main_toolbar_pinned_tabs?: string[] | null;
  show_calendar?: boolean;
  teacher_availability?: any | null;
  uid?: string;
  updated_at?: Date;
  user_uid: string;
}
export interface TeacherCredentialsRow {
  certificate_discipline: string | null;
  certificate_does_not_expire: boolean;
  certificate_expire_date: Date | null;
  certificate_issue_date: Date | null;
  certificate_number: string | null;
  certificate_secondary_discipline: string | null;
  certificate_state: string | null;
  created_at: Date;
  credential_type: string;
  degree_additional_info: string | null;
  degree_institution: string | null;
  degree_issue_date: Date | null;
  degree_subject: string | null;
  degree_type: string | null;
  deleted_at: Date | null;
  file: any;
  other_details: string | null;
  other_type: string | null;
  uid: string;
  updated_at: Date;
  user_uid: string;
}
export interface CreateTeacherCredentialsRow {
  certificate_discipline?: string | null;
  certificate_does_not_expire?: boolean;
  certificate_expire_date?: Date | null;
  certificate_issue_date?: Date | null;
  certificate_number?: string | null;
  certificate_secondary_discipline?: string | null;
  certificate_state?: string | null;
  created_at?: Date;
  credential_type: string;
  degree_additional_info?: string | null;
  degree_institution?: string | null;
  degree_issue_date?: Date | null;
  degree_subject?: string | null;
  degree_type?: string | null;
  deleted_at?: Date | null;
  file: any;
  other_details?: string | null;
  other_type?: string | null;
  uid?: string;
  updated_at?: Date;
  user_uid: string;
}
export interface TeacherScheduleAvailabilityRow {
  created_at: Date;
  deleted_at: Date | null;
  end_time: Date;
  is_recurring: boolean;
  is_unavailable: boolean | null;
  recurring_end_time: Date | null;
  start_time: Date;
  teacher_schedule_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreateTeacherScheduleAvailabilityRow {
  created_at?: Date;
  deleted_at?: Date | null;
  end_time: Date;
  is_recurring: boolean;
  is_unavailable?: boolean | null;
  recurring_end_time?: Date | null;
  start_time: Date;
  teacher_schedule_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface TeacherScheduleEnrollableTimesRow {
  created_at: Date;
  deleted_at: Date | null;
  end_time: Date;
  start_time: Date;
  teacher_schedule_uid: string;
  uid: string;
  updated_at: Date;
}
export interface CreateTeacherScheduleEnrollableTimesRow {
  created_at?: Date;
  deleted_at?: Date | null;
  end_time: Date;
  start_time: Date;
  teacher_schedule_uid: string;
  uid?: string;
  updated_at?: Date;
}
export interface TeacherSchedulesRow {
  buffer_time_between_meetings: any;
  created_at: Date;
  deleted_at: Date | null;
  meetings_start_time_increments: any;
  uid: string;
  updated_at: Date;
  user_uid: string;
}
export interface CreateTeacherSchedulesRow {
  buffer_time_between_meetings?: any;
  created_at?: Date;
  deleted_at?: Date | null;
  meetings_start_time_increments?: any;
  uid?: string;
  updated_at?: Date;
  user_uid: string;
}
export interface TeacherSimilarityRow {
  rank: number;
  score: string;
  similar_teacher_uid: string;
  teacher_uid: string;
}
export interface CreateTeacherSimilarityRow {
  rank: number;
  score: string;
  similar_teacher_uid: string;
  teacher_uid: string;
}
export interface TeacherStatsRow {
  created_at: Date | null;
  deleted_at: Date | null;
  key: string;
  uid: string;
  updated_at: Date | null;
  user_uid: string;
  value: number | null;
}
export interface CreateTeacherStatsRow {
  created_at?: Date | null;
  deleted_at?: Date | null;
  key: string;
  uid?: string;
  updated_at?: Date | null;
  user_uid: string;
  value?: number | null;
}
export interface TeacherTagsRow {
  created_at: Date;
  deleted_at: Date | null;
  key: string;
  uid: string;
  updated_at: Date;
  value: string;
}
export interface CreateTeacherTagsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  key: string;
  uid?: string;
  updated_at?: Date;
  value: string;
}
export interface TeacherTagsAssignedRow {
  created_at: Date;
  deleted_at: Date | null;
  teacher_tag_uid: string;
  uid: string;
  updated_at: Date;
  user_uid: string;
}
export interface CreateTeacherTagsAssignedRow {
  created_at?: Date;
  deleted_at?: Date | null;
  teacher_tag_uid: string;
  uid?: string;
  updated_at?: Date;
  user_uid: string;
}
export interface TeacherUnavailableBlocksRow {
  created_at: Date | null;
  deleted_at: Date | null;
  end_time: Date | null;
  start_time: Date;
  uid: string;
  updated_at: Date | null;
  user_uid: string;
}
export interface CreateTeacherUnavailableBlocksRow {
  created_at?: Date | null;
  deleted_at?: Date | null;
  end_time?: Date | null;
  start_time: Date;
  uid?: string;
  updated_at?: Date | null;
  user_uid: string;
}
export interface TemporaryTokensRow {
  created_at: Date;
  deleted_at: Date | null;
  expiration_time: Date;
  token: string;
  type: string;
  uid: string;
  updated_at: Date;
}
export interface CreateTemporaryTokensRow {
  created_at?: Date;
  deleted_at?: Date | null;
  expiration_time: Date;
  token: string;
  type: string;
  uid?: string;
  updated_at?: Date;
}
export interface TopicsRow {
  created_at: Date;
  deleted_at: Date | null;
  image_url: string;
  label: string;
  uid: string;
  updated_at: Date;
}
export interface CreateTopicsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  image_url: string;
  label: string;
  uid?: string;
  updated_at?: Date;
}
export interface TrendingSearchTopicsRow {
  num_searches: number;
  search_query: string;
  subject: string;
}
export interface CreateTrendingSearchTopicsRow {
  num_searches: number;
  search_query: string;
  subject: string;
}
export interface TrustPrivilegesRow {
  admin_action_note: string | null;
  admin_action_user: string;
  audit_log: any | null;
  created_at: Date;
  deleted_at: Date | null;
  name: string;
  override_value: string;
  uid: string;
  updated_at: Date;
  user_uid: string;
}
export interface CreateTrustPrivilegesRow {
  admin_action_note?: string | null;
  admin_action_user: string;
  audit_log?: any | null;
  created_at?: Date;
  deleted_at?: Date | null;
  name: string;
  override_value: string;
  uid?: string;
  updated_at?: Date;
  user_uid: string;
}
export interface UpcomingSectionsRow {
  activity_uid: string | null;
  next_meeting_end_time: Date | null;
  next_meeting_time: Date | null;
  section_uid: string | null;
  updated_at: Date | null;
}
export interface CreateUpcomingSectionsRow {
  activity_uid?: string | null;
  next_meeting_end_time?: Date | null;
  next_meeting_time?: Date | null;
  section_uid?: string | null;
  updated_at?: Date | null;
}
export interface UserChurnIndicatorsRow {
  last_page_view_at: Date | null;
  last_weekly_email_open_at: Date | null;
  updated_at: Date | null;
  user_uid: string;
}
export interface CreateUserChurnIndicatorsRow {
  last_page_view_at?: Date | null;
  last_weekly_email_open_at?: Date | null;
  updated_at?: Date | null;
  user_uid: string;
}
export interface UserEmailPreferencesRow {
  attribution: any | null;
  created_at: Date | null;
  deleted_at: Date | null;
  deletion_attribution: any | null;
  is_enabled: boolean | null;
  name: string | null;
  uid: string;
  updated_at: Date | null;
  user_uid: string | null;
}
export interface CreateUserEmailPreferencesRow {
  attribution?: any | null;
  created_at?: Date | null;
  deleted_at?: Date | null;
  deletion_attribution?: any | null;
  is_enabled?: boolean | null;
  name?: string | null;
  uid?: string;
  updated_at?: Date | null;
  user_uid?: string | null;
}
export interface UserFinancialAssistanceRow {
  application_status: string | null;
  city: string | null;
  covid: boolean | null;
  created_at: Date;
  deleted_at: Date | null;
  hispanic_latino: boolean | null;
  household_size: number | null;
  household_size_3_to_18: number | null;
  how_did_you_hear: string | null;
  income: string | null;
  interested_in_research: boolean | null;
  interested_in_sharing: boolean | null;
  learning_environments: string[] | null;
  lunch: boolean | null;
  name: string | null;
  needs_assistance: boolean | null;
  noschool: boolean | null;
  primary_language: string | null;
  race_ethnicity: string[] | null;
  reliable_internet: boolean | null;
  school_closed: boolean | null;
  state: string | null;
  uid: string;
  updated_at: Date;
  user_uid: string;
  zip_code: string | null;
}
export interface CreateUserFinancialAssistanceRow {
  application_status?: string | null;
  city?: string | null;
  covid?: boolean | null;
  created_at?: Date;
  deleted_at?: Date | null;
  hispanic_latino?: boolean | null;
  household_size?: number | null;
  household_size_3_to_18?: number | null;
  how_did_you_hear?: string | null;
  income?: string | null;
  interested_in_research?: boolean | null;
  interested_in_sharing?: boolean | null;
  learning_environments?: string[] | null;
  lunch?: boolean | null;
  name?: string | null;
  needs_assistance?: boolean | null;
  noschool?: boolean | null;
  primary_language?: string | null;
  race_ethnicity?: string[] | null;
  reliable_internet?: boolean | null;
  school_closed?: boolean | null;
  state?: string | null;
  uid?: string;
  updated_at?: Date;
  user_uid: string;
  zip_code?: string | null;
}
export interface UserIncidentsRow {
  category: string | null;
  created_at: Date;
  created_by_user_uid: string | null;
  deleted_at: Date | null;
  notes: string | null;
  uid: string;
  updated_at: Date;
  user_uid: string | null;
}
export interface CreateUserIncidentsRow {
  category?: string | null;
  created_at?: Date;
  created_by_user_uid?: string | null;
  deleted_at?: Date | null;
  notes?: string | null;
  uid?: string;
  updated_at?: Date;
  user_uid?: string | null;
}
export interface UserLoginAttemptsLogRow {
  auth_type: string;
  created_at: Date;
  deleted_at: Date | null;
  ip_address: string;
  learner_uid: string | null;
  uid: string;
  updated_at: Date;
  user_agent: string;
  user_uid: string;
  was_successful: boolean;
}
export interface CreateUserLoginAttemptsLogRow {
  auth_type?: string;
  created_at?: Date;
  deleted_at?: Date | null;
  ip_address: string;
  learner_uid?: string | null;
  uid?: string;
  updated_at?: Date;
  user_agent?: string;
  user_uid: string;
  was_successful?: boolean;
}
export interface UserPricingOffersRow {
  created_at: Date;
  deleted_at: Date | null;
  pricing_offer_uid: string;
  uid: string;
  updated_at: Date;
  user_uid: string;
}
export interface CreateUserPricingOffersRow {
  created_at?: Date;
  deleted_at?: Date | null;
  pricing_offer_uid: string;
  uid?: string;
  updated_at?: Date;
  user_uid: string;
}
export interface UserQuizAnswersRow {
  anonymous_id: string;
  created_at: Date;
  deleted_at: Date | null;
  type: string;
  uid: string;
  updated_at: Date;
  /** Before creating account this can be null */
  user_uid: string | null;
  /** This is the read-only answers from quiz */
  value: any;
}
export interface CreateUserQuizAnswersRow {
  anonymous_id: string;
  created_at?: Date;
  deleted_at?: Date | null;
  type?: string;
  uid?: string;
  updated_at?: Date;
  /** Before creating account this can be null */
  user_uid?: string | null;
  /** This is the read-only answers from quiz */
  value?: any;
}
export interface UsersRow {
  activity_format_description_flex_hidden_at: Date | null;
  activity_format_description_multi_day_hidden_at: Date | null;
  activity_format_description_one_time_hidden_at: Date | null;
  activity_format_description_ongoing_hidden_at: Date | null;
  admin_for_charter_school_uid: string | null;
  admin_for_organization_uid: string | null;
  approved_learner_signup_at: Date | null;
  attribution: any | null;
  background_check_started_at: Date | null;
  background_checked_at: Date | null;
  calendar_uid: string | null;
  chaperone_required: boolean | null;
  classroom_trained_at: Date | null;
  classroom_training_scheduled_at: Date | null;
  confirmed_at: Date | null;
  consent_to_share_class_history: boolean | null;
  consent_to_share_favourites: boolean | null;
  consented_to_coppa_notice_at: Date | null;
  created_at: Date | null;
  default_currency: string | null;
  default_schedule_filters: any;
  deleted_at: Date | null;
  derived_key: any | null;
  details: any | null;
  do_not_sell_my_personal_info: boolean;
  email: string;
  email_bounced_at: Date | null;
  email_settings_uid: string;
  email_updated_at: Date | null;
  interview_scheduled_at: Date | null;
  interviewed_at: Date | null;
  is_admin: boolean;
  is_crosslister: boolean | null;
  leader_link: string | null;
  leader_subscribed_at: Date | null;
  learner_mode_switch_auth_requirement: string | null;
  learner_uses_parent_mode: boolean;
  learners_verified_at: Date | null;
  locale: string | null;
  location: any | null;
  name: string | null;
  number_of_merges: number | null;
  owner_of_seller_org_uid: string | null;
  payout_email: string | null;
  payout_email_updated_at: Date | null;
  phone: string | null;
  photo: string | null;
  privacy_state: string | null;
  profile_link_uid: string | null;
  pw_updated_at: Date | null;
  race_ethnicity: string | null;
  recording_autopost_enabled_at: Date | null;
  recording_autopost_updated_at: Date | null;
  roles: string[] | null;
  salt: any | null;
  scheduled_removal_at: Date | null;
  seller_org_admin_teacher_account_uid: string | null;
  signup_initiator: string | null;
  skilljar_user_id: string | null;
  slug_id: string;
  stripe_customer_id: string | null;
  subscribed_at: Date | null;
  suspended_at: Date | null;
  suspended_reason: string | null;
  teacher_approved_at: Date | null;
  teacher_availability: any | null;
  teacher_featured_at: Date | null;
  teacher_for_seller_org_user_uid: string | null;
  uid: string;
  unsubscribed_at: Date | null;
  updated_at: Date | null;
  video_uid: string | null;
}
export interface CreateUsersRow {
  activity_format_description_flex_hidden_at?: Date | null;
  activity_format_description_multi_day_hidden_at?: Date | null;
  activity_format_description_one_time_hidden_at?: Date | null;
  activity_format_description_ongoing_hidden_at?: Date | null;
  admin_for_charter_school_uid?: string | null;
  admin_for_organization_uid?: string | null;
  approved_learner_signup_at?: Date | null;
  attribution?: any | null;
  background_check_started_at?: Date | null;
  background_checked_at?: Date | null;
  calendar_uid?: string | null;
  chaperone_required?: boolean | null;
  classroom_trained_at?: Date | null;
  classroom_training_scheduled_at?: Date | null;
  confirmed_at?: Date | null;
  consent_to_share_class_history?: boolean | null;
  consent_to_share_favourites?: boolean | null;
  consented_to_coppa_notice_at?: Date | null;
  created_at?: Date | null;
  default_currency?: string | null;
  default_schedule_filters?: any;
  deleted_at?: Date | null;
  derived_key?: any | null;
  details?: any | null;
  do_not_sell_my_personal_info?: boolean;
  email: string;
  email_bounced_at?: Date | null;
  email_settings_uid?: string;
  email_updated_at?: Date | null;
  interview_scheduled_at?: Date | null;
  interviewed_at?: Date | null;
  is_admin?: boolean;
  is_crosslister?: boolean | null;
  leader_link?: string | null;
  leader_subscribed_at?: Date | null;
  learner_mode_switch_auth_requirement?: string | null;
  learner_uses_parent_mode?: boolean;
  learners_verified_at?: Date | null;
  locale?: string | null;
  location?: any | null;
  name?: string | null;
  number_of_merges?: number | null;
  owner_of_seller_org_uid?: string | null;
  payout_email?: string | null;
  payout_email_updated_at?: Date | null;
  phone?: string | null;
  photo?: string | null;
  privacy_state?: string | null;
  profile_link_uid?: string | null;
  pw_updated_at?: Date | null;
  race_ethnicity?: string | null;
  recording_autopost_enabled_at?: Date | null;
  recording_autopost_updated_at?: Date | null;
  roles?: string[] | null;
  salt?: any | null;
  scheduled_removal_at?: Date | null;
  seller_org_admin_teacher_account_uid?: string | null;
  signup_initiator?: string | null;
  skilljar_user_id?: string | null;
  slug_id?: string;
  stripe_customer_id?: string | null;
  subscribed_at?: Date | null;
  suspended_at?: Date | null;
  suspended_reason?: string | null;
  teacher_approved_at?: Date | null;
  teacher_availability?: any | null;
  teacher_featured_at?: Date | null;
  teacher_for_seller_org_user_uid?: string | null;
  uid?: string;
  unsubscribed_at?: Date | null;
  updated_at?: Date | null;
  video_uid?: string | null;
}
export interface WaitlistSeatsRow {
  created_at: Date;
  deleted_at: Date | null;
  enrollment_uid: string | null;
  learner_uid: string;
  parent_uid: string;
  section_uid: string;
  uid: string;
  updated_at: Date;
  waitlist_source: string | null;
}
export interface CreateWaitlistSeatsRow {
  created_at?: Date;
  deleted_at?: Date | null;
  enrollment_uid?: string | null;
  learner_uid: string;
  parent_uid: string;
  section_uid: string;
  uid?: string;
  updated_at?: Date;
  waitlist_source?: string | null;
}
export interface ZoomMeetingsRow {
  created_at: Date | null;
  deleted_at: Date | null;
  details: any | null;
  join_url: string | null;
  section_uid: string | null;
  uid: string;
  updated_at: Date | null;
  zoom_id: string | null;
  zoom_recordings_synced_at: Date | null;
  zoom_user_id: string | null;
}
export interface CreateZoomMeetingsRow {
  created_at?: Date | null;
  deleted_at?: Date | null;
  details?: any | null;
  join_url?: string | null;
  section_uid?: string | null;
  uid?: string;
  updated_at?: Date | null;
  zoom_id?: string | null;
  zoom_recordings_synced_at?: Date | null;
  zoom_user_id?: string | null;
}
export interface ZoomRecordingsRow {
  created_at: Date | null;
  deleted_at: Date | null;
  play_url: string;
  recording_ended_at: Date;
  recording_started_at: Date;
  recording_type: string | null;
  section_uid: string;
  status: string | null;
  uid: string;
  updated_at: Date | null;
  zoom_recording_id: string;
  zoom_response: any | null;
}
export interface CreateZoomRecordingsRow {
  created_at?: Date | null;
  deleted_at?: Date | null;
  play_url: string;
  recording_ended_at: Date;
  recording_started_at: Date;
  recording_type?: string | null;
  section_uid: string;
  status?: string | null;
  uid?: string;
  updated_at?: Date | null;
  zoom_recording_id: string;
  zoom_response?: any | null;
}
export enum refund_policy {
  variable = "variable",
  limited = "limited",
  moderate = "moderate",
  flexible = "flexible",
}
export enum english_proficiency_level {
  A0 = "A0",
  A05 = "A05",
  PRE_A1 = "PRE_A1",
  A1 = "A1",
  A2 = "A2",
  B1 = "B1",
  B2 = "B2",
}

declare module "knex/types/tables" {
  interface Tables {
    activities: Knex.CompositeTableType<
      ActivitiesRow,
      CreateActivitiesRow,
      Partial<Omit<ActivitiesRow, "uid">>
    >;
    activities_as_search_document: Knex.CompositeTableType<
      ActivitiesAsSearchDocumentRow,
      CreateActivitiesAsSearchDocumentRow,
      Partial<Omit<ActivitiesAsSearchDocumentRow, "uid">>
    >;
    activity_actions: Knex.CompositeTableType<
      ActivityActionsRow,
      CreateActivityActionsRow,
      Partial<Omit<ActivityActionsRow, "uid">>
    >;
    activity_external_resources: Knex.CompositeTableType<
      ActivityExternalResourcesRow,
      CreateActivityExternalResourcesRow,
      Partial<Omit<ActivityExternalResourcesRow, "uid">>
    >;
    activity_schedule_settings: Knex.CompositeTableType<
      ActivityScheduleSettingsRow,
      CreateActivityScheduleSettingsRow,
      Partial<Omit<ActivityScheduleSettingsRow, "uid">>
    >;
    activity_scores: Knex.CompositeTableType<
      ActivityScoresRow,
      CreateActivityScoresRow,
      Partial<Omit<ActivityScoresRow, "uid">>
    >;
    activity_similarity: Knex.CompositeTableType<
      ActivitySimilarityRow,
      CreateActivitySimilarityRow,
      Partial<Omit<ActivitySimilarityRow, "uid">>
    >;
    activity_stats: Knex.CompositeTableType<
      ActivityStatsRow,
      CreateActivityStatsRow,
      Partial<Omit<ActivityStatsRow, "uid">>
    >;
    activity_tags: Knex.CompositeTableType<
      ActivityTagsRow,
      CreateActivityTagsRow,
      Partial<Omit<ActivityTagsRow, "uid">>
    >;
    activity_topics: Knex.CompositeTableType<
      ActivityTopicsRow,
      CreateActivityTopicsRow,
      Partial<Omit<ActivityTopicsRow, "uid">>
    >;
    activity_translations: Knex.CompositeTableType<
      ActivityTranslationsRow,
      CreateActivityTranslationsRow,
      Partial<Omit<ActivityTranslationsRow, "uid">>
    >;
    admin_enrollment_payment_refund_actions: Knex.CompositeTableType<
      AdminEnrollmentPaymentRefundActionsRow,
      CreateAdminEnrollmentPaymentRefundActionsRow,
      Partial<Omit<AdminEnrollmentPaymentRefundActionsRow, "uid">>
    >;
    admin_user_actions: Knex.CompositeTableType<
      AdminUserActionsRow,
      CreateAdminUserActionsRow,
      Partial<Omit<AdminUserActionsRow, "uid">>
    >;
    agreement_templates: Knex.CompositeTableType<
      AgreementTemplatesRow,
      CreateAgreementTemplatesRow,
      Partial<Omit<AgreementTemplatesRow, "uid">>
    >;
    apple_users: Knex.CompositeTableType<
      AppleUsersRow,
      CreateAppleUsersRow,
      Partial<Omit<AppleUsersRow, "uid">>
    >;
    attachment_conversions: Knex.CompositeTableType<
      AttachmentConversionsRow,
      CreateAttachmentConversionsRow,
      Partial<Omit<AttachmentConversionsRow, "uid">>
    >;
    attachments: Knex.CompositeTableType<
      AttachmentsRow,
      CreateAttachmentsRow,
      Partial<Omit<AttachmentsRow, "uid">>
    >;
    attendances: Knex.CompositeTableType<
      AttendancesRow,
      CreateAttendancesRow,
      Partial<Omit<AttendancesRow, "uid">>
    >;
    bundle_elements: Knex.CompositeTableType<
      BundleElementsRow,
      CreateBundleElementsRow,
      Partial<Omit<BundleElementsRow, "uid">>
    >;
    bundles: Knex.CompositeTableType<
      BundlesRow,
      CreateBundlesRow,
      Partial<Omit<BundlesRow, "uid">>
    >;
    categories: Knex.CompositeTableType<
      CategoriesRow,
      CreateCategoriesRow,
      Partial<Omit<CategoriesRow, "uid">>
    >;
    category_actions: Knex.CompositeTableType<
      CategoryActionsRow,
      CreateCategoryActionsRow,
      Partial<Omit<CategoryActionsRow, "uid">>
    >;
    category_relationships: Knex.CompositeTableType<
      CategoryRelationshipsRow,
      CreateCategoryRelationshipsRow,
      Partial<Omit<CategoryRelationshipsRow, "uid">>
    >;
    charter_schools: Knex.CompositeTableType<
      CharterSchoolsRow,
      CreateCharterSchoolsRow,
      Partial<Omit<CharterSchoolsRow, "uid">>
    >;
    class_post_comments: Knex.CompositeTableType<
      ClassPostCommentsRow,
      CreateClassPostCommentsRow,
      Partial<Omit<ClassPostCommentsRow, "uid">>
    >;
    class_post_scores: Knex.CompositeTableType<
      ClassPostScoresRow,
      CreateClassPostScoresRow,
      Partial<Omit<ClassPostScoresRow, "uid">>
    >;
    class_posts: Knex.CompositeTableType<
      ClassPostsRow,
      CreateClassPostsRow,
      Partial<Omit<ClassPostsRow, "uid">>
    >;
    class_recordings: Knex.CompositeTableType<
      ClassRecordingsRow,
      CreateClassRecordingsRow,
      Partial<Omit<ClassRecordingsRow, "uid">>
    >;
    class_requests: Knex.CompositeTableType<
      ClassRequestsRow,
      CreateClassRequestsRow,
      Partial<Omit<ClassRequestsRow, "uid">>
    >;
    class_schedule_requests: Knex.CompositeTableType<
      ClassScheduleRequestsRow,
      CreateClassScheduleRequestsRow,
      Partial<Omit<ClassScheduleRequestsRow, "uid">>
    >;
    club_attributes: Knex.CompositeTableType<
      ClubAttributesRow,
      CreateClubAttributesRow,
      Partial<Omit<ClubAttributesRow, "uid">>
    >;
    coupon_activity_constraints: Knex.CompositeTableType<
      CouponActivityConstraintsRow,
      CreateCouponActivityConstraintsRow,
      Partial<Omit<CouponActivityConstraintsRow, "uid">>
    >;
    coupon_codes: Knex.CompositeTableType<
      CouponCodesRow,
      CreateCouponCodesRow,
      Partial<Omit<CouponCodesRow, "uid">>
    >;
    coupons: Knex.CompositeTableType<
      CouponsRow,
      CreateCouponsRow,
      Partial<Omit<CouponsRow, "uid">>
    >;
    credit_accounts: Knex.CompositeTableType<
      CreditAccountsRow,
      CreateCreditAccountsRow,
      Partial<Omit<CreditAccountsRow, "uid">>
    >;
    credit_purchases: Knex.CompositeTableType<
      CreditPurchasesRow,
      CreateCreditPurchasesRow,
      Partial<Omit<CreditPurchasesRow, "uid">>
    >;
    credit_transactions: Knex.CompositeTableType<
      CreditTransactionsRow,
      CreateCreditTransactionsRow,
      Partial<Omit<CreditTransactionsRow, "uid">>
    >;
    currency_exchange_rates: Knex.CompositeTableType<
      CurrencyExchangeRatesRow,
      CreateCurrencyExchangeRatesRow,
      Partial<Omit<CurrencyExchangeRatesRow, "uid">>
    >;
    deletion_log: Knex.CompositeTableType<
      DeletionLogRow,
      CreateDeletionLogRow,
      Partial<Omit<DeletionLogRow, "uid">>
    >;
    directory_membership: Knex.CompositeTableType<
      DirectoryMembershipRow,
      CreateDirectoryMembershipRow,
      Partial<Omit<DirectoryMembershipRow, "uid">>
    >;
    donations: Knex.CompositeTableType<
      DonationsRow,
      CreateDonationsRow,
      Partial<Omit<DonationsRow, "uid">>
    >;
    enrollment_meeting_purchases: Knex.CompositeTableType<
      EnrollmentMeetingPurchasesRow,
      CreateEnrollmentMeetingPurchasesRow,
      Partial<Omit<EnrollmentMeetingPurchasesRow, "uid">>
    >;
    enrollment_pause_windows: Knex.CompositeTableType<
      EnrollmentPauseWindowsRow,
      CreateEnrollmentPauseWindowsRow,
      Partial<Omit<EnrollmentPauseWindowsRow, "uid">>
    >;
    enrollment_payment_refunds: Knex.CompositeTableType<
      EnrollmentPaymentRefundsRow,
      CreateEnrollmentPaymentRefundsRow,
      Partial<Omit<EnrollmentPaymentRefundsRow, "uid">>
    >;
    enrollment_payments: Knex.CompositeTableType<
      EnrollmentPaymentsRow,
      CreateEnrollmentPaymentsRow,
      Partial<Omit<EnrollmentPaymentsRow, "uid">>
    >;
    enrollments: Knex.CompositeTableType<
      EnrollmentsRow,
      CreateEnrollmentsRow,
      Partial<Omit<EnrollmentsRow, "uid">>
    >;
    entity_attributes: Knex.CompositeTableType<
      EntityAttributesRow,
      CreateEntityAttributesRow,
      Partial<Omit<EntityAttributesRow, "uid">>
    >;
    entity_availabilities: Knex.CompositeTableType<
      EntityAvailabilitiesRow,
      CreateEntityAvailabilitiesRow,
      Partial<Omit<EntityAvailabilitiesRow, "uid">>
    >;
    entity_availability_schedules: Knex.CompositeTableType<
      EntityAvailabilitySchedulesRow,
      CreateEntityAvailabilitySchedulesRow,
      Partial<Omit<EntityAvailabilitySchedulesRow, "uid">>
    >;
    experiment_overrides: Knex.CompositeTableType<
      ExperimentOverridesRow,
      CreateExperimentOverridesRow,
      Partial<Omit<ExperimentOverridesRow, "uid">>
    >;
    experiments: Knex.CompositeTableType<
      ExperimentsRow,
      CreateExperimentsRow,
      Partial<Omit<ExperimentsRow, "uid">>
    >;
    external_activities: Knex.CompositeTableType<
      ExternalActivitiesRow,
      CreateExternalActivitiesRow,
      Partial<Omit<ExternalActivitiesRow, "uid">>
    >;
    external_resource_policies: Knex.CompositeTableType<
      ExternalResourcePoliciesRow,
      CreateExternalResourcePoliciesRow,
      Partial<Omit<ExternalResourcePoliciesRow, "uid">>
    >;
    facebook_users: Knex.CompositeTableType<
      FacebookUsersRow,
      CreateFacebookUsersRow,
      Partial<Omit<FacebookUsersRow, "uid">>
    >;
    failed_content_security_checks: Knex.CompositeTableType<
      FailedContentSecurityChecksRow,
      CreateFailedContentSecurityChecksRow,
      Partial<Omit<FailedContentSecurityChecksRow, "uid">>
    >;
    failed_inbound_messages: Knex.CompositeTableType<
      FailedInboundMessagesRow,
      CreateFailedInboundMessagesRow,
      Partial<Omit<FailedInboundMessagesRow, "uid">>
    >;
    failed_outbound_messages: Knex.CompositeTableType<
      FailedOutboundMessagesRow,
      CreateFailedOutboundMessagesRow,
      Partial<Omit<FailedOutboundMessagesRow, "uid">>
    >;
    feedback: Knex.CompositeTableType<
      FeedbackRow,
      CreateFeedbackRow,
      Partial<Omit<FeedbackRow, "uid">>
    >;
    feedback_votes: Knex.CompositeTableType<
      FeedbackVotesRow,
      CreateFeedbackVotesRow,
      Partial<Omit<FeedbackVotesRow, "uid">>
    >;
    filestack_metadata: Knex.CompositeTableType<
      FilestackMetadataRow,
      CreateFilestackMetadataRow,
      Partial<Omit<FilestackMetadataRow, "uid">>
    >;
    followed_users: Knex.CompositeTableType<
      FollowedUsersRow,
      CreateFollowedUsersRow,
      Partial<Omit<FollowedUsersRow, "uid">>
    >;
    friend_requests: Knex.CompositeTableType<
      FriendRequestsRow,
      CreateFriendRequestsRow,
      Partial<Omit<FriendRequestsRow, "uid">>
    >;
    friendships: Knex.CompositeTableType<
      FriendshipsRow,
      CreateFriendshipsRow,
      Partial<Omit<FriendshipsRow, "uid">>
    >;
    funding_program_invoices: Knex.CompositeTableType<
      FundingProgramInvoicesRow,
      CreateFundingProgramInvoicesRow,
      Partial<Omit<FundingProgramInvoicesRow, "uid">>
    >;
    gift_cards: Knex.CompositeTableType<
      GiftCardsRow,
      CreateGiftCardsRow,
      Partial<Omit<GiftCardsRow, "uid">>
    >;
    google_users: Knex.CompositeTableType<
      GoogleUsersRow,
      CreateGoogleUsersRow,
      Partial<Omit<GoogleUsersRow, "uid">>
    >;
    holidays: Knex.CompositeTableType<
      HolidaysRow,
      CreateHolidaysRow,
      Partial<Omit<HolidaysRow, "uid">>
    >;
    invoices: Knex.CompositeTableType<
      InvoicesRow,
      CreateInvoicesRow,
      Partial<Omit<InvoicesRow, "uid">>
    >;
    known_spam_users: Knex.CompositeTableType<
      KnownSpamUsersRow,
      CreateKnownSpamUsersRow,
      Partial<Omit<KnownSpamUsersRow, "uid">>
    >;
    learner_allowance_payments: Knex.CompositeTableType<
      LearnerAllowancePaymentsRow,
      CreateLearnerAllowancePaymentsRow,
      Partial<Omit<LearnerAllowancePaymentsRow, "uid">>
    >;
    learner_allowances: Knex.CompositeTableType<
      LearnerAllowancesRow,
      CreateLearnerAllowancesRow,
      Partial<Omit<LearnerAllowancesRow, "uid">>
    >;
    learner_attributes: Knex.CompositeTableType<
      LearnerAttributesRow,
      CreateLearnerAttributesRow,
      Partial<Omit<LearnerAttributesRow, "uid">>
    >;
    learner_behavior_reports: Knex.CompositeTableType<
      LearnerBehaviorReportsRow,
      CreateLearnerBehaviorReportsRow,
      Partial<Omit<LearnerBehaviorReportsRow, "uid">>
    >;
    learner_devices: Knex.CompositeTableType<
      LearnerDevicesRow,
      CreateLearnerDevicesRow,
      Partial<Omit<LearnerDevicesRow, "uid">>
    >;
    learner_enrollment_requests: Knex.CompositeTableType<
      LearnerEnrollmentRequestsRow,
      CreateLearnerEnrollmentRequestsRow,
      Partial<Omit<LearnerEnrollmentRequestsRow, "uid">>
    >;
    learner_interest_topics: Knex.CompositeTableType<
      LearnerInterestTopicsRow,
      CreateLearnerInterestTopicsRow,
      Partial<Omit<LearnerInterestTopicsRow, "uid">>
    >;
    learner_interface_preferences: Knex.CompositeTableType<
      LearnerInterfacePreferencesRow,
      CreateLearnerInterfacePreferencesRow,
      Partial<Omit<LearnerInterfacePreferencesRow, "uid">>
    >;
    learner_meeting_summary: Knex.CompositeTableType<
      LearnerMeetingSummaryRow,
      CreateLearnerMeetingSummaryRow,
      Partial<Omit<LearnerMeetingSummaryRow, "uid">>
    >;
    learner_plan_activities: Knex.CompositeTableType<
      LearnerPlanActivitiesRow,
      CreateLearnerPlanActivitiesRow,
      Partial<Omit<LearnerPlanActivitiesRow, "uid">>
    >;
    learner_plan_goal_filters: Knex.CompositeTableType<
      LearnerPlanGoalFiltersRow,
      CreateLearnerPlanGoalFiltersRow,
      Partial<Omit<LearnerPlanGoalFiltersRow, "uid">>
    >;
    learner_plan_goals: Knex.CompositeTableType<
      LearnerPlanGoalsRow,
      CreateLearnerPlanGoalsRow,
      Partial<Omit<LearnerPlanGoalsRow, "uid">>
    >;
    learner_plan_rejected_suggestions: Knex.CompositeTableType<
      LearnerPlanRejectedSuggestionsRow,
      CreateLearnerPlanRejectedSuggestionsRow,
      Partial<Omit<LearnerPlanRejectedSuggestionsRow, "uid">>
    >;
    learner_plans: Knex.CompositeTableType<
      LearnerPlansRow,
      CreateLearnerPlansRow,
      Partial<Omit<LearnerPlansRow, "uid">>
    >;
    learner_search_guardrails: Knex.CompositeTableType<
      LearnerSearchGuardrailsRow,
      CreateLearnerSearchGuardrailsRow,
      Partial<Omit<LearnerSearchGuardrailsRow, "uid">>
    >;
    learner_section_reviews: Knex.CompositeTableType<
      LearnerSectionReviewsRow,
      CreateLearnerSectionReviewsRow,
      Partial<Omit<LearnerSectionReviewsRow, "uid">>
    >;
    learner_unlockables: Knex.CompositeTableType<
      LearnerUnlockablesRow,
      CreateLearnerUnlockablesRow,
      Partial<Omit<LearnerUnlockablesRow, "uid">>
    >;
    learners: Knex.CompositeTableType<
      LearnersRow,
      CreateLearnersRow,
      Partial<Omit<LearnersRow, "uid">>
    >;
    learning_actors: Knex.CompositeTableType<
      LearningActorsRow,
      CreateLearningActorsRow,
      Partial<Omit<LearningActorsRow, "uid">>
    >;
    learning_assignment_class_post_comments: Knex.CompositeTableType<
      LearningAssignmentClassPostCommentsRow,
      CreateLearningAssignmentClassPostCommentsRow,
      Partial<Omit<LearningAssignmentClassPostCommentsRow, "uid">>
    >;
    learning_assignment_class_posts: Knex.CompositeTableType<
      LearningAssignmentClassPostsRow,
      CreateLearningAssignmentClassPostsRow,
      Partial<Omit<LearningAssignmentClassPostsRow, "uid">>
    >;
    learning_assignment_lesson_posts: Knex.CompositeTableType<
      LearningAssignmentLessonPostsRow,
      CreateLearningAssignmentLessonPostsRow,
      Partial<Omit<LearningAssignmentLessonPostsRow, "uid">>
    >;
    learning_lessons: Knex.CompositeTableType<
      LearningLessonsRow,
      CreateLearningLessonsRow,
      Partial<Omit<LearningLessonsRow, "uid">>
    >;
    learning_statements: Knex.CompositeTableType<
      LearningStatementsRow,
      CreateLearningStatementsRow,
      Partial<Omit<LearningStatementsRow, "uid">>
    >;
    learning_syllabuses: Knex.CompositeTableType<
      LearningSyllabusesRow,
      CreateLearningSyllabusesRow,
      Partial<Omit<LearningSyllabusesRow, "uid">>
    >;
    learning_units: Knex.CompositeTableType<
      LearningUnitsRow,
      CreateLearningUnitsRow,
      Partial<Omit<LearningUnitsRow, "uid">>
    >;
    lesson_posts: Knex.CompositeTableType<
      LessonPostsRow,
      CreateLessonPostsRow,
      Partial<Omit<LessonPostsRow, "uid">>
    >;
    meeting_requests: Knex.CompositeTableType<
      MeetingRequestsRow,
      CreateMeetingRequestsRow,
      Partial<Omit<MeetingRequestsRow, "uid">>
    >;
    meetings: Knex.CompositeTableType<
      MeetingsRow,
      CreateMeetingsRow,
      Partial<Omit<MeetingsRow, "uid">>
    >;
    message_contents: Knex.CompositeTableType<
      MessageContentsRow,
      CreateMessageContentsRow,
      Partial<Omit<MessageContentsRow, "uid">>
    >;
    message_read_events: Knex.CompositeTableType<
      MessageReadEventsRow,
      CreateMessageReadEventsRow,
      Partial<Omit<MessageReadEventsRow, "uid">>
    >;
    message_spam_markers: Knex.CompositeTableType<
      MessageSpamMarkersRow,
      CreateMessageSpamMarkersRow,
      Partial<Omit<MessageSpamMarkersRow, "uid">>
    >;
    messages: Knex.CompositeTableType<
      MessagesRow,
      CreateMessagesRow,
      Partial<Omit<MessagesRow, "uid">>
    >;
    notifications: Knex.CompositeTableType<
      NotificationsRow,
      CreateNotificationsRow,
      Partial<Omit<NotificationsRow, "uid">>
    >;
    oidc_users: Knex.CompositeTableType<
      OidcUsersRow,
      CreateOidcUsersRow,
      Partial<Omit<OidcUsersRow, "uid">>
    >;
    order_payments: Knex.CompositeTableType<
      OrderPaymentsRow,
      CreateOrderPaymentsRow,
      Partial<Omit<OrderPaymentsRow, "uid">>
    >;
    organizations: Knex.CompositeTableType<
      OrganizationsRow,
      CreateOrganizationsRow,
      Partial<Omit<OrganizationsRow, "uid">>
    >;
    other_user_emails: Knex.CompositeTableType<
      OtherUserEmailsRow,
      CreateOtherUserEmailsRow,
      Partial<Omit<OtherUserEmailsRow, "uid">>
    >;
    parsed_class_recordings: Knex.CompositeTableType<
      ParsedClassRecordingsRow,
      CreateParsedClassRecordingsRow,
      Partial<Omit<ParsedClassRecordingsRow, "uid">>
    >;
    payment_schedule_payments: Knex.CompositeTableType<
      PaymentSchedulePaymentsRow,
      CreatePaymentSchedulePaymentsRow,
      Partial<Omit<PaymentSchedulePaymentsRow, "uid">>
    >;
    payment_schedule_payments_failures: Knex.CompositeTableType<
      PaymentSchedulePaymentsFailuresRow,
      CreatePaymentSchedulePaymentsFailuresRow,
      Partial<Omit<PaymentSchedulePaymentsFailuresRow, "uid">>
    >;
    payment_schedules: Knex.CompositeTableType<
      PaymentSchedulesRow,
      CreatePaymentSchedulesRow,
      Partial<Omit<PaymentSchedulesRow, "uid">>
    >;
    payout_batch_attempts: Knex.CompositeTableType<
      PayoutBatchAttemptsRow,
      CreatePayoutBatchAttemptsRow,
      Partial<Omit<PayoutBatchAttemptsRow, "uid">>
    >;
    payout_sources: Knex.CompositeTableType<
      PayoutSourcesRow,
      CreatePayoutSourcesRow,
      Partial<Omit<PayoutSourcesRow, "uid">>
    >;
    payouts: Knex.CompositeTableType<
      PayoutsRow,
      CreatePayoutsRow,
      Partial<Omit<PayoutsRow, "uid">>
    >;
    pricing_adjustments: Knex.CompositeTableType<
      PricingAdjustmentsRow,
      CreatePricingAdjustmentsRow,
      Partial<Omit<PricingAdjustmentsRow, "uid">>
    >;
    pricing_offer_email_addresses: Knex.CompositeTableType<
      PricingOfferEmailAddressesRow,
      CreatePricingOfferEmailAddressesRow,
      Partial<Omit<PricingOfferEmailAddressesRow, "uid">>
    >;
    pricing_offer_email_domains: Knex.CompositeTableType<
      PricingOfferEmailDomainsRow,
      CreatePricingOfferEmailDomainsRow,
      Partial<Omit<PricingOfferEmailDomainsRow, "uid">>
    >;
    pricing_offers: Knex.CompositeTableType<
      PricingOffersRow,
      CreatePricingOffersRow,
      Partial<Omit<PricingOffersRow, "uid">>
    >;
    private_class_messages: Knex.CompositeTableType<
      PrivateClassMessagesRow,
      CreatePrivateClassMessagesRow,
      Partial<Omit<PrivateClassMessagesRow, "uid">>
    >;
    private_learner_notes: Knex.CompositeTableType<
      PrivateLearnerNotesRow,
      CreatePrivateLearnerNotesRow,
      Partial<Omit<PrivateLearnerNotesRow, "uid">>
    >;
    saved_activities: Knex.CompositeTableType<
      SavedActivitiesRow,
      CreateSavedActivitiesRow,
      Partial<Omit<SavedActivitiesRow, "uid">>
    >;
    saved_class_lists: Knex.CompositeTableType<
      SavedClassListsRow,
      CreateSavedClassListsRow,
      Partial<Omit<SavedClassListsRow, "uid">>
    >;
    saved_searches: Knex.CompositeTableType<
      SavedSearchesRow,
      CreateSavedSearchesRow,
      Partial<Omit<SavedSearchesRow, "uid">>
    >;
    section_moderators: Knex.CompositeTableType<
      SectionModeratorsRow,
      CreateSectionModeratorsRow,
      Partial<Omit<SectionModeratorsRow, "uid">>
    >;
    section_recurring_meetings: Knex.CompositeTableType<
      SectionRecurringMeetingsRow,
      CreateSectionRecurringMeetingsRow,
      Partial<Omit<SectionRecurringMeetingsRow, "uid">>
    >;
    section_recurring_schedules: Knex.CompositeTableType<
      SectionRecurringSchedulesRow,
      CreateSectionRecurringSchedulesRow,
      Partial<Omit<SectionRecurringSchedulesRow, "uid">>
    >;
    sections: Knex.CompositeTableType<
      SectionsRow,
      CreateSectionsRow,
      Partial<Omit<SectionsRow, "uid">>
    >;
    sections_availability: Knex.CompositeTableType<
      SectionsAvailabilityRow,
      CreateSectionsAvailabilityRow,
      Partial<Omit<SectionsAvailabilityRow, "uid">>
    >;
    seller_org_agreements: Knex.CompositeTableType<
      SellerOrgAgreementsRow,
      CreateSellerOrgAgreementsRow,
      Partial<Omit<SellerOrgAgreementsRow, "uid">>
    >;
    seller_org_applications: Knex.CompositeTableType<
      SellerOrgApplicationsRow,
      CreateSellerOrgApplicationsRow,
      Partial<Omit<SellerOrgApplicationsRow, "uid">>
    >;
    seller_orgs: Knex.CompositeTableType<
      SellerOrgsRow,
      CreateSellerOrgsRow,
      Partial<Omit<SellerOrgsRow, "uid">>
    >;
    sent_notifications: Knex.CompositeTableType<
      SentNotificationsRow,
      CreateSentNotificationsRow,
      Partial<Omit<SentNotificationsRow, "uid">>
    >;
    teacher_activity_stats: Knex.CompositeTableType<
      TeacherActivityStatsRow,
      CreateTeacherActivityStatsRow,
      Partial<Omit<TeacherActivityStatsRow, "uid">>
    >;
    teacher_applications: Knex.CompositeTableType<
      TeacherApplicationsRow,
      CreateTeacherApplicationsRow,
      Partial<Omit<TeacherApplicationsRow, "uid">>
    >;
    teacher_attendances: Knex.CompositeTableType<
      TeacherAttendancesRow,
      CreateTeacherAttendancesRow,
      Partial<Omit<TeacherAttendancesRow, "uid">>
    >;
    teacher_attributes: Knex.CompositeTableType<
      TeacherAttributesRow,
      CreateTeacherAttributesRow,
      Partial<Omit<TeacherAttributesRow, "uid">>
    >;
    teacher_credentials: Knex.CompositeTableType<
      TeacherCredentialsRow,
      CreateTeacherCredentialsRow,
      Partial<Omit<TeacherCredentialsRow, "uid">>
    >;
    teacher_schedule_availability: Knex.CompositeTableType<
      TeacherScheduleAvailabilityRow,
      CreateTeacherScheduleAvailabilityRow,
      Partial<Omit<TeacherScheduleAvailabilityRow, "uid">>
    >;
    teacher_schedule_enrollable_times: Knex.CompositeTableType<
      TeacherScheduleEnrollableTimesRow,
      CreateTeacherScheduleEnrollableTimesRow,
      Partial<Omit<TeacherScheduleEnrollableTimesRow, "uid">>
    >;
    teacher_schedules: Knex.CompositeTableType<
      TeacherSchedulesRow,
      CreateTeacherSchedulesRow,
      Partial<Omit<TeacherSchedulesRow, "uid">>
    >;
    teacher_similarity: Knex.CompositeTableType<
      TeacherSimilarityRow,
      CreateTeacherSimilarityRow,
      Partial<Omit<TeacherSimilarityRow, "uid">>
    >;
    teacher_stats: Knex.CompositeTableType<
      TeacherStatsRow,
      CreateTeacherStatsRow,
      Partial<Omit<TeacherStatsRow, "uid">>
    >;
    teacher_tags: Knex.CompositeTableType<
      TeacherTagsRow,
      CreateTeacherTagsRow,
      Partial<Omit<TeacherTagsRow, "uid">>
    >;
    teacher_tags_assigned: Knex.CompositeTableType<
      TeacherTagsAssignedRow,
      CreateTeacherTagsAssignedRow,
      Partial<Omit<TeacherTagsAssignedRow, "uid">>
    >;
    teacher_unavailable_blocks: Knex.CompositeTableType<
      TeacherUnavailableBlocksRow,
      CreateTeacherUnavailableBlocksRow,
      Partial<Omit<TeacherUnavailableBlocksRow, "uid">>
    >;
    temporary_tokens: Knex.CompositeTableType<
      TemporaryTokensRow,
      CreateTemporaryTokensRow,
      Partial<Omit<TemporaryTokensRow, "uid">>
    >;
    topics: Knex.CompositeTableType<
      TopicsRow,
      CreateTopicsRow,
      Partial<Omit<TopicsRow, "uid">>
    >;
    trending_search_topics: Knex.CompositeTableType<
      TrendingSearchTopicsRow,
      CreateTrendingSearchTopicsRow,
      Partial<Omit<TrendingSearchTopicsRow, "uid">>
    >;
    trust_privileges: Knex.CompositeTableType<
      TrustPrivilegesRow,
      CreateTrustPrivilegesRow,
      Partial<Omit<TrustPrivilegesRow, "uid">>
    >;
    upcoming_sections: Knex.CompositeTableType<
      UpcomingSectionsRow,
      CreateUpcomingSectionsRow,
      Partial<Omit<UpcomingSectionsRow, "uid">>
    >;
    user_churn_indicators: Knex.CompositeTableType<
      UserChurnIndicatorsRow,
      CreateUserChurnIndicatorsRow,
      Partial<Omit<UserChurnIndicatorsRow, "uid">>
    >;
    user_email_preferences: Knex.CompositeTableType<
      UserEmailPreferencesRow,
      CreateUserEmailPreferencesRow,
      Partial<Omit<UserEmailPreferencesRow, "uid">>
    >;
    user_financial_assistance: Knex.CompositeTableType<
      UserFinancialAssistanceRow,
      CreateUserFinancialAssistanceRow,
      Partial<Omit<UserFinancialAssistanceRow, "uid">>
    >;
    user_incidents: Knex.CompositeTableType<
      UserIncidentsRow,
      CreateUserIncidentsRow,
      Partial<Omit<UserIncidentsRow, "uid">>
    >;
    user_login_attempts_log: Knex.CompositeTableType<
      UserLoginAttemptsLogRow,
      CreateUserLoginAttemptsLogRow,
      Partial<Omit<UserLoginAttemptsLogRow, "uid">>
    >;
    user_pricing_offers: Knex.CompositeTableType<
      UserPricingOffersRow,
      CreateUserPricingOffersRow,
      Partial<Omit<UserPricingOffersRow, "uid">>
    >;
    user_quiz_answers: Knex.CompositeTableType<
      UserQuizAnswersRow,
      CreateUserQuizAnswersRow,
      Partial<Omit<UserQuizAnswersRow, "uid">>
    >;
    users: Knex.CompositeTableType<
      UsersRow,
      CreateUsersRow,
      Partial<Omit<UsersRow, "uid">>
    >;
    waitlist_seats: Knex.CompositeTableType<
      WaitlistSeatsRow,
      CreateWaitlistSeatsRow,
      Partial<Omit<WaitlistSeatsRow, "uid">>
    >;
    zoom_meetings: Knex.CompositeTableType<
      ZoomMeetingsRow,
      CreateZoomMeetingsRow,
      Partial<Omit<ZoomMeetingsRow, "uid">>
    >;
    zoom_recordings: Knex.CompositeTableType<
      ZoomRecordingsRow,
      CreateZoomRecordingsRow,
      Partial<Omit<ZoomRecordingsRow, "uid">>
    >;
  }
}
