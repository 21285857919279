import { Box, Icon, SxProps, Typography, Theme } from "@outschool/backpack";
import { faCalendar, faCalendarXmark } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { formatDateTime } from "@outschool/time";
import UnitNumber from "./UnitNumber";

import React from "react";
type SectionLessonHeaderProps = {
  title: string;
  chipComponent?: React.ReactNode;
  unitNumber?: number | null;
  unitTitle?: string | null;
  showMeetingDate?: boolean;
  sx?: SxProps;
} & MeetingDateAndTimeZone;

// If meetingDate is provided, timeZone must also be provided.
type MeetingDateAndTimeZone =
  | {
      meetingDate?: never;
      timeZone?: never;
      isCancelled?: never;
    }
  | {
      meetingDate: Date | null;
      timeZone: string;
      isCancelled: boolean;
    };

export default function SectionLessonHeader({
  meetingDate,
  title,
  chipComponent,
  timeZone,
  isCancelled,
  showMeetingDate = false,
  unitNumber,
  unitTitle,
  sx,
}: SectionLessonHeaderProps) {
  const { t } = useTranslation(
    "ui-components-classroom\\components\\syllabus\\SectionLessonHeader"
  );
  const showUnitNumber = unitNumber !== null && unitNumber !== undefined;
  return (
    <Box
      sx={(_theme: Theme) => [
        {
          display: "flex",
          gap: "1em",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={(theme: Theme) => ({
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "1em",
          [theme.breakpoints.up("sm")]: {
            alignItems: "center",
          },
          [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            justifyContent: "space-between",
          },
          [theme.breakpoints.up("lg")]: {
            flexDirection: "row",
            justifyContent: "flex-start",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            maxWidth: "100%",
          }}
        >
          <Typography variant="h5" emphasized>
            {title}
          </Typography>
          {chipComponent}
        </Box>
        {Boolean(unitTitle && showUnitNumber) && (
          <Box
            sx={{ alignSelf: "flex-start", maxWidth: "50%", minWidth: "20%" }}
          >
            <UnitNumber unitNumber={unitNumber} unitTitle={unitTitle} />
          </Box>
        )}
      </Box>
      {showMeetingDate && (
        <Box
          sx={{
            ...(isCancelled
              ? { color: "error" }
              : !meetingDate
              ? { color: "grey.500" }
              : {}),
          }}
        >
          <Icon
            icon={!isCancelled ? faCalendar : faCalendarXmark}
            w={5}
            h={5}
          />{" "}
          <Typography
            variant="body2"
            {...(isCancelled ? { sx: { textDecoration: `line-through` } } : {})}
          >
            {meetingDate
              ? t("Meeting: {{meetingDate}}", {
                  meetingDate: formatDateTime(meetingDate, timeZone, true),
                })
              : t("No meeting scheduled")}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
