import {
  Box,
  Button,
  Collapse,
  Divider,
  Typography,
} from "@outschool/backpack";
import {
  AssignmentCommentFragment,
  AssignmentCommentThreadFragment,
} from "@outschool/gql-frontend-generated";
import { faComment } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import * as Time from "@outschool/time";
import { gql } from "@outschool/ui-apollo";
import {
  InlineAttachments,
  UserHeadshotImage,
} from "@outschool/ui-components-shared";
import { LegacyThemeProvider } from "@outschool/ui-legacy-component-library";
import React from "react";

import { ClassPostSenderFragment, MessageContentFragment } from "../../graphql";
import {
  ClassPostCommentContent,
  ClassPostCommentSenderPhotoAndPublishDate,
} from "../classroom";
import { PostSummaryItem } from "../syllabus/PostSummaryItem";
import { AssignmentMessageContent } from "./AssignmentMessageContent";

interface AssignmentSubmissionThreadProps {
  submission: AssignmentCommentThreadFragment;
  timeZone?: string;
}
export const AssignmentSubmissionThread = ({
  submission,
  timeZone,
}: AssignmentSubmissionThreadProps) => {
  const { sender, sentAt, messageContent, replies } = submission;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        backgroundColor: "neutral.50",
        borderRadius: 6,
        padding: 16,
        marginTop: 12,
      }}
    >
      <LearnerSubmissionThreadHeader sender={sender} sentAt={sentAt} />
      <AssignmentMessageContent messageContent={messageContent} />
      <InlineAttachments
        attachments={[
          ...(messageContent.attachments ?? []),
          ...(messageContent.video ? [messageContent.video] : []),
        ]}
      />
      <Divider />
      <LearnerSubmissionThreadReplies
        replies={replies || []}
        timeZone={timeZone}
      />
    </Box>
  );
};
AssignmentSubmissionThread.fragments = {} as {
  assignmentComment: any;
  assignmentCommentThread: any;
};
AssignmentSubmissionThread.fragments.assignmentComment = gql`
  fragment AssignmentComment on AssignmentClassPostComment {
    uid
    sender {
      ...ClassPost_sender
    }
    sentAt
    messageContent {
      ...MessageContentFragment
    }
    threadForLearnerUid
    isSubmission
  }
  ${MessageContentFragment}
  ${ClassPostSenderFragment}
`;
AssignmentSubmissionThread.fragments.assignmentCommentThread = gql`
  fragment AssignmentCommentThread on AssignmentClassPostComment {
    ...AssignmentComment
    replies {
      ...AssignmentComment
    }
  }
  ${AssignmentSubmissionThread.fragments.assignmentComment}
`;

interface LearnerSubmissionThreadHeaderProps {
  sender: AssignmentCommentThreadFragment["sender"];
  sentAt: string;
}
const LearnerSubmissionThreadHeader = ({
  sender,
  sentAt,
}: LearnerSubmissionThreadHeaderProps) => (
  <Box
    sx={{
      display: "flex",
      gap: 24,
      alignItems: "center",
    }}
  >
    <LegacyThemeProvider>
      <UserHeadshotImage user={sender} size={50} />
    </LegacyThemeProvider>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6">{sender.name}</Typography>
      <Typography variant="body1">
        {Time.formatDateTimeWithFullWeekday(sentAt)}
      </Typography>
    </Box>
  </Box>
);

interface LearnerSubmissionThreadRepliesProps {
  replies: AssignmentCommentFragment[];
  timeZone?: string;
}
const LearnerSubmissionThreadReplies = ({
  replies,
  timeZone,
}: LearnerSubmissionThreadRepliesProps) => {
  const { t } = useTranslation(
    "ui-components-classroom\\assignments\\AssignmentSubmissionThread"
  );
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <Box>
      <PostSummaryItem
        component={Button}
        variant="link"
        size="small"
        color="inherit"
        icon={faComment}
        label={t("{{numberOfComments}} comment", {
          numberOfComments: replies ? replies.length : 0,
          count: replies ? replies.length : 0,
          defaultValue_plural: "{{numberOfComments}} comments",
        })}
        onClick={() => setIsExpanded(!isExpanded)}
        disabled={!replies || replies.length === 0}
      />
      <Collapse in={isExpanded}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 16,
            marginTop: 16,
          }}
        >
          {replies?.map((reply: any) => (
            <LearnerSubmisionThreadComment
              key={reply.uid}
              comment={reply}
              timeZone={timeZone}
            />
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

interface LearnerSubmisionThreadCommentProps {
  comment: AssignmentCommentFragment;
  timeZone?: string;
}
const LearnerSubmisionThreadComment = ({
  comment,
  timeZone,
}: LearnerSubmisionThreadCommentProps) => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    <LegacyThemeProvider>
      <ClassPostCommentSenderPhotoAndPublishDate
        sender={comment.sender}
        publishedAt={comment.sentAt}
        timeZone={timeZone}
      />
      <ClassPostCommentContent
        sender={comment.sender}
        messageContent={comment.messageContent}
        isEducator
      />
    </LegacyThemeProvider>
  </Box>
);
