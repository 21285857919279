import {
  BaseMutationOptions,
  gql,
  useMutation,
  useQueryWithPreviousData,
} from "@outschool/ui-apollo";

import {
  CurrentLearnerPastActivitiesQuery,
  CurrentLearnerPastActivitiesQueryVariables,
  LearnerProfileQuery,
  LearnerProfileQueryVariables,
  SaveFavoriteClassToProfileMutation,
  SaveFavoriteClassToProfileMutationVariables,
  SaveLearnerProfileMutation,
  SaveLearnerProfileMutationVariables,
  SaveLearnerProfileSettingsMutation,
  SaveLearnerProfileSettingsMutationVariables,
} from "..";

export const learnerProfileQuery = gql`
  query LearnerProfile($learnerUid: ID!) {
    learnerProfile(learnerUid: $learnerUid) {
      uid
      name
      age
      avatar
      unlockedAvatars
      pronoun
      aboutMe
      location {
        state
        country
      }
      isProfileAgeHidden
      isProfileLocationHidden
      favoritedClassList {
        listings(searchFilters: {}, offset: 0, limit: 3) {
          activity {
            uid
            title
            slug_id
            age_min
            age_max
            isClub
            leader {
              uid
              name
              photo
              leader_link
              details {
                headline
              }
            }
            details {
              photo
            }
            video {
              uid
              file {
                url
              }
            }
            videoForLearners {
              uid
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export function useLearnerProfileQuery(learnerUid: string) {
  const { loading, error, data } = useQueryWithPreviousData<
    LearnerProfileQuery,
    LearnerProfileQueryVariables
  >(learnerProfileQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      learnerUid,
    },
  });

  return {
    loading,
    error,
    profile: data?.learnerProfile,
  };
}

export const currentLearnerPastActivitiesQuery = gql`
  query CurrentLearnerPastActivities($offset: Int, $limit: Int) {
    currentLearnerPastActivities(offset: $offset, limit: $limit) {
      totalResults
      activities {
        uid
        title
        slug_id
        age_min
        age_max
        isClub
        leader {
          uid
          name
          photo
          leader_link
          details {
            headline
          }
        }
        details {
          photo
        }
        video {
          uid
          file {
            url
          }
        }
        videoForLearners {
          uid
          file {
            url
          }
        }
      }
    }
  }
`;

interface UseCurrentLearnerPastActivitiesQuery {
  offset?: number;
  limit?: number;
}

export function useCurrentLearnerPastActivitiesQuery({
  offset,
  limit,
}: UseCurrentLearnerPastActivitiesQuery) {
  const { loading, error, data } = useQueryWithPreviousData<
    CurrentLearnerPastActivitiesQuery,
    CurrentLearnerPastActivitiesQueryVariables
  >(currentLearnerPastActivitiesQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      offset,
      limit,
    },
  });

  return {
    loading,
    error,
    totalResults: data?.currentLearnerPastActivities.totalResults ?? 0,
    activities: data?.currentLearnerPastActivities.activities ?? [],
  };
}

export const saveFavoriteClassesMutation = gql`
  mutation SaveFavoriteClassToProfile($activityUids: [ID!]!) {
    saveLearnerProfileClasses(activityUids: $activityUids) {
      listings(searchFilters: {}, offset: 0, limit: 3) {
        activity {
          uid
          title
          age_min
          age_max
          isClub
          leader {
            uid
            name
            photo
            leader_link
            details {
              headline
            }
          }
          details {
            photo
          }
          video {
            uid
            file {
              url
            }
          }
          videoForLearners {
            uid
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export function useSaveFavoriteClassesMutation() {
  return useMutation<
    SaveFavoriteClassToProfileMutation,
    SaveFavoriteClassToProfileMutationVariables
  >(saveFavoriteClassesMutation);
}

export const saveProfileMutation = gql`
  mutation SaveLearnerProfile($profileInput: LearnerProfileInput!) {
    saveLearnerProfile(learnerProfile: $profileInput) {
      uid
      avatar
      aboutMe
    }
  }
`;

export function useSaveLearnerProfileMutation() {
  return useMutation<
    SaveLearnerProfileMutation,
    SaveLearnerProfileMutationVariables
  >(saveProfileMutation);
}

export const saveLearnerProfileSettingsMutation = gql`
  mutation SaveLearnerProfileSettings(
    $learnerProfileSettingsInput: LearnerProfileSettingsInput!
  ) {
    saveLearnerProfileSettings(
      learnerProfileSettings: $learnerProfileSettingsInput
    )
  }
`;

export function useSaveLearnerProfileSettingsMutation({
  onCompleted,
}: BaseMutationOptions) {
  return useMutation<
    SaveLearnerProfileSettingsMutation,
    SaveLearnerProfileSettingsMutationVariables
  >(saveLearnerProfileSettingsMutation, { onCompleted });
}
