import { Box } from "@outschool/backpack";
import * as Time from "@outschool/time";
import { useLinkComponent } from "@outschool/ui-utils";
import React, { FC } from "react";

import User from "../../../lib/User";
import UserAvatar from "../common/UserAvatar";
import { Sender } from "../types";

export interface ClassPostSenderPhotoAndPublishDateProps {
  sender: Sender;
  publishedAt: Date;
  onUserClick?: (user: Sender) => void;
  userHref?: string;
  onDateClick?: () => void;
  dateHref?: string;
  timeZone: string;
}

const ClassPostSenderPhotoAndPublishDate: FC<
  ClassPostSenderPhotoAndPublishDateProps
> = ({ sender, publishedAt, dateHref, timeZone, onUserClick, userHref }) => {
  const Link = useLinkComponent();
  return (
    <Box
      flex
      sx={{
        flexDirection: "row",
        marginBottom: "0.5em",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      data-test-id="ClassPostHeader"
    >
      <Box flex>
        <Box
          sx={{
            marginRight: "1em",
          }}
        >
          <UserAvatar
            sender={sender}
            onClick={onUserClick}
            href={userHref}
            size={45}
          />
        </Box>
        <Box>
          <Box
            sx={{
              color: "grey.500",
            }}
          >
            {dateHref ? (
              <Link to={dateHref} style={{ color: "#6A7482" }}>
                {Time.formatDateTimeWithFullWeekday(publishedAt, timeZone)}
              </Link>
            ) : (
              <>{Time.formatDateTimeWithFullWeekday(publishedAt, timeZone)}</>
            )}
          </Box>
          <Box
            sx={{
              fontWeight: "fontWeightBold",
            }}
          >
            {User.shortenedLeaderName(sender.name)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClassPostSenderPhotoAndPublishDate;
