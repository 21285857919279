import { Box } from "@outschool/backpack";
import {
  ClassPost_MessageContentFragment,
  TextMimeType,
} from "@outschool/gql-frontend-generated";
import { TextWithLinks } from "@outschool/ui-components-shared";
import { RichTextView } from "@outschool/ui-rich-text-editor";
import React from "react";

interface AssignmentContentProps {
  messageContent: ClassPost_MessageContentFragment;
}
export const AssignmentMessageContent = ({
  messageContent,
}: AssignmentContentProps) =>
  messageContent.originalMimeType === TextMimeType.TextMarkdown ? (
    <RichTextView
      content={messageContent.htmlText}
      allowLinks
      allowEmails
      maxLines={Infinity}
    />
  ) : (
    <Box component="pre" sx={{ wordBreak: "break-word", margin: 0 }}>
      <TextWithLinks
        clickableLinks={true}
        text={messageContent.text?.trim() ?? ""}
        maxTextLength={Infinity}
        hideEmails={true}
      />
    </Box>
  );
