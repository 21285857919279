import { VideoPlayerProps } from "@outschool/ui-legacy-component-library";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { SystemStyleObject } from "@styled-system/css";
import React from "react";

import VideoModalWithImageFallback from "../VideoModalWithImageFallback";
import { ACTIVITY_MEDIA_HEIGHT, Maybe } from "./utils";

interface ClassVideoModalWithImageFallbackProps {
  photo?: Maybe<string>;
  videoUrl?: Maybe<string>;
  sx?: SystemStyleObject;
  VideoPlayer: (props: VideoPlayerProps) => JSX.Element;
}

const ClassVideoModalWithImageFallback: React.FC<
  ClassVideoModalWithImageFallbackProps
> = ({ photo, videoUrl, sx, VideoPlayer }) => (
  <VideoModalWithImageFallback
    // @ts-ignore
    sx={{
      width: "100% !important",
      height: ACTIVITY_MEDIA_HEIGHT,
      ...sx,
    }}
    imageSx={{
      filter: "initial",
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      height: ACTIVITY_MEDIA_HEIGHT,
    }}
    imageUrl={photo ?? undefined}
    videoUrl={videoUrl}
    VideoPlayer={VideoPlayer}
  />
);
export default ClassVideoModalWithImageFallback;
