import { LearnerAccessLevel } from "@outschool/gql-backend-generated";
import {
  CurrentLearnerFragmentFragment,
  FullCurrentUserFragmentFragment,
} from "@outschool/gql-frontend-generated";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

import { ClubContextProps, ClubProvider } from "./ClubProvider";

const DEMO_LEARNER_PROPS: CurrentLearnerFragmentFragment = {
  uid: "123",
  name: "Learner",
  // TODO: Import avatar type from a shared package
  avatar: "star",
  unlockedAvatars: [],
  email: "",
  username: "",
  age: 0,
  accessLevel: LearnerAccessLevel.Full,
  enrollmentToReviewSoon: null,
  hasUpcomingScheduleEvent: false,
  isLoginEnabled: false,
  isVerified: false,
  parent: {
    uid: "456",
    name: "",
    photo: null,
    publicName: "",
    learnerModeSwitchAuthRequirement: null,
  },
  siblings: [],
  timeZone: "America/Los_Angeles",
  pronoun: null,
  intro: null,
  optedOutOfPersonalizedRecommendationsAt: null,
  experiments: [],
  interfacePreferences: [],
};
const DUMMY_CLUB_POST_PATH_FUNCTION: (uid: string) => string = () => "";
const DUMMY_LEARNER_PROFILE_PATH_FUNCTION: ({
  uid,
}: {
  uid: string;
}) => string = () => "";
const DUMMY_SECTION_UID = "";

export function DemoClubProvider({
  children,
  currentUserOrLearner = DEMO_LEARNER_PROPS,
  readOnly = true,
  ...props
}: React.PropsWithChildren<
  {
    readOnly?: boolean;
    currentUserOrLearner?:
      | FullCurrentUserFragmentFragment
      | CurrentLearnerFragmentFragment;
  } & Pick<
    ClubContextProps,
    | "LottieReact"
    | "loadLottieMap"
    | "useClubPostAndCommentDefaultBackgroundColor"
    | "useClubPostAndCommentBackgroundColors"
  >
>) {
  return (
    <ClubProvider
      clubHomePath=""
      getClubPostPath={DUMMY_CLUB_POST_PATH_FUNCTION}
      learnerProfileRoute={DUMMY_LEARNER_PROFILE_PATH_FUNCTION}
      readOnly={readOnly}
      currentUserOrLearner={currentUserOrLearner}
      sectionUid={DUMMY_SECTION_UID}
      page="home"
      {...props}
    >
      {children}
    </ClubProvider>
  );
}
