import { Box, Typography } from "@outschool/backpack";
import {
  Editor as TiptapEditor,
  EditorContent as TiptapEditorContent,
} from "@tiptap/react";
import React from "react";

import { commonStyles } from "../EditorStyles";

export default function EditorContent({
  tiptapEditor,
  tiptapSx = {},
}: {
  tiptapEditor: TiptapEditor | null;
  tiptapSx?: any;
}) {
  if (!tiptapEditor) {
    return null;
  }
  return (
    <Typography
      component={Box}
      data-testid="editor-content"
      sx={{
        flexGrow: 1,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        "& .tiptap": {
          flexGrow: 1,
          borderColor: "grey.400",
          ...tiptapSx,
          ...commonStyles,
          "& p.is-editor-empty:first-of-type::before": {
            color: "grey.500",
            content: "attr(data-placeholder)",
            float: "left",
            height: 0,
            pointerEvents: "none",
          },
        },
      }}
    >
      <TiptapEditorContent
        style={{
          flexGrow: 1,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        editor={tiptapEditor}
      />
    </Typography>
  );
}
