import { Box, SxProps } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { FilestackImage } from "@outschool/ui-components-shared";
import React from "react";

import { ACTIVITY_MEDIA_HEIGHT } from "./utils";

interface CardImageProps {
  imageUrl: string;
  sx?: SxProps;
}

function CardImage({ imageUrl, sx }: CardImageProps) {
  return (
    <Box
      sx={[
        {
          position: "relative",
          width: "100%",
          height: ACTIVITY_MEDIA_HEIGHT,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <FilestackImage
        src={imageUrl}
        imageWidth={320}
        imageHeight={230}
        sx={{
          gridArea: "video",
          objectFit: "cover",
          width: "100%",
          filter: "initial",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          height: ACTIVITY_MEDIA_HEIGHT,
        }}
      />
    </Box>
  );
}

export default CardImage;
