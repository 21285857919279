import { gql } from "@outschool/ui-apollo";

import { MeetingWithAttendancesAndRecordings } from "../fragments/Meeting";

export const SetMeetingRecordingsShown = gql`
  mutation SetMeetingRecordingsShown(
    $meetingUid: ID!
    $isShown: Boolean!
    $reason: String
  ) {
    setMeetingRecordingsShown(
      meetingUid: $meetingUid
      isShown: $isShown
      reason: $reason
    ) {
      ...MeetingWithAttendancesAndRecordings
    }
  }
  ${MeetingWithAttendancesAndRecordings}
`;

export const RequestMeetingRecordingsShown = gql`
  mutation RequestMeetingRecordingsShown(
    $meetingUid: ID!
    $learnerUid: ID!
    $message: String
  ) {
    requestMeetingRecordingsShown(
      meetingUid: $meetingUid
      learnerUid: $learnerUid
      message: $message
    ) {
      ...MeetingWithAttendancesAndRecordings
    }
  }
  ${MeetingWithAttendancesAndRecordings}
`;
