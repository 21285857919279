import uniqBy from "lodash/uniqBy";

import dayjs from "./dayjsExtended";
import timezoneNormMap from "./timezoneNormMap";
import timezoneList from "./timezones";
import * as Unlocalized from "./unlocalized";

export { dayjs };
export * from "./dateMath";

/*****************/
/*** CONSTANTS ***/
/*****************/

/**
 * Keep these constants in sync with the `@outschool/db-queries` package
 */
export const OUTSCHOOL_TIMEZONE = "America/Los_Angeles";
export const DAY_START_HOUR = 6; // 6 AM
export const DAY_END_HOUR = 22; // 10 PM
export const MIDNIGHT_HOUR = 24;
export const REASONABLE_DAY_START_HOUR = 6; // 6 AM Determined by guessing, should be reconsidered
export const REASONABLE_DAY_END_HOUR = 20; // 8 PM Determined by guessing, should be reconsidered

export const AFTER_SCHOOL_START_HOUR = 16; // 4 PM
export const AUTO_SCHEDULE_DAY_START_HOUR = 0; // 12 AM
export const AUTO_SCHEDULE_DAY_END_HOUR = 24; // 12 AM Next Day
export const RENEWAL_SCRIPT_HOUR = 17; // 5 PM
export const SCHEDULED_PAYMENT_SCRIPT_HOUR = 2;

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
] as const;
export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
] as const;
export const DAYS_PLURAL = [
  "Sundays",
  "Mondays",
  "Tuesdays",
  "Wednesdays",
  "Thursdays",
  "Fridays",
  "Saturdays",
] as const;
export const DAYS_SHORT = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
] as const;
// All supported time zones
export const TIMEZONES = timezoneList;
// Time zone list filtered to continent or ocean area names
export const TIMEZONES_WITHOUT_OFFSET = timezoneList.filter(name =>
  name.match(
    /^(((Africa|America|Antarctica|Asia|Australia|Europe|Arctic|Atlantic|Indian|Pacific)\/.+))$/
  )
);
// Regex to match time zones
export const timeZoneRegex = new RegExp(
  "^(" + // String start
    "([a-zA-Z]+\\/[a-zA-Z_\\-\\/]+)" + // Match "[Region]/[Location]" structure
    "|([A-Z]{3})" + // Match e.g. "PST", "GMT", "UTC" etc.
    "|([A-Z]{3}\\d[A-Z]{3})" + // Match e.g. EST5EDT, PST8PDT
    ")$" // String end
);
// The original name of these long names should be like "Pacific Standard Time" and
// such but for the sake of friendliness and to not use too much space we used
// more common names for the timezone abbrevations
export const TIMEZONE_SPECIAL_NAMES: Record<string, Record<string, string>> = {
  en: {
    // In order of Outschool popularity as of 2017-09-07
    // Using: 'SELECT details->>'browserTimeZone' AS tz, count(uid) AS user_count FROM users GROUP BY tz ORDER BY user_count desc;'
    "US/Eastern": "Eastern",
    "US/Alaska": "Alaska",
    "US/Arizona": "Arizona time",
    "US/Central": "Central",
    "US/East-Indiana": "Eastern",
    "US/Hawaii": "Hawaii",
    "US/Indiana-Starke": "Eastern",
    "US/Michigan": "Central",
    "US/Mountain": "Mountain",
    "US/Pacific": "Pacific",
    "America/New_York": "Eastern",
    "America/Chicago": "Central",
    "America/Los_Angeles": "Pacific",
    "America/Denver": "Mountain",
    "America/Toronto": "Eastern",
    "America/Phoenix": "Arizona time",
    "America/Edmonton": "Mountain",
    "America/Vancouver": "Pacific",
    "America/Detroit": "Eastern",
    "America/Halifax": "Atlantic",
    "America/Indianapolis": "Eastern",
    "America/Anchorage": "Alaska",
    "America/Regina": "Saskatchewan",
    "America/Montreal": "Eastern",
    "Pacific/Honolulu": "Hawaii",
    "America/Winnipeg": "Central",
    "America/Boise": "Mountain",
    "America/Moncton": "Atlantic",
    "America/Thunder_Bay": "Eastern",
    "America/Indiana/Indianapolis": "Eastern",
    "America/Indiana/Vincennes": "Eastern",
  },
  ko: {
    "Asia/Seoul": "KST",
  },
};

// Types
export type Month = (typeof MONTHS)[number];
export type Day = (typeof DAYS)[number];
export type DayShort = (typeof DAYS_SHORT)[number];

/*****************/
/*** UTILITIES ***/
/*****************/

//** Timezones **//

/** Return a list of all time zones we support */
export const getAllTimeZoneNames = () => TIMEZONES;
// https://stackoverflow.com/questions/17575790/environment-detection-node-js-or-browser
const isBrowser = new Function(
  "try {return this===window;}catch(e){ return false;}"
);
/** Guess the browser / system time zone */
export function guessBrowserTimeZone(): string {
  return isBrowser() ? dayjs.tz.guess() : OUTSCHOOL_TIMEZONE;
}
/* Test if a time zone is syntactically valid. */
export function zoneIsValid(timeZone: string): boolean {
  return timeZoneRegex.test(timeZone);
}
/** Test if a time zone is in our list of supported zones */
export function zoneIsSupported(timeZone: string): boolean {
  return TIMEZONES.includes(timeZone);
}
/**
 * Given an IANA time zone, return a comparable time zone from our list of
 * supported zones.
 */
export function normalizeTimeZone(oldTz: string, location?: string) {
  // Fall back to a timezone based on rules in this order:
  const newTz =
    // 1. Same time zone
    TIMEZONES.find(newTz => newTz === oldTz) ??
    // 2. Manually selected from pre-calculated map
    timezoneNormMap[oldTz] ??
    // 3. Same city as the user's location data
    (location
      ? TIMEZONES.find(newTz => {
          const parts = formatIANATimeZoneParts(newTz);
          location === parts[parts.length - 1];
        })
      : null) ??
    // 4. Same region & offset
    TIMEZONES.find(newTz => {
      const [oldRegion] = formatIANATimeZoneParts(oldTz);
      const [newRegion] = formatIANATimeZoneParts(newTz);
      return (
        oldRegion === newRegion &&
        formatUTCOffset(newTz) === formatUTCOffset(oldTz)
      );
    }) ??
    // 5. Only same offset
    TIMEZONES.find(newTz => formatUTCOffset(newTz) === formatUTCOffset(oldTz));
  if (!newTz) {
    throw new Error(`Could not normalize time zone: "${oldTz}"`);
  }
  return newTz;
}

//** Relative time **//

export function isSame(t1: dayjs.ConfigType, t2: dayjs.ConfigType) {
  return dayjs(t1).isSame(t2);
}

export function isNightTime(time: dayjs.ConfigType, userTimeZone: string) {
  const hour = !!time ? dayjs.tz(time, userTimeZone).hour() : undefined;
  return hour !== undefined && (hour < DAY_START_HOUR || hour >= DAY_END_HOUR);
}

export function isSummer(time: dayjs.ConfigType) {
  const timeDayjs = dayjs(time);
  return timeDayjs.month() > 4 && timeDayjs.month() < 8;
}

export function isThisYear(
  time: dayjs.ConfigType,
  timeZone: string = OUTSCHOOL_TIMEZONE
) {
  return getDayjs(time, timeZone).isSame(getNow(timeZone), "year");
}

export function endOfIsoMonthUtc(time?: dayjs.ConfigType) {
  // endOf does not set to 00:00. So, add 1 day and get startOf instead.
  return dayjs(time)
    .utcOffset(0)
    .endOf("M")
    .add(1, "day")
    .startOf("M")
    .toDate();
}

export function startOfIsoWeekUtc(time?: dayjs.ConfigType): Date {
  return dayjs(time).utcOffset(0).startOf("isoWeek").toDate();
}

export function startOfIsoMonthUtc(time?: dayjs.ConfigType): Date {
  return dayjs(time).utcOffset(0).startOf("M").toDate();
}

export function endOfIsoWeekUtc(time?: dayjs.ConfigType): Date {
  // endOf does not set to 00:00. So, add 1 day and get startOf instead.
  return dayjs(time)
    .utcOffset(0)
    .endOf("isoWeek")
    .add(1, "day")
    .startOf("isoWeek")
    .toDate();
}

export function nextRenewalDeadline(
  timeZone?: string,
  time?: dayjs.ConfigType
): dayjs.Dayjs {
  return dayjs(time)
    .utc()
    .endOf("isoWeek")
    .hour(RENEWAL_SCRIPT_HOUR)
    .minute(0)
    .second(0)
    .millisecond(0)
    .tz(timeZone);
}

export function nextMonthlyPaymentDeadline(timeZone?: string): dayjs.Dayjs {
  return dayjs()
    .add(1, "month")
    .utc()
    .startOf("month")
    .hour(SCHEDULED_PAYMENT_SCRIPT_HOUR)
    .minute(0)
    .second(0)
    .tz(timeZone);
}

export function nextWeeklyPaymentDeadline(timeZone?: string): dayjs.Dayjs {
  return dayjs()
    .utc()
    .startOf("isoWeek")
    .hour(SCHEDULED_PAYMENT_SCRIPT_HOUR)
    .minute(0)
    .second(0)
    .tz(timeZone);
}

/**
 * Get the last calendar date it was "day" relative to "now" as a JS Date.
 * If it is currently "day", this returns the start of today.
 *
 * @param day Either the locale-string for a day, or its number.  See: https://momentjs.com/docs/#/get-set/day/
 * @param now What moment/date to use for "now".  Defaults to current time.
 */
export function getLastDay(day: Day | number, now?: dayjs.ConfigType) {
  const otherDay = dayjs(now).utcOffset(0).startOf("day").day(day);
  const currentDay = dayjs(now).utcOffset(0).startOf("day");
  // if "day" of current week is in the future,
  // last day must be in last week
  if (otherDay > currentDay) {
    return otherDay.subtract(1, "week").toDate();
  } else {
    return otherDay.toDate();
  }
}
/****************/
/** FORMATTING **/
/****************/

/**
 * NOTE: If a string contains multiple "elements" (e.g. hours+minutes,
 * day+month, or time+time zone), we should not use hard-coded format
 * strings. There's no guarantee that other languages will use the same
 * ordering for these formats, so we need to use either dayjs localized
 * format strings or the Intl.DateTimeFormat library for formatting.
 *
 * Individual dayjs format string elements (e.g. "ddd") are localized.
 */

//** Helper functions **//

/** Dayjs constructor with timezone fallback */
function getDayjs(
  input: dayjs.ConfigType,
  timeZone: string = OUTSCHOOL_TIMEZONE,
  locale?: string
): dayjs.Dayjs {
  const djs = dayjs.tz(input, timeZone);
  return locale ? djs.locale(locale) : djs;
}

/** Returns the current time in the given timezone */
function getNow(timeZone: string = OUTSCHOOL_TIMEZONE) {
  return dayjs().tz(timeZone);
}

/** Format a dayjs object using `Intl.DateTimeFormat.format()` */
function formatWithOptions(
  djs: dayjs.Dayjs,
  options: Intl.DateTimeFormatOptions,
  showTz?: boolean
): string {
  if (!djs.isValid()) {
    console.warn("Invalid date passed to formatWithOptions");
    return "Invalid date";
  }
  const timeZone = djs.getTz() ?? OUTSCHOOL_TIMEZONE; // Custom helper function, reads djs.$x.$timezone
  const timeZoneString = showTz ? djs.format(" zz") : "";
  const formattedDateString = new Intl.DateTimeFormat(djs.locale(), {
    timeZone,
    ...options,
  }).format(djs.toDate());
  return formattedDateString + timeZoneString;
}

/** Format a range between two dayjs objects with `formatRange()` */
function formatRangeWithOptions(
  start: dayjs.Dayjs,
  end: dayjs.Dayjs,
  options: Intl.DateTimeFormatOptions,
  showTz?: boolean
): string {
  if (!(start.isValid() && end.isValid())) {
    console.warn("Invalid date passed to formatRangeWithOptions");
    return "Invalid date range";
  } else if (end < start) {
    console.warn("End time cannot be before start time.");
    return "Invalid date range";
  }
  const timeZone = start.getTz() ?? OUTSCHOOL_TIMEZONE;
  const timeZoneString = showTz ? start.format(" zz") : "";
  const formattedRangeString = new Intl.DateTimeFormat(start.locale(), {
    timeZone,
    ...options,
  }).formatRange(start.toDate(), end.toDate());
  return formattedRangeString + timeZoneString;
}

function formatNumberWithOptions(n: number, opts: Intl.NumberFormatOptions) {
  return new Intl.NumberFormat(dayjs.locale(), {
    style: "unit",
    ...opts,
  }).format(n);
}

//** Test for browser support **//

function noIntlRelativeTimeFormat(): boolean {
  return !("RelativeTimeFormat" in Intl);
}
function noIntlFormatRange(): boolean {
  return !("formatRange" in Intl.DateTimeFormat.prototype);
}
/** Check whether a given option is supported by Intl.DateTimeFormat */
export function noIntlFormatOption(option: string): boolean {
  try {
    return !(
      option in
      new Intl.DateTimeFormat("en", {
        [option]: "unsupportedValue",
      }).resolvedOptions()
    );
  } catch (e) {
    // A RangeError means the option is supported, but the value we passed wasn't.
    // If this error is thrown, we can assume the option is supported.
    return !(e instanceof RangeError);
  }
}
/** Check whether a given value is supported by a given option in Intl.DateTimeFormat */
export function noIntlFormatOptionValue(
  option: string,
  value: string
): boolean {
  if (noIntlFormatOption(option)) {
    return false;
  }
  try {
    return !(
      option in
      new Intl.DateTimeFormat("en", { [option]: value }).resolvedOptions()
    );
  } catch (e) {
    return e instanceof RangeError;
  }
}

//** Formatting dates **//

/** e.g. "Mon" */
export function formatWeekday(
  date: dayjs.ConfigType,
  timeZone?: string
): string {
  return getDayjs(date, timeZone).format("ddd");
}

/** e.g. "Jun 12" */
export function formatDate(
  date: dayjs.ConfigType,
  timeZone?: string,
  alwaysShowYear = false
): string {
  const djs = getDayjs(date, timeZone);
  const showYear = alwaysShowYear || !isThisYear(date, timeZone);
  return formatWithOptions(djs, {
    month: "short",
    day: "numeric",
    ...(showYear && { year: "numeric" }),
  });
}

/** e.g. "6/12" or "6/12/24" */
export function formatShortDate(
  date: dayjs.ConfigType,
  timeZone?: string,
  alwaysShowYear = false
): string {
  const djs = getDayjs(date, timeZone);
  const showYear = alwaysShowYear || !isThisYear(date, timeZone);
  return formatWithOptions(djs, {
    month: "numeric",
    day: "numeric",
    ...(showYear && { year: "2-digit" }),
  });
}

export function formatTime(
  time: dayjs.ConfigType,
  timeZone?: string,
  showTz = true,
  alwaysShowMinutes = false
): string {
  const djs = getDayjs(time, timeZone);
  const showMinutes = alwaysShowMinutes || !!djs.minutes();
  return formatWithOptions(
    djs,
    {
      hour: "numeric",
      ...(showMinutes && { minute: "2-digit" }),
    },
    showTz
  );
}

/** e.g. "Eastern (2:15 PM)"*/
export function timezoneChipFormat(
  time: dayjs.ConfigType,
  timeZone: string
): string {
  const djs = dayjs.tz(time, timeZone);
  return djs.format("zz (LT)");
}

/** Format an hour + minutes as a time string. E.g.
 * (1, 30) --> "1:30 AM"
 * (15, 15) --> "3:15 PM"
 */
export function formatAbsoluteTime(hour: number, minute?: number) {
  const djs = dayjs(`${hour}:${minute}`, "h:m").tz(OUTSCHOOL_TIMEZONE, true);
  return formatTime(djs, OUTSCHOOL_TIMEZONE, false);
}

// Previously this function rendered "{date} at {time}" in English. Neither Intl
// nor dayjs can localize this, so we get as close as we can with Intl and expect
// that to be the best, most standardized format for any given locale. If we need
// the "at", we should format date and time separately and pass the two strings into
// an i18next "t" call on the frontend so our translators can handle it.
export function formatDateTime(
  dateTime: dayjs.ConfigType,
  timeZone?: string,
  showTz = false
): string {
  const djs = getDayjs(dateTime, timeZone);
  const showYear = !isThisYear(djs, timeZone);
  const showMinutes = !!djs.minutes();
  return formatWithOptions(
    djs,
    {
      ...(showYear && { year: "numeric" }),
      month: "short",
      day: "numeric",
      hour: "numeric",
      ...(showMinutes && { minute: "2-digit" }),
    },
    showTz
  );
}

export function formatDateTimeWithYear(
  dateTime: dayjs.ConfigType,
  timeZone?: string,
  showTz = false
): string {
  const djs = getDayjs(dateTime, timeZone);
  const showMinutes = !!djs.minutes();
  return formatWithOptions(
    djs,
    {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      ...(showMinutes && { minute: "2-digit" }),
    },
    showTz
  );
}

export function formatDateTimeWithFullWeekday(
  dateTime: dayjs.ConfigType,
  timeZone?: string,
  showTz = false
): string {
  const djs = getDayjs(dateTime, timeZone);
  const showYear = !isThisYear(djs, timeZone);
  const showMinutes = !!djs.minutes();
  return formatWithOptions(
    djs,
    {
      month: "short",
      day: "numeric",
      weekday: "long",
      hour: "numeric",
      ...(showMinutes && { minute: "2-digit" }),
      ...(showYear && { year: "numeric" }),
    },
    showTz
  );
}

export function formatDateTimeWithWeekday(
  dateTime: dayjs.ConfigType,
  timeZone?: string
): string {
  const djs = getDayjs(dateTime, timeZone);
  const showYear = !isThisYear(djs, timeZone);
  const showMinutes = !!djs.minutes();
  return formatWithOptions(djs, {
    month: "short",
    day: "numeric",
    weekday: "short",
    hour: "numeric",
    ...(showMinutes && { minute: "2-digit" }),
    ...(showYear && { year: "numeric" }),
  });
}

export function formatDateTimeWithWeekdayMonthAndYear(
  dateTime: dayjs.ConfigType,
  timeZone?: string
): string {
  const djs = getDayjs(dateTime, timeZone);
  const showYear = !isThisYear(djs, timeZone);
  const showMinutes = !!djs.minutes();
  return formatWithOptions(djs, {
    month: "short",
    day: "numeric",
    weekday: "short",
    hour: "numeric",
    ...(showMinutes && { minute: "2-digit" }),
    ...(showYear && { year: "numeric" }),
  });
}

//
// output: "Monday, Sept 10, 2024"
export function formatWeekdayMonthAndYear(
  dateTime: dayjs.ConfigType,
  timeZone?: string
): string {
  const djs = getDayjs(dateTime, timeZone);
  const showYear = !isThisYear(djs, timeZone);
  return formatWithOptions(djs, {
    month: "short",
    day: "numeric",
    weekday: "short",
    ...(showYear && { year: "numeric" }),
  });
}

export function formatDateWithFullMonth(
  date: dayjs.ConfigType,
  timeZone?: string
): string {
  const djs = getDayjs(date, timeZone);
  const showYear = !isThisYear(djs, timeZone);
  return formatWithOptions(djs, {
    month: "long",
    day: "numeric",
    ...(showYear && { year: "numeric" }),
  });
}

export function formatDateWithWeekday(
  date: dayjs.ConfigType,
  timeZone?: string,
  alwaysShowYear?: boolean
): string {
  const djs = getDayjs(date, timeZone);
  const showYear = alwaysShowYear || !isThisYear(djs, timeZone);
  return formatWithOptions(djs, {
    month: "short",
    day: "numeric",
    weekday: "short",
    ...(showYear && { year: "numeric" }),
  });
}

export function formatDateWithWeekdayLong(
  date: dayjs.ConfigType,
  timeZone?: string,
  alwaysShowYear?: boolean
): string {
  const djs = getDayjs(date, timeZone);
  const showYear = alwaysShowYear || !isThisYear(djs, timeZone);
  return (
    formatWithOptions(djs, {
      month: "short",
      day: "numeric",
      weekday: "long",
      ...(showYear && { year: "numeric" }),
    })
      // remove first commma after day
      .replace("day,", "day")
  );
}

export function formatDayWithFullMonth(
  date: dayjs.ConfigType,
  timeZone?: string
): string {
  const djs = getDayjs(date, timeZone);
  return formatWithOptions(djs, {
    month: "long",
    day: "numeric",
  });
}

export function formatFullWeekday(
  date: dayjs.ConfigType,
  timeZone?: string,
  locale?: string
): string {
  return getDayjs(date, timeZone, locale).format("dddd");
}

export function formatShortWeekday(
  date: dayjs.ConfigType,
  timeZone?: string,
  locale?: string
): string {
  return getDayjs(date, timeZone, locale).format("ddd");
}

export function formatFullWeekDayFullMonthTime(
  time: dayjs.ConfigType,
  timeZone?: string
): string {
  const djs = getDayjs(time, timeZone);
  const showMinutes = !!djs.minutes();
  return formatWithOptions(djs, {
    weekday: "long",
    month: "long",
    day: "numeric",
    ...(showMinutes ? { minute: "2-digit" } : {}),
  });
}

export function describeDuration(
  startDate: dayjs.ConfigType,
  endDate: dayjs.ConfigType
): string {
  if (!(dayjs(startDate).isValid() && dayjs(endDate).isValid())) {
    console.log("Invalid dayjs passed to describeDuration");
    return "Invalid duration";
  }
  return dayjs.duration(dayjs(startDate).diff(endDate)).humanize();
}

export function getNowString(): string {
  if (noIntlRelativeTimeFormat()) {
    return "now";
  }
  // Localized string indicating "now" (dayjs can't do this)
  return new Intl.RelativeTimeFormat(dayjs.locale(), {
    style: "narrow",
    numeric: "auto",
  }).format(0, "second");
}

/**
 * This function provides a human-friendly representation of a given date by comparing it 
 * with today's date and returning formatted strings such as "Today at...", "Yesterday at...", 
 or a full date format for dates further in the past.
 *
 * @param {string} date - The date string to be formatted, which can be in any format recognized by Day.js.
 * @returns {string} A formatted string representing the date in relation to the current date.
 *         Examples of possible returns:
 *         - "Today at 2:30 PM"
 *         - "Yesterday at 5:00 AM"
 *         - "Monday, May 24, 2023 at 4:00 PM" for dates that are not yesterday, or today.
 * Usage:
 *     customCalendar('2023-06-01 14:20'); // Returns "Today at 2:20 PM" if today is June 1, 2023.
 *     customCalendar('2023-05-31 08:00'); // Returns "Yesterday at 8:00 AM" if today is June 1, 2023.
 *     customCalendar('2022-12-25 10:00'); // Returns "Sunday, December 25, 2022 at 10:00 AM" if today is far from December 25, 2022.
 */
export function customCalendar(date: dayjs.ConfigType): string {
  const now = dayjs();
  const target = dayjs(date);
  const formatString = "h:mm A"; // Customize time format here

  if (target.isSame(now, "day")) {
    return `Today at ${target.format(formatString)}`;
  } else if (target.isSame(now.subtract(1, "day"), "day")) {
    return `Yesterday at ${target.format(formatString)}`;
  } else {
    return target.format("dddd, MMMM D, YYYY [at] h:mm A"); // Full date for dates beyond yesterday and tomorrow
  }
}
/**
 * The function uses a customCalendar implementation to format dates that are in the past
 * in a readable style like "Today at...", "Yesterday at...", or a full date format for older dates.
 * If the date is not in the past, the function returns a string representing the current time
 * @param {dayjs.ConfigType} date - The date to be formatted. The type dayjs.ConfigType accepts
 *                                  various date inputs like strings, Date objects, or dayjs objects.
 * @returns {string} A string that either formats the date relative to today's date using the
 *         customCalendar function or returns the current time string via getNowString(), based
 *         on whether the date is before the current time or not.
 *
 * Example Usage:
 *   fromNowCalendar('2023-06-01 14:20');  // Might return "Today at 2:20 PM" if today is June 1, 2023,
 *                                          // or "Just now" if the date is in the future or now.
 */
export function fromNowCalendar(date: dayjs.ConfigType): string {
  return dayjs(date).isBefore() ? customCalendar(date) : getNowString();
}
export function fromNow(date: dayjs.ConfigType): string {
  return dayjs(date).isBefore() ? dayjs(date).fromNow() : getNowString();
}

export function fromNowForFutureTime(date: dayjs.ConfigType): string {
  return dayjs().isBefore(date) ? dayjs(date).fromNow() : getNowString();
}

export function fromNowOrAbsolute(
  date: dayjs.ConfigType,
  timeZone?: string
): string {
  return dayjs(date).isBefore(dayjs().subtract(1, "week"))
    ? formatDate(date, timeZone)
    : fromNow(date);
}

export function isToday(date: string, timeZone: string): boolean {
  const currentDate = dayjs().tz(timeZone);
  const futureDate = dayjs(date).tz(timeZone);
  return futureDate.isSame(currentDate, "day");
}

export function adaptivePastDateString(
  date: dayjs.ConfigType,
  timeZone?: string
): string {
  const djs = getDayjs(date, timeZone);
  const now = getNow(timeZone);
  if (djs.isAfter(now.startOf("day"))) {
    return formatTime(date, timeZone, false);
  } else if (djs.isAfter(now.startOf("year"))) {
    return formatWithOptions(djs, {
      month: "short",
      day: "numeric",
    });
  } else {
    return djs.format("ll");
  }
}

// TODO Rename: "formatDateShortMonthOrdinal"
/** e.g.: "Jan 5th"  or "Sep 1st" */
export function startDayShortString(
  startTime: dayjs.ConfigType,
  timeZone?: string
): string {
  const djs = getDayjs(startTime, timeZone);
  // Couldn't figure out how to do this consistently across locales
  return dayjs.locale() === "en"
    ? djs.format("MMM Do")
    : formatWithOptions(djs, {
        month: "short",
        day: "numeric",
      });
}

// TODO Rename: "formatDateShortMonthOrdinalWithYear"
/** e.g.: "Jan 5th, 2018" */
export function startDayShortStringWithYear(
  startTime: dayjs.ConfigType,
  timeZone?: string
): string {
  const djs = getDayjs(startTime, timeZone);
  return dayjs.locale() === "en"
    ? djs.format("MMM Do, YYYY")
    : formatWithOptions(djs, {
        month: "short",
        day: "numeric",
        year: "numeric",
      });
}

// TODO Rename: "formatFullMonthAndYear"
/** e.g.: January 2022 */
export function monthAndYearString(
  startTime: dayjs.ConfigType,
  timeZone?: string
): string {
  const djs = getDayjs(startTime, timeZone);
  return formatWithOptions(djs, {
    month: "long",
    year: "numeric",
  });
}

export function getNoonString() {
  if (noIntlFormatOption("dayPeriod")) {
    return "noon";
  }
  const djs = dayjs("12:00", "HH:mm").tz(OUTSCHOOL_TIMEZONE, true);
  return formatWithOptions(djs, {
    dayPeriod: "short",
  });
}

//** Formatting date ranges **//

/** e.g. Mon, Sep 5 – Tue, Sep 6, 2022 */
export function fullDurationStringWithoutTimes(
  startTime: dayjs.ConfigType | undefined | null,
  endTime: dayjs.ConfigType | undefined | null,
  timeZone?: string
): string {
  if (noIntlFormatRange()) {
    return Unlocalized.fullDurationStringWithoutTimes.apply(null, arguments);
  }
  if (!startTime || !endTime) {
    // TODO translate
    return "Not yet scheduled";
  }
  const start = getDayjs(startTime, timeZone);
  const end = getDayjs(endTime, timeZone);
  const showYear = !(isThisYear(start, timeZone) && isThisYear(end, timeZone));
  return formatRangeWithOptions(start, end, {
    month: "short",
    day: "numeric",
    weekday: "short",
    ...(showYear && { year: "numeric" }),
  });
}

/**
 * eg: Mon, Sep 5, 1:00pm - Mon, Dec 12, 2:15 PM
 * or: Mon, Sep 5, 1:00pm - 2:15 PM
 */
export function fullDurationStringWithStartTime(
  startTime: dayjs.ConfigType | undefined | null,
  endTime: dayjs.ConfigType | undefined | null,
  timeZone?: string,
  showTz: boolean = true
): string {
  if (noIntlFormatRange()) {
    return Unlocalized.fullDurationStringWithStartTime.apply(null, arguments);
  }
  if (!startTime || !endTime) {
    // TODO translate
    return "Not yet scheduled";
  }
  const start = getDayjs(startTime, timeZone);
  const end = getDayjs(endTime, timeZone);
  const moreThanDay = start.add(12, "hour").isBefore(end);
  const showYear = !(isThisYear(start, timeZone) && isThisYear(end, timeZone));
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    weekday: "short",
    ...(showYear && { year: "numeric" }),
  };
  if (moreThanDay) {
    const dateRangeString = formatRangeWithOptions(start, end, dateOptions);
    const startTimeString = start.format("LT" + (showTz ? " zz" : ""));
    // A space is the most locale-neutral separator between date and time here
    return `${dateRangeString} ${startTimeString}`;
  } else {
    return formatRangeWithOptions(
      start,
      end,
      {
        ...dateOptions,
        hour: "numeric",
        minute: "2-digit",
      },
      showTz
    );
  }
}

/**
 * eg: Mon, Sep 5, 1:00 PM – Mon, Dec 12, 2:15 PM
 * or: Mon, Sep 5, 1:00 PM – 2:15 PM
 */
export function fullDurationStringWithTimes(
  startTime: dayjs.ConfigType | undefined | null,
  endTime: dayjs.ConfigType | undefined | null,
  timeZone?: string,
  hideTimeZone = false
): string {
  if (noIntlFormatRange()) {
    return Unlocalized.fullDurationStringWithTimes.apply(null, arguments);
  }
  if (!startTime || !endTime) {
    // TODO return null and handle on consumer end
    return "Not yet scheduled";
  }
  const start = getDayjs(startTime, timeZone);
  const end = getDayjs(endTime, timeZone);
  const showYear = !(isThisYear(start, timeZone) && isThisYear(end, timeZone));
  return formatRangeWithOptions(
    start,
    end,
    {
      ...(showYear && { year: "numeric" }),
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "2-digit",
    },
    !hideTimeZone
  );
}

/**
 * eg: Mon, Sep 5, 1:00 PM
 */
export function fullDurationStringWithoutEndTime(
  startTime: dayjs.ConfigType | undefined | null,
  timeZone?: string
): string {
  if (noIntlFormatRange()) {
    return Unlocalized.fullDurationStringWithStartTime.apply(null, arguments);
  }
  if (!startTime) {
    // TODO translate
    return "Not yet scheduled";
  }
  const start = getDayjs(startTime, timeZone);
  const showYear = !isThisYear(start, timeZone);
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    weekday: "short",
    ...(showYear && { year: "numeric" }),
  };
  return formatWithOptions(
    start,
    {
      ...dateOptions,
      hour: "numeric",
      minute: "2-digit",
    },
    false
  );
}

/**
 * Returns a 'month day, year' representation of start and end times.
 * It produces the shortest representation possible:
 * Same day:
 *   Nov 7, 2017
 * Same month:
 *   Nov 7 - 10, 2017
 * Same year:
 *   Nov 7 - Dec 5, 2017
 * Otherwise:
 *   Nov 7, 2017 - Jan 5, 2018
 * @param startTime
 * @param endTime
 * @param timeZone
 * @returns {string}
 */
export function pastDurationString(
  startTime: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
  timeZone?: string
): string {
  if (noIntlFormatRange()) {
    return Unlocalized.pastDurationString.apply(null, arguments);
  }
  const start = getDayjs(startTime, timeZone);
  const end = getDayjs(endTime, timeZone);
  // Intl.DateTimeFormat.prototype.formatRange does this for us!
  return formatRangeWithOptions(start, end, {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

export function pastDurationStringWithTimes(
  startTime: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
  timeZone?: string
): string {
  if (noIntlFormatRange()) {
    return Unlocalized.pastDurationStringWithTimes.apply(null, arguments);
  }
  const start = getDayjs(startTime, timeZone);
  const end = getDayjs(endTime, timeZone);
  return formatRangeWithOptions(start, end, {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
  });
}

/** 9am - 10am Central */
/** if start to end goes over midnight, I.E 11pm - 1am dates will be shown next to duration, 5/31/2025, 11 PM – 6/1/2025, 6 AM Pacific*/
export function briefDurationTimesString(
  startTime: dayjs.ConfigType | undefined | null,
  endTime: dayjs.ConfigType | undefined | null,
  timeZone?: string,
  showTz = true
): string {
  if (noIntlFormatRange()) {
    return Unlocalized.briefDurationTimesString.apply(null, arguments);
  }
  if (!startTime || !endTime) {
    // TODO translate
    return "Not yet scheduled";
  }
  const start = getDayjs(startTime, timeZone);
  let end = getDayjs(endTime, timeZone)
    .year(start.year())
    .month(start.month())
    .day(start.day());
  if (end.isBefore(start)) {
    end = getDayjs(endTime, timeZone);
  }
  const showMinutes = !(start.minutes() === 0 && end.minutes() === 0);
  return formatRangeWithOptions(
    start,
    end,
    {
      hour: "numeric",
      ...(showMinutes && { minute: "2-digit" }),
    },
    showTz
  );
}

export function briefDurationHoursString(startHour: number, endHour: number) {
  const start = dayjs(`${startHour}`, "h").tz(OUTSCHOOL_TIMEZONE, true);
  const end = dayjs(`${endHour}`, "h").tz(OUTSCHOOL_TIMEZONE, true);
  return briefDurationTimesString(start, end, OUTSCHOOL_TIMEZONE, false);
}

/** eg. Feb 1 - Mar 1 */
export function briefDurationStringWithoutTimes(
  startTime: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
  timeZone?: string
): string {
  if (noIntlFormatRange()) {
    return Unlocalized.briefDurationStringWithoutTimes.apply(null, arguments);
  }
  if (!startTime || !endTime) {
    return "Not yet scheduled";
  }
  const start = getDayjs(startTime, timeZone);
  const end = getDayjs(endTime, timeZone);
  const showYear = !(isThisYear(start, timeZone) && isThisYear(end, timeZone));
  return formatRangeWithOptions(start, end, {
    ...(showYear && { year: "numeric" }),
    month: "short",
    day: "numeric",
  });
}

/**
 * eg. Thursday 11:30 AM - 12 PM Eastern
 * or  Thursday 11:30 PM - Friday 12:25 AM Eastern
 */
export function weeklyDayOfWeekWithDuration(
  startTime: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
  timeZone?: string,
  showTz = true
): string {
  if (noIntlFormatRange()) {
    return Unlocalized.fullDurationStringWithStartTime.apply(null, arguments);
  }
  if (!startTime || !endTime) {
    return "Not yet scheduled";
  }

  const start = getDayjs(startTime, timeZone);
  const end = getDayjs(endTime, timeZone);
  const moreThanDay = !dayjs(start).isSame(end, "day");
  if (moreThanDay) {
    const startTimeString = start.format("dddd LT");
    const endTimeString = end.format("dddd LT");
    const tzString = start.format("zz");
    return `${startTimeString} - ${endTimeString} ${tzString}`;
  } else {
    return formatRangeWithOptions(
      start,
      end,
      {
        weekday: "long",
        hour: "numeric",
        minute: "2-digit",
      },
      showTz
    );
  }
}

/**  eg. Wed, Feb 1 - Mar 1, intentionally doesn't add weekday for the second date */
export function briefDateDurationWithWeekday(
  startTime: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
  timeZone?: string
): string {
  if (noIntlFormatRange()) {
    return Unlocalized.briefDateDurationWithWeekday.apply(null, arguments);
  }
  const start = getDayjs(startTime, timeZone);
  const end = getDayjs(endTime, timeZone);
  const showYear = !(isThisYear(start, timeZone) && isThisYear(end, timeZone));
  return formatRangeWithOptions(start, end, {
    ...(showYear && { year: "numeric" }),
    month: "short",
    day: "numeric",
    weekday: "short",
  });
}
/**
 * eg. Thursday 11:30 AM - 12 PM Eastern
 * or  Thursday 11:30 PM - Friday 12:25 AM Eastern
 */
export function weeklyDayOfWeek(
  startTime: dayjs.ConfigType,
  meetingDays: string | null,
  timeZone?: string
): string {
  if (noIntlFormatRange()) {
    return Unlocalized.fullDurationStringWithStartTime.apply(null, arguments);
  }
  if (!startTime || !meetingDays) {
    return "";
  }

  return meetingDays + ", " + formatTime(startTime, timeZone, false);
}

/**
 * startDay and endDay are 0-indexed (0=Sunday, 6=Saturday)
 * @returns E.g. Wed - Fri
 */
export function shortWeekdayRange(startDay: number, endDay: number): string {
  const start = getNow().day(startDay);
  const end = getNow().day(endDay);
  if (noIntlFormatRange()) {
    return `${start.format("ddd")} - ${end.format("ddd")}`;
  }
  return formatRangeWithOptions(start, end, {
    weekday: "short",
  });
}

/**
 * eg: December 18 - 25
 * or: December 30 - January 6
 */
export function shortDurationString(
  startTime: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
  timeZone?: string,
  monthFormat: "long" | "short" = "long"
): string {
  if (noIntlFormatRange()) {
    return Unlocalized.shortDurationString.apply(null, arguments);
  }
  const start = getDayjs(startTime, timeZone);
  const end = getDayjs(endTime, timeZone);
  return formatRangeWithOptions(start, end, {
    month: monthFormat,
    day: "numeric",
  });
}

/**
 * eg: 9:00 - 10:00 AM Pacific
 * or: 11:00 AM - 12:00 PM Pacific
 */
export function startEndTime(
  startTime: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
  timeZone?: string,
  showTz = true
): string {
  if (noIntlFormatRange()) {
    return Unlocalized.startEndTime.apply(null, arguments);
  }
  const start = getDayjs(startTime, timeZone);
  const end = getDayjs(endTime, timeZone);
  return formatRangeWithOptions(
    start,
    end,
    {
      hour: "numeric",
      minute: "2-digit",
    },
    showTz
  );
}

/** e.g. 21 - 25 */
export function startEndDay(
  startDate: dayjs.ConfigType,
  endDate = startDate,
  timeZone?: string
): string {
  if (noIntlFormatRange()) {
    return Unlocalized.startEndDay.apply(null, arguments);
  }
  const start = getDayjs(startDate, timeZone);
  const end = getDayjs(endDate, timeZone);
  return formatRangeWithOptions(start, end, {
    day: "numeric",
  });
}

//** Time zones **//

/**
 * Returns a localized & colloquial version of the time zone name
 * e.g. "Eastern" or "日本標準時" */
export function timeZoneName(timeZone?: string) {
  return getNow(timeZone).format("zz");
}
/**
 * e.g. "America/New_York" --> ["America", "New York"]
 */
export function formatIANATimeZoneParts(timeZone: string): string[] {
  return timeZone.split("/").map(part => part.replace("_", " "));
}
/**
 * e.g. "America/New_York" --> "America (New York)"
 */
export function formatIANATimeZoneName(timeZone: string): string {
  const [continent, location] = formatIANATimeZoneParts(timeZone);
  return `${continent} (${location})`;
}
/** e.g. "UTC+5" or "UTC+5:30" */
export function formatUTCOffset(timeZone?: string, full: boolean = false) {
  const baseOffset = getNow(timeZone).format("Z");
  const offset = full
    ? baseOffset
    : baseOffset.replace(/^(([\+\-])0)|(:00)/g, "$2");
  return "UTC" + offset;
}

// Eg: 12/21/2023
export function formatMMDDYYYY(
  dateTime: dayjs.ConfigType,
  timeZone?: string | null
) {
  return dayjs(dateTime)
    .tz(timeZone ?? OUTSCHOOL_TIMEZONE)
    .format("L");
}

//** Input formats **//

// (These should all use "en")

export function formatForGcal(time: string | Date): string {
  return dayjs(time).locale("en").utc().format("YYYYMMDD[T]HHmmss[Z]");
}

// https://stackoverflow.com/questions/804118/best-timestamp-format-for-csv-excel
export function formatCSVDateTime(
  dateTime: dayjs.ConfigType,
  timeZone?: string
): string {
  return getDayjs(dateTime, timeZone).locale("en").format("YYYY-MM-DD h:mm A");
}

// https://stackoverflow.com/questions/804118/best-timestamp-format-for-csv-excel
export function formatCSVDate(
  dateTime: dayjs.ConfigType,
  timeZone?: string
): string {
  return getDayjs(dateTime, timeZone).locale("en").format("YYYY-MM-DD");
}

// Formats date values for populating html inputs (type="date")
export function dateInputString(
  dateTime: dayjs.ConfigType | undefined,
  timeZone?: string
) {
  return (
    dateTime && getDayjs(dateTime, timeZone).locale("en").format("YYYY-MM-DD")
  );
}

// Formats time values for populating html inputs.
export function timeInputString(
  dateTime: dayjs.ConfigType | undefined,
  timeZone?: string
) {
  return dateTime && getDayjs(dateTime, timeZone).locale("en").format("h:mm A");
}

// ISO 8601
export function isoFormat(time: dayjs.ConfigType): string {
  return dayjs(time).utc().toISOString();
}

//** Number formatting **//

export function numWeeksString(n: number): string {
  let result = "";
  try {
    result = formatNumberWithOptions(n, {
      unit: "week",
      unitDisplay: "long",
    });
  } catch (e) {
    // "unit" option is not always supported
    result = `${n} week${n == 1 ? "" : "s"}`;
  }
  return result;
}

//** Utilities **//

export function createDayjs(
  input?: dayjs.ConfigType,
  dateFormat?: string,
  strict?: boolean
): dayjs.Dayjs {
  return dayjs(input, dateFormat, strict);
}

export function createDayjsTz(
  input: dayjs.ConfigType,
  timeZone = OUTSCHOOL_TIMEZONE,
  dateFormat?: string
): dayjs.Dayjs {
  return dateFormat
    ? dayjs.tz(input, dateFormat, timeZone)
    : dayjs.tz(input, timeZone);
}

/**
 * Call a function with args in a context where the dayjs locale is set to English,
 * regardless of the current global locale. Returns that function's return value.
 * @param fn The function to be run
 * @param args The arguments to fn
 * @returns The return value of fn
 */
export function withEnglish(fn: (...args: any[]) => any, args: any[]) {
  const originalLocale = dayjs.locale();
  dayjs.locale("en");
  const result = fn.apply(this, args);
  dayjs.locale(originalLocale);
  return result;
}

/**
 * Run some code in a context where the dayjs locale is set to English,
 * regardless of the current global locale. Returns void.
 * @param fn The code to be run with dayjs.locale() == "en"
 * @returns {void}
 */
export function inEnglish(fn: Function) {
  const originalLocale = dayjs.locale();
  dayjs.locale("en");
  let error = null;
  try {
    fn();
  } catch (e) {
    error = e;
  }
  dayjs.locale(originalLocale);
  if (error) {
    throw error;
  }
}

// Returns pluralized day of week of a given date (eg. Thursdays)
export function pluralizeDayOfWeek(date: Date, timeZone: string): string {
  const djs = getDayjs(date, timeZone);
  return DAYS_PLURAL[djs.day()];
}

// Converts timeOfDay string to hour start and end window
export function timeOfDayToStartEndTimes(timeOfDay: string) {
  let startAfterTime: number | undefined;
  let endByTime: number | undefined;
  switch (timeOfDay) {
    case "Before noon":
      startAfterTime = DAY_START_HOUR;
      endByTime = 12;
      break;
    case "Noon - 4pm":
      startAfterTime = 12;
      endByTime = AFTER_SCHOOL_START_HOUR;
      break;
    case "After 4pm":
      startAfterTime = AFTER_SCHOOL_START_HOUR;
      endByTime = DAY_END_HOUR;
      break;
  }
  return { startAfterTime, endByTime };
}

// Converts a list of datetimes into days of the week, ex. "Mondays"
export function getDaysOfWeek({
  dateTimes,
  isOneTime,
  timeZone,
  locale,
}: {
  dateTimes: dayjs.ConfigType[];
  isOneTime: boolean;
  timeZone: string;
  locale: string;
}) {
  let content: string;
  if (isOneTime && dateTimes.length > 0) {
    content = formatFullWeekday(dateTimes[0], timeZone, locale);
  } else {
    const days = dateTimes.map(dt => ({
      short: formatShortWeekday(dt, timeZone, locale),
      long: formatFullWeekday(dt, timeZone, locale),
    }));

    if (!days) {
      content = "";
    } else if (days.length === 1) {
      content = days[0].long;
    } else {
      const uniqueDays = uniqBy(days, "short");

      if (uniqueDays.length === 1) {
        content =
          locale === "en" ? `${uniqueDays[0].long}s` : uniqueDays[0].long;
      } else {
        content = uniqueDays.map(day => day.short).join(", ");
      }
    }
  }
  return content;
}

/**
 * eg: Mo, We, Fr 10am
 * or: Mo 10am, We 11am, Fr 12:30pm
 * NOTE: Watch out for daylight savings time changes, which can cause the same time to be
 *       represented differently in different weeks.
 */
export function weeklyTimeShortString({
  weeklyTimes,
  timezone = OUTSCHOOL_TIMEZONE,
}: {
  weeklyTimes: {
    dayOfWeek: number;
    hour: number;
    minute: number;
  }[];
  timezone?: string;
}) {
  if (weeklyTimes.length === 0) {
    return "";
  }

  // If all the times are the same for all days, return a single time for all days
  // E.g. "Mo, We, Fr 10am"
  if (
    weeklyTimes.every(
      ({ hour, minute }) =>
        hour === weeklyTimes[0].hour && minute === weeklyTimes[0].minute
    )
  ) {
    // Days of week need to be converted from UTC to local time with hour and minute information.
    const daysOfWeek = weeklyTimes.map(({ dayOfWeek, hour, minute }) =>
      dayjs
        .utc()
        .day(dayOfWeek)
        .hour(hour)
        .minute(minute)
        .tz(timezone)
        .format("dd")
    );
    return `${daysOfWeek.join(", ")} @${dayjs
      .utc()
      .hour(weeklyTimes[0].hour)
      .minute(weeklyTimes[0].minute)
      .tz(timezone)
      .format(weeklyTimes[0].minute === 0 ? "ha" : "h:mma")}`;
  }

  // Otherwise return a time for each day, e.g. "Mo 10am, We 11am, Fr 12:30pm"
  return weeklyTimes
    .map(({ dayOfWeek, hour, minute }) => {
      const rawTime = dayjs.utc().day(dayOfWeek).hour(hour).minute(minute);
      const time = rawTime
        .tz(timezone)
        .format(minute === 0 ? "dd @ha" : "dd @h:mma");
      return time;
    })
    .join(", ");
}

/** eg. 01/31 - 12/31 */
export function durationShortString(
  startTime: dayjs.ConfigType,
  endTime: dayjs.ConfigType,
  timezone = OUTSCHOOL_TIMEZONE,
  monthFormat: "short" | "numeric" = "numeric"
): string {
  const start = getDayjs(startTime, timezone);
  const end = getDayjs(endTime, timezone);
  const showYear = !(isThisYear(start, timezone) && isThisYear(end, timezone));
  return formatRangeWithOptions(start, end, {
    month: monthFormat,
    day: "numeric",
    ...(showYear && { year: "2-digit" }),
  });
}

export function calculateDateBounds(
  date: string | Date | null,
  view: string | null,
  userTimeZone: string
): { start: Date; end: Date } {
  const start = dayjs.tz(date || undefined, userTimeZone);
  const end = dayjs.tz(date || undefined, userTimeZone);

  switch (view) {
    case "month":
      return {
        start: start.startOf("month").subtract(1, "week").toDate(),
        end: end.endOf("month").add(1, "week").toDate(),
      };
    case "week":
      return {
        start: start.startOf("week").toDate(),
        end: end.endOf("week").toDate(),
      };
    default:
      // Agenda or List View
      return {
        start: start.startOf("minute").toDate(),
        end: end.endOf("minute").add(10, "years").toDate(),
      };
  }
}
