import { gql } from "@outschool/ui-apollo";

import { PrivateClassMessageFragment } from "../fragments/PrivateClassMessage";

export const privateClassMessagesQuery = gql`
  query PrivateClassMessages($sectionUid: ID!, $learnerUid: ID!) {
    privateClassMessages(sectionUid: $sectionUid, learnerUid: $learnerUid) {
      ...PrivateClassMessageFragment
    }
  }
  ${PrivateClassMessageFragment}
`;
