import { Box, Icon, Theme, Typography } from "@outschool/backpack";
import { ActivityOverview_LeaderFragment } from "@outschool/gql-frontend-generated";
import { faChevronDown } from "@outschool/icons";
import {
  BASE_LOCALE,
  useLocale,
  useTranslation,
} from "@outschool/localization";
import { leaderPath, leaderUrl } from "@outschool/routes";
import { UserHeadshotImage } from "@outschool/ui-components-shared";
import { Screen, pxToRem, useLinkComponent } from "@outschool/ui-utils";
import React from "react";

import SimpleRatings from "../Reviews/SimpleRatings";
import StarRatings from "../Reviews/StarRatings";
import EducatorTierBadge from "./EducatorTierBadge";

export function TeacherLink({
  leader,
  children,
}: {
  leader: ActivityOverview_LeaderFragment;
  children: React.ReactElement;
}) {
  const Link = useLinkComponent();
  const locale = useLocale();
  const isMobile = Screen.useIsMobile();

  const target = !isMobile ? "_blank" : undefined;

  // locales other than "en" will have the locale in the path
  const to =
    locale !== BASE_LOCALE || isMobile
      ? leaderPath(leader.uid, leader.leader_link)
      : leaderUrl(leader.uid, leader.leader_link);

  return leader.suspended_at === null ? (
    <Link target={target} trackingName={"leader_link_activity_header"} to={to}>
      {children}
    </Link>
  ) : (
    children
  );
}

export default function TeacherNameAndRatings({
  leader,
  onClickViewTeacher,
  mobileCdp = false,
  emphasizedTeacherName = false,
  isTeacherDetails = false,
  onClickViewReviews,
  clipTeacherName = true,
}: {
  leader: ActivityOverview_LeaderFragment;
  onClickViewTeacher?: (event: React.SyntheticEvent) => void;
  mobileCdp?: boolean;
  emphasizedTeacherName?: boolean;
  isTeacherDetails?: boolean;
  onClickViewReviews?: (event: React.SyntheticEvent) => void;
  clipTeacherName?: boolean;
}) {
  const { t } = useTranslation(
    "ui-components-website\\src\\Teachers\\TeacherNameAndRatings"
  );
  const leaderHasReviews = leader.reviewCount > 0;
  const shouldEnableLink = !leaderHasReviews || !onClickViewTeacher;
  const WrappedTeacherLink = shouldEnableLink ? TeacherLink : Box;

  const fontColor = "primary.800";
  const reviewsSx = {
    fontSize: mobileCdp ? "1.6rem" : "1.8rem",
    lineHeight: "125%",
    fontWeight: 500,
    "&:hover": {
      textDecoration: "underline",
    },
    "&:focus": {
      textDecoration: "underline",
    },
  };

  const ratingSx = {
    fontSize: mobileCdp ? pxToRem(16) : pxToRem(18),
    lineHeight: "125%",
    fontWeight: 500,
    "&:hover": {
      textDecoration: "underline",
    },
    "&:focus": {
      textDecoration: "underline",
    },
  };

  const educatorBadgeSx = isTeacherDetails
    ? {
        flexDirection: "column",
        alignItems: "flexStart",
        justifyContent: "center",
      }
    : [];
  const educatorBadge = (
    <Box sx={{ display: "flex", gap: pxToRem(8), ...educatorBadgeSx }}>
      {leader.educatorTiers?.map(tier => (
        <EducatorTierBadge key={tier} tier={tier} />
      ))}
    </Box>
  );

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const month = new Intl.DateTimeFormat("en", { month: "long" }).format(date);
    const year = date.getFullYear();
    return `${month}, ${year}`;
  };

  const simpleRatings = leaderHasReviews &&
    leader.averageActivityStarRating !== null && (
      <SimpleRatings
        starRating={leader.averageActivityStarRating}
        reviewCount={leader.reviewCount}
        spellOutReviews={mobileCdp}
        isBolded={mobileCdp ? false : undefined}
        ratingSx={{ ...ratingSx }}
        reviewsSx={{ ...reviewsSx }}
        onClickViewReviews={onClickViewReviews}
      />
    );

  return (
    <Box sx={{ maxWidth: isTeacherDetails ? "39rem" : "43.6rem" }}>
      <Box flex sx={{ gap: pxToRem(12), alignItems: "center" }}>
        <TeacherLink leader={leader}>
          <UserHeadshotImage
            user={leader}
            size={62}
            onClick={onClickViewTeacher}
            sx={{
              cursor: "pointer",
            }}
          />
        </TeacherLink>
        <Box
          flex
          sx={{
            flexDirection: "column",
            gap: "4px",
            minWidth: 0,
          }}
        >
          <Box>
            <Box sx={{ cursor: "pointer" }}>
              <WrappedTeacherLink leader={leader}>
                <Typography
                  variant={emphasizedTeacherName ? "h5" : "h6"}
                  sx={(theme: Theme) => ({
                    display: "block",
                    color: fontColor,
                    ...(clipTeacherName
                      ? {
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          [theme.breakpoints.down("md")]: {
                            whiteSpace: "normal",
                          },
                        }
                      : {}),
                  })}
                  onClick={onClickViewTeacher}
                >
                  {leader.name}
                </Typography>
              </WrappedTeacherLink>
            </Box>
            {isTeacherDetails && !!leader.teacher_approved_at && (
              <Box>
                <Typography
                  sx={{
                    color: "grey.900",
                    fontSize: pxToRem(14),
                    fontWeight: 400,
                    lineHeight: pxToRem(21),
                  }}
                >
                  {t("Joined {{approvedDate}}", {
                    approvedDate: formatDate(leader.teacher_approved_at),
                  })}
                </Typography>
              </Box>
            )}
          </Box>
          {!isTeacherDetails && (
            <Box
              flex
              sx={{ alignItems: "center", flexWrap: "wrap", gap: "0.8rem" }}
            >
              {simpleRatings}
              <Icon
                icon={faChevronDown}
                sx={{
                  cursor: "pointer",
                  fontSize: "0.8em",
                }}
                onClick={onClickViewReviews}
              />
              {educatorBadge}
            </Box>
          )}
        </Box>
      </Box>
      {isTeacherDetails && (
        <Box
          flex
          sx={{
            alignItems: "center",
            justifyContent: "flex-start",
            paddingTop: pxToRem(16),
            width: "100%",
          }}
        >
          {leader.averageActivityStarRating &&
          leader.averageActivityStarRating > 0 ? (
            <Box
              flex
              sx={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingRight: pxToRem(18),
              }}
            >
              <Typography
                sx={(theme: Theme) => ({
                  fontSize: pxToRem(32),
                  color: "common.black",
                  fontWeight: 700,
                  lineHeight: pxToRem(38.4),
                  letterSpacing: pxToRem(-0.2),
                  [theme.breakpoints.down("md")]: {
                    fontSize: pxToRem(28),
                    lineHeight: pxToRem(35),
                    letterSpacing: undefined,
                  },
                })}
              >
                {leader.averageActivityStarRating?.toFixed(1)}
              </Typography>
              <StarRatings
                size={pxToRem(12)}
                rating={leader.averageActivityStarRating ?? 0}
              />
            </Box>
          ) : null}
          {leader.averageActivityStarRating &&
          leader.averageActivityStarRating > 0 &&
          leader?.reviewCount > 0 ? (
            <Box sx={{ display: "grid" }}>
              <Box
                sx={{
                  borderRightWidth: pxToRem(1),
                  borderRightStyle: "transparent",
                  height: pxToRem(14),
                }}
              ></Box>
              <Box
                sx={{
                  borderRightWidth: pxToRem(1),
                  borderRightStyle: "solid",
                  borderColor: "grey.300",
                  height: pxToRem(41),
                }}
              ></Box>
            </Box>
          ) : null}
          {leader?.reviewCount > 0 ? (
            <Box
              flex
              sx={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingX: pxToRem(18),
              }}
            >
              <Typography
                sx={(theme: Theme) => ({
                  fontSize: pxToRem(32),
                  color: "common.black",
                  fontWeight: 700,
                  lineHeight: pxToRem(38.4),
                  letterSpacing: pxToRem(-0.2),
                  [theme.breakpoints.down("md")]: {
                    fontSize: pxToRem(28),
                    lineHeight: pxToRem(35),
                    letterSpacing: undefined,
                  },
                })}
              >
                {leader.reviewCount}
              </Typography>
              <Typography
                sx={{
                  fontSize: pxToRem(14),
                  color: "grey.900",
                  fontWeight: 500,
                  lineHeight: pxToRem(18.2),
                  letterSpacing: pxToRem(0.1),
                }}
              >
                {t`reviews`}
              </Typography>
            </Box>
          ) : null}
          {leader?.reviewCount > 0 && leader.educatorTiers.length > 0 ? (
            <Box sx={{ display: "grid" }}>
              <Box
                sx={{
                  borderRightWidth: pxToRem(1),
                  borderRightStyle: "transparent",
                  height: pxToRem(14),
                }}
              ></Box>
              <Box
                sx={{
                  borderRightWidth: pxToRem(1),
                  borderRightStyle: "solid",
                  borderColor: "grey.300",
                  height: pxToRem(41),
                }}
              ></Box>
            </Box>
          ) : null}
          <Box
            flex
            sx={{
              paddingLeft: pxToRem(18),
            }}
          >
            {educatorBadge}
          </Box>
        </Box>
      )}
    </Box>
  );
}
