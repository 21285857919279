import { Box, SxProps, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import * as FilestackUrls from "@outschool/filestack-urls";
import { BoxButton } from "@outschool/ui-legacy-component-library";
import { Screen, useBrowserSupportsWebpAnimation } from "@outschool/ui-utils";
import { SystemStyleObject } from "@styled-system/css";
import React, { useState } from "react";

import { useClubContext, useVideoPlayerComponent } from "../../providers";
import LazyBox from "../LazyBox";
import TextWithLinks from "../TextWithLinks";
import { CONTENT_BOX_BORDER_RADIUS } from "./Club";
import ClubContentHeader, { Sender } from "./ClubContentHeader";
import { BackgroundColorPickerPalette } from "./ClubPostCommentBackgroundColorPickerPalette";
import ImageModal from "./ImageModal";
import { ManageContentOptions } from "./ManageContentPopover";

export const POST_ASPECT_RATIO = 4 / 3;
// TODO: Find a better way to scale font size
export const SMALL_CARD_FONT_SIZE = 27;
export const DEFAULT_CARD_FONT_SIZE = 40;
export const SHARED_POST_SX: SystemStyleObject = {
  display: "flex",
  "&:after": {
    content: '""',
    display: "block",
    height: 0,
    width: 0,
    paddingBottom: `calc(${POST_ASPECT_RATIO} * 100%)`,
  },
  flexDirection: "column",
  fontFamily: "'Ginto Normal',sans-serif",
  justifyContent: "space-between",
  fontSize: "2em",
  borderRadius: CONTENT_BOX_BORDER_RADIUS,
  padding: "16px",
  position: "relative",
  fontWeight: "bold",
  boxShadow:
    "0px 9px 28px rgba(0, 0, 0, 0.25), 0px 2px 0px rgba(0, 0, 0, 0.03)",
};

const CARD_CONTENT_SX: SystemStyleObject = {
  position: "absolute",
  padding: "1em",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  overflow: "hidden",
  borderRadius: CONTENT_BOX_BORDER_RADIUS,
};

export const MAX_POST_PREVIEW_TEXT_LENGTH = 105;

function getFirstParagraphFromText(text: string): string {
  const lines = text.split("\n");
  if (lines.length > 0) {
    // Only display the first paragraph if there is a line break
    return lines[0];
  }
  return text;
}
interface PostCardWithTextProps {
  text?: string;
  title?: string;
  sx?: SxProps;
  textStyle?: any;
  maxTextLength?: number;
  hideTextOnHover?: boolean;
}
export function PostCardWithText({
  text = "",
  title,
  maxTextLength = MAX_POST_PREVIEW_TEXT_LENGTH,
  sx,
  textStyle = {},
  hideTextOnHover = false,
}: PostCardWithTextProps) {
  const isMobile = Screen.useIsMobile();
  const displayedText = title || getFirstParagraphFromText(text);
  const hoverStyles = hideTextOnHover
    ? {
        "&:hover > pre": {
          opacity: 0,
        },
      }
    : undefined;
  const hideText = isMobile && hideTextOnHover;
  return (
    <Box
      sx={[
        {
          ...CARD_CONTENT_SX,
          paddingTop: "1.5em",
          display: "flex",
          alignItems: "end",
          overflow: "hidden",
          transition: "opacity 0.6s",
          fontSize: DEFAULT_CARD_FONT_SIZE,

          a: {
            fontWeight: 900,
            color: "#fff",
            textDecoration: "underline",
          },

          ...hoverStyles,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {!hideText && (
        <pre
          style={{
            display: "flex",
            position: "relative",
            top: "small",
            left: "small",
            color: "white",
            wordBreak: "break-word",
            margin: 0,
            alignSelf: "flex-end",
            maxHeight: "100%",
            transition: "opacity 0.6s",
            alignItems: "end",
            ...textStyle,
          }}
        >
          {displayedText && (
            <TextWithLinks
              clickableLinks={true}
              text={displayedText.trim()}
              hideEmails={true}
              maxTextLength={maxTextLength}
            />
          )}
        </pre>
      )}
    </Box>
  );
}

const getMediaTextStyle = (hasText: boolean): SystemStyleObject => {
  return {
    position: "absolute",
    bottom: 0,
    left: 0,
    background: hasText
      ? "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%)"
      : undefined,
    padding: "16px",
    width: "100%",
    minHeight: "60%",
  };
};

interface PostCardWithImageProps {
  text?: string;
  imageUrl: string;
  textSx?: SystemStyleObject;
  title?: string;
  hideTextOnHover?: boolean;
}

export function PostCardWithImage({
  imageUrl,
  text = "",
  textSx = {},
  title = "",
  hideTextOnHover = false,
}: PostCardWithImageProps) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const supportsWebP = useBrowserSupportsWebpAnimation();
  return (
    <BoxButton
      onClick={() => {
        setModalOpen(true);
      }}
    >
      <ImageModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        imageUrl={imageUrl}
      />
      <PostCardWithText
        text={text}
        maxTextLength={MAX_POST_PREVIEW_TEXT_LENGTH}
        sx={{
          backgroundImage: `url(${FilestackUrls.imageUrl(
            imageUrl,
            {
              w: 450,
              h: 600,
              fit: "crop",
            },
            supportsWebP ? "webp" : "jpg"
          )})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: 0,
        }}
        textStyle={{ ...getMediaTextStyle(Boolean(text || title)), ...textSx }}
        title={title}
        hideTextOnHover={hideTextOnHover}
      />
    </BoxButton>
  );
}

interface PostWithVideoProps {
  text?: string;
  videoUrl?: string;
  textSx?: SxProps;
  videoSx?: SystemStyleObject;
  hideTextOnHoverOrIfMobileScreen?: boolean;
  loop?: boolean;
  muted?: boolean;
  title?: string;
  hideTextOnHover?: boolean;
}

export function PostCardWithVideo({
  text,
  videoUrl,
  textSx = {},
  videoSx = {},
  muted = false,
  loop = false,
  title = "",
  hideTextOnHover = false,
}: PostWithVideoProps) {
  const hoverSx = hideTextOnHover
    ? {
        "&:hover > div:nth-of-type(2)": {
          // Allows user to interact with video controls
          pointerEvents: "none",
          opacity: 0,
        },
      }
    : undefined;
  return (
    <Box
      sx={{
        transition: "opacity 0.6s",
        ...hoverSx,
      }}
    >
      {videoUrl && (
        <ClubPostVideoPreview
          videoUrl={videoUrl}
          sx={videoSx}
          muted={muted}
          loop={loop}
        />
      )}
      <PostCardWithText
        text={text}
        maxTextLength={MAX_POST_PREVIEW_TEXT_LENGTH}
        sx={textSx as any}
        textStyle={{
          ...getMediaTextStyle(Boolean(text || title)),
        }}
        title={title}
        hideTextOnHover={hideTextOnHover}
      />
    </Box>
  );
}

interface ClubPostContentHeaderProps {
  sender: Sender;
  isHoveringOverPost?: boolean;
  showReplyIcon?: boolean;
  isPostPromoted?: boolean;
  isPostPinned?: boolean;
  manageContentOptions?: Partial<ManageContentOptions>;
}

export function ClubPostContentHeader({
  sender,
  isHoveringOverPost,
  manageContentOptions = {},
  showReplyIcon,
  isPostPromoted,
  isPostPinned,
}: ClubPostContentHeaderProps) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
      }}
    >
      <ClubContentHeader
        sender={sender}
        isHoveringOverPost={isHoveringOverPost}
        manageContentOptions={manageContentOptions}
        showReplyIcon={showReplyIcon}
        isContentPromoted={isPostPromoted}
        isContentPinned={isPostPinned}
        nameSx={{
          fontSize: 16,
        }}
      />
    </Box>
  );
}

interface ClubPostVideoPreviewProps {
  videoUrl: string;
  muted?: boolean;
  loop?: boolean;
  sx?: SystemStyleObject;
}
function ClubPostVideoPreview({
  videoUrl,
  sx = {},
  muted = false,
  loop = false,
}: ClubPostVideoPreviewProps) {
  const VideoPlayer = useVideoPlayerComponent();
  return (
    <LazyBox placeholder={<Box />}>
      <VideoPlayer
        sx={{
          position: "absolute",
          overflow: "hidden",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          background: "rgba(0,0,0,0.2)",
          borderRadius: CONTENT_BOX_BORDER_RADIUS,
          ...sx,
        }}
        videoSx={{
          objectFit: "cover",
          height: "100%",
        }}
        src={videoUrl}
        muted={muted}
        autoPlay={true}
        loop={loop}
      />
    </LazyBox>
  );
}

interface PostContent {
  sender: Sender;
  text?: string;
  imageUrl?: string;
  videoUrl?: string;
  cardSx?: SxProps;
  title?: string;
  isPostPromoted?: boolean;
  isPostPinned?: boolean;
  hideFullPostText?: boolean;
  manageContentOptions?: Partial<ManageContentOptions>;
  isNewPost?: boolean;
  setBackgroundColor?: React.Dispatch<React.SetStateAction<string>>;
  initialBackgroundColor?: string | null;
}
export function PostContent({
  text,
  imageUrl,
  videoUrl,
  sender,
  cardSx = {},
  title,
  manageContentOptions,
  isPostPromoted = false,
  isPostPinned = false,
  hideFullPostText = false,
  isNewPost = false,
  setBackgroundColor = () => {},
  initialBackgroundColor = null,
}: PostContent) {
  const { useClubPostAndCommentDefaultBackgroundColor, currentUserOrLearner } =
    useClubContext();
  const [isHoveringOverPost, setIsHoveringOverPost] = useState<boolean>(false);
  const hasMedia = imageUrl || videoUrl;
  const defaultBackgroundColor = useClubPostAndCommentDefaultBackgroundColor({
    senderUid: sender.uid,
  });
  const backgroundColor =
    initialBackgroundColor === null
      ? defaultBackgroundColor
      : initialBackgroundColor;
  let PostCardContent;
  if (imageUrl) {
    PostCardContent = (
      <PostCardWithImage
        title={title}
        imageUrl={imageUrl}
        text={text}
        hideTextOnHover={true}
      />
    );
  } else if (videoUrl) {
    PostCardContent = (
      <PostCardWithVideo
        title={title}
        text={text}
        videoUrl={videoUrl}
        hideTextOnHover={true}
      />
    );
  } else {
    PostCardContent = (
      <PostCardWithText
        title={title}
        text={text}
        maxTextLength={MAX_POST_PREVIEW_TEXT_LENGTH}
      />
    );
  }

  return (
    <>
      <Box
        onMouseEnter={() => setIsHoveringOverPost(true)}
        onMouseLeave={() => setIsHoveringOverPost(false)}
        sx={[
          {
            alignItems: "flex-end",
            height: "max-content",
            background: hasMedia ? "initial" : backgroundColor,
          },
          ...(Array.isArray(SHARED_POST_SX)
            ? SHARED_POST_SX
            : [SHARED_POST_SX]),
          ...(Array.isArray(cardSx) ? cardSx : [cardSx]),
        ]}
      >
        <ClubPostContentHeader
          sender={sender}
          isHoveringOverPost={isHoveringOverPost}
          manageContentOptions={manageContentOptions}
          isPostPromoted={isPostPromoted}
          isPostPinned={isPostPinned}
        />
        {PostCardContent}
        {isNewPost &&
          !hasMedia &&
          currentUserOrLearner?.__typename === "CurrentLearner" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <BackgroundColorPickerPalette
                onClick={setBackgroundColor}
                isPaletteVertical={true}
                chosenColor={backgroundColor}
              />
            </Box>
          )}
      </Box>
      {!hideFullPostText && <FullPostText title={title} text={text} />}
    </>
  );
}

interface FullPostTextProps {
  text?: string;
  title?: string;
}
function FullPostText({ text = "", title = "" }: FullPostTextProps) {
  return (
    <Box
      sx={{
        display: title || text ? "block" : "none",
        background: "white",
        padding: "2em",
        lineHeight: "150%",
        borderRadius: "16px",
        margin: "2em",
        width: "100%",
        maxWidth: "800px",
      }}
    >
      <pre
        style={{
          position: "relative",
          top: "small",
          left: "small",
          wordBreak: "break-word",
          margin: 0,
          fontFamily: "Helvetica, Arial",
          fontWeight: 500,
        }}
      >
        {title && (
          <Typography
            variant="h4"
            sx={{
              marginBottom: "0.5em",
            }}
          >
            <TextWithLinks
              clickableLinks={true}
              text={title.trim()}
              hideEmails={true}
            />
          </Typography>
        )}
        {text && (
          <TextWithLinks
            clickableLinks={true}
            text={text.trim()}
            hideEmails={true}
          />
        )}
      </pre>
    </Box>
  );
}
