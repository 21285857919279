import { Button } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import React, { FC } from "react";

type LoadMoreButtonType = "comment" | "post";

interface LoadMoreButtonProps {
  remainingCount: number;
  loadType?: LoadMoreButtonType;
  onClick: () => void;
}

const LoadMoreButton: FC<LoadMoreButtonProps> = ({
  remainingCount,
  loadType = "comment",
  onClick,
}) => {
  const buttonText = useButtonText(loadType, remainingCount);
  return remainingCount > 0 ? (
    <Button
      variant="link"
      onClick={onClick}
      sx={{
        mb: "1em",
      }}
      data-test-id="ClassPostLoadMoreButton"
    >
      {buttonText}
    </Button>
  ) : null;
};

function useButtonText(loadType: LoadMoreButtonType, remainingCount: number) {
  const { t } = useTranslation(
    "ui-components-classroom\\classroom\\common\\LoadMoreButton"
  );
  switch (loadType) {
    case "comment":
      return t("See {{remainingCount}} more comment", {
        defaultValue_plural: "See {{remainingCount}} more comments",
        count: remainingCount,
        remainingCount,
      });
    case "post":
      return t("See {{remainingCount}} more post", {
        defaultValue_plural: "See {{remainingCount}} more posts",
        count: remainingCount,
        remainingCount,
      });
    default:
      return t("See more");
  }
}

export default LoadMoreButton;
