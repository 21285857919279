import { gql } from "@outschool/ui-apollo";

export const MessageSenderFragment = gql`
  fragment MessageSenderFragment on MessageSender {
    uid
    name
    photo
    avatar
    isLearner
  }
`;
