import { getReferrerAttribution } from "@outschool/attribution";
import { Box, Icon } from "@outschool/backpack";
import { faFacebook } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import {
  facebookAuthRedirectUrl,
  facebookAuthUrl,
  learnerFacebookAuthRedirectUrl,
} from "@outschool/routes";
import { FacebookButton } from "@outschool/ui-components-shared";
import { useNavigation } from "@outschool/ui-utils";
import React from "react";

type ContinueWithFacebookProps = {
  clientId: string;
  isLearnerApp?: boolean;
  setAuthStrategy: (strategy: "facebook") => void;
  trackingName: string;
  getAnonymousId?: () => Promise<string | null>;
  onClick?: () => void;
};

export default function ContinueWithFacebook({
  clientId,
  isLearnerApp,
  setAuthStrategy,
  trackingName,
  getAnonymousId = () => Promise.resolve(null),
  onClick: onClickProp = () => {},
}: ContinueWithFacebookProps) {
  const { t } = useTranslation("ui-auth\\src\\ContinueWithFacebook");
  const navigate = useNavigation();
  const onClick = async () => {
    setAuthStrategy("facebook");
    const anonymousId = await getAnonymousId();
    const redirectUri = isLearnerApp
      ? learnerFacebookAuthRedirectUrl()
      : facebookAuthRedirectUrl();
    const loginRedirectRoute = facebookAuthUrl(clientId, redirectUri, {
      ...getReferrerAttribution(),
      anonymousId,
    });
    // See http://caniuse.com/#feat=referrer-policy
    if (window && window.document) {
      const meta = window.document.createElement("meta");
      meta.setAttribute("name", "referrer");
      meta.setAttribute("content", "never");
      window.document.head.appendChild(meta);
    }

    onClickProp();
    // perform redirect
    navigate(loginRedirectRoute, { hardNav: true });
  };

  return (
    <FacebookButton
      onClick={onClick}
      trackingName={trackingName}
      sx={{ padding: "0 !important", display: "flex" }}
    >
      <Box
        sx={{
          fontSize: "1.5em",
          padding: "10px",
          width: "44px",
        }}
      >
        <Icon
          icon={faFacebook}
          sx={{
            display: "block",
          }}
        />
      </Box>
      <Box
        sx={{
          flex: 1,
          padding: "12.5px",
          minWidth: "215px",
        }}
      >
        {t`Continue with Facebook`}
      </Box>
    </FacebookButton>
  );
}
