import { Box, Button, Icon, Typography } from "@outschool/backpack";
import { filestackPreviewUrl } from "@outschool/filestack-urls";
import { faFile, faFileImage, faFilePdf, faTrashAlt } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import React from "react";

import ExternalLink from "../ExternalLink";
import { Attachment, OnDeleteHandler } from "./types";

/*

A business-logic aware component which renders an Attachment as a block element
with file name + icon

 */
export interface AttachmentLineProps {
  attachment: Attachment; // the attachment to display
  onDelete: OnDeleteHandler; // if truthy, show a delete Button and use this as its onClick
}
export default function AttachmentLine({
  attachment,
  onDelete,
}: AttachmentLineProps) {
  const { t } = useTranslation(
    "ui-components-shared\\components\\attachments\\AttachmentLine"
  );
  const { file } = attachment;
  const fileSize =
    file.size > 1024 * 1024
      ? `${Math.round(file.size / 1024 / 1024)} MB`
      : `${Math.round(file.size / 1024)} KB`;

  return (
    <Box
      flex
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "neutral.50",
        padding: 12,
        borderRadius: 8,
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <ExternalLink
          url={file.url}
          trackingName="attachment_line"
          trackingSharedProperties={{
            attachment_uid: attachment?.uid,
            file_mimetype: attachment?.file?.mimetype,
            file_url: attachment?.file.url,
          }}
        >
          <Icon icon={getIconForMimeType(file.mimetype)} /> {file.filename}
        </ExternalLink>{" "}
        <Typography
          variant="inherit"
          sx={{
            color: "grey.300",
            whiteSpace: "nowrap",
          }}
        >
          ({fileSize})
        </Typography>
        {hasPreviewSupport(file.mimetype, file.filename) && (
          <ExternalLink url={filestackPreviewUrl(file.url)}>
            {" "}
            {t("(preview)")}
          </ExternalLink>
        )}
      </Box>
      {onDelete && (
        <React.Fragment>
          {" "}
          <Button
            variant="text"
            color="primary"
            onClick={(evt: any) => onDelete && onDelete(attachment, evt)}
            title={t("Delete Attachment")}
            startIcon={<Icon icon={faTrashAlt} />}
          >
            {t("Delete")}
          </Button>
        </React.Fragment>
      )}
    </Box>
  );
}

// TODO: should these be broken into a util file?

function getIconForMimeType(mimetype: string | null | undefined) {
  switch (mimetype) {
    case "image/jpeg":
    case "image/png":
      return faFileImage;
    case "application/pdf":
      return faFilePdf;
    default:
      return faFile;
  }
}

function hasPreviewSupport(
  mimetype: string | null | undefined,
  fileName: string
) {
  switch (mimetype) {
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/msword":
    case "application/vnd.oasis.opendocument.text":
      return true;
    case "application/octet-stream":
      return hasPreviewSupportByExtension(fileName);
    default:
      return false;
  }
}

function hasPreviewSupportByExtension(fileName: string) {
  const tokens = fileName.split(".");
  const fileExtension = tokens[tokens.length - 1];
  switch (fileExtension) {
    case "doc":
    case "docx":
    case "ppt":
    case "pptx":
    case "odt":
      return true;
    default:
      return false;
  }
}
