import { Box, Icon, SxProps, Typography } from "@outschool/backpack";
import { faCalendar, faCalendarXmark } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import { formatDateTime } from "@outschool/time";
import React from "react";

import LessonNumber from "./LessonNumber";
import UnitNumber from "./UnitNumber";

type UnitLessonHeaderProps = {
  lessonNumber?: number | null;
  unitNumber?: number | null;
  unitTitle?: string | null;
  showMeetingDate?: boolean;
  sx?: SxProps;
} & MeetingDateAndTimeZone;

// If meetingDate is provided, timeZone must also be provided.
type MeetingDateAndTimeZone =
  | {
      meetingDate?: never;
      timeZone?: never;
    }
  | {
      meetingDate: Date | null;
      timeZone: string;
    };

export default function UnitLessonHeader({
  lessonNumber,
  meetingDate,
  timeZone,
  showMeetingDate = false,
  unitNumber,
  unitTitle,
  sx,
}: UnitLessonHeaderProps) {
  const { t } = useTranslation(
    "ui-components-classroom\\components\\syllabus\\UnitLessonHeader"
  );
  return (
    <Box>
      <Box
        flex
        sx={[
          {
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1em",
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Box sx={{ width: "50%" }}>
          <LessonNumber lessonNumber={lessonNumber} />
        </Box>
        <Box sx={{ width: "50%" }}>
          <UnitNumber unitNumber={unitNumber} unitTitle={unitTitle} />
        </Box>
      </Box>
      {showMeetingDate && (
        <Box sx={{ ...(!meetingDate && { color: "grey.500" }) }}>
          <Icon icon={meetingDate ? faCalendar : faCalendarXmark} />{" "}
          <Typography variant="body2">
            {meetingDate
              ? t("Meeting: {{meetingDate}}", {
                  meetingDate: formatDateTime(meetingDate, timeZone, true),
                })
              : t("No meeting scheduled")}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
