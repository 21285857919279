import { Box, SxProps } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { makeEnterKeyPressHandler } from "@outschool/ui-utils";
import React, { PropsWithChildren } from "react";

interface ClassCardBaseProps {
  sx?: SxProps;
  onClick?: () => void;
}

const ClassCardBase: React.FC<PropsWithChildren<ClassCardBaseProps>> = ({
  children,
  onClick,
  sx,
}) => {
  const onClickProps = onClick
    ? {
        onClick,
        tabIndex: 0,
        onKeyPress: makeEnterKeyPressHandler(onClick),
      }
    : {};
  return (
    <Box
      flex
      sx={[
        {
          flexDirection: "column",
          borderRadius: 16,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.25)",
          position: "relative",
          backgroundColor: "common.white",
          width: "100%",
          cursor: onClick ? "pointer" : undefined,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...onClickProps}
    >
      {children}
    </Box>
  );
};

export default ClassCardBase;
