import { gql } from "@outschool/ui-apollo";

import {
  ClassPostCommentFragment,
  ClassPostFragments,
} from "../fragments/Classroom";
import { MessageContentFragment } from "../fragments/MessageContent";
import { MessageSenderFragment } from "../fragments/MessageSender";

export const updateClubPostMutation = gql`
  mutation updateClassPostInClub(
    $classPostUid: ID!
    $messageContentInput: MessageContentInput
    $title: String
    $plainText: Boolean
  ) {
    updateClassPost(
      classPostUid: $classPostUid
      messageContentInput: $messageContentInput
      title: $title
    ) {
      ...ClassPost_classPost
    }
  }
  ${ClassPostFragments.classPost}
`;

export const updateClassPostCommentMutation = gql`
  mutation updateClassPostComment(
    $classPostCommentUid: ID!
    $messageContentInput: MessageContentInput
    $plainText: Boolean
  ) {
    updateClassPostComment(
      classPostCommentUid: $classPostCommentUid
      messageContentInput: $messageContentInput
    ) {
      ...ClassPost_comment
    }
  }
  ${ClassPostCommentFragment}
`;

export const addClassPostCommentMutation = gql`
  mutation addClassPostComment(
    $classPostCommentInput: ClassPostCommentInput!
    $plainText: Boolean
  ) {
    addClassPostComment(classPostCommentInput: $classPostCommentInput) {
      ...ClassPost_comment
    }
  }
  ${ClassPostCommentFragment}
`;

export const deleteClassPostCommentMutation = gql`
  mutation deleteClassPostComment($classPostCommentUid: ID!) {
    deleteClassPostComment(classPostCommentUid: $classPostCommentUid)
  }
`;

export const addClassPostCommentReplyMutation = gql`
  mutation addClassPostCommentReply(
    $classPostCommentReplyInput: ClassPostCommentReplyInput!
  ) {
    addClassPostCommentReply(
      classPostCommentReplyInput: $classPostCommentReplyInput
    ) {
      uid
      sender {
        ...MessageSenderFragment
      }
      messageContent {
        ...MessageContentFragment
      }
      sendAt
      mentionedEnrollmentUids
      mentionedTeacherUid
      backgroundColor
    }
  }
  ${MessageSenderFragment}
  ${MessageContentFragment}
`;

export const promoteClassPostMutation = gql`
  mutation promoteClassPost($classPostUid: ID!) {
    promoteClassPost(classPostUid: $classPostUid)
  }
`;

export const pinClassPostMutation = gql`
  mutation pinClassPost($classPostUid: ID!) {
    pinClassPost(classPostUid: $classPostUid)
  }
`;

export const unpinClassPostMutation = gql`
  mutation unpinClassPost($classPostUid: ID!) {
    unpinClassPost(classPostUid: $classPostUid)
  }
`;
