import { Box, Icon, SxProps } from "@outschool/backpack";
import { faStar, faStarHalfAlt, fasStar } from "@outschool/icons";
import ldNoop from "lodash/noop";
import ldRange from "lodash/range";
import React from "react";

function starIcon(rating: number, starIndex: number) {
  const roundedRating = Math.round(rating * 2) / 2.0;

  if (roundedRating >= starIndex + 1) {
    return fasStar;
  } else if (roundedRating > starIndex) {
    return faStarHalfAlt;
  } else {
    return faStar;
  }
}

interface StarRatingsProps {
  rating: number;
  /**
   * icon font-size with units
   */
  size?: string;
  isSelectable?: boolean;
  color?: string;
  sx?: SxProps;
  starCount?: number;
  onChange?: Function;
}

const StarRatings: React.FC<StarRatingsProps> = ({
  rating,
  size,
  isSelectable = false,
  color = "#FDC840",
  onChange = () => {},
  sx = {},
  starCount = 5,
}) => {
  const [hoveredRating, setHoveredRating] = React.useState<number | null>(null);
  const renderedRating = isSelectable ? hoveredRating || rating : rating;
  return (
    <Box
      sx={[
        {
          display: "inline-flex",
          color: color,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {ldRange(starCount).map(i => {
        return (
          <Icon
            key={i}
            icon={starIcon(renderedRating, i)}
            sx={{
              fontSize: size,
              cursor: isSelectable ? "pointer" : "default",
            }}
            onMouseOver={isSelectable ? () => setHoveredRating(i + 1) : ldNoop}
            onMouseOut={isSelectable ? () => setHoveredRating(null) : ldNoop}
            onClick={isSelectable ? () => onChange(i + 1) : ldNoop}
          />
        );
      })}
    </Box>
  );
};

export default StarRatings;
