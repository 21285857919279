import { gql } from "@outschool/ui-apollo";

export const ActivityOverviewFragments = {
  activity: gql`
    fragment ActivityOverview_activity on Activity {
      uid
      completedEnrollmentCount
      reviewCount
      averageStarRating
      isClub
      title
    }
  `,
  leader: gql`
    fragment ActivityOverview_leader on Leader {
      uid
      name
      suspended_at
      teacher_approved_at
      reviewCount
      averageActivityStarRating
      photo
      leader_link
      details {
        headline
        about
      }
      video {
        file {
          url
          thumbUrl
        }
      }
      educatorTiers
      credentials {
        uid
        degreeType
        degreeSubject
        certificateDiscipline
        otherType
        certificateState
        name
        degreeInstitution
        degreeAdditionalInfo
      }
      ownerOfSellerOrg {
        uid
      }
    }
  `,
};
