import { keyframes } from "@emotion/react";
import { faChevronLeft, faChevronRight } from "@outschool/icons";
import { responsive, useInterval } from "@outschool/ui-utils";
import React from "react";

import BoxButton from "./BoxButton";
import IconButton from "./IconButton";
import LegacyBox from "./LegacyBox";
import LegacyFlex from "./LegacyFlex";
import Space from "./Space";

const FADE_IN = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

interface LoopingCarouselProps {
  items: React.ReactNode[];
  noFade?: boolean;
  intervalMs?: number;
  hideDots?: boolean;
  showNavigationArrows?: boolean;
}

export default function LoopingCarousel({
  items,
  noFade = false,
  intervalMs = 5000,
  hideDots = false,
  showNavigationArrows = false,
}: LoopingCarouselProps) {
  const [carouselIdx, setCarouselIdx] = React.useState(0);
  const [isHovered, setIsHovered] = React.useState(false);
  useInterval(
    () => setCarouselIdx(mod(carouselIdx + 1, items.length)),
    isHovered ? null : intervalMs
  );
  return (
    <LegacyBox
      sx={{ height: "100%", width: "100%" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {showNavigationArrows && (
        <NavigationArrows
          carouselIdx={carouselIdx}
          setCarouselIdx={setCarouselIdx}
          items={items}
        />
      )}
      {items.map((item, idx) =>
        idx !== carouselIdx ? null : (
          <LegacyFlex
            sx={{
              animation: noFade ? undefined : `${FADE_IN} ease 3s`,
              justifyContent: "center",
            }}
            key={idx}
          >
            {item}
          </LegacyFlex>
        )
      )}
      <Space y="medium" />
      {!hideDots && (
        <NavigationDots
          carouselIdx={carouselIdx}
          setCarouselIdx={setCarouselIdx}
          items={items}
        />
      )}
    </LegacyBox>
  );
}

interface NavigationProps {
  items: React.ReactNode[];
  carouselIdx: number;
  setCarouselIdx: React.Dispatch<React.SetStateAction<number>>;
}

function NavigationArrows({
  items,
  setCarouselIdx,
  carouselIdx,
}: NavigationProps) {
  const totalItems = items.length;
  const isLeftArrowDisabled = carouselIdx === 0;
  const isRightArrowDisabled = carouselIdx + 1 === items.length;

  return (
    <LegacyFlex
      sx={{
        width: "100%",
        justifyContent: responsive({ default: "center", small: "flex-end" }),
        alignItems: "center",
        paddingRight: responsive({ default: 0, small: 24 }),
      }}
    >
      <IconButton
        iconProps={{ sx: { fontSize: "1.333em" } }}
        disabled={isLeftArrowDisabled}
        sx={{
          color: isLeftArrowDisabled ? "gray300" : "peacock700",
          "&:hover:disabled, &:active:disabled": {
            color: "gray300",
          },
        }}
        icon={faChevronLeft}
        onClick={() => setCarouselIdx(mod(carouselIdx - 1, totalItems))}
      />
      <span style={{ fontWeight: 500 }}>
        {carouselIdx + 1}/{totalItems}
      </span>
      <IconButton
        iconProps={{ sx: { fontSize: "1.333em" } }}
        disabled={isRightArrowDisabled}
        sx={{
          color: isRightArrowDisabled ? "gray300" : "peacock700",
          "&:hover:disabled, &:active:disabled": {
            color: "gray300",
          },
        }}
        icon={faChevronRight}
        onClick={() => setCarouselIdx(mod(carouselIdx + 1, totalItems))}
      />
    </LegacyFlex>
  );
}

function NavigationDots({
  items,
  carouselIdx,
  setCarouselIdx,
}: NavigationProps) {
  return (
    <LegacyFlex
      sx={{
        width: "59px",
        alignItems: "center",
        margin: "0 auto",
        justifyContent: "space-between",
      }}
    >
      {items.map((_item, idx) => (
        <BoxButton
          key={idx}
          sx={{
            width: "11px",
            height: "11px",
            borderRadius: "50%",
            background: "#4B01D4",
            opacity: idx === carouselIdx ? 1 : 0.5,
            cursor: "pointer",
          }}
          onClick={() => setCarouselIdx(idx)}
        />
      ))}
    </LegacyFlex>
  );
}

function mod(n: number, total: number) {
  return ((n % total) + total) % total;
}
