import { gql } from "@outschool/ui-apollo";

import { AttachmentFragment } from "./Attachment";

export const MessageContentFragment = gql`
  fragment MessageContentFragment on MessageContent {
    uid
    text
    htmlText: text(mimeType: TEXT_HTML)
    originalMimeType
    attachments {
      ...AttachmentFragment
    }
    video {
      ...AttachmentFragment
    }
    editedAt
  }
  ${AttachmentFragment}
`;

export const MessageContentWithMentionsFragment = gql`
  fragment MessageContentWithMentionsFragment on MessageContent {
    uid
    text(plainText: $plainText)
    htmlText: text(mimeType: TEXT_HTML)
    originalMimeType
    attachments {
      ...AttachmentFragment
    }
    video {
      ...AttachmentFragment
    }
  }
  ${AttachmentFragment}
`;
