// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@outschool/backpack";
import { addParamsToUrl, queryStringToObject } from "@outschool/routes";
import { useIsMobile } from "@outschool/ui-utils";
import React, { useEffect, useState } from "react";

export const YOUTUBE_BASE_REGEX =
  /(https?:\/\/)?(www\.)?(youtube.com|youtu.be)/i;
export const YOUTUBE_WATCH_ID_REGEX =
  /(https?:\/\/)?(www\.)?youtube.com\/watch\?.*v=([^\s/<>]+)/i;
export const YOUTUBE_USER_ID_REGEX =
  /(https?:\/\/)?(www\.)?youtube.com\/user\/([^\s/<>]+)/i;
export const YOUTUBE_CHANNEL_ID_REGEX =
  /(https?:\/\/)?(www\.)?youtube.com\/channel\/([^\s/<>]+)/i;
export const YOUTU_BE_WATCH_IDREGEX =
  /(https?:\/\/)?(www\.)?youtu.be\/([^\s\/<>]+)/i;

export const YoutubeSupportedRegexs = [
  YOUTUBE_CHANNEL_ID_REGEX,
  YOUTUBE_USER_ID_REGEX,
  YOUTUBE_WATCH_ID_REGEX,
  YOUTU_BE_WATCH_IDREGEX,
];

export const YoutubeParsers = [
  {
    linkText: "Click to play YouTube video",
    matcher: (url: string) => YOUTUBE_WATCH_ID_REGEX.exec(url),
    idGenerator: (url: string) => {
      const match = YOUTUBE_WATCH_ID_REGEX.exec(url) || [];
      const videoId = match[match.length - 1];
      const start = getStartTime(url)?.toString();

      let iframeSrc = `https://www.youtube-nocookie.com/embed/${videoId}?autoplay=0&iv_load_policy=3&rel=0&modestbranding=1&cc_lang_pref=en&cc_load_policy=1`;
      iframeSrc = addParamsToUrl(iframeSrc, {
        start,
      });

      return iframeSrc;
    },
  },
  {
    linkText: "Click to play latest YouTube user video",
    matcher: (url: string) => YOUTUBE_USER_ID_REGEX.exec(url),
    idGenerator: (url: string) => {
      const match = YOUTUBE_USER_ID_REGEX.exec(url) || [];
      const userId = match[match.length - 1];

      const iframeSrc = `https://www.youtube-nocookie.com/embed/?listType=user_uploads&list=${userId}&autoplay=0&iv_load_policy=3&rel=0&modestbranding=1&cc_lang_pref=en&cc_load_policy=1`;

      return iframeSrc;
    },
  },
  {
    linkText: "Click to play latest YouTube channel video",
    matcher: (url: string) => YOUTUBE_CHANNEL_ID_REGEX.exec(url),
    idGenerator: async (url: string) => {
      const match = YOUTUBE_CHANNEL_ID_REGEX.exec(url) || [];
      const channelId = match[match.length - 1];
      const videoId = await getVideoIdFromChannel(channelId);

      const iframeSrc = `https://www.youtube-nocookie.com/embed/${videoId}?autoplay=0&iv_load_policy=3&rel=0&modestbranding=1&cc_lang_pref=en&cc_load_policy=1`;

      return iframeSrc;
    },
  },
  {
    linkText: "Click to play YouTube video",
    matcher: (url: string) => YOUTU_BE_WATCH_IDREGEX.exec(url),
    idGenerator: (url: string) => {
      const match = YOUTU_BE_WATCH_IDREGEX.exec(url) || [];
      const videoId = match[match.length - 1];
      const start = getStartTime(url)?.toString();

      let iframeSrc = `https://www.youtube-nocookie.com/embed/${videoId}?autoplay=0&iv_load_policy=3&rel=0&modestbranding=1&cc_lang_pref=en&cc_load_policy=1`;
      iframeSrc = addParamsToUrl(iframeSrc, {
        start,
      });

      return iframeSrc;
    },
  },
];

async function getVideoIdFromChannel(channelId: string) {
  const response = await fetch(`/api/youtube/${channelId}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  });

  if (response.ok) {
    // @ts-ignore
    const { link } = await response.json();
    const match = YOUTUBE_WATCH_ID_REGEX.exec(link);

    if (match) {
      return match[match.length - 1];
    }
  }

  return null;
}

function getStartTime(url: string) {
  const [, query] = url.split("?");
  if (!query) {
    return undefined;
  }
  const queryParams = queryStringToObject(query);
  const { time_continue, t } = queryParams;

  let start = NaN;
  if (time_continue) {
    start = Number.parseInt(time_continue.toString());
  } else if (t && typeof t === "string") {
    // t is formatted as {x}m{y}s. ex: 2m12s
    const [, minutes, seconds, onlySeconds] =
      /(\d+)m(\d+)s|(\d+)s/.exec(t) || [];
    if (minutes) {
      start = parseInt(minutes) * 60 + parseInt(seconds) * 1;
    } else if (onlySeconds) {
      start = parseInt(onlySeconds) * 1;
    } else {
      start = Number.parseInt(t);
    }
  }

  return Number.isNaN(start) ? undefined : start;
}

interface YoutubeLinkInModalProps {
  url: string;
  linkText: string;
}

export function YoutubeLinkInModal({
  url,
  linkText,
}: React.PropsWithChildren<YoutubeLinkInModalProps>) {
  const [open, setOpen] = React.useState(false);
  const isMobile = useIsMobile();

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
      >
        {linkText}
      </a>
      <Dialog
        open={open}
        fullScreen={isMobile}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: isMobile ? 0 : "2em",
            padding: "1em",
            minWidth: isMobile ? undefined : "600px",
          },
        }}
      >
        <DialogTitle
          component="div"
          sx={{
            marginBottom: "0.5em",
          }}
        >
          <Typography variant="h3">YouTube Video</Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <YoutubeEmbed url={url} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function YoutubeEmbed({ url }: { url: string }) {
  const [iframeSrc, setIframeSrc] = useState<string>();

  useEffect(() => {
    const getVideoSrc = async (url: string) => {
      const parser = YoutubeParsers.find(parser => !!parser.matcher(url));

      if (!!parser) {
        setIframeSrc(await parser.idGenerator(url));
      }
    };

    getVideoSrc(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <iframe
      style={{
        width: "100%",
        aspectRatio: "4 / 3",
      }}
      data-test-id="youtube-embedded-video"
      sandbox="allow-same-origin allow-scripts"
      title="Embedded Youtube video"
      allowFullScreen={true}
      src={iframeSrc}
      frameBorder="0"
    />
  );
}

{
  /* <iframe
  width="560"
  height="315"
  src="https://www.youtube-nocookie.com/embed/nXbEFTv9zr0?si=_9-BIj1An_AJeD5x"
  title="YouTube video player"
  frameborder="0"
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  allowfullscreen
></iframe>; */
}
