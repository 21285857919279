// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { ReactionType, isReactionText } from "@outschool/animation-resources";
import { Box, SxProps } from "@outschool/backpack";
import * as FilestackUrls from "@outschool/filestack-urls";
import { useBrowserSupportsWebpAnimation } from "@outschool/ui-utils";
import React from "react";

import { useClubContext, useVideoPlayerComponent } from "../../providers";
import { CONTENT_BOX_BORDER_RADIUS, MAX_COMMENT_CHAR_COUNT } from "./Club";
import { OccasionalLottie, TextWithLinks, useFindAndLoadReaction } from "..";

const SHARED_COMMENT_BOX_SX: SxProps = {
  position: "relative",
  width: "100%",
  minHeight: "200px",
  borderRadius: CONTENT_BOX_BORDER_RADIUS,

  color: "common.white",
  fontWeight: "bold",
};

interface CommentContentProps {
  comment: {
    backgroundColor: string | null;
    messageContent: {
      text?: string | null;
      attachments: { file: { url: string } }[];
      video?: { file: { url: string } } | null;
    };
    sender: {
      uid: string;
    };
  };
  isDocentComment?: boolean;
  sx?: SxProps;
}
export default function CommentContent({
  comment,
  isDocentComment,
  sx = {},
}: CommentContentProps) {
  const hasImage = comment.messageContent.attachments.length > 0;
  if (hasImage) {
    return <CommentWithImage comment={comment} sx={sx} />;
  }

  const hasVideo = !!comment.messageContent.video?.file;
  if (hasVideo) {
    return <CommentWithVideo comment={comment} sx={sx} />;
  }

  const messageText = comment.messageContent.text ?? "";
  if (isReactionText(messageText)) {
    return (
      <CommentWithReaction
        reactionType={messageText}
        senderUid={comment.sender.uid}
        sx={sx}
        initialBackgroundColor={comment.backgroundColor}
      />
    );
  }

  return (
    <CommentWithText
      comment={comment}
      isDocentComment={isDocentComment}
      sx={sx}
    />
  );
}

function CommentWithImage({ comment, sx = {} }: CommentContentProps) {
  const supportsWebP = useBrowserSupportsWebpAnimation();
  const imageFile = comment.messageContent.attachments[0];
  return (
    <Box
      sx={[
        {
          ...SHARED_COMMENT_BOX_SX,
          position: "relative",

          backgroundImage: `url(${FilestackUrls.imageUrl(
            imageFile.file.url,
            {
              w: 600,
              h: 400,
              fit: "crop",
            },
            supportsWebP ? "webp" : "jpg"
          )})`,

          backgroundSize: "cover",
          backgroundPosition: "center",
          cursor: "pointer",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    ></Box>
  );
}

function CommentWithVideo({ comment, sx }: CommentContentProps) {
  const VideoPlayer = useVideoPlayerComponent();
  const videoUrl = comment.messageContent.video?.file?.url;
  if (!videoUrl) {
    return null;
  }
  return (
    <Box
      flex
      sx={[
        {
          ...SHARED_COMMENT_BOX_SX,
          justifyContent: "flex-end",
          cursor: "pointer",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <VideoPlayer
        sx={{
          pointerEvents: "none",
          position: "absolute",
          overflow: "hidden",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          background: "rgba(0,0,0,0.2)",
          borderRadius: "10px",
        }}
        videoSx={{
          /* eslint-disable-next-line i18next/no-literal-string */
          objectFit: "cover",
          height: "100%",
        }}
        src={videoUrl}
        /* eslint-disable-next-line i18next/no-literal-string */
        playButtonType="white-center"
      />
    </Box>
  );
}

interface CommentWithReaction {
  reactionType: ReactionType;
  senderUid: string;
  sx: SxProps;
  initialBackgroundColor: string | null;
}

function CommentWithReaction({
  reactionType,
  senderUid,
  sx,
  initialBackgroundColor,
}: CommentWithReaction) {
  const reaction = useFindAndLoadReaction(reactionType);
  const { useClubPostAndCommentDefaultBackgroundColor } = useClubContext();
  const defaultBackgroundColor = useClubPostAndCommentDefaultBackgroundColor({
    senderUid,
  });
  return (
    <Box
      sx={[
        {
          ...SHARED_COMMENT_BOX_SX,
          background:
            initialBackgroundColor === null
              ? defaultBackgroundColor
              : initialBackgroundColor,
          position: "relative",
          overflow: "hidden",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {reaction ? <OccasionalLottie animation={reaction.animation} /> : null}
    </Box>
  );
}

function CommentWithText({
  comment,
  isDocentComment,
  sx,
}: CommentContentProps) {
  const text = comment.messageContent.text ?? "";
  const { useClubPostAndCommentDefaultBackgroundColor } = useClubContext();
  const defaultBackgroundColor = useClubPostAndCommentDefaultBackgroundColor({
    senderUid: comment.sender.uid,
  });
  return (
    <Box
      sx={[
        {
          ...SHARED_COMMENT_BOX_SX,
          paddingTop: "60px",
          background:
            comment.backgroundColor === null
              ? defaultBackgroundColor
              : comment.backgroundColor,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <pre
        style={{
          wordBreak: "break-word",
          margin: "8px 16px",
          fontSize: getCommentTextFontSize({ comment }),
        }}
      >
        <TextWithLinks
          clickableLinks={isDocentComment}
          text={text.trim()}
          hideEmails={true}
        />
      </pre>
    </Box>
  );
}

function getCommentTextFontSize({
  comment,
}: {
  comment: CommentContentProps["comment"];
}) {
  // Consider using a library like react-fit-text or react-scale-text
  const MIN_FONT_SIZE = 15;
  const MAX_FONT_SIZE = 40;
  let fontSize = MIN_FONT_SIZE;
  const hasMedia =
    comment.messageContent.video?.file ||
    comment.messageContent.attachments?.length > 0;
  const charCount = comment.messageContent.text?.length ?? 1;
  if (!hasMedia && charCount < 200) {
    fontSize = Math.min(
      MIN_FONT_SIZE + Math.round(MAX_COMMENT_CHAR_COUNT / charCount),
      MAX_FONT_SIZE
    );
  }
  return fontSize;
}
