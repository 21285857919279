import {
  YoutubeLinkInModal,
  YoutubeParsers,
  YoutubeSupportedRegexs,
} from "@outschool/ui-youtube";
import React from "react";

import ExternalLink from "./ExternalLink";

interface TextWithLinksProps extends SplitContext {
  text?: string | null;
  maxTextLength?: number;
}

const TextWithLinks: React.FC<TextWithLinksProps> = ({
  text,
  maxTextLength = Infinity,
  ...splitContext
}) => {
  if (!text || !text.trim()) {
    return null;
  }

  const parsedNodes = splitUrls(text, splitContext);
  let lengthSoFar = 0;
  return (
    <>
      {parsedNodes.map((node, i) => {
        // Do we have remaining textLength to fill?
        if (lengthSoFar >= maxTextLength) {
          return null;
        }

        const truncateNodeText = node.textLength + lengthSoFar >= maxTextLength;

        const truncatedNodeLength = truncateNodeText
          ? maxTextLength - lengthSoFar - 3
          : node.textLength;

        lengthSoFar += node.textLength;
        node.textLength = truncatedNodeLength;

        const nodeElement =
          node.type === "text" ? (
            <Text {...node} />
          ) : node.type === "url" ? (
            <Url {...node} />
          ) : node.type === "email" ? (
            <Email {...node} />
          ) : node.type === "mention" ? (
            <Mention {...node} />
          ) : (
            // exhaustive check to make sure we handle all nodes statically
            ((x: never) => {
              throw new Error(`unrecognized node type: ${(x as any).type}`);
            })(node)
          );

        return (
          <React.Fragment key={i}>
            {nodeElement}
            {truncateNodeText && "..."}
          </React.Fragment>
        );
      })}
    </>
  );
};

type Node = TextNode | UrlNode | EmailNode | MentionNode;

interface BaseNode {
  type: string;
  textLength: number;
}

interface TextNode extends BaseNode {
  type: "text";
  text: string;
}
const textNode = (text: string): TextNode => ({
  type: "text",
  text,
  textLength: text.length,
});
const Text: React.FC<TextNode> = ({ text, textLength }) => (
  <>{text.substr(0, textLength)}</>
);

interface UrlNode extends BaseNode {
  type: "url";
  url: string;
}
const urlRegex = /(https?):\/\/[^\s"]+/im; // https://stackoverflow.com/a/1411800
const urlNode = (url: string): UrlNode => ({
  type: "url",
  url,
  textLength: url.length,
});
const withProtocol = (url: string) =>
  !/^https?:\/\//i.test(url) ? `https://${url}` : url;

const Url: React.FC<UrlNode> = ({ url, textLength }) => {
  const supportedParser = YoutubeParsers.find(parser => !!parser.matcher(url));
  if (!!supportedParser) {
    const fullUrl = withProtocol(url);
    return (
      <YoutubeLinkInModal url={fullUrl} linkText={supportedParser.linkText} />
    );
  }

  const linkText = textLength ? url.substr(0, textLength) : url;
  return <ExternalLink url={url}>{linkText}</ExternalLink>;
};

interface EmailNode extends BaseNode {
  type: "email";
  email: string;
  hidden: boolean;
}
const emailRegex =
  /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/im;
const emailNode = (email: string): EmailNode => ({
  type: "email",
  email,
  textLength: email.length,
  hidden: false,
});
const hiddenEmailMessage = "[email address not shown]";
const hiddenEmailNode = (): EmailNode => ({
  type: "email",
  email: "",
  textLength: hiddenEmailMessage.length,
  hidden: true,
});
const Email: React.FC<EmailNode> = ({ email, hidden, textLength }) => {
  const emailText = hidden ? hiddenEmailMessage : email;
  const truncatedText = emailText.substr(0, textLength);
  return hidden ? (
    <>{truncatedText}</>
  ) : (
    <a href={`mailto:${email}`}>{truncatedText}</a>
  );
};

interface MentionNode extends BaseNode {
  type: "mention";
  mention: string;
}
const mentionsRegex =
  /@\[([^\]]+)\]\(([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|teacher)\)/;
const mentionNode = (mention: string): MentionNode => {
  // This match will always exist, mention must match the regex, and there is a
  // group around the mentioned name
  const mentionName = mentionsRegex.exec(mention)![1]!;
  return {
    type: "mention",
    mention: mentionName,
    textLength: 1 + mentionName.length,
  };
};
const Mention: React.FC<MentionNode> = ({ mention, textLength }) => (
  <strong>@{mention.substr(0, textLength)}</strong>
);

type NodeConstructor = (s: string) => Node;
type SplitFn = (text: string, context: SplitContext) => Node[];

const splitUrls: SplitFn = (text, context) => {
  const { clickableLinks } = context;
  return clickableLinks
    ? splitOnRegex(text, urlRegex, urlNode, splitYoutubeUrls, context)
    : splitYoutubeUrls(text, context);
};

const splitYoutubeUrls: SplitFn = (text, context) => {
  return splitOnRegex(
    text,
    YoutubeSupportedRegexs,
    urlNode,
    splitEmails,
    context
  );
};

const splitEmails: SplitFn = (text, context) => {
  const { hideEmails } = context;
  return splitOnRegex(
    text,
    emailRegex,
    hideEmails ? hiddenEmailNode : emailNode,
    splitMentions,
    context
  );
};

const splitMentions: SplitFn = (text, context) => {
  return splitOnRegex(text, mentionsRegex, mentionNode, splitText, context);
};

const splitText: SplitFn = text => {
  return text ? [textNode(text)] : [];
};

interface SplitContext {
  clickableLinks?: boolean;
  hideEmails?: boolean;
}

const splitOnRegex = (
  text: string,
  regex: RegExp | RegExp[],
  constructor: NodeConstructor,
  nextFn: SplitFn,
  context: SplitContext
): Node[] => {
  const regexArray = Array.isArray(regex) ? regex : [regex];

  const found = regexArray.find(regex => !!regex.test(text));
  if (!!found) {
    const result = found.exec(text)!;
    const start = text.substr(0, result.index);
    const match = result[0];
    const rest = text.substr(result.index + match.length);
    return [
      ...nextFn(start, context),
      constructor(match),
      ...splitOnRegex(rest, regex, constructor, nextFn, context),
    ];
  } else {
    return nextFn(text, context);
  }
};

export default TextWithLinks;
