import { Icon, MenuButton, SxProps, Typography } from "@outschool/backpack";
import { faPaperclip } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import React from "react";

import { useAddAttachmentMutation } from "../hooks/useAddAttachmentMutation";
import { AttachmentUpload, VideoUpload } from "./FilestackUpload";
import { AttachmentFragmentFragment } from "..";

export default function InlineEditableAttachments({
  showVideoUpload = false,
  video = null,
  value = [],
  onChange = () => {},
  onVideoChanged = () => {},
  useTextButton = false,
  sx,
}: {
  showVideoUpload: boolean;
  video: AttachmentFragmentFragment | null;
  value: AttachmentFragmentFragment[];
  onChange: (value: AttachmentFragmentFragment[]) => any;
  onVideoChanged: (video: AttachmentFragmentFragment | null) => any;
  useTextButton?: boolean;
  sx?: SxProps;
}) {
  const { addAttachment } = useAddAttachmentMutation();
  const [isVideoModalOpen, setIsVideoModalOpen] = React.useState(false);
  const [isFileModalOpen, setIsFileModalOpen] = React.useState(false);

  // Save each file as a separate attachment
  const createAttachments = async (files: any) => {
    const attachments: AttachmentFragmentFragment[] = [];
    for (const file of files) {
      const attachment = await addAttachment(file);
      if (attachment) {
        attachments.push(attachment);
      }
    }
    return attachments;
  };

  const handleUploaded = async (files: any) => {
    const attachments = await createAttachments(files);
    if (attachments.length > 0) {
      onChange([...value, ...attachments]);
    }
  };

  const handleVideoUploaded = async (file: any) => {
    const video = await addAttachment(file, true);
    if (video) {
      onVideoChanged(video);
    }
  };

  const { t } = useTranslation(
    "ui-components-classroom\\InlineEditableAttachments"
  );

  return (
    <>
      <VideoUpload
        onUploaded={handleVideoUploaded}
        startUploadingWhenMaxFilesReached={true}
        isOpen={isVideoModalOpen}
        setIsOpen={setIsVideoModalOpen}
      />
      <AttachmentUpload
        onUploaded={handleUploaded}
        isOpen={isFileModalOpen}
        setIsOpen={setIsFileModalOpen}
      />
      <MenuButton
        menuItems={[
          ...(showVideoUpload
            ? video
              ? [
                  {
                    label: t`Remove video`,
                    onClick: () => {
                      onVideoChanged(null);
                    },
                  },
                ]
              : [
                  {
                    label: t`Add a Video`,
                    onClick: () => {
                      setIsVideoModalOpen(true);
                    },
                  },
                ]
            : []),
          {
            label: t`Add a File`,
            onClick: () => {
              setIsFileModalOpen(true);
            },
          },
        ]}
        /* eslint-disable i18next/no-literal-string */
        slotProps={{
          button: {
            variant: "link",
            sx,
          },
        }}
        /* eslint-enable i18next/no-literal-string */
      >
        <Icon icon={faPaperclip} size="small" />
        {useTextButton && (
          <Typography variant="inherit" sx={{ marginLeft: 8 }}>
            {t`Add an Attachment`}
          </Typography>
        )}
      </MenuButton>
    </>
  );
}
