import {
  LEARNER_REFRESH_TOKEN_LOCAL_STORAGE_NAME,
  LEARNER_SESSION_TOKEN_LOCAL_STORAGE_NAME,
} from "@outschool/auth-shared";
import { isLocalStorageSupported } from "@outschool/local-storage";
import Cookie from "js-cookie";
import { useCallback } from "react";
import {
  authCodeTransferUrl,
  parentAuthCodeTransferUrl,
  pathToUrl,
} from "@outschool/routes";
import { useNavigation } from "@outschool/ui-utils";

const reviewAppCookie = "x-outschool-use-learner-mode";

export function switchToLearnerModeWithToken({
  isReviewApp,
  transferToken,
  learnerAppUrl,
  isLearnerLogin,
}: {
  isReviewApp: boolean;
  transferToken: string;
  learnerAppUrl?: string;
  isLearnerLogin?: boolean;
}) {
  if (isReviewApp) {
    // NOTE: We use a custom cookie in order to avoid supporting an
    // extra subdomain in Review apps.
    Cookie.set(reviewAppCookie, "true");
  }

  clearLearnerTokenLocalStorageData();
  /* eslint-disable-next-line no-restricted-syntax */
  window?.location.replace(
    authCodeTransferUrl({
      transferToken,
      learnerAppUrl,
      isLearnerLogin,
    })
  );
}

export function useSwitchToParentMode(redirect?: string) {
  const navigate = useNavigation();

  return useCallback(
    (transferToken: string) => {
      clearLearnerTokenData();
      navigate(parentAuthCodeTransferUrl(transferToken, redirect), {
        hardNav: true,
      });
    },
    [navigate, redirect]
  );
}

function clearLearnerTokenLocalStorageData() {
  const localStorage = isLocalStorageSupported();
  localStorage?.removeItem(LEARNER_SESSION_TOKEN_LOCAL_STORAGE_NAME);
  localStorage?.removeItem(LEARNER_REFRESH_TOKEN_LOCAL_STORAGE_NAME);
}

function clearLearnerTokenCookieData() {
  Cookie.remove(reviewAppCookie);
  Cookie.remove("learnerToken");
}

export function clearLearnerTokenData() {
  clearLearnerTokenLocalStorageData();
  clearLearnerTokenCookieData();
}

export function clearLearnerTokenDataAndGoToMainApp() {
  clearLearnerTokenData();
  /* eslint-disable-next-line no-restricted-syntax */
  window?.location.replace(pathToUrl("/"));
}
