import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { Editor as TiptapEditor, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React from "react";
import LinkView from "../extensions/LinkView";
import { YOUTUBE_BASE_REGEX } from "@outschool/ui-youtube";

function extensions({
  placeholder,
  editable,
  allowLinks,
  embedYoutubeVideos,
}: {
  placeholder: string;
  editable: boolean;
  allowLinks: boolean;
  embedYoutubeVideos: boolean;
}) {
  return [
    StarterKit.configure({
      heading: {
        levels: [3],
      },
      codeBlock: {
        HTMLAttributes: {
          class: "code-block",
        },
      },
      blockquote: false,
      hardBreak: false,
      horizontalRule: false,
      strike: false,
    }),
    Underline,
    Placeholder.configure({
      placeholder,
    }),
    ...(!editable
      ? [LinkView.configure({ allowLinks, embedYoutubeVideos })]
      : []),
  ];
}

export function useTiptapEditor({
  content = "",
  placeholder = "",
  editable = true,
  allowLinks = false,
  embedYoutubeVideos = true,
}: {
  content?: string;
  placeholder?: string;
  editable?: boolean;
  allowLinks?: boolean;
  embedYoutubeVideos?: boolean;
} = {}): {
  tiptapEditor: TiptapEditor | null;
  getHtml: () => string;
  setHtml: (md: string) => void;
} {
  const tiptapEditor = useEditor({
    extensions: extensions({
      placeholder,
      editable,
      allowLinks,
      embedYoutubeVideos,
    }),
    content,
    editable,
    editorProps: {
      transformPastedHTML(html) {
        const parsed = new DOMParser().parseFromString(html, "text/html");

        // replace YT links with URLs
        const links = parsed.body.getElementsByTagName("a");
        for (let i = 0; i < links.length; i++) {
          const link = links[i];
          if (YOUTUBE_BASE_REGEX.test(link.href)) {
            link.outerHTML = link.href;
          }
        }

        // replace <pre> with series of <p> tags per line
        const pres = parsed.body.getElementsByTagName("pre");
        for (let i = 0; i < pres.length; i++) {
          const pre = pres[i];
          const lines = pre.innerHTML
            .split("\n")
            .map(line => `<p>${line}</p>`)
            .join("\n");
          pre.outerHTML = lines;
        }

        return parsed.body.innerHTML;
      },
    },
  });

  const getHtml = React.useCallback(
    () => (tiptapEditor?.isEmpty ? "" : tiptapEditor?.getHTML() ?? ""),
    [tiptapEditor]
  );

  const setHtml = React.useCallback(
    (html: string) => tiptapEditor?.commands?.setContent(html),
    [tiptapEditor]
  );

  return { tiptapEditor, getHtml, setHtml };
}
