import {
  Menu as MUIMenu,
  MenuItem as MUIMenuItem,
  MenuItemProps,
  MenuProps,
} from "@mui/material";
import React from "react";

import useUniqueId from "../../utils/useUniqueId";
import { Button, ButtonProps } from "../Button/Button";

export { MUIMenu as Menu, MenuProps, MUIMenuItem as MenuItem, MenuItemProps };
export { menuClasses, menuItemClasses } from "@mui/material";

type MenuItem = {
  label: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export interface MenuButtonProps {
  /**
   * Slots override the given underlying component.
   *
   * Mimics MUI's notion of "slots" for composition.
   * https://mui.com/base/getting-started/usage/#slots
   */
  slots?: {
    button?: React.ElementType<ButtonProps>;
    menu?: React.ElementType<MenuProps>;
    menuItem?: React.ElementType<MenuItemProps>;
  };
  /** Props given to specific slots */
  slotProps?: {
    button?: Omit<ButtonProps, "children">;
    menu?: Omit<MenuProps, "children" | "open">;
    menuItem?: Omit<MenuItemProps, "children">;
  };
  /**
   * Items found in the menu.
   */
  menuItems: Array<MenuItem>;
  /**
   * Sets the button to the disabled state when menu is open
   */
  disableWhenOpen?: boolean;
  /** The content of the Button */
  children: React.ReactNode;
}

/**
 * A Button which opens a Menu.
 * Handles all state for opening/closing the Menu, plus a11y considerations.
 *
 * All underlying components can be overridden via the `slots` prop.
 */
export const MenuButton = ({
  slots,
  slotProps,
  menuItems,
  disableWhenOpen = false,
  children: buttonChildren,
}: MenuButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const buttonId = useUniqueId("MenuButton-button");
  const menuId = useUniqueId("MenuButton-menu");

  const {
    button: ButtonComponent = Button,
    menu: Menu = MUIMenu,
    menuItem: MenuItem = MUIMenuItem,
  } = slots || {};
  const {
    button: buttonProps,
    menu: menuProps,
    menuItem: menuItemProps,
  } = slotProps || {};

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    buttonProps?.onClick?.(event);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    menuProps?.onClose?.();
  };

  return (
    <>
      <ButtonComponent
        disabled={disableWhenOpen ? isOpen : undefined}
        {...buttonProps}
        // Props below are non-overridable
        onClick={handleButtonClick}
        id={buttonId}
        // a11y sugar
        /* eslint-disable i18next/no-literal-string */
        aria-controls={isOpen ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        /* eslint-enable i18next/no-literal-string */
      >
        {buttonChildren}
      </ButtonComponent>
      <Menu
        /* eslint-disable i18next/no-literal-string */
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        /* eslint-enable i18next/no-literal-string */
        {...menuProps}
        // Props below are non-overridable
        id={menuId}
        aria-labelledby={buttonId}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleMenuClose}
      >
        {menuItems.map(({ label, onClick }) => (
          <MenuItem
            key={label}
            {...menuItemProps}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              handleMenuClose();
              onClick(event);
              menuItemProps?.onClick?.(event);
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
