import {
  AutoMarkAttendanceMutation as AutoMarkAttendanceMutationType,
  AutoMarkAttendanceMutationVariables,
  AutoMarkTeacherAttendanceMutation as AutoMarkTeacherAttendanceMutationType,
  AutoMarkTeacherAttendanceMutationVariables,
} from "@outschool/gql-frontend-generated";
import { gql, useMutation } from "@outschool/ui-apollo";

export const AutoMarkAttendanceMutation = gql`
  mutation AutoMarkAttendance($sectionUid: ID) {
    markAttended(sectionUid: $sectionUid)
  }
`;

const AutoMarkTeacherAttendanceMutation = gql`
  mutation AutoMarkTeacherAttendance($sectionUid: ID!) {
    markTeacherAttended(sectionUid: $sectionUid)
  }
`;

export interface UseMarkAttendanceArgs {
  currentUserCanManage: boolean;
  sectionUid: string;
}

const context = {
  retryMutationOnError: true,
};

export default function useMarkAttendance({
  currentUserCanManage,
  sectionUid,
}: UseMarkAttendanceArgs) {
  const learnerMutation = useMutation<
    AutoMarkAttendanceMutationType,
    AutoMarkAttendanceMutationVariables
  >(AutoMarkAttendanceMutation, {
    context,
    variables: { sectionUid },
  });
  const teacherMutation = useMutation<
    AutoMarkTeacherAttendanceMutationType,
    AutoMarkTeacherAttendanceMutationVariables
  >(AutoMarkTeacherAttendanceMutation, {
    context,
    variables: { sectionUid },
  });

  return currentUserCanManage ? teacherMutation : learnerMutation;
}
