import { Icon, Typography } from "@outschool/backpack";
import { faCircleCheckRegular } from "@outschool/icons";
import { useTranslation } from "@outschool/localization";
import * as Time from "@outschool/time";
import React from "react";

interface AssignmentSubmittedOnProps {
  sentAt: string;
  timeZone?: string;
}

export const AssignmentSubmittedOn = ({
  sentAt,
  timeZone,
}: AssignmentSubmittedOnProps) => {
  const { t } = useTranslation(
    "ui-components-classroom\\assignments\\AssignmentSubmittedOn"
  );

  return (
    <Typography
      variant="subtitle2"
      sx={{ color: "success.600", fontWeight: "fontWeightBold" }}
    >
      <Icon
        icon={faCircleCheckRegular}
        size={`inherit`}
        sx={{ marginRight: 8 }}
      />
      {t("Submitted on: {{ assignmentSubmissionDate }}", {
        assignmentSubmissionDate: Time.formatDate(sentAt, timeZone, true),
      })}
    </Typography>
  );
};
