import {
  Box,
  Button,
  Collapse,
  Typography,
  useTheme,
} from "@outschool/backpack";
import { TextMimeType } from "@outschool/gql-frontend-generated";
import { useTranslation } from "@outschool/localization";
import {
  AttachmentGallery,
  TextWithLinks,
} from "@outschool/ui-components-shared";
import { TrackingProperties } from "@outschool/ui-legacy-component-library";
import { RichTextView } from "@outschool/ui-rich-text-editor";
import React, { FC, useState } from "react";

import { MessageContent, Sender } from "../types";

export interface ClassPostContentProps {
  sender: Sender;
  messageContent: MessageContent;
  trackingLabel?: string;
  trackingMetadata?: TrackingProperties;
  allowRichText?: boolean;
  /**
   * Height of the collapsed content in pixels.
   * @default 150
   */
  collapseSize?: number;
}

const ClassPostContent: FC<ClassPostContentProps> = ({
  sender,
  messageContent,
  trackingLabel,
  trackingMetadata,
  allowRichText = false,
  collapseSize = 150,
}) => {
  const { t } = useTranslation(
    "ui-components-classroom\\classroom\\ClassPost\\ClassPostContent"
  );
  const [expanded, setExpanded] = useState(false);

  const theme = useTheme();

  // Put ref in state because we need it to cause a re-render so that showExpandButton recalculates
  const [contentRef, setContentRef] = React.useState<
    HTMLDivElement | HTMLPreElement | null
  >(null);
  const contentSize = contentRef?.scrollHeight;
  const showExpandButton =
    !expanded && (contentSize || Infinity) > collapseSize;

  return (
    <Box data-test-id="ClassPostContent">
      <Box
        sx={{
          marginBottom: 40,
          position: "relative",
          paddingY: 16,
        }}
      >
        <Collapse
          in={expanded}
          collapsedSize={
            // Don't require collapsed content to be bigger than it needs to be
            contentSize && contentSize < collapseSize
              ? contentSize
              : theme.spacing(collapseSize)
          }
        >
          {allowRichText &&
          messageContent.originalMimeType === TextMimeType.TextMarkdown ? (
            <RichTextView
              content={messageContent.htmlText}
              allowLinks={true}
              maxLines={Infinity}
              allowEmails={false}
              ref={setContentRef}
            />
          ) : (
            <Box
              component="pre"
              sx={{ wordBreak: "break-word", margin: 0 }}
              ref={setContentRef}
            >
              <TextWithLinks
                clickableLinks={true}
                text={messageContent.text?.trim() ?? ""}
                maxTextLength={Infinity}
                hideEmails={true}
              />
            </Box>
          )}
        </Collapse>
        {showExpandButton && (
          <Box
            // Make the button float in the margin so that when it disappears it doesn't make content jump
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              position: "absolute",
              bottom: -24,
            }}
          >
            <Typography
              variant="subtitle1"
              color="textSecondary"
              sx={{ userSelect: "none" }}
            >
              ⋯
            </Typography>
            <Button
              onClick={() => setExpanded(true)}
              variant="link"
              size="small"
            >
              {t`Show more`}
            </Button>
          </Box>
        )}
      </Box>
      <AttachmentGallery
        video={messageContent.video}
        attachments={messageContent.attachments}
        trackingLabel={trackingLabel}
        trackingMetadata={trackingMetadata}
        senderName={sender.name}
        isPostOwner={false}
      />
    </Box>
  );
};

export default ClassPostContent;
