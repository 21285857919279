import React from "react";

import { ActiveReactionAnimationConsumer } from "../../providers/ActiveReactionAnimationProvider";
import { useFeatureFlag } from "../../providers/FeatureFlags";
import LottiePlayer, {
  LottieAnimation,
  LottiePlayerProps,
} from "./LottiePlayer";

interface OccationalLottieProps extends LottiePlayerProps {
  pauseIntervalMs?: number;
  animation: LottieAnimation;
}

const OccasionalLottie: React.FC<OccationalLottieProps> = ({
  pauseIntervalMs = 10 * 1000,
  animation,
  ...props
}) => {
  const lottieRef = React.useRef<any>();
  const limitGroupLottieAnimations = useFeatureFlag(
    "limit-group-lottie-animations" // matches FeatureFlag.LimitGroupLottieAnimations in Env
  );

  const timeoutIdRef = React.useRef<NodeJS.Timeout>();
  React.useEffect(() => {
    if (limitGroupLottieAnimations) {
      return () => undefined;
    }
    timeoutIdRef.current = setTimeout(
      () => lottieRef.current?.play(),
      Math.random() * pauseIntervalMs
    );
    return () => clearTimeout(timeoutIdRef.current!);
  }, [pauseIntervalMs, limitGroupLottieAnimations]);
  const onComplete = React.useCallback(() => {
    if (limitGroupLottieAnimations) {
      return;
    }
    lottieRef.current?.pause();
    timeoutIdRef.current = setTimeout(() => {
      lottieRef.current?.play();
    }, (0.5 + Math.random()) * pauseIntervalMs);
  }, [pauseIntervalMs, limitGroupLottieAnimations]);
  return limitGroupLottieAnimations ? (
    <ActiveReactionAnimationConsumer>
      {({ shouldAnimate }) => (
        <LottiePlayer
          lottieRef={lottieRef}
          animation={animation}
          loop={true}
          autoplay={shouldAnimate}
          {...props}
        />
      )}
    </ActiveReactionAnimationConsumer>
  ) : (
    <LottiePlayer
      lottieRef={lottieRef}
      animation={animation}
      loop={true}
      autoplay={false}
      onLoopComplete={onComplete}
      {...props}
    />
  );
};

export default OccasionalLottie;
