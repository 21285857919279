import { Typography } from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import * as Time from "@outschool/time";
import React from "react";

interface AssingmentDueDateProps {
  dueDate?: Date;
}
export const AssignmentDueDate = ({ dueDate }: AssingmentDueDateProps) => {
  const { t } = useTranslation("ui-components-classroom\\AssingmentDueDate");

  const relativeDueDate = dueDate ? Time.fromNowForFutureTime(dueDate) : null;
  const formattedDueDate = dueDate ? Time.formatDateWithWeekday(dueDate) : null;

  return (
    <Typography variant="body1" sx={{ display: "block" }}>
      {relativeDueDate && formattedDueDate ? (
        <Trans t={t}>
          Due:
          <Typography
            variant="inherit"
            sx={{ fontWeight: "fontWeightBold", marginLeft: 8 }}
          >
            {{ relativeDueDate }} ({{ formattedDueDate }})
          </Typography>
        </Trans>
      ) : (
        <Trans t={t}>
          Due:
          <Typography
            variant="inherit"
            sx={{ fontWeight: "fontWeightBold", marginLeft: 8 }}
          >
            N/A
          </Typography>
        </Trans>
      )}
    </Typography>
  );
};
