import { Typography } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import React from "react";

export default function CanceledAnnouncement() {
  const { t } = useTranslation(
    "ui-components-classroom\\classroom\\CanceledAnnouncement"
  );
  return (
    <Typography variant="h2">{t("This section has been canceled.")}</Typography>
  );
}
