import { Box, Icon, SxProps, visuallyHidden } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { faCheck, faTimes } from "@outschool/icons";
import { createPathWithActivity } from "@outschool/routes";
import { VideoPlayerFactory } from "@outschool/ui-legacy-component-library";
import {
  makeEnterKeyPressHandler,
  useLinkComponent,
} from "@outschool/ui-utils";
import React, { useMemo } from "react";

import ActivityExperience from "../classCard/ActivityExperience";
import ActivityTeacher from "../classCard/ActivityTeacher";
import ActivityTitle from "../classCard/ActivityTitle";
import CardImage from "../classCard/CardImage";
import ClassCardBase from "../classCard/ClassCardBase";
import ClassDetails from "../classCard/ClassDetails";
import ClassVideoModalWithImageFallback from "../classCard/ClassVideoModalWithImageFallback";
import { Activity } from "../classCard/types";

interface BaseProps {
  activity: Activity;
  trackingPrefix: string;
  trackingProperties?: { [key: string]: string | number };
}

interface ClassCardProps extends BaseProps {
  saveButton?: (props: BaseProps) => React.ReactElement;
  onClick?: () => void;
  videoToShow?: "LEARNER_VIDEO" | "MAIN_VIDEO" | "NONE";
  activityUrl?: typeof createPathWithActivity;
  onRemove?: () => void;
  isSelected?: boolean;
  sx?: SxProps;
  VideoPlayer: ReturnType<typeof VideoPlayerFactory>;
}

interface ClassCardStyleStates {
  default: SxProps;
  selected: SxProps;
  removable: SxProps;
}

function ClassCard({
  activity,
  trackingPrefix,
  trackingProperties,
  saveButton,
  onClick,
  videoToShow = "LEARNER_VIDEO",
  activityUrl,
  isSelected,
  onRemove,
  sx,
  VideoPlayer,
}: ClassCardProps) {
  const Link = useLinkComponent();

  const activityVideoUrl = useMemo(() => {
    if (videoToShow === "NONE") {
      return null;
    } else if (videoToShow === "LEARNER_VIDEO") {
      return activity.videoForLearners?.file.url;
    } else {
      return activity.video?.file.url;
    }
  }, [videoToShow, activity]);

  const state = useMemo<keyof ClassCardStyleStates>(() => {
    if (onClick && isSelected) {
      return "selected";
    }
    if (onRemove) {
      return "removable";
    }
    return "default";
  }, [onClick, isSelected, onRemove]);

  const Details = () => (
    <>
      {!activityVideoUrl && (
        <CardImage imageUrl={activity.details?.photo ?? ""} />
      )}
      <ClassDetails>
        <ActivityTitle activity={activity}>
          {saveButton &&
            saveButton({ activity, trackingPrefix, trackingProperties })}
        </ActivityTitle>
        <ActivityExperience isClub={activity.isClub ?? false} />
        <ActivityTeacher leader={activity.leader} />
        {state === "selected" && (
          <Box
            flex
            sx={{
              backgroundColor: "#29a877",
              borderBottomRightRadius: "10px",
              borderTopLeftRadius: "16px",
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "50px",
              height: "60px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon
              sx={{
                color: "common.white",
                fontSize: "2em",
              }}
              icon={faCheck}
            />
          </Box>
        )}
      </ClassDetails>
    </>
  );

  const styleStates: ClassCardStyleStates = {
    default: [
      {
        cursor: onClick ? "pointer" : undefined,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ],
    selected: [
      {
        borderRadius: "16px",
        outline: "solid",
        outlineColor: "#29a877",
        outlineWidth: "4px",
        boxShadow: "12px 12px 0 rgba(91, 189, 152, .4)",
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ],
    removable: [
      {
        position: "relative",
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ],
  };

  const styles: SxProps = styleStates[state] ?? styleStates.default;

  return (
    <ClassCardBase
      sx={styles}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {onRemove && (
        <Box
          flex
          onClick={onRemove}
          tabIndex={0}
          onKeyPress={makeEnterKeyPressHandler(onRemove)}
          sx={{
            backgroundColor: "rgba(255,255,255, .8)",
            borderTopRightRadius: "16px",
            borderBottomLeftRadius: "16px",
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 3,
            width: "50px",
            height: "60px",
            cursor: "pointer",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon
            sx={{
              color: "grey.500",
              fontSize: "2em",
            }}
            icon={faTimes}
          />
          <span style={visuallyHidden}>Remove</span>
        </Box>
      )}
      {activityVideoUrl && (
        <ClassVideoModalWithImageFallback
          photo={activity.details?.photo}
          videoUrl={activityVideoUrl}
          VideoPlayer={VideoPlayer}
        />
      )}
      {activityUrl ? (
        <Link
          to={activityUrl(activity)}
          style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          trackingName={`${trackingPrefix}.go_to_class`}
          sharedProperties={{
            activityUid: activity.uid,
            ...trackingProperties,
          }}
        >
          <Details />
        </Link>
      ) : (
        <Details />
      )}
    </ClassCardBase>
  );
}

export default ClassCard;
