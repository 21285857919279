import React from "react";

import { SxProps, Typography } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";

export default function LessonNumber({
  lessonNumber,
  sx = {},
}: {
  lessonNumber?: number | null;
  sx?: SxProps;
}) {
  const { t } = useTranslation(
    "ui-components-classroom\\components\\syllabus\\LessonNumber"
  );
  if (!lessonNumber) {
    return null;
  }
  return (
    <Typography
      emphasized
      variant="overline"
      sx={{
        textTransform: "uppercase",
        color: "info.700",
        ...(Array.isArray(sx) ? sx : [sx]),
      }}
    >
      {t("Lesson {{lessonNumber}}", { lessonNumber })}
    </Typography>
  );
}
