import { SxProps, styled } from "@mui/system";
import { fasPlayCircle } from "@outschool/icons";
import React from "react";

import { Theme, useTheme } from "../..";
import { default as Icon } from "../Icon/Icon";
import { Box, Typography } from "..";

export type VideoProps = BaseVideoProps & {
  title?: string;
  splash?: React.ReactNode;
  errorMessage?: React.ReactNode;
  onlyPlayButton?: boolean;
  playButtonRef?: React.Ref<SVGElement>;
  trackingRef?: React.Ref<HTMLElement>;
  videoSx?: SxProps;
  showControls?: boolean;
  fixedAspectRatio?: boolean;
  onPlayClick?: () => void;
  onVideoCanPlay?: () => void;
  onVideoError?: (error: Error) => void;
};

export default function Video({
  title,
  src,
  poster,
  splash,
  errorMessage,
  onlyPlayButton = false,
  autoPlay,
  muted,
  sx,
  videoSx,
  videoRef,
  showControls = true,
  fixedAspectRatio = true,
  trackingRef,
  playButtonRef,
  onPlayClick,
  onLoadedData,
  onVideoCanPlay,
  onVideoError,
}: VideoProps) {
  const theme = useTheme();

  const [playButtonClicked, setPlayButtonClicked] = React.useState(!!autoPlay);
  const [controls, setControls] = React.useState(!!autoPlay);
  const [videoCanPlay, setVideoCanPlay] = React.useState(true);
  const videoPlayerRef = videoRef || React.createRef();
  const handleVideoCanPlay = () => {
    onVideoCanPlay && onVideoCanPlay();
    setVideoCanPlay(true);
  };
  const handlePlayClick = () => {
    videoPlayerRef?.current?.play()?.catch(handleVideoError);
    setPlayButtonClicked(true);
    setControls(true);
    onPlayClick?.();
  };
  const handleVideoError = (error: Error) => {
    onVideoError?.(error);
    setVideoCanPlay(false);
  };

  return (
    <Box
      ref={trackingRef}
      sx={[
        {
          position: "relative",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={{
          ...(fixedAspectRatio && {
            // Create a wrapper div to force a 16:9 ratio
            width: "100%",
            maxWidth: "1024px",
            paddingBottom: "56.25%",
            overflow: "hidden",
            position: "relative",
          }),
        }}
      >
        <BaseVideo
          videoRef={videoPlayerRef}
          src={src}
          controls={showControls && controls}
          controlsList="nodownload" // React scraps this but after React 16, this should be good.
          preload="auto"
          poster={poster}
          playsInline={true}
          autoPlay={autoPlay}
          muted={muted}
          onLoadedData={onLoadedData}
          onVideoCanPlay={handleVideoCanPlay}
          onVideoError={handleVideoError}
          sx={{
            width: "100%",
            maxHeight: "100%",
            boxSizing: "border-box",
            ...(fixedAspectRatio && {
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              height: "100%",
            }),
            ...videoSx,
          }}
        />
      </Box>
      {(!playButtonClicked || !videoCanPlay) && (
        // Use backgroundImage to act as the 'Poster' for our video.
        // This gives us more control over the image displayed before video displayed
        // and works around the weird spec debate about "poster" vs "first frame" in the video tag spec
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            color: videoCanPlay
              ? theme.palette.primary.main
              : theme.palette.error.main,
            backgroundColor: videoCanPlay ? "primary.200" : "error.200",
          }}
        >
          <Icon
            ref={playButtonRef}
            icon={fasPlayCircle}
            onClick={handlePlayClick}
            sx={(theme: Theme) => ({
              color: videoCanPlay
                ? theme.palette.primary.main
                : theme.palette.error.main,
              cursor: "pointer",

              [theme.breakpoints.up("md")]: {
                fontSize: "4em",
              },
            })}
          />
          {!videoCanPlay ? (
            <Typography sx={{ margin: "1em", textAlign: "center" }}>
              {errorMessage}
            </Typography>
          ) : (
            <>
              {!onlyPlayButton && (
                <>
                  <Typography
                    variant="h3"
                    sx={{ textAlign: "center", margin: "1em" }}
                  >
                    {title}
                  </Typography>
                  {splash}
                </>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

const StyledVideo = styled("video")({});

export type BaseVideoProps = React.VideoHTMLAttributes<HTMLVideoElement> & {
  sx?: SxProps;
  onVideoCanPlay?: () => void;
  onVideoError?: (error: Error) => void;
  videoRef?: React.MutableRefObject<HTMLVideoElement | null>;
};

/** A video element tag which accepts sx props */
function BaseVideo({
  onVideoCanPlay,
  onVideoError,
  videoRef,
  ...props
}: BaseVideoProps) {
  const [node, setNode] = React.useState<HTMLVideoElement | null>(null);
  React.useEffect(() => {
    if (!node) {
      return undefined;
    }

    const canPlayHandler = () => onVideoCanPlay?.();
    const errorHandler = (event: any) => {
      const error: Error =
        event?.target?.error ??
        event?.originalTarget?.error ??
        event?.path?.[0]?.error ??
        new Error("Unknown video error (could not be captured)");
      onVideoError?.(error);
    };

    node.addEventListener("canplay", canPlayHandler);
    node.addEventListener("error", errorHandler);

    // Prevent right-clicking to e.g. download
    node.addEventListener("contextmenu", e => e.preventDefault());

    return () => {
      node.removeEventListener("canplay", canPlayHandler);
      node.removeEventListener("error", errorHandler);
    };
  }, [node, onVideoCanPlay, onVideoError]);

  return (
    <StyledVideo
      ref={(node: HTMLVideoElement | null) => {
        setNode(node);
        if (videoRef) {
          videoRef.current = node;
        }
      }}
      {...props}
    />
  );
}
