import { dayjs } from "@outschool/time";

import { PageOption } from "../components/PageSelect";

export interface TimeBounds {
  start?: Date;
  end?: Date;
}

const SHORT_MONTH_FORMAT = "MMM";
export function monthOptionsForRange(
  start: Date,
  end?: Date
): PageOption<TimeBounds>[] {
  // If end is in the future, change it to now
  if (!end || dayjs().isBefore(end)) {
    end = new Date();
  }

  if (dayjs(end).subtract(2, "month").isBefore(start)) {
    // If start and end are within 2 months, return a single unbounded page
    return [{ label: "Single page", value: {} }];
  }

  const options: PageOption<TimeBounds>[] = [];

  // First page:
  //    - Can span 1.5 months if we're fewer than 15 days in
  //    - Only has a start bound, will query up to the current time
  let firstPageStart = dayjs(end).startOf("month");
  const firstPageOption = {
    label: firstPageStart.format(SHORT_MONTH_FORMAT),
    value: { start: firstPageStart.toDate() },
  };
  if (dayjs(end).date() < 15) {
    firstPageStart = firstPageStart.subtract(1, "month");
    firstPageOption.label += `/${firstPageStart.format(SHORT_MONTH_FORMAT)}`;
    firstPageOption.value.start = firstPageStart.toDate();
  }
  options.push(firstPageOption);

  // Middle pages:
  //    - Always 1 month long
  //    - Bounded on both sides
  let prevBound = firstPageStart;
  let nextBound = prevBound.subtract(1, "month");
  while (nextBound.isAfter(start)) {
    options.push({
      label: nextBound.format(SHORT_MONTH_FORMAT),
      value: {
        start: nextBound.toDate(),
        end: prevBound.toDate(),
      },
    });
    prevBound = nextBound;
    nextBound = prevBound.subtract(1, "month");
  }

  // Last Page
  //    - Labeled as one month
  //    - Only has an end bound, will query for all posts up to that date
  options.push({
    label: nextBound.format(SHORT_MONTH_FORMAT),
    value: { end: prevBound.toDate() },
  });

  return options;
}
