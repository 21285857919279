import { Box, Button } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import {
  AttachmentGallery,
  TextWithLinks,
} from "@outschool/ui-components-shared";
import React, { FC, useState } from "react";

import { COMMENT_CONTENT_TRUNCATE_LENGTH } from "../../../lib/constants";
import { MessageContent, Sender } from "../types";

export interface ClassPostCommentContentProps {
  sender: Sender;
  messageContent: MessageContent;
  isEducator?: boolean;
  isSender?: boolean;
  trackingLabel?: string;
  trackingMetadata?: {
    [key: string]: string | number;
  };
}

const ClassPostCommentContent: FC<ClassPostCommentContentProps> = ({
  sender,
  messageContent,
  isEducator,
  isSender,
  trackingLabel,
  trackingMetadata,
}) => {
  const { t } = useTranslation(
    "ui-components-classroom\\classroom\\ClassPostComment\\ClassPostCommentContent"
  );
  const [expanded, setExpanded] = useState(
    !messageContent.text ||
      messageContent.text.length < COMMENT_CONTENT_TRUNCATE_LENGTH
  );

  return (
    <Box
      data-test-id="ClassPostCommentContent"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
      }}
    >
      <Box
        sx={{
          padding: 8,
          backgroundColor: isSender ? "rgba(43, 167, 206, 0.10)" : "#F8F8F9",
          borderRadius: "6px",
        }}
      >
        <pre style={{ wordBreak: "break-word", margin: 0 }}>
          <TextWithLinks
            clickableLinks={isEducator}
            text={messageContent.text?.trim() ?? ""}
            maxTextLength={
              expanded ? Infinity : COMMENT_CONTENT_TRUNCATE_LENGTH
            }
            hideEmails={true}
          />{" "}
          {!expanded && (
            <Button variant="link" onClick={() => setExpanded(true)}>
              {t`Show more`}
            </Button>
          )}
        </pre>
      </Box>
      <AttachmentGallery
        video={messageContent.video}
        attachments={messageContent.attachments}
        trackingLabel={trackingLabel}
        trackingMetadata={trackingMetadata}
        senderName={sender.name}
        isPostOwner={false}
      />
    </Box>
  );
};

export default ClassPostCommentContent;
