import type { LearnerProfileQuery, LearnerProfileQueryVariables } from "../..";

import { Box, Button, Switch, Typography } from "@outschool/backpack";
import { ErrorMessage } from "@outschool/ui-components-shared";
import {
  Modal,
  Space,
  useComponentTrackingContext,
} from "@outschool/ui-legacy-component-library";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PropsWithChildren } from "react";

import {
  learnerProfileQuery,
  useSaveLearnerProfileSettingsMutation,
} from "../../hooks/LearnerProfileQuery";
import { locationString } from "./LearnerProfile";

interface SettingsModalProps {
  open: boolean;
  onClose: () => void;
  learner: NonNullable<LearnerProfileQuery["learnerProfile"]>;
  isProfileAgeHidden: boolean;
  setIsProfileAgeHidden: React.Dispatch<React.SetStateAction<boolean>>;
  isProfileLocationHidden: boolean;
  setIsProfileLocationHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SettingsModal({
  open,
  onClose,
  learner,
  isProfileAgeHidden,
  isProfileLocationHidden,
  setIsProfileAgeHidden,
  setIsProfileLocationHidden,
}: SettingsModalProps) {
  const track = useComponentTrackingContext();
  const [errorMessage, setError] = React.useState<string | null>(null);
  const [mutate, { loading, error }] = useSaveLearnerProfileSettingsMutation({
    onCompleted: () => {
      onClose();
    },
  });
  const trackAction = React.useCallback(
    (
      action:
        | "hide_age"
        | "show_age"
        | "hide_location"
        | "show_location"
        | "save"
    ) => {
      track(
        "learner_profile.settings." + action,
        {},
        {
          integrations: { All: false },
        }
      );
    },
    [track]
  );

  const onCloseModal = () => {
    setIsProfileAgeHidden(learner.isProfileAgeHidden);
    setIsProfileLocationHidden(learner.isProfileLocationHidden);
    onClose();
  };

  const onHideAgeToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hideAge = !e.target.checked;
    trackAction(hideAge ? "hide_age" : "show_age");
    setIsProfileAgeHidden(hideAge);
  };

  const onHideLocationToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isHideLocationChecked = !e.target.checked;
    trackAction(isHideLocationChecked ? "hide_location" : "show_location");
    setIsProfileLocationHidden(isHideLocationChecked);
  };

  const handleSave = () => {
    trackAction("save");
    mutate({
      variables: {
        learnerProfileSettingsInput: {
          isProfileAgeHidden,
          isProfileLocationHidden,
        },
      },
      update(store) {
        const data = store.readQuery<
          LearnerProfileQuery,
          LearnerProfileQueryVariables
        >({
          query: learnerProfileQuery,
          variables: { learnerUid: learner.uid },
        });
        if (!data?.learnerProfile) {
          return;
        }
        store.writeQuery({
          query: learnerProfileQuery,
          variables: { learnerUid: learner.uid },
          data: {
            ...data,
            learnerProfile: {
              ...data.learnerProfile,
              isProfileAgeHidden,
              isProfileLocationHidden,
            },
          },
        });
      },
    }).then(
      data => {},
      errorMessage => {
        setError(errorMessage);
      }
    );
  };

  const changedSettings =
    learner.isProfileAgeHidden !== isProfileAgeHidden ||
    learner.isProfileLocationHidden !== isProfileLocationHidden;
  const learnerLocationString =
    learner.location && locationString(learner.location);
  return (
    <Modal
      sx={{
        width: "90%",
        borderRadius: "xlarge",
        padding: "2.4rem",
      }}
      fullBleed={false}
      open={open}
      onClose={onCloseModal}
    >
      <Typography variant="h2">Privacy settings</Typography>
      <Typography
        variant="h5"
        sx={{
          color: "grey.500",
        }}
      >
        Decide what you want to show on your profile.
      </Typography>
      <Space y={24} />
      {learnerLocationString && (
        <SettingWithSwitch>
          <SettingName>{learnerLocationString}</SettingName>
          <Switch
            checked={!isProfileLocationHidden}
            onChange={onHideLocationToggle}
          />
        </SettingWithSwitch>
      )}
      <Space y={16} />
      <SettingWithSwitch>
        <SettingName>Age {learner.age}</SettingName>
        <Switch checked={!isProfileAgeHidden} onChange={onHideAgeToggle} />
      </SettingWithSwitch>
      <ErrorMessage value={error} sx={{ marginTop: 16 }} />
      <Box sx={{ width: "100%", textAlign: "right", marginTop: 24 }}>
        <Button
          variant="contained"
          disabled={!changedSettings || loading}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
}

const SettingWithSwitch: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <Box
    flex
    sx={{
      alignItems: "center",
      justifyContent: "space-between",
      border: "1px solid #E2E2E2",
      borderRadius: 8,
      paddingX: "1em",
      paddingY: "20px",
    }}
  >
    {children}
  </Box>
);

const SettingName: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <Typography
    variant="inherit"
    sx={{
      fontSize: 16,
      fontWeight: "fontWeightBold",
      paddingRight: "0.5em",
    }}
  >
    {children}
  </Typography>
);
