import { Box, Icon, SxProps, Typography } from "@outschool/backpack";
import * as icons from "@outschool/icons";
import { AvatarImage, DEFAULT_AVATAR } from "@outschool/ui-components-shared";
import {
  BoxButton,
  useComponentTrackingContext,
  useImpressionTracking,
} from "@outschool/ui-legacy-component-library";
import { useNodeRef } from "@outschool/ui-utils";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { SystemStyleObject } from "@styled-system/css";
import React, { PropsWithChildren, useState } from "react";

import PickLearnerAvatarModal from "../PickLearnerAvatarModal";

interface BaseProps {
  learner: {
    avatar?: string | null;
    unlockedAvatars?: any;
    name?: string | null;
  };
  onSave?: (avatar: string) => void;
  isEditable?: boolean;
  showEditIcon?: boolean;
  iconSize?: number;
}

interface LearnerProfileIconAvatarProps extends BaseProps {
  sx?: SystemStyleObject;
}

interface LearnerProfileIconProps extends BaseProps {
  avatarSx?: SystemStyleObject;
  sx?: SxProps;
}

function LearnerProfileIconAvatar({
  learner,
  isEditable,
  iconSize,
  onSave,
  sx,
}: LearnerProfileIconAvatarProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [iconNode, setIconNode] = useNodeRef<HTMLDivElement>();
  const trackingPrefix = "learner_profile.avatar";
  const { trackTouch } = useImpressionTracking({
    node: iconNode,
    uniqueId: trackingPrefix,
    trackingLabel: trackingPrefix,
    impressionEventName: trackingPrefix + ".view",
    trackingEventName: trackingPrefix + ".edit",
  });
  const track = useComponentTrackingContext();

  function handleEditClick() {
    if (isEditable) {
      setIsModalOpen(true);
      trackTouch();
    }
  }

  function handleSelect(avatar: string) {
    onSave && onSave(avatar);
    setIsModalOpen(false);
    track(
      `${trackingPrefix}.save`,
      { avatar },
      { integrations: { All: false } }
    );
  }

  function handleCancel() {
    setIsModalOpen(false);
    track(`${trackingPrefix}.cancel`, {}, { integrations: { All: false } });
  }

  const containerSx: SystemStyleObject = {
    backgroundColor: "#EEEEEE",
    borderRadius: "200px",
    ...(iconSize ? { height: iconSize, width: iconSize } : {}),
    border: "2px solid white",
    ...sx,
  };

  return (
    <Box
      flex
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
      ref={setIconNode}
    >
      {isEditable ? (
        <BoxButton
          sx={{
            ...containerSx,
            cursor: "pointer",
            "&:hover > div > div, &:focus > div > div": {
              display: "flex",
            },
          }}
          onClick={handleEditClick}
        >
          <Box
            flex
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              flex
              sx={{
                display: "none",
                position: "absolute",
                backgroundColor: "rgba(46,50,56,.50)",
                height: iconSize,
                width: iconSize,
                borderRadius: 200,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  color: "common.white",
                }}
              >
                Edit
              </Typography>
            </Box>
            <AvatarImage
              avatar={learner.avatar || DEFAULT_AVATAR}
              width={iconSize}
              height={iconSize}
              name={learner.name || ""}
            />
          </Box>
        </BoxButton>
      ) : (
        <Box sx={containerSx}>
          <AvatarImage
            avatar={learner.avatar || DEFAULT_AVATAR}
            width={iconSize}
            height={iconSize}
            name={learner.name || ""}
          />
        </Box>
      )}
      <PickLearnerAvatarModal
        unlockedAvatars={learner.unlockedAvatars}
        open={isModalOpen}
        onSelect={handleSelect}
        onCancel={handleCancel}
      />
    </Box>
  );
}

function LearnerProfileIcon({
  learner,
  iconSize = 75,
  isEditable,
  showEditIcon = true,
  onSave,
  avatarSx,
  sx,
  children,
}: PropsWithChildren<LearnerProfileIconProps>) {
  return (
    <Box
      sx={{
        position: "relative",
        marginTop: -10,
      }}
    >
      <LearnerProfileIconAvatar
        learner={learner}
        isEditable={isEditable}
        iconSize={iconSize}
        onSave={onSave}
        sx={avatarSx}
      />
      <Box
        sx={[
          {
            background:
              "linear-gradient(162.93deg, #FDFCFD 0%, #F0F6F8 104.03%);",
            borderRadius: 10,
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.16)",
            marginTop: `-${iconSize / 2}px`,
            paddingX: "0.5em",
            paddingBottom: "1em",
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Box
          flex
          sx={{
            justifyContent: "flex-end",
            alignItems: "center",
            height: `${iconSize / 2}px`,
          }}
        >
          {showEditIcon && (
            <Icon
              icon={icons.fasPencil}
              sx={{
                color: "primary.700",
                fontSize: "16px",
              }}
            />
          )}
        </Box>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "extra-bold",
            textAlign: "center",
            color: "common.black",
            marginTop: "0.5em",
          }}
          data-test-id="LearnerProfileIcon.learnerName"
        >
          {learner.name}
        </Typography>
        {children}
      </Box>
    </Box>
  );
}

export default LearnerProfileIcon;
