import { Attachment } from "./types";

// based on conversion matrix from Filepicker
// https://www.filestack.com/docs/concepts/transform/
const SUPPORTED_IMAGE_MIME_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/gif",
  "image/heic",
  "image/heif",
  "image/bmp",
  "image/tiff",
  "image/xvg+xml",
  /* TODO: investigate if we should support these mimetypes
  "application/photoshop",
  "application/psd",
  "application/x-photoshop",
  "image/photoshop",
  "image/psd",
  "image/x-psd",
  "image/vnd.adobe.photoshop",
  "application/illustrator",
  "application/postscript",
  */
];

export const isImageAttachment = (attachment: {
  file: { mimetype?: string | null };
}) =>
  attachment &&
  attachment.file &&
  attachment.file.mimetype &&
  SUPPORTED_IMAGE_MIME_TYPES.indexOf(attachment.file.mimetype.toLowerCase()) !==
    -1;

// TODO: actually figure out what videos are supported/unsupported like w/ imgs
export const isVideoAttachment = (attachment: {
  file: { mimetype?: string | null };
}) =>
  attachment &&
  attachment.file &&
  attachment.file.mimetype &&
  attachment.file.mimetype.startsWith("video/");

export const isPreviewable = (attachment: Attachment) =>
  isImageAttachment(attachment) || isVideoAttachment(attachment);
