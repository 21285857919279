import { Box, Typography } from "@outschool/backpack";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

interface ActivityExperienceProps {
  isClub: boolean;
}

const ActivityExperience: React.FC<ActivityExperienceProps> = ({ isClub }) => {
  return (
    <Box
      sx={{
        paddingLeft: "1em",
        fontWeight: "fontWeightBold",
        marginBottom: "1em",
      }}
    >
      {isClub ? (
        <Typography
          variant="inherit"
          sx={{
            color: "primary.700",
          }}
        >
          Group
        </Typography>
      ) : (
        <Typography
          variant="inherit"
          sx={{
            color: "#14855a",
          }}
        >
          Class
        </Typography>
      )}
    </Box>
  );
};

export default ActivityExperience;
