import { useEffect, useRef } from "react";

export default function usePreviousSet<T>(values: T | T[]): Set<T> {
  const valuesArr = values instanceof Array ? values : [values];
  const setRef = useRef<Set<T> | undefined>();

  if (!setRef.current) {
    setRef.current = new Set(valuesArr);
  }

  useEffect(() => valuesArr.forEach(v => setRef.current!.add(v)));
  return setRef.current!;
}
