import { Box, SxProps, Theme } from "@outschool/backpack";
import {
  Container,
  CurvedTopper,
} from "@outschool/ui-legacy-component-library";
import { responsive } from "@outschool/ui-utils";
import React from "react";

interface HeroLayoutProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  sx?: SxProps;
  includeBackgroundGradient?: Boolean;
  children: React.ReactNode;
}

const HeroLayout = React.forwardRef<HTMLDivElement, HeroLayoutProps>(
  ({ header, footer, sx, includeBackgroundGradient, children }, ref) => {
    return (
      <Box
        flex
        sx={[
          {
            minHeight: "100vh",
            flexDirection: "column",
            ...(includeBackgroundGradient
              ? {
                  background:
                    "linear-gradient(162.93deg, #FDFCFD 0%, #F0F6F8 104.03%);",
                }
              : {
                  backgroundColor: "#F8F8F9",
                }),
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        ref={ref}
      >
        {header}
        <Hero />
        <Container
          sx={{
            marginTop: responsive({ default: -300, small: -440 }),
          }}
        >
          {children}
        </Container>
        {footer}
      </Box>
    );
  }
);

// Hero "image" is currently a peacock50 blob shape
const Hero = () => {
  return (
    <>
      <Box
        sx={(theme: Theme) => ({
          height: "340px",

          [theme.breakpoints.up("sm")]: {
            height: "500px",
          },

          backgroundColor: "#F0F3FF",
        })}
      />
      <CurvedTopper color="peacock50" animate={false} inverted={true} />
    </>
  );
};

export default HeroLayout;
