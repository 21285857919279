import { SxProps, Typography } from "@outschool/backpack";
import { useMachineTranslation, useTranslation } from "@outschool/localization";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import React from "react";

export default function UnitNumber({
  unitNumber,
  unitTitle,
  sx = {},
}: {
  unitNumber?: number | null;
  unitTitle?: string | null;
  sx?: SxProps;
}) {
  const { t } = useTranslation(
    "ui-components-classroom\\components\\syllabus\\UnitNumber"
  );
  const { translate } = useMachineTranslation();

  if (!unitNumber) {
    return null;
  }

  return (
    <Typography
      emphasized
      variant="overline"
      sx={{
        display: "block",
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textTransform: "uppercase",
        textAlign: "right",
        color: "secondary.700",
        ...sx,
      }}
    >
      {t("Unit {{unitNumber}}", { unitNumber })}
      {unitTitle && ` - ${translate(unitTitle)}`}
    </Typography>
  );
}
